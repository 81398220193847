import { Support } from "../../src/support";
import { Rotator, puma, PageEnum } from "../../src/rotator";
import { VrManager } from "./vrManager";

export class NoInternetConnection
{
	private static _container: HTMLElement;

	static initialize()
	{
		this._container = puma("<div></div>").appendTo(Rotator.container())[0];
		Rotator.title("INTERNET ASSENTE");

		this.createControls();
	}

	private static createControls()
	{
		//#region Info
		let divInfo = vr.div(this._container, { class: "vrPatientDivSlot" });

		vr.createLabel({
			text: "Connessione a internet limitata o assente",
			colorSettings: { textColor: Rotator.color() },
			cssContainer: "margin-bottom: 5px;",
			align: vr.TextAlignEnum.Center
		}, divInfo);

		vr.createLabel(
			{
				text: "Si è verificato un problema per quanto riguarda la connessione ad internet. <br /><br />Verifica che il dispositivo sia collegato alla rete e che il segnale arrivi in maniera adeguata. <br /><br />Una volta risolto il problema, sarà sufficiente ricaricare la pagina.",
				width: "100%"
			}, divInfo);

		vr.createButton({
			text: "Ricarica",
			width: "100%",
			colorSettings: { textColor: Rotator.color() },
			css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
			cssContainer: "margin-top: 15px;",
			onClick: (e) => 
			{
				if (Support.checkInternetConnection())
				{
					VrManager.notify("Connessione ristabilita", "", { type: vr.NotifierTypeEnum.Success });
					Rotator.goToFirstPage();
				}
				else
					VrManager.notify("Nessuna connessione", "", { type: vr.NotifierTypeEnum.Error });
			}
		}, divInfo);
		//#endregion
	}
}