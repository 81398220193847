import { Support } from "../../src/support";
import { PageEnum, puma, Rotator, SailorTypeEnum } from "../../src/rotator";
import { Profile } from "../../src/privateArea/profile";
import { BirthAddressInfo, ForeignAddress, ItalianAddress } from "../../src/shared/common";
import { IdentityDocumentInfo, Register, RegisterRequest, RegisterResponse, RegisterResult } from "../../src/login/register";
import { LoginParameters } from "../../src/login/login";
import { VrManager } from "../../src/shared/vrManager";

export class SailorRegister
{
	//#region Variables
	private static _container: HTMLElement;
	private static _parameters: SailorRegisterParameters;

	private static _txtName: vr.TextBox;
	private static _txtSurname: vr.TextBox;
	private static _txtFiscalCode: vr.TextBox;
	private static _txtPhoneNumber: vr.TextBox;
	private static _txtEmail: vr.TextBox;
	private static _txtLandline: vr.TextBox;
	private static _dtpBirthdate: vr.DatePicker;
	private static _cmbBirthCity: vr.ComboBox;
	private static _swtItalianForeignBirthCity: vr.Switch;
	private static _cmbForeignBirthCityCountry: vr.ComboBox;
	private static _txtForeignBirthCity: vr.TextBox;
	private static _btgGender: vr.ButtonGroup;

	private static _txtIdentityCard: vr.TextBox;
	private static _dtpIdentityCardEmissionDate: vr.DatePicker;
	private static _txtPassport: vr.TextBox;
	private static _dtpPassportEmissionDate: vr.DatePicker;

	private static _swtItalianForeignResidenceAddress: vr.Switch;
	private static _txtItalyResidenceAddress: vr.TextBox;
	private static _cmbItalyResidenceCity: vr.ComboBox;
	private static _txtItalyResidenceProvince: vr.TextBox;
	private static _cmbItalyResidenceZip: vr.ComboBox;
	private static _txtOutItalyResidenceAddress: vr.TextBox;
	private static _cmbOutItalyResidenceCountry: vr.ComboBox;

	private static _chkDomicile: vr.CheckBox;
	private static _swtItalianForeignDomicileAddress: vr.Switch;
	private static _txtItalyDomicileAddress: vr.TextBox;
	private static _cmbItalyDomicileCity: vr.ComboBox;
	private static _txtItalyDomicileProvince: vr.TextBox;
	private static _cmbItalyDomicileZip: vr.ComboBox;
	private static _txtOutItalyDomicileAddress: vr.TextBox;
	private static _cmbOutItalyDomicileCountry: vr.ComboBox;

	private static _swtSailorMode: vr.Switch;
	private static _txtSerialNumber: vr.TextBox;
	private static _txtCompanyName: vr.TextBox;
	private static _cmbCategories: vr.ComboBox;

	private static _txtPortAuthorityContactName: vr.TextBox;
	private static _txtPortAuthorityContactPhoneNumber: vr.TextBox;
	private static _txtPortAuthorityContactEmail: vr.TextBox;
	private static _txtShipOwnerContactName: vr.TextBox;
	private static _txtShipOwnerContactPhoneNumber: vr.TextBox;
	private static _txtShipOwnerContactEmail: vr.TextBox;

	private static _btnRegister: vr.Button;
	//#endregion

	static initialize(parameters: SailorRegisterParameters)
	{
		this._parameters = parameters;
		this._container = puma("<div class='forward padContainer'></div>").appendTo(Rotator.container())[0];
		Rotator.title("REGISTRATI");

		Support.checkLogin(() => Rotator.goToMainPage(), () => 
		{
			this.createControls();
		});
	}

	static createControls()
	{
		//#region Info
		let divInfo = vr.div(this._container, { class: "vrPatientDivSlot" });
		this._txtName = vr.createTextBox(
			{
				label: "Nome",
				placeholder: "Nome",
				onEnterKey: () => this._btnRegister.click(),
				mode: vr.TextModeEnum.Text,
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, divInfo);
		this._txtName.focus();

		this._txtSurname = vr.createTextBox(
			{
				label: "Cognome",
				placeholder: "Cognome",
				onEnterKey: () => this._btnRegister.click(),
				mode: vr.TextModeEnum.Text,
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, divInfo);

		this._txtPhoneNumber = vr.createTextBox(
			{
				label: "Cellulare",
				placeholder: "Cellulare",
				onEnterKey: () => this._btnRegister.click(),
				mode: vr.TextModeEnum.Text,
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, divInfo);

		this._txtEmail = vr.createTextBox(
			{
				label: "Email",
				placeholder: "Email",
				onEnterKey: () => this._btnRegister.click(),
				mode: vr.TextModeEnum.Text,
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, divInfo);

		this._txtLandline = vr.createTextBox(
			{
				label: "Telefono fisso",
				placeholder: "Telefono fisso",
				mode: vr.TextModeEnum.Text,
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, divInfo);

		this._btgGender = vr.createButtonGroup(
			{
				label: "Sesso",
				width: "100%",
				labelSettings: { colorSettings: { textColor: Rotator.color() } },
				cssContainer: "margin-bottom: 10px;",
				//selectedColor: Rotator.color(),
				items:
					[
						{ text: "Maschio", value: "M" },
						{ text: "Femmina", value: "F" }
					],
				selectionMode: vr.SelectionModeEnum.Single
			}, divInfo);
		//#endregion

		//#region Documents identity
		let divDocumentIdentityCard = vr.div(this._container, { class: "vrPatientDivSlot" });
		this._txtIdentityCard = vr.createTextBox(
			{
				label: "Carta d'identità",
				placeholder: "Carta d'identità",
				mode: vr.TextModeEnum.Text,
				inputMode: vr.TextTransformModeEnum.Uppercase,
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, divDocumentIdentityCard);

		this._dtpIdentityCardEmissionDate = vr.createDatePicker(
			{
				label: "Data di emissione",
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, divDocumentIdentityCard);

		let divDocumentPassport = vr.div(this._container, { class: "vrPatientDivSlot" });
		this._txtPassport = vr.createTextBox(
			{
				label: "Passaporto",
				placeholder: "Passaporto",
				mode: vr.TextModeEnum.Text,
				inputMode: vr.TextTransformModeEnum.Uppercase,
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, divDocumentPassport);

		this._dtpPassportEmissionDate = vr.createDatePicker(
			{
				label: "Data di emissione",
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, divDocumentPassport);
		//#endregion

		//#region BirthCity
		let divBirthInfo = vr.div(this._container, { class: "vrPatientDivSlot", css: "margin-top: 10px;" });
		this._dtpBirthdate = vr.createDatePicker(
			{
				label: "Data di nascita",
				width: "100%",
				labelSettings: { colorSettings: { textColor: Rotator.color() } },
				cssContainer: "margin-bottom: 10px;",
			}, divBirthInfo);

		let divBirthCity = vr.div(divBirthInfo);
		vr.createLabel({
			text: "Città di nascita",
			colorSettings: { textColor: Rotator.color() },
		}, divBirthCity);

		this._swtItalianForeignBirthCity = vr.createSwitch({
			labelOff: "Italia",
			labelOn: "Fuori Italia",
			width: "100%",
			cssContainer: "margin-bottom: 5px;",
			onChange: (e) => 
			{
				if (e.checked)
				{
					puma(divItalyBirthCity).hide();
					puma(divForeignBirthCity).show();
				}
				else
				{
					puma(divItalyBirthCity).show();
					puma(divForeignBirthCity).hide();
				}
			}
		}, divBirthCity);

		let divItalyBirthCity = vr.div(divBirthCity);
		this._cmbBirthCity = vr.createComboBox(
			{
				label: "Città di nascita",
				width: "100%",
				cssContainer: "margin-right: 10px;",
				webService: {
					authKey: "h]K)x@)k2j.@{=7D",
					method: Rotator.patientUrl() + "/api/UtilityWebApi/GetAllCities",
					itemsPropertyName: "cities",
					typedTextPropertyName: "searchText",
					parameters: () =>
					{
						return {
							onlyActive: false
						}
					}
				}
			}, divItalyBirthCity);

		let divForeignBirthCity = vr.div(divBirthCity, { css: "display: none;" });
		this._cmbForeignBirthCityCountry = vr.createComboBox(
			{
				label: "Nazione",
				width: "100%"
			}, divForeignBirthCity);

		this._txtForeignBirthCity = vr.createTextBox(
			{
				label: "Città",
				width: "100%",
				cssContainer: "margin-right: 10px;"
			}, divForeignBirthCity);
		//#endregion

		//#region Fiscal code
		let divFiscalCode = vr.div(this._container, { class: "vrPatientDivSlot" });
		this._txtFiscalCode = vr.createTextBox({
			label: "Codice fiscale",
			width: "100%",
			labelSettings: { colorSettings: { textColor: Rotator.color() } },
			inputMode: vr.TextTransformModeEnum.Uppercase,
			onBlur: (e) =>
			{
				Profile.checkFiscalCode(this._txtFiscalCode.value<string>(), this._cmbForeignBirthCityCountry.value<number>(), (e) =>
				{
					if (!e.isValid)
						this._txtFiscalCode.error();
					else
						this._txtFiscalCode.hideError();
				});
			}
		}, divFiscalCode);

		let btnCalcFiscalCode = vr.createButton({
			text: "Calcola codice",
			width: "100%",
			cssContainer: "margin-top: 10px;",
			colorSettings: { textColor: Rotator.color() },
			css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
			visible: false,
			onClick: (e) =>
			{
				let birthAddressInfo = null;
				if (!this._swtItalianForeignBirthCity.checked())
				{
					//#region Italian
					if (this._cmbBirthCity.value() != null && this._cmbBirthCity.value() != "")
					{
						birthAddressInfo = new BirthAddressInfo();
						birthAddressInfo.istatCode = this._cmbBirthCity.value<string>()!;
					}
					//#endregion
				}
				else
				{
					//#region Foreign
					let countrySelected = this._cmbForeignBirthCityCountry.getSelectedItem<any>();
					if (countrySelected != null)
					{
						birthAddressInfo = new BirthAddressInfo();
						birthAddressInfo.countryCode = this._cmbForeignBirthCityCountry.value<string>()!;
						birthAddressInfo.address = this._txtForeignBirthCity.value();
					}
					//#endregion
				}

				Profile.computeFiscalCode(this._txtName.value(), this._txtSurname.value(), this._btgGender.getSelectedValues()[0], this._dtpBirthdate.value()!, birthAddressInfo,
					(e) =>
					{
						if (e.invalidFieldsMessage != null && e.invalidFieldsMessage != "")
							VrManager.notify(e.invalidFieldsMessage, "", { type: vr.NotifierTypeEnum.Error });
						else
						{
							this._txtFiscalCode.value(e.fiscalCode);
							this._txtFiscalCode.hideError();
						}
					});
			}
		}, divFiscalCode);

		if (this._txtName.visible() && this._txtSurname.visible() && this._btgGender.visible() && this._dtpBirthdate.visible() && puma(divBirthCity).is(":visible"))
			btnCalcFiscalCode.show();
		//#endregion

		//#region Address Residence
		let divResidence = vr.div(this._container, { class: "vrPatientDivSlot", css: "margin-top: 10px;" });

		vr.createLabel({
			text: "Indirizzo di residenza",
			colorSettings: { textColor: Rotator.color() },
		}, divResidence);

		this._swtItalianForeignResidenceAddress = vr.createSwitch({
			labelOff: "Italia",
			labelOn: "Fuori Italia",
			width: "100%",
			cssContainer: "margin-bottom: 5px;",
			onChange: (e) => 
			{
				if (e.checked)
				{
					puma(divItalyResidence).hide();
					puma(divOutItalyResidence).show();
				}
				else
				{
					puma(divItalyResidence).show();
					puma(divOutItalyResidence).hide();
				}
			}
		}, divResidence);

		let divItalyResidence = vr.div(divResidence);
		this._txtItalyResidenceAddress = vr.createTextBox(
			{
				label: "Via",
				width: "100%"
			}, divItalyResidence);

		this._cmbItalyResidenceCity = vr.createComboBox(
			{
				label: "Comune",
				width: "100%",
				cssContainer: "margin-right: 10px;",
				webService: {
					authKey: "h]K)x@)k2j.@{=7D",
					method: Rotator.patientUrl() + "/api/UtilityWebApi/GetAllCities",
					itemsPropertyName: "cities",
					typedTextPropertyName: "searchText",
					parameters: () =>
					{
						return {
							onlyActive: true
						}
					}
				},
				onAfterChange: (e) =>
				{
					Register.calcProvinceAndZipByCity(e.value, (e) =>
					{
						this._txtItalyResidenceProvince.clear();
						this._cmbItalyResidenceZip.clearItems();

						this._txtItalyResidenceProvince.value(e.city.provinceAbbreviation);
						this._cmbItalyResidenceZip.items(e.city.zipCodes.map(k => { return { text: k.zipCode, value: k.zipCode } }));
						this._cmbItalyResidenceZip.value(e.city.zipCodes.first().zipCode);
					});
				}
			}, divItalyResidence);

		this._txtItalyResidenceProvince = vr.createTextBox(
			{
				label: "Provincia",
				enable: false,
				align: vr.TextAlignEnum.Center,
				width: "Calc(50% - 10px)",
				cssContainer: "margin-right: 10px;"
			}, divItalyResidence);

		this._cmbItalyResidenceZip = vr.createComboBox(
			{
				label: "CAP",
				placeholder: "",
				width: "50%"
			}, divItalyResidence);

		let divOutItalyResidence = vr.div(divResidence, { css: "display: none;" });
		this._txtOutItalyResidenceAddress = vr.createTextBox(
			{
				label: "Indirizzo",
				width: "100%",
				cssContainer: "margin-right: 10px;"
			}, divOutItalyResidence);

		this._cmbOutItalyResidenceCountry = vr.createComboBox(
			{
				label: "Nazione",
				width: "100%"
			}, divOutItalyResidence);
		//#endregion

		//#region Address Domicile
		let divDomicile = vr.div(this._container, { class: "vrPatientDivSlot", css: "margin-top: 10px;" });

		vr.createLabel({
			text: "Indirizzo di domicilio",
			colorSettings: { textColor: Rotator.color() },
		}, divDomicile);

		this._chkDomicile = vr.createCheckBox({
			text: "Il domicilio coincide con la residenza",
			cssContainer: "margin-bottom: 10px;",
			checked: true,
			onCheck: (e) =>
			{
				if (e.checked)
					puma(divDomicileInternal).hide();
				else
					puma(divDomicileInternal).show();
			}
		}, divDomicile);

		let divDomicileInternal = vr.div(divDomicile, { css: "display: none;" });
		this._swtItalianForeignDomicileAddress = vr.createSwitch({
			labelOff: "Italia",
			labelOn: "Fuori Italia",
			width: "100%",
			cssContainer: "margin-bottom: 5px;",
			onChange: (e) => 
			{
				if (e.checked)
				{
					puma(divItalyDomicile).hide();
					puma(divOutItalyDomicile).show();
				}
				else
				{
					puma(divItalyDomicile).show();
					puma(divOutItalyDomicile).hide();
				}
			}
		}, divDomicileInternal);

		let divItalyDomicile = vr.div(divDomicileInternal);
		this._txtItalyDomicileAddress = vr.createTextBox(
			{
				label: "Via",
				width: "100%"
			}, divItalyDomicile);

		this._cmbItalyDomicileCity = vr.createComboBox(
			{
				label: "Comune",
				width: "100%",
				cssContainer: "margin-right: 10px;",
				webService: {
					authKey: "h]K)x@)k2j.@{=7D",
					method: Rotator.patientUrl() + "/api/UtilityWebApi/GetAllCities",
					itemsPropertyName: "cities",
					typedTextPropertyName: "searchText",
					parameters: () =>
					{
						return {
							onlyActive: true
						}
					}
				},
				onAfterChange: (e) =>
				{
					Register.calcProvinceAndZipByCity(e.value, (e) =>
					{
						this._txtItalyDomicileProvince.clear();
						this._cmbItalyDomicileZip.clearItems();

						this._txtItalyDomicileProvince.value(e.city.provinceAbbreviation);
						this._cmbItalyDomicileZip.items(e.city.zipCodes.map(k => { return { text: k.zipCode, value: k.zipCode } }));
						this._cmbItalyDomicileZip.value(e.city.zipCodes.first().zipCode);
					});
				}
			}, divItalyDomicile);

		this._txtItalyDomicileProvince = vr.createTextBox(
			{
				label: "Provincia",
				enable: false,
				align: vr.TextAlignEnum.Center,
				width: "Calc(50% - 10px)",
				cssContainer: "margin-right: 10px;"
			}, divItalyDomicile);

		this._cmbItalyDomicileZip = vr.createComboBox(
			{
				label: "CAP",
				placeholder: "",
				width: "50%"
			}, divItalyDomicile);

		let divOutItalyDomicile = vr.div(divDomicileInternal, { css: "display: none;" });
		this._txtOutItalyDomicileAddress = vr.createTextBox(
			{
				label: "Indirizzo",
				width: "100%",
				cssContainer: "margin-right: 10px;"
			}, divOutItalyDomicile);

		this._cmbOutItalyDomicileCountry = vr.createComboBox(
			{
				label: "Nazione",
				width: "100%"
			}, divOutItalyDomicile);
		//#endregion

		//#region Sailor info
		let divSailor = vr.div(this._container, { class: "vrPatientDivSlot", css: "margin-top: 10px;" });
		if (Rotator.sailorType() == SailorTypeEnum.Sasn)
		{
			this._swtSailorMode = vr.createSwitch({
				labelOff: "Marittimo",
				labelOn: "Aeronavigante",
				cssContainer: "margin-bottom: 10px;"
			}, divSailor);

			this._txtSerialNumber = vr.createTextBox({
				label: "Matricola",
				validation: {
					maxLength: 10,
					minLength: 3
				},
				mode: vr.TextModeEnum.Text,
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, divSailor);

			this._txtCompanyName = vr.createTextBox({
				label: "Compagnia",
				mode: vr.TextModeEnum.Text,
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, divSailor);

			this._cmbCategories = vr.createComboBox({
				label: "Categoria",
				mode: vr.ComboBoxTypeEnum.DropDown,
				nullable: true,
				width: "100%",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, divSailor);
		}
		else if (Rotator.sailorType() == SailorTypeEnum.Usmaf)
		{
			this._txtCompanyName = vr.createTextBox({
				label: "Ditta",
				mode: vr.TextModeEnum.Text,
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, divSailor);

			//#region Port Authority
			let groupBoxPortAuthorityContacts = vr.createGroupBox({
				title: "Contatti Capitaneria",
			}, divSailor);

			this._txtPortAuthorityContactName = vr.createTextBox({
				label: "Città/Codice fiscale",
				mode: vr.TextModeEnum.Text,
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, groupBoxPortAuthorityContacts.content());

			this._txtPortAuthorityContactPhoneNumber = vr.createTextBox({
				label: "Telefono",
				mode: vr.TextModeEnum.Text,
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, groupBoxPortAuthorityContacts.content());

			this._txtPortAuthorityContactEmail = vr.createTextBox({
				label: "Email",
				mode: vr.TextModeEnum.Text,
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, groupBoxPortAuthorityContacts.content());
			//#endregion

			//#region Ship owner
			let groupBoxShipOwnerContacts = vr.createGroupBox({
				title: "Contatti Armatore",
				cssContainer: "margin-top: 10px;"
			}, divSailor);

			this._txtShipOwnerContactName = vr.createTextBox({
				label: "Società/Codice fiscale",
				mode: vr.TextModeEnum.Text,
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, groupBoxShipOwnerContacts.content());

			this._txtShipOwnerContactPhoneNumber = vr.createTextBox({
				label: "Telefono",
				mode: vr.TextModeEnum.Text,
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, groupBoxShipOwnerContacts.content());

			this._txtShipOwnerContactEmail = vr.createTextBox({
				label: "Email",
				mode: vr.TextModeEnum.Text,
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, groupBoxShipOwnerContacts.content());
			//#endregion
		}
		//#endregion

		this._btnRegister = vr.createButton({
			text: "Registrati",
			colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
			css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
			cssContainer: "margin-bottom: 30px;",
			width: "100%",
			onClick: (e) =>
			{
				//#region Check
				let error = false;
				if (this._txtName.isEmpty())
				{
					this._txtName.error("Nome obbligatorio", vr.ErrorModeEnum.Solid);
					error = true;
				}

				if (this._txtSurname.isEmpty())
				{
					this._txtSurname.error("Cognome obbligatorio", vr.ErrorModeEnum.Solid);
					error = true;
				}

				if (this._txtPhoneNumber.isEmpty())
				{
					this._txtPhoneNumber.error("Cellulare obbligatorio", vr.ErrorModeEnum.Solid);
					error = true;
				}

				if (error)
					return;
				//#endregion

				this.registerSailor();
			}
		}, this._container);

		Register.getAllCountries((e: any) =>
		{
			this._cmbOutItalyResidenceCountry.items(e.countries);
			this._cmbOutItalyDomicileCountry.items(e.countries);
			this._cmbForeignBirthCityCountry.items(e.countries);
		});

		Register.getAllCategories((e: any) =>
		{
			this._cmbCategories.items(e.categories);
		});
	}

	private static registerSailor()
	{
		let request = new RegisterRequest();
		request.webApiBirthDate = Date.toWebApiDateTime(this._dtpBirthdate.value());
		request.surname = this._txtSurname.value();
		request.name = this._txtName.value();
		request.phone = this._txtPhoneNumber.value();
		request.mail = this._txtEmail.value();
		request.fiscalCode = this._txtFiscalCode.value();
		request.landline = this._txtLandline.value();
		request.gender = this._btgGender.getSelectedValues()[0];
		request.privacy = true;
		request.privacyCommercial = true;

		let identityCard = new IdentityDocumentInfo();
		identityCard.number = this._txtIdentityCard.value();
		identityCard.emissionDate = Date.toWebApiDateTime(this._dtpIdentityCardEmissionDate.value());
		request.identityCard = identityCard;

		let passport = new IdentityDocumentInfo();
		passport.number = this._txtPassport.value();
		passport.emissionDate = Date.toWebApiDateTime(this._dtpPassportEmissionDate.value());
		request.passport = passport;

		request.birthAddressInfo = new BirthAddressInfo();
		if (!this._swtItalianForeignBirthCity.checked())
		{
			//#region Italian
			request.birthAddressInfo.istatCode = this._cmbBirthCity.value<string>()!;
			//#endregion
		}
		else
		{
			//#region Foreign
			let countrySelected = this._cmbForeignBirthCityCountry.getSelectedItem<any>();
			if (countrySelected != null)
				request.birthAddressInfo.countryCode = this._cmbForeignBirthCityCountry.value<string>()!;

			request.birthAddressInfo.address = this._txtForeignBirthCity.value();
			//#endregion
		}

		//#region Residence
		if (!this._swtItalianForeignResidenceAddress.checked())
		{
			//#region Italian address
			let italianAddress = new ItalianAddress();
			italianAddress.street = this._txtItalyResidenceAddress.value();
			italianAddress.istatCode = this._cmbItalyResidenceCity.value<string>()!;
			italianAddress.zipCode = this._cmbItalyResidenceZip.text();
			request.italianAddress = italianAddress;
			//#endregion
		}
		else 
		{
			//#region Foreign address
			let foreignAddress = new ForeignAddress();
			foreignAddress.address = this._txtOutItalyResidenceAddress.value();

			let countrySelected = this._cmbOutItalyResidenceCountry.getSelectedItem<any>();
			if (countrySelected != null)
				foreignAddress.countryCode = this._cmbOutItalyResidenceCountry.value<string>()!;

			request.foreignAddress = foreignAddress;
			//#endregion
		}
		//#endregion

		//#region Domicile
		if (this._chkDomicile.checked())
		{
			request.italianAddressDomicile = request.italianAddress;
			request.foreignAddressDomicile = request.foreignAddress;
		}
		else
		{
			if (!this._swtItalianForeignDomicileAddress.checked())
			{
				//#region Italian address
				let italianAddress = new ItalianAddress();
				italianAddress.street = this._txtItalyDomicileAddress.value();
				italianAddress.istatCode = this._cmbItalyDomicileCity.value<string>()!;
				italianAddress.zipCode = this._cmbItalyDomicileZip.text();
				request.italianAddressDomicile = italianAddress;
				//#endregion
			}
			else 
			{
				//#region Foreign address
				let foreignAddress = new ForeignAddress();
				foreignAddress.address = this._txtOutItalyDomicileAddress.value();

				let countrySelected = this._cmbOutItalyDomicileCountry.getSelectedItem<any>();
				if (countrySelected != null)
					foreignAddress.countryCode = this._cmbOutItalyDomicileCountry.value<string>()!;

				request.foreignAddressDomicile = foreignAddress;
				//#endregion
			}
		}
		//#endregion

		//#region Sailor
		request.isSailor = Rotator.sailor();
		request.sailorType = Rotator.sailorType();

		if (Rotator.sailorType() == SailorTypeEnum.Sasn)
		{
			request.sailorSasnMode = (this._swtSailorMode.checked()) ? SailorSasnModeEnum.AirShipper : SailorSasnModeEnum.Marine;
			request.sailorSasnSerialNumber = this._txtSerialNumber.value();
			request.sailorSasnCompanyName = this._txtCompanyName.value();
			request.categoryId = Number(this._cmbCategories.value());
		}
		else if (Rotator.sailorType() == SailorTypeEnum.Usmaf)
		{
			request.sailorUsmafCompanyName = this._txtCompanyName.value();

			request.sailorUsmafPortAuthorityContactName = this._txtPortAuthorityContactName.value();
			request.sailorUsmafPortAuthorityContactPhoneNumber = this._txtPortAuthorityContactPhoneNumber.value();
			request.sailorUsmafPortAuthorityContactEmail = this._txtPortAuthorityContactEmail.value();

			request.sailorUsmafShipOwnerContactName = this._txtShipOwnerContactName.value();
			request.sailorUsmafShipOwnerContactPhoneNumber = this._txtShipOwnerContactPhoneNumber.value();
			request.sailorUsmafShipOwnerContactEmail = this._txtShipOwnerContactEmail.value();
		}
		//#endregion

		request.throwException = false;
		request.createUserAccount = true;
		request.call(
			{
				success: (response: RegisterResponse) => 
				{
					if (response.registerResult == RegisterResult.PatientCreated || response.registerResult == RegisterResult.PatientFound)
					{
						let loginParameters = new LoginParameters();
						loginParameters.fromRegister = true;
						loginParameters.username = response.username;
						Rotator.page(PageEnum.Login, loginParameters);
					}
					else
						Register.manageRegisterResult(response.registerResult, response.ExceptionMessage);
				},
				loader: Rotator.container()
			});
	}
}

//#region Classes
class SailorRegisterParameters
{

}

export enum SailorSasnModeEnum
{
	Marine = 10,
	AirShipper = 20
}
//#endregion