import { WebApiRequestBase, WebApiResponseBase } from "../shared/webApi";
import { PageEnum, puma, Rotator } from "../rotator";
import { Support } from "../support";
import { FileItem, FileTypeEnum, ReportItem } from "../shared/common";

export class FileList
{
	private static _container: HTMLElement;
	private static _files: FileItem[];
	private static _divFiles: HTMLElement;

	static initialize()
	{
		this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
		Rotator.title("FILE");

		Support.checkLogin(() => this.getFiles((e) => 
		{
			if (Rotator.configuration().appUploadFiles)
			{
				vr.createUpload({
					uploadButton: "Carica file...",
					fileList: false,
					progressBar: true,
					multiple: true,
					dropAreaText: !Rotator.isMobile(),
					cssContainer: "margin-bottom: 5px;",
					webApiSettings:
					{
						url: Rotator.patientUrl() + "/api/PrivateAreaWebApi/UploadFile",
						parameters: (e) =>
						{
							return [
								{ key: "sessionId", value: Rotator.setCookieSession() },
								{ key: "mimeType", value: e.file.type },
								{ key: "fileName", value: e.file.name }
							]
						}
					},
					onSuccess: (e) =>
					{
						this.getFiles((e) => 
						{
							this._files = e.files;
							this.drawFiles();
						});
					}
				}, this._container);
			}

			this._files = e.files;
			this._divFiles = vr.div(this._container);
			this.drawFiles();
		}), null, () => Rotator.page(PageEnum.FileList));
	}

	private static drawFiles()
	{
		puma(this._divFiles).empty();
		if (this._files.length == 0)
		{
			let divNoRecords = vr.div(this._divFiles, { class: "vrPatientDivSlot", css: "margin-top: 5px;" });
			vr.createLabel({
				text: "Non sono presenti file",
				width: "100%",
				align: vr.TextAlignEnum.Center
			}, divNoRecords);
			return;
		}

		for (let file of this._files)
			this.drawFileDetail(file, this._divFiles);
	}

	static drawFileDetail(file: FileItem, container: HTMLElement): HTMLElement
	{
		let divFile = vr.div(container, { class: "vrPatientDivSlot" });

		//#region Icon/Info
		let divIconInfo = vr.div(divFile, { css: "display: flex;" });

		let iconInfo = this.getFileInfo(file.mimeType);
		vr.icon(iconInfo.icon, divIconInfo, { css: "font-size: 4em; margin-top: 6px; margin-right: 10px; color: " + iconInfo.color + ";" });

		let divInfo = vr.div(divIconInfo, { css: "text-align: left; width: 100%;" });
		vr.createLabel({
			text: file.fileName,
			bold: true,
			css: "text-overflow: ellipsis; overflow: hidden; width: 100%;",
			cssContainer: "width: Calc(100% - 55px);"
		}, divInfo);

		if (file.uploadDate != null)
		{
			let uploadedByIcon = (file.uploadedByPatient) ? vr.IconClassicLight.Upload : vr.IconClassicLight.Download;
			vr.createLabel(
				{
					text: "<i style='font-size: 0.9em;' class='" + uploadedByIcon + "'></i> - " + new Date(file.uploadDate).toItalyShortString() + " - " + new Date(file.uploadDate).toTimeString(),
					noBr: true,
					width: "100%"
				}, divInfo);
		}

		if (Rotator.configuration().appShowPlantInFiles)
		{
			vr.createLabel(
				{
					text: file.plantCompanyName,
					noBr: true,
					width: "100%"
				}, divInfo);
		}

		vr.createLabel({
			text: vrshared.UtilityManager().formatStorageSize(file.size),
			width: "100%"
		}, divInfo);

		if (file.representerOfName != null && file.representerOfName.length > 0)
		{
			vr.createLabel(
				{
					text: "File di " + file.representerOfName,
					noBr: true,
					width: "100%"
				}, divInfo);
		}
		//#endregion

		//#region Buttons
		let divButtons = vr.div(divFile, { css: "margin-top: 10px; display: flex; justify-content: space-between;" });
		let downloadButton = vr.createButton({
			text: "Scarica",
			colorSettings: { textColor: Rotator.color() },
			css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
			cssContainer: "width: 100%;",
			onClick: (e) => Support.downloadFile(file.id, file.fileName, file.mimeType as FileTypeEnum)
		}, divButtons);

		let showButton = vr.createButton({
			text: "Visualizza",
			colorSettings: { textColor: Rotator.color() },
			css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
			cssContainer: "width: 100%; margin-left: 5px;",
			onClick: (e) =>
			{
				Support.getFileData(file.id, (e) =>
				{
					if (file.mimeType == FileTypeEnum.Pdf)
					{
						pdfViewer.content(e.base64);
						pdfViewer.open();
					}
					else
						vrshared.UtilityManager().previewFile(e.base64, file.mimeType as FileTypeEnum);
				});
			}
		}, divButtons);

		let pdfViewer = vr.createPdfViewer({
			popup: { maximize: true },
			base64: true
		});

		if (Rotator.isMobile())
		{
			downloadButton.show();
			showButton.hide();
		}
		else
		{
			downloadButton.show();
			showButton.show();
		}
		//#endregion

		return divFile;
	}

	static getFiles(callback?: (e: GetFilesResponse) => void)
	{
		let request = new GetFilesRequest();
		request.plantIdList = [Rotator.configuration().plant.id];
		request.call(
			{
				success: (response: GetFilesResponse) =>
				{
					if (callback != null)
						callback(response);
				},
				mode: vrshared.WebApiModeEnum.Async,
				loader: Rotator.container()
			});
	}

	static getFileInfo(mimeType: string)
	{
		//#region Icon
		let icon = vr.IconClassicLight.FilePdf;
		let color = "coral";
		switch (mimeType)
		{
			case "application/pdf":
				{
					icon = vr.IconClassicLight.FilePdf;
					color = "coral";
				}
				break;
			case "image/png":
			case "image/jpeg":
				{
					icon = vr.IconClassicLight.FileImage;
					color = "#adbd29";
				}
				break;
			case "application/msword":
			case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
				{
					icon = vr.IconClassicLight.FileWord;
					color = "#01A6F0";
				}
				break;
			case "application/vnd.ms-excel":
			case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
				{
					icon = vr.IconClassicLight.FileExcel;
					color = "#1D6F42";
				}
				break;
			case "text/plain":
				{
					icon = vr.IconClassicLight.FileLines;
					color = "#bdbdbd";
				}
				break;
			case "application/zip":
			case "application/x-zip-compressed":
			case "application/x-compressed":
			case "multipart/x-zip":
				{
					icon = vr.IconClassicLight.FileZipper;
					color = "yellow";
				}
				break;
			case "video/mp4":
			case "video/3gpp":
			case "video/x-flv":
			case "video/quicktime":
			case "video/x-msvideo":
			case "video/x-ms-wmv":
				{
					icon = vr.IconClassicLight.FileVideo;
					color = "purple";
				}
				break;
		}
		return { icon: icon, color: color };
	}
}

//#region Classes
class GetFilesRequest extends WebApiRequestBase
{
	public method: string = "/api/PrivateAreaWebApi/GetFiles";
	public plantIdList: number[];
}

class GetFilesResponse extends WebApiResponseBase
{
	public files: FileItem[];
}
//#endregion