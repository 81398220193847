import { Rotator, puma } from "../rotator";
import { Booking, StepEnum } from "./booking";

export class FromList
{
    private static _container: HTMLElement;

    static initialize()
    {
        this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
        Rotator.title("PRENOTA");
        this.createControls();
    }

    static createControls()
    {
        Booking.createStep(StepEnum.From, this._container, true, () => Rotator.back());

        let divCalendar = vr.div(this._container, { css: "text-align: center;" });
        vr.createCalendar(
            {
                width: "100%",
                selectedColor: Rotator.color(),
                value: (Rotator.bookingParameters().from != null) ? new Date(Rotator.bookingParameters().from) : new Date(),
                availableFrom: new Date(),
                onAfterChange: (e) =>
                {
                    Rotator.bookingParameters().from = e.value!;
                    Rotator.back();
                }
            }, divCalendar);
    }
}