import { Rotator, puma } from "../rotator";
import { SelectionListControl } from "./selectionListControl";
import { Booking, StepEnum } from "./booking";
import { WebApiRequestBase, WebApiResponseBase } from "../../src/shared/webApi";
import { ResourceResponse } from "../../src/shared/common";

export class DoctorList
{
    private static _container: HTMLElement;

    static initialize()
    {
        this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
        Rotator.title("PRENOTA");
        this.createControls();
    }

    static createControls()
    {
        Booking.createStep(StepEnum.Doctor, this._container, true, () => Rotator.back());

        let selectionListControl = new SelectionListControl(
            {
                container: this._container,
                rebind:
                {
                    authKey: "10(P9m+U3aM@10tt-Oeo",
                    method: "/api/BookingWebApi/GetResources",
                    itemsPropertyName: "resourcesList",
                    rebindAtStartup: true,
                    parameters: () =>
                    {
                        let parameters: any = {};
                        parameters.plantIdList = [Rotator.configuration().plant.id];

                        if (Rotator.bookingParameters().activityType != null)
                            parameters.activityTypeId = Rotator.bookingParameters().activityType.id;

                        if (Rotator.bookingParameters().workplace != null)
                            parameters.workplaceIdList = [Rotator.bookingParameters().workplace!.id];

                        parameters.resourceId = Rotator.webComponentParameters()[0].specificDoctorId;
                        parameters.resourceIdList = Rotator.webComponentParameters()[0].specificDoctorIdList;

                        return parameters;
                    },
                    callback: () => 
                    {
                        if (Rotator.bookingParameters().resource != null)
                            selectionListControl.select(Rotator.bookingParameters().resource.id);
                    }
                },
                onSelect: (e) =>
                {
                    Rotator.bookingParameters().resource = (e.dataItem.id == 0) ? null : e.dataItem;
                    Rotator.back();
                }
            }
        );
    }

    static getResources(callback?: (e: GetResourcesResponse) => void, loader: HTMLElement | boolean = false)
    {
        let workplaceIdList = (Rotator.bookingParameters().workplace != null) ? [Rotator.bookingParameters().workplace!.id] : null;
        let request = new GetResourcesRequest();
        request.resourceId = Rotator.webComponentParameters()[0].specificDoctorId;
        request.workplaceIdList = workplaceIdList;

        if (Rotator.webComponentParameters()[0].specificDoctorId == null || Rotator.webComponentParameters()[0].specificDoctorId == 0)
        {
            if (Rotator.bookingParameters().activityType != null)
                request.activityTypeId = Rotator.bookingParameters().activityType.id;
        }

        request.call(
            {
                success: (response: GetResourcesResponse) =>
                {
                    Rotator.bookingParameters().resourceList = response.resourcesList;

                    if (Rotator.webComponentParameters()[0].specificDoctorId != null)
                    {
                        let resource = Rotator.bookingParameters().resourceList.filter(k => k.id == Rotator.webComponentParameters()[0].specificDoctorId)[0];
                        Rotator.bookingParameters().resource = resource;
                    }

                    if (callback != null)
                        callback(response);
                },
                mode: vrshared.WebApiModeEnum.Async,
                loader: loader,
                loaderDelay: 200
            });
    }
}

//#region Classes
class GetResourcesRequest extends WebApiRequestBase
{
    public method: string = "/api/BookingWebApi/GetResources";

    public activityTypeId: number;
    public workplaceIdList: number[] | null;
    public includeActivitiesInResponse: boolean;
    public onlyBookableOnline?: boolean;
    public resourceId?: number;
}

class GetResourcesResponse extends WebApiResponseBase
{
    public resourcesList: ResourceResponse[];
}
//#endregion