import { WebApiRequestBase, WebApiResponseBase } from "../../src/shared/webApi";
import { PageEnum, puma, Rotator } from "../../src/rotator";
import { Support } from "../../src/support";
import { DocumentItem, FileTypeEnum } from "../../src/shared/common";
import { PaymentData, PaymentDataParameters } from "./paymentData";
import { VrManager } from "../../src/shared/vrManager";

export class DocumentList
{
	private static _container: HTMLElement;
	private static _documents: DocumentItem[];
	private static _divDocuments: HTMLElement;

	static initialize()
	{
		this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
		Rotator.title("FATTURE");

		Support.checkLogin(() => this.getDocuments((e) => 
		{
			this._documents = e.documents;
			this._divDocuments = vr.div(this._container);
			this.drawDocuments();
		}), null, () => Rotator.page(PageEnum.DocumentList));
	}

	private static drawDocuments()
	{
		puma(this._divDocuments).empty();
		if (this._documents.length == 0)
		{
			let divNoRecords = vr.div(this._divDocuments, { class: "vrPatientDivSlot" });
			vr.createLabel({
				text: "Non sono presenti fatture",
				width: "100%",
				align: vr.TextAlignEnum.Center
			}, divNoRecords);
			return;
		}

		for (let document of this._documents)
			this.drawDocumentDetail(document, this._divDocuments);
	}

	static drawDocumentDetail(document: DocumentItem, container: HTMLElement): HTMLElement
	{
		let divDocument = vr.div(container, { class: "vrPatientDivSlot" });

		//#region Icon/Info
		let divIconInfo = vr.div(divDocument, { css: "display: flex;" });

		//#region Status
		let statusColor = "";
		let status = "#000";
		if (document.paymentDate != null)
		{
			//statusColor = "#00B500";
			status = "Pagata il " + new Date(document.paymentDate).toItalyShortString();
		}
		else 
		{
			if (new Date(document.expirationDate) >= new Date())
			{
				//statusColor = "#a9a9a9";
				status = "Da pagare entro il " + new Date(document.expirationDate).toItalyShortString();
			}
			else
			{
				statusColor = "#F40F02";
				status = "Scaduta il " + new Date(document.expirationDate).toItalyShortString();
			}
		}
		//#endregion

		vr.icon(vr.IconClassicLight.FilePdf, divIconInfo, { css: "font-size: 4em; margin-top: 6px; margin-right: 10px; color: " + Rotator.color() });

		let divInfo = vr.div(divIconInfo);
		vr.createLabel({
			text: "Fattura nr. " + document.number,
			bold: true,
			width: "100%"
		}, divInfo);

		vr.createLabel({
			text: "Emessa il " + new Date(document.date).toItalyShortString(),
			width: "100%"
		}, divInfo);

		vr.createLabel({
			text: status,
			width: "100%",
			colorSettings: { textColor: statusColor }
		}, divInfo);

		if (Rotator.configuration().appShowPlantInFiles)
		{
			vr.createLabel(
				{
					text: document.plantCompanyName,
					noBr: true,
					width: "100%"
				}, divInfo);
		}
		//#endregion

		//#region Buttons
		let divButtons = vr.div(divDocument, { css: "margin-top: 10px; display: flex; justify-content: space-between;" });
		let downloadButton = vr.createButton({
			text: "Scarica",
			colorSettings: { textColor: Rotator.color() },
			css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
			cssContainer: "width: 100%;",
			onClick: (e) => Support.downloadDocument(document.documentId, document.number + "_" + new Date(document.date).toItalyShortString() + ".pdf")
		}, divButtons);

		let showButton = vr.createButton({
			text: "Visualizza",
			colorSettings: { textColor: Rotator.color() },
			css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
			cssContainer: "width: 100%; margin-left: 5px;",
			onClick: (e) =>
			{
				Support.getDocumentData(document.documentId, (base64) =>
				{
					pdfViewer.content(base64);
					pdfViewer.open();
				});
			}
		}, divButtons);

		let pdfViewer = vr.createPdfViewer({
			popup: { maximize: true },
			base64: true
		});

		if (Rotator.isMobile())
		{
			downloadButton.show();
			showButton.hide();
		}
		else
		{
			downloadButton.show();
			showButton.show();
		}

		let paypalClientId = (Rotator.configuration().appPaypalSandboxMode) ? Rotator.configuration().appPaypalClientIdSandbox : Rotator.configuration().appPaypalClientId;
		let paypalClientSecret = (Rotator.configuration().appPaypalSandboxMode) ? Rotator.configuration().appPaypalClientSecretSandbox : Rotator.configuration().appPaypalClientSecret;

		let hasPaypal = true;
		if (paypalClientId == null || paypalClientId == "" || paypalClientSecret == null || paypalClientSecret == "")
			hasPaypal = false;

		let payButton = vr.createButton({
			text: "Paga",
			colorSettings: { textColor: Rotator.color() },
			css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
			cssContainer: "width: 100%; margin-left: 5px;",
			class: "vrButtonPayDocument vrButtonPatient",
			visible: hasPaypal && (document.paymentDate == null && (document.expirationDateRows == null || document.expirationDateRows.length <= 1)),
			onClick: (e) =>
			{
				let parameters = new PaymentDataParameters();
				parameters.fromDocumentPage = true;
				parameters.price = document.grossPrice;
				parameters.itemName = document.number;
				parameters.callbackAfterPayment = (e) =>
				{
					PaymentData.setDocumentPayment(document.documentId, (response) =>
					{
						VrManager.notify("Pagamento effettuato con successo!");
						Rotator.page(PageEnum.DocumentList);
					});
				}
				Rotator.page(PageEnum.PaymentData, parameters);
			}
		}, divButtons);
		//#endregion

		return divDocument;
	}

	static getDocuments(callback?: (e: GetDocumentsResponse) => void)
	{
		let request = new GetDocumentsRequest();
		request.plantIdList = [Rotator.configuration().plant.id];
		request.call(
			{
				success: (response: GetDocumentsResponse) =>
				{
					if (callback != null)
						callback(response);
				},
				mode: vrshared.WebApiModeEnum.Async,
				loader: Rotator.container()
			});
	}
}

//#region Classes
class GetDocumentsRequest extends WebApiRequestBase
{
	public method: string = "/api/PrivateAreaWebApi/GetDocuments";
	public plantIdList: number[];
}

class GetDocumentsResponse extends WebApiResponseBase
{
	public documents: DocumentItem[];
}
//#endregion