import { Rotator, puma, PageEnum } from "../../src/rotator";
import { Confirmation1 } from "./confirmation1";
import { OnlineBookingLoadingFileMode } from "../../src/shared/common";
import { VrManager } from "../../src/shared/vrManager";

export class FileLoading
{
	private static _container: HTMLElement;

	static initialize()
	{
		this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
		Rotator.title("CARICA FILE");

		this.createControls();
	}

	static createControls()
	{
		Confirmation1.drawSummary(vr.div(this._container, { class: "vrPatientDivSlot" }));
		let activityType = Rotator.bookingParameters().activityType;

		let divFiles = vr.div(this._container, { class: "vrPatientDivSlot" });
		vr.createLabel(
			{
				text: String.isNotNullOrEmpty(activityType.onlineBookingLoadingFileMessage)
					? activityType.onlineBookingLoadingFileMessage
					: ((activityType.onlineBookingLoadingFileModeId == OnlineBookingLoadingFileMode.RequireLoading) ? "Questa prestazione prevede necessariamente il caricamento di alcuni file" : "Per questa prestazione è possibile caricare dei file"),
				width: "100%",
			}, divFiles);

		let upload = vr.createUpload({
			uploadButton: "Carica file...",
			fileList: true,
			progressBar: true,
			multiple: true,
			autoUpload: false,
			dropAreaText: !Rotator.isMobile(),
			cssContainer: "margin-bottom: 5px; margin-top: 10px;",
		}, divFiles);

		if (Rotator.bookingParameters().filesLoaded != null)
			upload.addFiles(Rotator.bookingParameters().filesLoaded!);

		vr.createButton(
			{
				text: (Rotator.bookingParameters().filesLoaded != null) ? "Aggiorna o carica file" : "Carica file",
				colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
				css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
				cssContainer: "margin-top: 5px;",
				class: "vrButtonBookingAppointment vrButtonPatient",
				width: "100%",
				onClick: (e) => 
				{
					let files = upload.getFiles();
					if (files.length == 0)
					{
						if (Rotator.bookingParameters().filesLoaded == null
							|| (Rotator.bookingParameters().filesLoaded != null && activityType.onlineBookingLoadingFileModeId == OnlineBookingLoadingFileMode.RequireLoading))
							VrManager.notify("Caricare almeno un file", undefined, { type: vr.NotifierTypeEnum.Warning });

						e.sender.text("Carica file");
						Rotator.bookingParameters().filesLoaded = null;
						return;
					}

					Rotator.bookingParameters().filesLoaded = files;
					Rotator.page(PageEnum.Confirmation2);
				}
			}, this._container);

		if (activityType.onlineBookingLoadingFileModeId == OnlineBookingLoadingFileMode.RequireLoading)
		{
			vr.createButton(
				{
					text: "Annulla prenotazione",
					colorSettings: { textColor: Rotator.color() },
					css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
					cssContainer: "margin-top: 10px;",
					width: "100%",
					class: "vrButtonBookingCancelAppointment vrButtonPatient",
					onClick: (e) => 
					{
						Rotator.bookingParameters().filesLoaded = null;
						Rotator.confirmationParameters(null);
						Rotator.goToMainPage();
					}
				}, this._container);
		}
	}
}