import { Support } from "../../src/support";
import { Rotator, PageEnum, puma } from "../rotator";
import { VrManager } from "./vrManager";

export class WebApiRequestBase extends vrshared.WebApiRequest()
{
	public method: string;
	public checkConnection: boolean = true;
	private sessionId: string;

	call<T extends WebApiResponseBase>(settings?: vrshared.WebApiSettings): T
	{
		this.sessionId = Rotator.setCookieSession();
		this.method = Rotator.patientUrl() + this.method;

		if (settings == null)
			settings = new vrshared.WebApiSettings();

		let success = settings!.success;
		settings.success = (response) =>
		{
			if (response != null && (response as any).sessionExpired === true)
			{
				let request = new GetConfigurationRequest();
				request.configurations = Rotator.webComponentParameters();
				request.call(
					{
						success: (response: GetConfigurationResponse) => super.call(settings)
					});
				return;
			}

			if (settings!.loader !== false)
				vrshared.LoaderManager().hide();

			if (response.warningMessage != null && response.warningMessage != "")
			{
				VrManager.notify(response.warningMessage, "", { type: vr.NotifierTypeEnum.Error });
				if (settings!.error != null)
					settings!.error(response);
			}
			else
			{
				if (success != null)
					success(response);
			}
		}

		let error = settings!.error;
		settings.showError = false;
		settings.error = (response) =>
		{
			if (response.statusCode == 401) // Unauthorized
			{
				let request = new LogoutRequest();
				request.call(
					{
						success: (response: LogoutResponse) => 
						{
							puma(".vrPatientDivMenuItem[state='2']").show();
							puma(".vrPatientDivMenuItem[state='1']").hide();
							Rotator.page(PageEnum.Login);
						},
						loader: false,
						showError: false
					});
			}

			vrshared.LoaderManager().hide();
			if (error != null)
				error(response);

			let message = response.message;
			if (response.statusCode == 403) // Forbidden
				message += "<br />Controllare la validità dell'Api Key";

			if (response.message != null)
				VrManager.notify(message, (response.statusCode == 200) ? "" : response.stackTrace, { type: vr.NotifierTypeEnum.Error });
		}

		//#region Check Internet connection
		if (!Support.checkInternetConnection()) 
		{
			Rotator.page(PageEnum.NoInternetConnection);
			return null as any as T;
		};
		//#endregion

		let response = super.call<T>(settings);
		return response;
	}
}

export class WebApiResponseBase extends vrshared.WebApiResponse()
{
	declare public StackTrace: string | undefined;
	declare public ExceptionMessage: string | null;
	declare public Success: boolean;
	declare public ShowErrorPopup: boolean;
}

//#region GetConfiguration
class GetConfigurationRequest extends WebApiRequestBase
{
	public method: string = "/api/LoginWebApi/GetConfiguration";
	public configurations: any[];
}

class GetConfigurationResponse extends vrshared.WebApiResponse()
{
	public configuration: any;
}

class LogoutRequest extends WebApiRequestBase 
{
	public method: string = "/api/LoginWebApi/Logout";
}

class LogoutResponse extends WebApiResponseBase 
{
}
//#endregion