import { Popup, Support } from "../../src/support";
import { puma, Rotator } from "../../src/rotator";
import { Profile } from "../../src/privateArea/profile";
import { ContactTypeEnum, WebApiDateTime } from "../../src/shared/common";
import { VrManager } from "../../src/shared/vrManager";
import { WebApiRequestBase, WebApiResponseBase } from "../../src/shared/webApi";

export class WaitingList
{
	private static _container: HTMLElement;
	private static _parameters: WaitingListParameters;
	private static _txtName: vr.TextBox;
	private static _txtSurname: vr.TextBox;
	private static _txtEmail: vr.TextBox;
	private static _txtPhoneNumber: vr.TextBox;
	private static _dtpDeadline: vr.DatePicker;
	private static _txtNotes: vr.TextBox;
	private static _chkPrivacy: vr.CheckBox;
	private static _subjectId: number;

	static initialize(parameters: WaitingListParameters)
	{
		this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
		this._parameters = parameters;
		Rotator.title("LISTA DI ATTESA");
		this.createControls();
	}

	private static createControls()
	{
		let divInfo = vr.div(this._container, { class: "vrPatientDivSlot" });

		this._txtName = vr.createTextBox({
			label: "Nome",
			width: "100%",
			cssContainer: "margin-top: 5px;",
			labelSettings: { colorSettings: { textColor: Rotator.color() } }
		}, divInfo);

		this._txtSurname = vr.createTextBox({
			label: "Cognome",
			width: "100%",
			cssContainer: "margin-top: 5px;",
			labelSettings: { colorSettings: { textColor: Rotator.color() } }
		}, divInfo);

		this._txtEmail = vr.createTextBox({
			label: "Email",
			width: "100%",
			cssContainer: "margin-top: 5px;",
			labelSettings: { colorSettings: { textColor: Rotator.color() } }
		}, divInfo);

		this._txtPhoneNumber = vr.createTextBox({
			label: "Cellulare",
			width: "100%",
			cssContainer: "margin-top: 5px;",
			labelSettings: { colorSettings: { textColor: Rotator.color() } }
		}, divInfo);

		let divDeadline = vr.div(divInfo, { css: "position: relative;" });
		this._dtpDeadline = vr.createDatePicker(
			{
				label: "Fino a che giorno è disposto/a ad attendere?",
				width: "100%",
				cssContainer: "margin-top: 5px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } },
				value: new Date().addMonths(1)
			}, divDeadline);

		//#region Deadline mobile
		if (Rotator.isMobile())
		{
			let birthdatePopup = new Popup(this._container, { height: 365, width: 320 });
			let calDeadline = vr.createCalendar(
				{
					width: "100%",
					selectedColor: Rotator.color(),
					onAfterChange: (e) =>
					{
						this._dtpDeadline.value(e.value, false);
						birthdatePopup.close();
					}
				}, birthdatePopup.element());
			puma(calDeadline.element()).find(".vrDatePickerPopup_table")[0].style.cssText += "min-height: 285px;";

			puma(this._dtpDeadline.container()).find(".vrIcon").hide();
			vr.createButton({
				icon: vr.IconClassicLight.Calendar,
				css: "border: none; background: none;",
				cssContainer: "position: absolute; right: 15px; margin-top: 17px;",
				onClick: (e) => birthdatePopup.open()
			}, divDeadline);
		}
		//#endregion

		this._txtNotes = vr.createTextBox({
			label: "Note",
			width: "100%",
			cssContainer: "margin-top: 5px;",
			labelSettings: { colorSettings: { textColor: Rotator.color() } },
			mode: vr.TextModeEnum.MultiLine,
			rows: 3
		}, divInfo);

		//#region Privacy
		let divPrivacy = vr.div(divInfo, { css: "text-align: left; display: flex; margin-top: 10px;" });
		this._chkPrivacy = vr.createCheckBox(
			{
			}, divPrivacy);

		let privacyLabelText = Rotator.configuration().appPrivacyLabelText;
		if (privacyLabelText == null || privacyLabelText == "")
			privacyLabelText = "Ho letto e accetto la [privacy] (*)";

		if (privacyLabelText.includes("["))
		{
			let textToHighlight = privacyLabelText.substring(
				privacyLabelText.lastIndexOf("[") + 1,
				privacyLabelText.lastIndexOf("]")
			);
			privacyLabelText = privacyLabelText.replace(/\[.*\]/, "<span style='color: " + Rotator.color() + "'>" + textToHighlight + "</span>")
		}
		else
		{
			privacyLabelText = privacyLabelText.replace("privacy", "<span style='color: " + Rotator.color() + "'>privacy</span>");
			privacyLabelText = privacyLabelText.replace("Privacy", "<span style='color: " + Rotator.color() + "'>Privacy</span>");
		}

		vr.createLabel({
			text: privacyLabelText,
			fontSize: "1.1em",
			cssContainer: "top: -1px;",
			width: "Calc(100% - 35px)",
			onClick: (e) => 
			{
				wndPrivacy.open(null, false, { top: Rotator.container().scrollTop });
			}
		}, divPrivacy);

		let wndPrivacy = vr.createWindow(
			{
				title: "Privacy",
				maximized: false,
				content: Rotator.configuration().privacyText,
				css: "overflow-y: auto;",
				cssContainer: "width: 100% !important; height: " + (Rotator.container().clientHeight - 20) + "px !important; position: absolute !important; left: 0px !important;",
				draggable: false,
				modal: false,
				hideCloseIcon: true,
				footer:
					[
						{ type: vr.WindowFooterItemTypeEnum.Close, text: "Chiudi" },
						{
							type: vr.WindowFooterItemTypeEnum.Ok, text: "Accetta", onClick: (e) => 
							{
								wndPrivacy.close();
								this._chkPrivacy.checked(true);
							}
						}
					]
			}, Rotator.shadowRoot() as any);
		puma(wndPrivacy.container()).appendToPuma(this._container);
		//#endregion

		//#region Buttons
		vr.createButton({
			text: "Aggiungimi alla lista",
			colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
			css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
			class: "vrButtonAddToWaitingList vrButtonPatient",
			cssContainer: "margin-top: -5px;",
			width: "100%",
			onClick: (e) => 
			{
				//#region Check
				let error = "";

				if (this._txtName.isEmpty())
					error += "Nome, ";

				if (this._txtSurname.isEmpty())
					error += "Cognome, ";

				if (this._txtPhoneNumber.isEmpty())
					error += "Cellulare, ";

				if (this._dtpDeadline.value() == null)
					error += "Data, ";

				if (!this._chkPrivacy.checked())
					error += "Privacy, ";

				if (error.length > 0)
				{
					error = "Riempire i campi obbligatori: " + error.substring(0, error.length - 2);
					VrManager.notify(error, "", { type: vr.NotifierTypeEnum.Error });
					return;
				}
				//#endregion

				let request = new AddToWaitingListRequest();
				request.customerId = this._subjectId;
				request.customerName = this._txtName.value();
				request.customerSurname = this._txtSurname.value();
				request.customerEmail = this._txtEmail.value();
				request.customerPhoneNumber = this._txtPhoneNumber.value();
				request.deadline = Date.toWebApiDateTime(this._dtpDeadline.value());
				request.privacy = this._chkPrivacy.checked();
				request.workplaceId = this._parameters.workplaceId;
				request.note = this._txtNotes.value();
				request.activityTypeId = Rotator.bookingParameters().activityType.id;
				request.resourceId = this._parameters.resourceId;
				request.call(
					{
						success: (response: AddToWaitingListResponse) =>
						{
							Rotator.burgerButton().show();
							Rotator.backButton().hide();

							puma(this._container).empty();
							let divMessageConfirmation = vr.div(this._container, { class: "vrPatientDivSlot" });

							vr.createLabel({
								text: Rotator.configuration().appConfirmationMessageAddedToWaitingList,
								width: "100%"
							}, divMessageConfirmation);

							vr.createButton(
								{
									text: "Prenota un altro",
									colorSettings: { textColor: Rotator.color() },
									css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
									cssContainer: "margin-top: 10px;",
									width: "100%",
									class: "vrButtonBookingBookAnother vrButtonPatient",
									onClick: (e) => Rotator.goToMainPage()
								}, divMessageConfirmation);
						},
						mode: vrshared.WebApiModeEnum.Async,
						loader: Rotator.container()
					});
			}
		}, this._container);
		//#endregion

		Support.checkLogin(() => 
		{
			Profile.getSubject(null, (response) =>
			{
				this._subjectId = response.id;

				//#region Name & Surname
				this._txtName.value(response.name);
				if (response.name.length > 0)
					this._txtName.disable();

				this._txtSurname.value(response.surname);
				if (response.surname.length > 0)
					this._txtSurname.disable();
				//#endregion

				//#region Contacts
				if (response.contacts != null && response.contacts.length > 0)
				{
					let emailContactInfo = response.contacts.filter(k => k.conactType == ContactTypeEnum.PrivateEmail)[0];
					if (emailContactInfo != null)
					{
						this._txtEmail.tag(emailContactInfo.contactId);
						this._txtEmail.value(emailContactInfo.value);
						this._txtEmail.disable();
					}

					let mobilePhoneContactInfo = response.contacts.filter(k => k.conactType == ContactTypeEnum.PrivateMobile)[0];
					if (mobilePhoneContactInfo != null)
					{
						this._txtPhoneNumber.tag(mobilePhoneContactInfo.contactId);
						this._txtPhoneNumber.value(mobilePhoneContactInfo.value);
						this._txtPhoneNumber.disable();
					}
				}
				//#endregion

				if (response.hasPrivacy)
				{
					puma(divPrivacy).hide();
					this._chkPrivacy.checked(true);
				}
			});
		});
	}
}

//#region Classes
export class WaitingListParameters
{
	workplaceId: number;
	resourceId: number;
}

class AddToWaitingListRequest extends WebApiRequestBase
{
	public method: string = "/api/BookingWebApi/AddToWaitingList";

	customerName: string;
	customerSurname: string;
	customerEmail: string;
	customerPhoneNumber: string;
	deadline: WebApiDateTime;
	privacy: boolean;
	customerId: number;
	workplaceId: number;
	note: string;
	activityTypeId: number;
	resourceId: number;
}

class AddToWaitingListResponse extends WebApiResponseBase
{

}
//#endregion