import { Support } from "../../src/support";
import { AppLoginModeEnum, puma, Rotator } from "../../src/rotator";
import { MandatoryFieldInfo, OnlineBookingMandatoryFieldTypeEnum, Register } from "../../src/login/register";
import { Confirmation2 } from "../../src/booking/confirmation2";
import { GetSubjectResponse, Profile } from "./profile";
import { WebApiRequestBase, WebApiResponseBase } from "../../src/shared/webApi";
import { BirthAddressInfo } from "../../src/shared/common";
import { VrManager } from "../../src/shared/vrManager";

export class PaymentData
{
	private static _container: HTMLElement;
	private static _fields: MandatoryFieldInfo[];
	private static _subjectId: number;
	private static _subjectDisplayName: string;
	private static _appointmentId?: number | null;
	private static _needsToAddStampRow: boolean;

	private static _txtName: vr.TextBox;
	private static _txtSurname: vr.TextBox;
	private static _txtEmail: vr.TextBox;
	private static _txtPhoneNumber: vr.TextBox;
	private static _dtpBirthdate: vr.DatePicker;
	private static _txtFiscalCode: vr.TextBox;
	private static _txtLandline: vr.TextBox;
	private static _btgGender: vr.ButtonGroup;

	private static _cmbBirthCity: vr.ComboBox;
	private static _swtItalianForeignBirthCity: vr.Switch;
	private static _cmbForeignBirthCityCountry: vr.ComboBox;
	private static _txtForeignBirthCity: vr.TextBox;

	private static _swtItalianForeignResidenceAddress: vr.Switch;
	private static _txtItalyResidenceAddress: vr.TextBox;
	private static _cmbItalyResidenceCity: vr.ComboBox;
	private static _txtItalyResidenceProvince: vr.TextBox;
	private static _cmbItalyResidenceZip: vr.ComboBox;
	private static _txtOutItalyResidenceAddress: vr.TextBox;
	private static _cmbOutItalyResidenceCountry: vr.ComboBox;

	private static _btnConfirmData: vr.Button;
	private static _divAppointmentCreated: HTMLElement;
	private static _btnPaypalButton: vr.PaypalButton;
	private static _parameters: PaymentDataParameters;
	static _getSubjectResponse: GetSubjectResponse;

	static initialize(parameters: PaymentDataParameters)
	{
		this._container = puma("<div></div>").appendTo(Rotator.container())[0];
		Rotator.title("DATI PAGAMENTO");

		this._parameters = parameters;
		this._appointmentId = (parameters != null) ? parameters.appointmentId : null;

		Support.checkLogin(() => 
		{
			Register.getMandatoryFields((e) =>
			{
				this._fields = e.fields;
				this.createControls();
				Profile.getSubject(
					{
						txtName: this._txtName,
						txtSurname: this._txtSurname,
						txtEmail: this._txtEmail,
						btgGender: this._btgGender,
						cmbBirthCity: this._cmbBirthCity,
						cmbForeignBirthCityCountry: this._cmbForeignBirthCityCountry,
						cmbItalyResidenceCity: this._cmbItalyResidenceCity,
						txtItalyResidenceProvince: this._txtItalyResidenceProvince,
						cmbItalyResidenceZip: this._cmbItalyResidenceZip,
						cmbOutItalyResidenceCountry: this._cmbOutItalyResidenceCountry,
						dtpBirthdate: this._dtpBirthdate,
						swtItalianForeignBirthCity: this._swtItalianForeignBirthCity,
						swtItalianForeignResidenceAddress: this._swtItalianForeignResidenceAddress,
						txtFiscalCode: this._txtFiscalCode,
						txtForeignBirthCity: this._txtForeignBirthCity,
						txtItalyResidenceAddress: this._txtItalyResidenceAddress,
						txtLandline: this._txtLandline,
						txtOutItalyResidenceAddress: this._txtOutItalyResidenceAddress,
						txtPhoneNumber: this._txtPhoneNumber
					},
					(response) =>
					{
						this._getSubjectResponse = response;
						this._subjectId = response.id;
						this._subjectDisplayName = response.name + " " + response.surname;
					});
			});
		});
	}

	private static createControls()
	{
		vr.createLabel({
			text: "Verifica i tuoi dati e prosegui con il pagamento",
			width: "100%",
			align: vr.TextAlignEnum.Center,
			cssContainer: "margin-bottom: 10px;"
		}, this._container);

		//#region Info
		let divInfo = vr.div(this._container, { class: "vrPatientDivSlot" });

		this._txtName = vr.createTextBox({
			label: "Nome",
			width: "100%",
			cssContainer: "margin-top: 5px;",
			labelSettings: { colorSettings: { textColor: Rotator.color() } }
		}, divInfo);

		this._txtSurname = vr.createTextBox({
			label: "Cognome",
			width: "100%",
			cssContainer: "margin-top: 5px;",
			labelSettings: { colorSettings: { textColor: Rotator.color() } }
		}, divInfo);

		this._txtEmail = vr.createTextBox({
			label: "Email",
			width: "100%",
			cssContainer: "margin-top: 5px;",
			labelSettings: { colorSettings: { textColor: Rotator.color() } }
		}, divInfo);
		Register.manageFields(this._fields, OnlineBookingMandatoryFieldTypeEnum.EMail, this._txtEmail);

		this._txtPhoneNumber = vr.createTextBox({
			label: "Cellulare",
			width: "100%",
			cssContainer: "margin-top: 5px;",
			labelSettings: { colorSettings: { textColor: Rotator.color() } }
		}, divInfo);

		this._txtLandline = vr.createTextBox(
			{
				label: "Telefono fisso",
				placeholder: "Telefono fisso",
				mode: vr.TextModeEnum.Text,
				width: "100%",
				cssContainer: "margin-top: 5px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, divInfo);
		Register.manageFields(this._fields, OnlineBookingMandatoryFieldTypeEnum.Landline, this._txtLandline);

		this._btgGender = vr.createButtonGroup(
			{
				label: "Sesso",
				width: "100%",
				labelSettings: { colorSettings: { textColor: Rotator.color() } },
				cssContainer: "margin-top: 5px;",
				//selectedColor: Rotator.color(),
				items:
					[
						{ text: "Maschio", value: "M" },
						{ text: "Femmina", value: "F" }
					],
				selectionMode: vr.SelectionModeEnum.Single
			}, divInfo);
		//#endregion

		//#region BirthCity
		let divBirthInfo = vr.div(this._container, { class: "vrPatientDivSlot" });

		this._dtpBirthdate = vr.createDatePicker(
			{
				label: "Data di nascita",
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, divBirthInfo);

		vr.createLabel({
			text: "Città di nascita",
			colorSettings: { textColor: Rotator.color() },
		}, divBirthInfo);

		this._swtItalianForeignBirthCity = vr.createSwitch({
			labelOff: "Italia",
			labelOn: "Fuori Italia",
			width: "100%",
			cssContainer: "margin-bottom: 5px;",
			onChange: (e) => 
			{
				if (e.checked)
				{
					puma(divItalyBirthCity).hide();
					puma(divForeignBirthCity).show();
				}
				else
				{
					puma(divItalyBirthCity).show();
					puma(divForeignBirthCity).hide();
				}
			}
		}, divBirthInfo);

		let divItalyBirthCity = vr.div(divBirthInfo);
		this._cmbBirthCity = vr.createComboBox(
			{
				label: "Città",
				width: "100%",
				cssContainer: "margin-right: 10px;",
				webService: {
					method: Rotator.patientUrl() + "/api/UtilityWebApi/GetAllCities",
					itemsPropertyName: "cities",
					typedTextPropertyName: "searchText",
					parameters: () =>
					{
						return {
							onlyActive: false
						}
					}
				}
			}, divItalyBirthCity);

		let divForeignBirthCity = vr.div(divBirthInfo, { css: "display: none;" });
		this._cmbForeignBirthCityCountry = vr.createComboBox(
			{
				label: "Nazione",
				width: "100%"
			}, divForeignBirthCity);

		this._txtForeignBirthCity = vr.createTextBox(
			{
				label: "Città",
				width: "100%",
				cssContainer: "margin-right: 10px;"
			}, divForeignBirthCity);
		//#endregion

		//#region Fiscal code
		let divFiscalCode = vr.div(this._container, { class: "vrPatientDivSlot" });
		this._txtFiscalCode = vr.createTextBox({
			label: "Codice fiscale",
			width: "100%",
			labelSettings: { colorSettings: { textColor: Rotator.color() } },
			inputMode: vr.TextTransformModeEnum.Uppercase,
			onBlur: (e) =>
			{
				Profile.checkFiscalCode(this._txtFiscalCode.value<string>(), this._cmbForeignBirthCityCountry.value<number>(), (e) =>
				{
					if (!e.isValid)
						this._txtFiscalCode.error();
					else
						this._txtFiscalCode.hideError();
				});
			}
		}, divFiscalCode);

		vr.createButton({
			text: "Calcola codice",
			width: "100%",
			cssContainer: "margin-top: 10px;",
			colorSettings: { textColor: Rotator.color() },
			css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
			onClick: (e) =>
			{
				let birthAddressInfo = null;
				if (!this._swtItalianForeignBirthCity.checked())
				{
					//#region Italian
					if (this._cmbBirthCity.value() != null && this._cmbBirthCity.value() != "")
					{
						birthAddressInfo = new BirthAddressInfo();
						birthAddressInfo.istatCode = this._cmbBirthCity.value<string>()!;
					}
					//#endregion
				}
				else
				{
					//#region Foreign
					let countrySelected = this._cmbForeignBirthCityCountry.getSelectedItem<any>();
					if (countrySelected != null)
					{
						birthAddressInfo = new BirthAddressInfo();
						birthAddressInfo.countryCode = this._cmbForeignBirthCityCountry.value<string>()!;
						birthAddressInfo.address = this._txtForeignBirthCity.value();
					}
					//#endregion
				}

				Profile.computeFiscalCode(this._txtName.value(), this._txtSurname.value(), this._btgGender.getSelectedValues()[0], this._dtpBirthdate.value()!, birthAddressInfo,
					(e) =>
					{
						if (e.invalidFieldsMessage != null && e.invalidFieldsMessage != "")
							VrManager.notify(e.invalidFieldsMessage, "", { type: vr.NotifierTypeEnum.Error });
						else
						{
							this._txtFiscalCode.value(e.fiscalCode);
							this._txtFiscalCode.hideError();
						}
					});
			}
		}, divFiscalCode);
		//#endregion

		//#region Address
		let divResidence = vr.div(this._container, { class: "vrPatientDivSlot", css: "margin-top: 10px;" });

		vr.createLabel({
			text: "Indirizzo di residenza",
			colorSettings: { textColor: Rotator.color() },
		}, divResidence);

		this._swtItalianForeignResidenceAddress = vr.createSwitch({
			labelOff: "Italia",
			labelOn: "Fuori Italia",
			width: "100%",
			cssContainer: "margin-bottom: 5px;",
			onChange: (e) => 
			{
				if (e.checked)
				{
					puma(divItalyResidence).hide();
					puma(divOutItalyResidence).show();
				}
				else
				{
					puma(divItalyResidence).show();
					puma(divOutItalyResidence).hide();
				}
			}
		}, divResidence);

		let divItalyResidence = vr.div(divResidence);
		this._txtItalyResidenceAddress = vr.createTextBox(
			{
				label: "Via",
				width: "100%"
			}, divItalyResidence);

		this._cmbItalyResidenceCity = vr.createComboBox(
			{
				label: "Comune",
				width: "100%",
				cssContainer: "margin-right: 10px;",
				webService: {
					method: Rotator.patientUrl() + "/api/UtilityWebApi/GetAllCities",
					itemsPropertyName: "cities",
					typedTextPropertyName: "searchText",
					parameters: () =>
					{
						return {
							onlyActive: true
						}
					}
				},
				onAfterChange: (e) =>
				{
					Register.calcProvinceAndZipByCity(e.value, (e) =>
					{
						this._txtItalyResidenceProvince.clear();
						this._cmbItalyResidenceZip.clearItems();

						this._txtItalyResidenceProvince.value(e.city.provinceAbbreviation);
						this._cmbItalyResidenceZip.items(e.city.zipCodes.map(k => { return { text: k.zipCode, value: k.zipCode } }));
						this._cmbItalyResidenceZip.value(e.city.zipCodes.first().zipCode);
					});
				}
			}, divItalyResidence);

		this._txtItalyResidenceProvince = vr.createTextBox(
			{
				label: "Provincia",
				enable: false,
				align: vr.TextAlignEnum.Center,
				width: "Calc(50% - 10px)",
				cssContainer: "margin-right: 10px;"
			}, divItalyResidence);

		this._cmbItalyResidenceZip = vr.createComboBox(
			{
				label: "CAP",
				placeholder: "",
				width: "50%"
			}, divItalyResidence);

		let divOutItalyResidence = vr.div(divResidence, { css: "display: none;" });
		this._txtOutItalyResidenceAddress = vr.createTextBox(
			{
				label: "Indirizzo",
				width: "100%",
				cssContainer: "margin-right: 10px;"
			}, divOutItalyResidence);

		this._cmbOutItalyResidenceCountry = vr.createComboBox(
			{
				label: "Nazione",
				width: "100%"
			}, divOutItalyResidence);
		//#endregion

		this._btnConfirmData = vr.createButton({
			text: "Verifica e conferma i dati",
			colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
			css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
			cssContainer: "margin-bottom: 30px;",
			width: "100%",
			class: "vrButtonConfirmData vrButtonPatient",
			onClick: (e) => this.checkAndManageData(e)
		}, this._container)
	}

	private static checkAndManageData(e: any)
	{
		//#region Check
		let fieldFiscalCodeForIndex = this._fields.filter(k => k.fieldType == OnlineBookingMandatoryFieldTypeEnum.FiscalCode)[0];
		let fieldFiscalCode = this._fields[this._fields.indexOf(fieldFiscalCodeForIndex)];
		fieldFiscalCode.isMandatory = true;

		let fieldResidenceAddressForIndex = this._fields.filter(k => k.fieldType == OnlineBookingMandatoryFieldTypeEnum.ResidenceAddress)[0];
		let fieldResidenceAddress = this._fields[this._fields.indexOf(fieldResidenceAddressForIndex)];
		fieldResidenceAddress.isMandatory = true;

		let error = Register.checkMandatoryFields(this._fields, this._txtFiscalCode, this._dtpBirthdate, this._swtItalianForeignBirthCity,
			this._cmbBirthCity, this._cmbForeignBirthCityCountry, this._txtForeignBirthCity, this._btgGender, this._swtItalianForeignResidenceAddress,
			this._txtItalyResidenceAddress, this._cmbItalyResidenceCity, this._txtItalyResidenceProvince, this._cmbItalyResidenceZip,
			this._txtOutItalyResidenceAddress, this._cmbOutItalyResidenceCountry, this._txtLandline);

		let fieldEmail = this._fields.filter(k => k.fieldType == OnlineBookingMandatoryFieldTypeEnum.EMail)[0];
		if (fieldEmail != null && this._txtEmail.isEmpty() && fieldEmail.isMandatory)
		{
			if (!(Rotator.bookingParameters().activityType.doNotRequireLogin || Rotator.configuration().appLoginRequired == AppLoginModeEnum.Never))
			{
				VrManager.notify("Email obbligatoria", "", { type: vr.NotifierTypeEnum.Error });
				error = true;
			}
		}

		if (this._txtName.isEmpty())
		{
			VrManager.notify("Nome obbligatorio", "", { type: vr.NotifierTypeEnum.Error });
			error = true;
		}

		if (this._txtSurname.isEmpty())
		{
			VrManager.notify("Cognome obbligatorio", "", { type: vr.NotifierTypeEnum.Error });
			error = true;
		}

		if (error)
		{
			e.preventDefault();
			return;
		}
		//#endregion

		vrshared.LoaderManager().show(Rotator.container());
		window.setTimeout(() =>
		{
			Profile.updateSubject(
				{
					txtName: this._txtName,
					txtSurname: this._txtSurname,
					txtEmail: this._txtEmail,
					btgGender: this._btgGender,
					cmbBirthCity: this._cmbBirthCity,
					cmbForeignBirthCityCountry: this._cmbForeignBirthCityCountry,
					cmbItalyResidenceCity: this._cmbItalyResidenceCity,
					txtItalyResidenceProvince: this._txtItalyResidenceProvince,
					cmbItalyResidenceZip: this._cmbItalyResidenceZip,
					cmbOutItalyResidenceCountry: this._cmbOutItalyResidenceCountry,
					dtpBirthdate: this._dtpBirthdate,
					swtItalianForeignBirthCity: this._swtItalianForeignBirthCity,
					swtItalianForeignResidenceAddress: this._swtItalianForeignResidenceAddress,
					txtFiscalCode: this._txtFiscalCode,
					txtForeignBirthCity: this._txtForeignBirthCity,
					txtItalyResidenceAddress: this._txtItalyResidenceAddress,
					txtLandline: this._txtLandline,
					txtOutItalyResidenceAddress: this._txtOutItalyResidenceAddress,
					txtPhoneNumber: this._txtPhoneNumber
				}, this._subjectId, false, false, this._fields,
				(response) =>
				{
					Profile.getSubject(null,
						(response) =>
						{
							this._getSubjectResponse = response;
							vrshared.LoaderManager().hide();

							if (this._appointmentId == null && !this._parameters.fromDocumentPage)
							{
								Confirmation2.createAppointment((e2) =>
								{
									vrshared.LoaderManager().hide();
									this._appointmentId = e2.appointmentId;
									this._needsToAddStampRow = e2.needsToAddStampRow;

									puma(this._container).empty();

									this._divAppointmentCreated = vr.div(this._container, { class: "vrPatientDivSlot" });
									vr.createLabel({
										width: "100%",
										cssContainer: "margin-bottom: 10px;",
										align: vr.TextAlignEnum.Center,
										text: (String.isNotNullOrEmpty(Rotator.configuration().appMessagePrePayment)) ? Rotator.configuration().appMessagePrePayment
											: "L'appuntamento è stato creato, e ora è possibile procedere con il pagamento online premendo sul pulsante 'PayPal' (anche per pagare con carta di credito).<br /><br />Potrebbe essere necessario aspettare fino ad un minuto, quindi attendere la notifica di avvenuto pagamento e non lasciare o aggiornare la pagina."
									}, this._divAppointmentCreated);

									this.createPaypalButton();
								},
									(error) =>
									{
										let fromHours = String(Rotator.confirmationParameters()!.timeslot.hourFrom.hours).padStart(2, "0");
										let fromMinutes = String(Rotator.confirmationParameters()!.timeslot.hourFrom.minutes).padStart(2, "0");
										let date = new Date(Rotator.confirmationParameters()!.timeslot.day).toItalyLongString();

										Support.sendErrorEmail(`Si è verificato un errore durante la prenotazione del seguente appuntamento: <br />
											UrlCliente: ${Rotator.webComponentParameters()[0].url} <br />	
											Professionista: ${Rotator.confirmationParameters()!.resource!.name}<br />
											Utente: ${this._subjectDisplayName}<br />
											Prestazione: ${Rotator.bookingParameters().activityType.onlineBookingName}<br />
											Sede: ${Rotator.confirmationParameters()!.workplace.name}<br /> 
											Data: ${date + " alle ore " + fromHours + ":" + fromMinutes}
											${(String.isNotNullOrEmpty(Rotator.configuration().appName) ? "<br />Nome app: " + Rotator.configuration().appName : "")}

											<br /><br />
											Message: ${error.message}<br />
											StackTrace: ${error.stackTrace}<br />
										`);

										vrshared.LoaderManager().hide();
										e.preventDefault();
									}, false);
							}
							else
							{
								puma(this._container).empty();

								vr.createLabel({
									width: "100%",
									cssContainer: "margin-bottom: 10px;",
									align: vr.TextAlignEnum.Center,
									text: "I dati sono stati confermati, e quindi è ora possibile procedere con il pagamento online premendo sul pulsante 'PayPal'.<br /><br />Potrebbe essere necessario aspettare fino ad un minuto, quindi attendere la notifica di avvenuto pagamento e non lasciare o aggiornare la pagina."
								}, this._container);

								this.createPaypalButton();
							}
						});
				},
				(error) =>
				{
					vrshared.LoaderManager().hide();
					e.preventDefault();
				});
		}, 500);
	}

	private static createPaypalButton()
	{
		let paypalClientId = (Rotator.configuration().appPaypalSandboxMode) ? Rotator.configuration().appPaypalClientIdSandbox : Rotator.configuration().appPaypalClientId;
		let paypalClientSecret = (Rotator.configuration().appPaypalSandboxMode) ? Rotator.configuration().appPaypalClientSecretSandbox : Rotator.configuration().appPaypalClientSecret;

		if (paypalClientId == null || paypalClientId == "" || paypalClientSecret == null || paypalClientSecret == "")
		{
			VrManager.notify("Paypal ClientID e/o ClientSecret non impostati correttamente", "", { type: vr.NotifierTypeEnum.Error });
			return;
		}

		if (Rotator.configuration().appPaypalSandboxMode)
		{
			let divSandboxAlert = vr.div(this._container, { class: "vrPatientDivSlot" });
			vr.createLabel({
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				align: vr.TextAlignEnum.Center,
				colorSettings: { textColor: "#bb2626" },
				text: "<b>Attenzione:</b> Modalità Sandbox (test)"
			}, divSandboxAlert);
		}

		this._btnPaypalButton = vr.createPaypalButton({
			width: "100%",
			style: { height: 40 },
			onBeforePayment: (e) =>
			{
				if (paypalClientId == null || paypalClientId == "" || paypalClientSecret == null || paypalClientSecret == "")
				{
					VrManager.notify("Paypal ClientID e/o ClientSecret non impostati correttamente", "", { type: vr.NotifierTypeEnum.Error });
					e.preventDefault();
					return;
				}
			},
			createOrderRequest: {
				method: Rotator.webComponentParameters()[0].url + "/api/PaypalWebApi/CreatePayment/",
				loader: Rotator.container(),
				parameters: () =>
				{
					let sessionId = vrshared.CookieManager().getCookie("sessionId");
					let amount = this._parameters.price + ((this._needsToAddStampRow === true) ? 2 : 0);
					let itemName = this._parameters.itemName;
					let plantId = Rotator.webComponentParameters()[0].plantId;

					return {
						sessionId: sessionId,
						amount: amount,
						itemName: itemName,
						authKey: "1D7822D72F5397BEF65D677C89E85",
						plantId: (plantId == 0) ? 1 : plantId,
						environment: (Rotator.configuration().appPaypalSandboxMode) ? vr.PaypalEnvironmentEnum.Sandbox : vr.PaypalEnvironmentEnum.Production,
						payerInfo:
						{
							name: this._txtName.value(),
							surname: this._txtSurname.value(),
							email: this._txtEmail.value(),
						},
						billingInfo:
						{
							city: this._cmbItalyResidenceCity.text(),
							countryCode: (this._getSubjectResponse.italianAddress != null) ? this._getSubjectResponse.italianAddress.countryCode : "",
							street: (this._getSubjectResponse.italianAddress != null) ? this._getSubjectResponse.italianAddress.street : "",
							zip: (this._getSubjectResponse.italianAddress != null) ? this._getSubjectResponse.italianAddress.zipCode : "",
							province: this._txtItalyResidenceProvince.value()
						}
					}
				}
			},
			approveRequest: {
				method: Rotator.webComponentParameters()[0].url + "/api/PaypalWebApi/ExecutePayment/",
				loader: Rotator.container(),
				parameters: () =>
				{
					let sessionId = vrshared.CookieManager().getCookie("sessionId");
					let plantId = Rotator.webComponentParameters()[0].plantId;

					return {
						sessionId: sessionId,
						authKey: "1D7822D72F5397BEF65D677C89E85",
						plantId: (plantId == 0) ? 1 : plantId,
						environment: (Rotator.configuration().appPaypalSandboxMode) ? vr.PaypalEnvironmentEnum.Sandbox : vr.PaypalEnvironmentEnum.Production
					}
				},
				callback: (e) => 
				{
					if (this._parameters.callbackAfterPayment != null)
					{
						let paymentDataEvent = new PaymentDataEvent();
						paymentDataEvent.appointmentId = this._appointmentId!;
						this._parameters.callbackAfterPayment(paymentDataEvent);
					}
				},
				errorCallback: (e) =>
				{
					let fromHours = String(Rotator.confirmationParameters()!.timeslot.hourFrom.hours).padStart(2, "0");
					let fromMinutes = String(Rotator.confirmationParameters()!.timeslot.hourFrom.minutes).padStart(2, "0");
					let date = new Date(Rotator.confirmationParameters()!.timeslot.day).toItalyLongString();

					Support.sendErrorEmail(`Si è verificato un errore durante il pagamento: <br />
						UrlCliente: ${Rotator.webComponentParameters()[0].url} <br />
						Professionista: ${Rotator.confirmationParameters()!.resource!.name}<br />
						Utente: ${this._subjectDisplayName}<br />
						Prestazione: ${Rotator.bookingParameters().activityType.onlineBookingName}<br />
						Sede: ${Rotator.confirmationParameters()!.workplace.name}<br /> 
						Data: ${date + " alle ore " + fromHours + ":" + fromMinutes}
						${(String.isNotNullOrEmpty(Rotator.configuration().appName) ? "<br />Nome app: " + Rotator.configuration().appName : "")}
					`, this._appointmentId!);
				}
			}
		}, this._container);

		vr.createImage({
			value: "https://booking.vrapp.it/images/patient/creditCardLogo.png",
			cssContainer: "width: 100%; margin-top: -4px; z-index: 999;",
			css: "margin: 0 auto; height: 22px !important; background-color: #ffc439; padding: 5px; padding-top: 0px;"
		}, this._container)

		Rotator.burgerButton().show();
		Rotator.backButton().hide();

		vr.createSeparator({
			orientation: vr.OrientationEnum.Horizontal,
			width: "100%"
		}, this._container)

		vr.createLabel({
			text: "Torna alla prenotazione",
			width: "100%",
			cssContainer: "margin-bottom: 5px;",
			align: vr.TextAlignEnum.Center
		}, this._container)

		vr.createButton(
			{
				text: "Prenota un altro",
				colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
				css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
				cssContainer: "margin-bottom: 20px;",
				width: "100%",
				class: "vrButtonBookingBookAnother vrButtonPatient",
				onClick: (e) => Rotator.goToMainPage()
			}, this._container);
	}

	static createInvoice(appointmentId: number, callback?: (e: CreateInvoiceResponse) => void)
	{
		let request = new CreateInvoiceRequest();
		request.appointmentId = appointmentId;
		request.call(
			{
				success: (response: CreateInvoiceResponse) =>
				{
					if (callback != null)
						callback(response);
				},
				error: (error) =>
				{
					if (Rotator.confirmationParameters() != null)
					{
						let fromHours = String(Rotator.confirmationParameters()!.timeslot.hourFrom.hours).padStart(2, "0");
						let fromMinutes = String(Rotator.confirmationParameters()!.timeslot.hourFrom.minutes).padStart(2, "0");
						let date = new Date(Rotator.confirmationParameters()!.timeslot.day).toItalyLongString();

						Support.sendErrorEmail(`Si è verificato un errore durante la creazione della fattura per il seguente appuntamento: <br />
						UrlCliente: ${Rotator.webComponentParameters()[0].url} <br />
						Professionista: ${Rotator.confirmationParameters()!.resource!.name} <br />
						Utente: ${this._subjectDisplayName} <br />
						Prestazione: ${Rotator.bookingParameters().activityType.onlineBookingName} <br />
						Sede: ${Rotator.confirmationParameters()!.workplace.name} <br />
						Data: ${date + " alle ore " + fromHours + ":" + fromMinutes}
						${(String.isNotNullOrEmpty(Rotator.configuration().appName) ? "<br />Nome app: " + Rotator.configuration().appName : "")}

						<br /><br />
						Message: ${error.message}<br />
						StackTrace: ${error.stackTrace}<br />
					`, appointmentId);
					}
				},
				mode: vrshared.WebApiModeEnum.Async,
				loader: Rotator.container()
			});
	}

	static setDocumentPayment(documentId: number, callback?: (e: SetDocumentPaymentResponse) => void)
	{
		let request = new SetDocumentPaymentRequest();
		request.documentId = documentId;
		request.call(
			{
				success: (response: SetDocumentPaymentResponse) =>
				{
					if (callback != null)
						callback(response);
				},
				error: (error) =>
				{
					Support.sendErrorEmail(`Si è verificato un errore durante il pagamento della fattura
						<br /><br />
						Message: ${error.message} <br />
						StackTrace: ${error.stackTrace} <br />`);
				},
				mode: vrshared.WebApiModeEnum.Async,
				loader: Rotator.container()
			});
	}
}

//#region Classes
class CreateInvoiceRequest extends WebApiRequestBase
{
	public method: string = "/api/PrivateAreaWebApi/CreateInvoice";

	public appointmentId: number;
}

class CreateInvoiceResponse extends WebApiResponseBase
{
	public invoiceId: number;
	public pdfDocument: string;
	public pdfDocumentName: string;
	public cashAdvanceInfo: CashAdvanceInfo;
}

export class CashAdvanceInfo
{
	public cashAdvancePrice: number;
}

export class PaymentDataParameters
{
	appointmentId?: number | null;
	fromDocumentPage?: boolean;
	price: number;
	itemName: string;
	callbackAfterPayment?: (e: PaymentDataEvent) => void;
}

class PaymentDataEvent
{
	appointmentId: number;
}

class SetDocumentPaymentRequest extends WebApiRequestBase
{
	public method: string = "/api/PrivateAreaWebApi/SetDocumentPayment";

	public documentId: number;
}

class SetDocumentPaymentResponse extends WebApiResponseBase
{
	public documentId: number;
}
//#endregion