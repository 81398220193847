import { Rotator, puma, PageEnum } from "../rotator";
import { WebApiRequestBase, WebApiResponseBase } from "../shared/webApi";
import { ResourceResponse, Timeslot, WorkplaceResponse, GenderEnum } from "../shared/common";
import { TimeslotListParameters } from "./timeslotList";
import { DoctorList } from "./doctorList";
import { PriceTypeEnum, Support } from "../support";
import { WaitingListParameters } from "./waitingList";

export class ResourceTimeslots
{
    private static _container: HTMLElement;
    private static _indexForLoader: number;

    static initialize()
    {
        this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
        Rotator.title("DISPONIBILITÀ");
        this._indexForLoader = 0;

        if (Rotator.bookingParameters().resourceList == null)
            DoctorList.getResources((e) => this.drawResourcesSlot(), Rotator.container());
        else
            this.drawResourcesSlot();
    }

    private static drawResourcesSlot()
    {
        let resources = Rotator.bookingParameters().resourceList.filter(k => k.id != 0);
        if (Rotator.bookingParameters().resource != null)
            resources = resources.filter(k => k.id == Rotator.bookingParameters().resource.id);

        if (resources.length == 0)
        {
            vr.createLabel({
                text: "Non è presente alcun personale sanitario disponibile per effettuare la prestazione selezionata",
                width: "100%",
                align: vr.TextAlignEnum.Center
            }, this._container);
        }
        else if (resources.length > 1)
            vrshared.LoaderManager().show(Rotator.container(), true, "resourcesLoadDiv");

        //resources = resources.take(1);
        for (let resource of resources)
        {
            let divSlot = vr.div(this._container, { class: "vrPatientDivSlot" });
            let divResourceInfo = vr.div(divSlot, { css: "display: flex;" });

            //#region Image/Icon
            if (resource.imageBase64 != null && resource.imageBase64 != "")
            {
                vr.createImage(
                    {
                        value: resource.imageBase64,
                        base64: true,
                        width: 70,
                        height: 70,
                        cssContainer: "justify-content: center; margin-right: 10px;",
                        css: "border-radius: 35px;"
                    }, divResourceInfo);
            }
            else
                vr.icon(vr.IconClassicLight.UserDoctor, divResourceInfo, { css: "justify-content: center; margin-right: 10px; font-size: 5em; color: " + Rotator.color() + ";" });
            //#endregion

            //#region Info
            let divInfo = vr.div(divResourceInfo, { css: "display: inline-block; width: Calc(100% - 75px); text-align: left; min-height: 70px;" });

            let title = (resource.title != null && resource.title != "") ? resource.title : ((resource.genderEnum == GenderEnum.Female) ? "Dott.ssa" : "Dott.");
            vr.createLabel(
                {
                    text: "<span style='font-size: 0.8em; font-weight: normal;'>" + title + "</span> " + resource.name,
                    width: "100%",
                    css: "font-weight: 600;",
                    noBr: true
                }, divInfo);

            if (resource.qualification != null && resource.qualification.length > 0)
            {
                vr.createLabel(
                    {
                        text: resource.qualification,
                        width: "100%",
                        css: "font-size: 1em;"
                    }, divInfo);
            }

            if (resource.rating != null && resource.rating.values.length > 0)
            {
                vr.createRating(
                    {
                        cssContainer: "margin-top: 7px;",
                        max: resource.rating.total,
                        value: resource.rating.values.sum() / resource.rating.values.length,
                        total: resource.rating.values.length,
                        enable: false
                    }, divInfo);
            }
            //#endregion

            //#region ActivityType
            let divActivityType = vr.div(divSlot, { css: "margin-top: 10px;" });
            vr.icon(vr.IconClassicLight.FileLines, divActivityType, { css: "margin-right: 5px; position: relative; top: -1px; color: " + Rotator.color() });
            vr.createLabel(
                {
                    text: Rotator.bookingParameters().activityType.onlineBookingName,
                    width: "Calc(100% - 25px)",
                    class: "vrPatient_lblActivityType"
                }, divActivityType);
            //#endregion

            //#region Workplaces
            if (Rotator.bookingParameters().workplace == null)
            {
                let divWorkplaces = vr.div(divSlot);
                this.getNextAvailability(resource.id, null, Rotator.bookingParameters().from, (e) =>
                {
                    let divSlots = vr.div(divSlot, { id: "vrPatientDivSlots", css: "margin-top: 10px; min-height: 137px;" });
                    if (e.nextAvailabilitiesByWorkplace.length == 0)
                    {
                        let workplaceList = Rotator.bookingParameters().workplaceList.filter(k => k.id != 0);
                        let workplace = null;
                        if (workplaceList.length == 1)
                            workplace = workplaceList[0];

                        this.drawWorkplaceTimeslots(divSlots, workplace, resource, []);
                    }
                    else 
                    {
                        if (e.nextAvailabilitiesByWorkplace.length > 1)
                        {
                            for (let availabilityWorkplace of e.nextAvailabilitiesByWorkplace)
                            {
                                let workplace = Rotator.bookingParameters().workplaceList.filter(k => k.id == availabilityWorkplace.workplaceId)[0];
                                let divWorkplace = vr.div(divWorkplaces, { class: "vrPatientDiwWorkplace", css: "width: Calc(50% - 20px); border-bottom: solid 3px #eaeaea; display: inline-block; text-align: center; padding: 10px;" });
                                vr.icon(vr.IconClassicLight.MapPin, divWorkplace, { css: "margin-right: 5px; position: relative; top: -1px; color: " + Rotator.color() });
                                puma(divWorkplace).attr("value", workplace.id);
                                puma(divWorkplace).click((e: any) =>
                                {
                                    //#region Transiction effect
                                    let oldSelected = puma(e.currentTarget).parent().find(".vrPatientDiwWorkplace.vrPatientWorkplaceSelected");
                                    if (oldSelected.attr("value") == puma(e.currentTarget).attr("value"))
                                        return;

                                    let colorOpacity = Rotator.color() + "47";
                                    oldSelected.css("border-bottom", "solid 3px " + colorOpacity);
                                    window.setTimeout(() => oldSelected.css("border-bottom", "solid 3px #eaeaea"), 100);
                                    oldSelected.removeClass("vrPatientWorkplaceSelected");
                                    //#endregion

                                    puma(e.currentTarget).css("border-bottom", "solid 3px " + Rotator.color());
                                    puma(e.currentTarget).addClass("vrPatientWorkplaceSelected");
                                    this.getNextAvailability(resource.id, workplace.id, Rotator.bookingParameters().from, (e2) =>
                                    {
                                        this.drawWorkplaceTimeslots(divSlots, workplace, resource, e2.nextAvailabilities);
                                    }, divSlots);
                                });

                                vr.createLabel({
                                    text: workplace.city,
                                    noBr: true
                                }, divWorkplace);
                            }

                            puma(puma(divSlot).find(".vrPatientDiwWorkplace")[0]).addClass("vrPatientWorkplaceSelected");
                            puma(puma(divSlot).find(".vrPatientDiwWorkplace")[0]).css("border-bottom", "solid 3px " + Rotator.color());
                        }

                        let workplace = Rotator.bookingParameters().workplaceList.filter(k => k.id == e.nextAvailabilitiesByWorkplace[0].workplaceId)[0];
                        this.getNextAvailability(resource.id, workplace.id, Rotator.bookingParameters().from, (e3) =>
                        {
                            this.drawWorkplaceTimeslots(divSlots, workplace, resource, e3.nextAvailabilities);
                        }, divSlots);
                    }
                    vrshared.LoaderManager().hide("resourcesLoadDiv");
                }, false);
            }
            else
            {
                let divSlots = vr.div(divSlot, { id: "vrPatientDivSlots", css: "margin-top: 10px; min-height: 137px;" });
                this.getNextAvailability(resource.id, Rotator.bookingParameters().workplace!.id, Rotator.bookingParameters().from, (f) =>
                {
                    this.drawWorkplaceTimeslots(divSlots, Rotator.bookingParameters().workplace!, resource, f.nextAvailabilities);
                    vrshared.LoaderManager().hide("resourcesLoadDiv");
                }, divSlots);
            }
            //#endregion
        }
    }

    static drawWorkplaceTimeslots(divSlot: HTMLElement, workplace: WorkplaceResponse | null, resource: ResourceResponse | null, timeslots: Timeslot[])
    {
        puma(divSlot).empty();

        //#region Address
        if (resource != null && workplace != null)
        {
            let divWorkplace = vr.div(divSlot, { css: "margin-top: 5px;" });
            vr.icon(vr.IconClassicLight.MapPin, divWorkplace, { css: "margin-right: 5px; position: relative; top: -1px; color: " + Rotator.color() });
            let address = Support.getAddressName(workplace);
            vr.createLabel(
                {
                    text: address + "<br /><span style='font-size: 0.9em; color: #737373'>" + workplace.name + "</span>",
                    width: "Calc(100% - 25px)"
                }, divWorkplace);
        }
        //#endregion

        let defaultTimeslot = timeslots[0];
        if (defaultTimeslot == null)
        {
            let divNoTimeslots = vr.div(divSlot, { css: "display: flex; align-items: center; justify-content: center; min-height: 93px; flex-direction: column;" });

            //#region No timeslots
            let noAvailabilityMessage = Rotator.configuration().appNoAvailabilitiesMessage;
            vr.createLabel({
                text: (noAvailabilityMessage != null) ? noAvailabilityMessage : "Non sono presenti disponibilità",
                css: "font-size: 1.3em;",
                width: "100%",
                cssContainer: "height: 50px; margin-bottom: 10px; margin-top: 10px;",
                colorSettings: { textColor: "#737373" },
                align: vr.TextAlignEnum.Center,
                noBr: 2
            }, divNoTimeslots);

            //#region Buttons
            let divNoAvailabilityButtons = null;
            if ((Rotator.configuration().appNoAvailabilitiesPhoneNumber != null && Rotator.configuration().appNoAvailabilitiesPhoneNumber.length > 0)
                || (Rotator.configuration().appNoAvailabilitiesMail != null && Rotator.configuration().appNoAvailabilitiesMail.length > 0))
                divNoAvailabilityButtons = vr.div(divNoTimeslots, { css: "width: 100%; display: flex;" });

            if (Rotator.configuration().appNoAvailabilitiesPhoneNumber != null && Rotator.configuration().appNoAvailabilitiesPhoneNumber.length > 0)
            {
                vr.createButton({
                    text: "Chiama",
                    colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
                    css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
                    width: "100%",
                    onClick: (e) =>
                    {
                        let link = document.createElement("a");
                        link.href = "tel:" + Rotator.configuration().appNoAvailabilitiesPhoneNumber;
                        link.click();
                    }
                }, divNoAvailabilityButtons);
            }

            if (Rotator.configuration().appNoAvailabilitiesMail != null && Rotator.configuration().appNoAvailabilitiesMail.length > 0
                && !Rotator.bookingParameters().activityType.useWaitingListIfNoAvailabilities)
            {
                vr.createButton({
                    text: "Email",
                    colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
                    css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
                    width: "100%",
                    onClick: (e) =>
                    {
                        let link = document.createElement("a");
                        link.href = "mailto:" + Rotator.configuration().appNoAvailabilitiesMail;
                        link.click();
                    }
                }, divNoAvailabilityButtons);
            }
            //#endregion

            //#region Waiting list
            if (Rotator.bookingParameters().activityType.useWaitingListIfNoAvailabilities === true
                && Rotator.version() >= 19 && workplace != null)
            {
                vr.createButton({
                    text: "Aggiungi alla lista di attesa",
                    colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
                    css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
                    cssContainer: "margin-top: 5px;",
                    width: "100%",
                    onClick: (e) =>
                    {
                        let waitingListParameters = new WaitingListParameters();
                        waitingListParameters.workplaceId = workplace.id;
                        waitingListParameters.resourceId = (resource != null) ? resource.id : 0;
                        Rotator.page(PageEnum.WaitingList, waitingListParameters);
                    }
                }, divNoTimeslots);
            }
            //#endregion

            //#endregion

            return;
        }

        //#region Price
        let activityTypeName = Rotator.bookingParameters().activityType.onlineBookingName;
        let displayPrice = Support.displayPrice(defaultTimeslot.price, PriceTypeEnum.ActivityType, true);
        puma(divSlot).parent().find(".vrPatient_lblActivityType").html(activityTypeName + displayPrice);
        //#endregion

        let divFirstAvailability = vr.div(divSlot, { css: "margin-top: 5px;" });
        vr.icon(vr.IconClassicLight.Calendar, divFirstAvailability, { css: "margin-right: 5px; position: relative; top: -1px; color: " + Rotator.color() });
        vr.createLabel(
            {
                text: "Prima disponibilità: <span style='font-weight: 600; color: " + Rotator.color() + "'>" + new Date(defaultTimeslot.day).toItalyShortString() + "</span>",
                width: "Calc(100% - 25px)"
            }, divFirstAvailability);

        let divTimeslots = vr.div(divSlot, { css: "padding-top: 10px;" });
        let i = 0;

        timeslots = timeslots.filter(k => new Date(new Date(k.day.replace("Z", "")).setHours(0, 0, 0)).isEqualsTo(new Date(new Date(defaultTimeslot.day).setHours(0, 0, 0))));
        for (let timeslot of timeslots)
        {
            ResourceTimeslots.createButtonTimeslot(resource, workplace!, divTimeslots, true, timeslots, timeslot);
            if (i == 2)
            {
                i++;
                break;
            }
            i++;
        }

        // Fill empty slots
        while (i <= 2)
        {
            vr.createButton(
                {
                    css: "box-shadow: #ccc 0px 1px 3px; width: calc(100% - 10px); color: #000;",
                    cssContainer: "width: 25%; justify-content: center; margin-bottom: 10px; min-height: 46px;",
                    text: "<span style='font-weight: 600; color: #CCC;'>x</span>"
                }, divTimeslots);
            i++;
        }

        ResourceTimeslots.createButtonTimeslot(resource, workplace!, divTimeslots, false, timeslots);
    }

    static createButtonTimeslot(resource: ResourceResponse | null, workplace: WorkplaceResponse, divTimeslots: HTMLElement, isSlot = false, timeslots: Timeslot[], timeslot?: Timeslot)
    {
        let text = "Altre";
        let btnClass = "vrButtonBookingTimeslotOther";
        if (isSlot && timeslot != null)
        {
            let fromHours = String(timeslot.hourFrom.hours).padStart(2, "0");
            let fromMinutes = String(timeslot.hourFrom.minutes).padStart(2, "0");
            text = fromHours + ":" + fromMinutes;

            btnClass = "vrButtonBookingTimeslot";
        }

        vr.createButton(
            {
                text: text,
                icon: (isSlot) ? undefined : vr.IconClassicLight.ChevronRight,
                iconSettings: { position: vr.PositionEnum.Right },
                colorSettings: (isSlot) ? { textColor: Rotator.color() } : { textColor: Rotator.textColor(), background: Rotator.color() },
                css: ((Rotator.isMobile() ? "font-size: 1em !important;" : "")) + "box-shadow: 0 1px 3px " + ((isSlot) ? Rotator.color() : "rgba(0,0,0,.3)") + "; width: Calc(100% - 10px);" + ((!isSlot) ? "font-size: 1em; height: 44px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" : ""),
                cssContainer: "width: 25%; justify-content: center; margin-bottom: 10px; min-height: 46px;",
                class: btnClass,
                tag: isSlot,
                onClick: (e) =>
                {
                    let resourceParameter = resource;
                    if (resource == null && timeslot != null)
                        resourceParameter = Rotator.bookingParameters().resourceList.filter(k => k.id == timeslot.resourceSubjectId)[0];

                    let parameters = new TimeslotListParameters();
                    parameters.resource = resourceParameter;
                    parameters.workplace = workplace;
                    parameters.resourceInstrumentId = (timeslot != null) ? timeslot.resourceInstrumentId : 0;
                    parameters.resourceSpaceId = (timeslot != null) ? timeslot.resourceSpaceId : 0;

                    if (timeslots != null)
                        parameters.timeslots = timeslots;

                    if (timeslot != null)
                    {
                        parameters.timeslot = timeslot;
                        parameters.price = timeslot.price;
                    }
                    else
                    {
                        parameters.timeslot = timeslots.first();
                        parameters.price = timeslots.first().price;
                    }

                    if (e.sender.tag() === false)
                        Rotator.page(PageEnum.TimeslotList, parameters);
                    else
                        Rotator.page(PageEnum.Confirmation1, parameters);
                }
            }, divTimeslots);
    }

    static getNextAvailability(resourceId: number | null, workplaceId: number | null, from: Date, callback?: (e: GetNextAvailabilityResponse) => void, divSlot?: HTMLElement | boolean)
    {
        this._indexForLoader++;
        if (divSlot !== false)
            vrshared.LoaderManager().show(divSlot as HTMLElement, true, this._indexForLoader);

        let request = new GetNextAvailabilityRequest();
        request.activityTypeId = Rotator.bookingParameters().activityType.id;
        request.searchFrom = Date.convertDateFromClient(from)!;
        request.resourceId = (resourceId == null) ? 0 : resourceId;
        request.workplaces = (workplaceId != null) ? [workplaceId] : Rotator.bookingParameters().workplaceList.map(k => k.id);
        request.call(
            {
                success: (response: GetNextAvailabilityResponse) =>
                {
                    if (callback != null)
                        callback(response);
                },
                mode: vrshared.WebApiModeEnum.Async,
                loader: false
            });
    }
}

//#region Classes
class GetNextAvailabilityRequest extends WebApiRequestBase
{
    public method: string = "/api/BookingWebApi/GetNextAvailability";

    public searchFrom: Date;
    public workplaces: number[] | null;
    public resourceId: number | null;
    public activityTypeId: number;
}

class GetNextAvailabilityResponse extends WebApiResponseBase
{
    public nextAvailabilities: Timeslot[];
    public nextAvailabilitiesByWorkplace: NextAvailabilityByWorkplace[];
}

class NextAvailabilityByWorkplace
{
    public workplaceId: number;
    public workplaceName: string;
    public nextAvailabilities: Timeslot[];
}
//#endregion