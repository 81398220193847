import { Popup, Support } from "../../src/support";
import { PageEnum, puma, Rotator } from "../../src/rotator";
import { WebApiRequestBase, WebApiResponseBase } from "../../src/shared/webApi";

export class Dashboard
{
	private static _container: HTMLElement;

	static initialize()
	{
		puma(Rotator.container()).addClass("vettorePatientContainerAppDashboard");
		this._container = puma("<div style='height: 100%;'></div>").appendTo(Rotator.container())[0];
		Rotator.title(Rotator.configuration().appName);

		this.createControls();
	}

	private static createControls()
	{
		//#region Logo
		if (Rotator.configuration().appDashboardShowLogo)
		{
			let logo = Rotator.configuration().appLogo;
			if (logo != null && logo != "")
			{
				vr.createImage({
					base64: true,
					value: logo,
					width: "100%",
					position: vr.ImagePositionTypeEnum.Center,
					cssContainer: "max-height: 80px; margin-top: 10px; z-index: 2;",
					height: 80
				}, this._container);
			}
		}
		//#endregion

		//#region Background
		if (String.isNotNullOrEmpty(Rotator.configuration().appDashboardBackgroundColor))
			this._container.style.cssText += "background-color: " + Rotator.configuration().appDashboardBackgroundColor + ";";
		else
			this._container.style.cssText += "background-color: " + Rotator.color() + ";";

		if (String.isNotNullOrEmpty(Rotator.configuration().appDashboardBackgroundImage))
		{
			vr.createImage({
				width: "100%",
				height: "100%",
				base64: true,
				value: Rotator.configuration().appDashboardBackgroundImage,
				cssContainer: "position: absolute; top: 0px; left: 0px; z-index: 1;"
			}, this._container)
		}
		//#endregion

		//#region Tiles
		let divTiles = vr.div(this._container, { css: "display: flex; flex-wrap: wrap; position: absolute; bottom: 20px; width: 100%;" });

		let request = new GetTilesRequest();
		request.call(
			{
				success: (response: GetTilesResponse) =>
				{
					for (let tile of response.tiles)
					{
						let divTile = vr.div(divTiles, { css: "flex: " + ((Rotator.isTablet()) ? "33" : "50") + "%; display: flex; justify-content: center;" });
						vr.createButton({
							text: tile.text,
							width: "Calc(100% - 10px)",
							css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%; padding: 20px;",
							class: "vrButtonBookingTile vrButtonPatient",
							cssContainer: "margin-bottom: 10px;",
							colorSettings: {
								textColor: (tile.color.isNotNullOrEmpty()) ? tile.color : "#000",
								background: (tile.backgroundColor.isNotNullOrEmpty()) ? tile.backgroundColor : "#FFF"
							},
							icon: tile.icon as vr.IconClassicLight,
							onClick: (e) =>
							{
								switch (tile.typeEnum)
								{
									case TileTypeEnum.Link:
										Support.openLinkPopup(tile.value);
										break;
									case TileTypeEnum.Navigation:
										{
											Rotator.webComponentParameters()[0].specificDoctorIdList = [];
											Rotator.webComponentParameters()[0].specificActivityTypeIdList = [];
											Rotator.webComponentParameters()[0].specificActivityTypeGroupIdList = [];
											Rotator.webComponentParameters()[0].specificWorkplaceId = 0;
											Rotator.page(tile.value as PageEnum);
										}
										break;
									case TileTypeEnum.Email:
										{
											let aTag = document.createElement("a");
											aTag.id = "dashboard_tempUrl";
											aTag.href = "mailto:" + tile.value;

											document.body.appendChild(aTag);
											aTag.click();
											puma("#dashboard_tempUrl").remove();
										}
										break;
									case TileTypeEnum.Call:
										{
											let aTag = document.createElement("a");
											aTag.id = "dashboard_tempUrl";
											aTag.href = "tel:" + tile.value;

											document.body.appendChild(aTag);
											aTag.click();
											puma("#dashboard_tempUrl").remove();
										}
										break;
									case TileTypeEnum.PopupText:
										let popup = new Popup(Rotator.container(),
											{
												closeable: true,
												height: "100%",
												modal: true,
												width: "Calc(100% - 40px)",
												css: "top: 40px !important; height: Calc(100% - 65px) !important;",
												content: tile.value
											});
										popup.open();
										break;
									case TileTypeEnum.SpecificDoctor:
										{
											Rotator.webComponentParameters()[0].specificDoctorIdList = tile.value.removeSpaces().split(",").toNumberArrayList();
											Rotator.page(PageEnum.Booking);
										}
										break;
									case TileTypeEnum.SpecificActivityType:
										{
											Rotator.webComponentParameters()[0].specificActivityTypeIdList = tile.value.removeSpaces().split(",").toNumberArrayList();
											Rotator.page(PageEnum.Booking);
										}
										break;
									case TileTypeEnum.SpecificActivityTypeGroup:
										{
											Rotator.webComponentParameters()[0].specificActivityTypeGroupIdList = tile.value.removeSpaces().split(",").toNumberArrayList();
											Rotator.page(PageEnum.Booking);
										}
										break;
									case TileTypeEnum.SpecificWorkplace:
										{
											Rotator.webComponentParameters()[0].specificWorkplaceId = Number(tile.value);
											Rotator.page(PageEnum.Booking);
										}
										break;
								}
							}
						}, divTile)
					}
				},
				mode: vrshared.WebApiModeEnum.Async,
				loader: Rotator.container()
			});
		//#endregion
	}
}

//#region ChangeTilePosition
class GetTilesRequest extends WebApiRequestBase
{
	public method: string = "/api/UtilityWebApi/GetTiles";
}

class GetTilesResponse extends WebApiResponseBase
{
	public tiles: OnlineBookingTileItem[];
}

class OnlineBookingTileItem
{
	public id?: number | null;
	public color: string;
	public backgroundColor: string;
	public icon: string | null;
	public typeEnum: TileTypeEnum;
	public displayType: string;
	public value: string;
	public position: number;
	public text: string;
}

enum TileTypeEnum
{
	Link = 1,
	Email = 2,
	Sms = 3,
	Call = 4,
	SpecificDoctor = 5,
	SpecificWorkplace = 6,
	SpecificActivityType = 7,
	PopupText = 8,
	Text = 9,
	Navigation = 10,
	SpecificActivityTypeGroup = 11
}
//#endregion