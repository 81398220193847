import { Rotator, puma, PageEnum } from "../rotator";
import { PriceTypeEnum, Support } from "../support";
import { AvailabilityRequestMultiplant, AvailabilityResponse, GenderEnum, ResourceResponse, Timeslot, WorkplaceResponse } from "../shared/common";
import { WebApiRequestBase, WebApiResponseBase } from "../shared/webApi";
import { ResourceTimeslots } from "./resourceTimeslots";

export class TimeslotList
{
    private static _container: HTMLElement;
    private static _parameters: TimeslotListParameters;
    private static _startDate: Date;
    private static _divTimeslots: HTMLElement;
    private static _initialDate: Date;
    private static _divWorkplace: HTMLElement;
    private static _popupAddress: HTMLElement;
    private static _popupAddressBackground: HTMLElement;
    private static _btnBack: vr.Button;

    static initialize(parameters: TimeslotListParameters)
    {
        if (parameters == null)
        {
            Rotator.back();
            return;
        }

        this._parameters = parameters;
        this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
        Rotator.title("DISPONIBILITÀ");
        this.createControls();
    }

    private static createControls()
    {
        //#region Info
        let divInfo = vr.div(this._container, { css: "padding: 10px; padding-top: 0px; padding-bottom: 0px;" });

        if (this._parameters.resource != null && !Rotator.sailor())
        {
            let resource = this._parameters.resource;
            let title = (resource.title != null && resource.title != "") ? resource.title : ((resource.genderEnum == GenderEnum.Female) ? "Dott.ssa" : "Dott.");
            vr.createLabel(
                {
                    text: title + " " + "<span style='font-weight: 600;'>" + resource.name + "</span>",
                    width: "100%"
                }, divInfo);
        }

        let divActivityType = vr.div(divInfo, { css: "margin-top: 5px;" });
        vr.icon(vr.IconClassicLight.FileLines, divActivityType, { css: "margin-right: 5px; color: " + Rotator.color() });

        let displayPrice = Support.displayPrice(this._parameters.price, PriceTypeEnum.ActivityType, true);
        vr.createLabel(
            {
                text: Rotator.bookingParameters().activityType.onlineBookingName + displayPrice,
                width: "Calc(100% - 25px)"
            }, divActivityType);

        //#region Address
        let workplace = this._parameters.workplace;
        this._divWorkplace = vr.div(divActivityType, { css: "margin-top: 10px; border-radius: 10px; padding: 5px; padding-left: 10px; padding-right: 10px;" });
        this._divWorkplace.style.cssText += "background-color: " + Rotator.color() + "; color: " + Rotator.textColor() + "; box-shadow: 0 1px 5px " + Rotator.color();
        puma(this._divWorkplace).on("click", (e: JQuery.ClickEvent) =>
        {
            if (puma(this._popupAddress).is(":visible"))
                this.hidePopupAddress();
            else
                this.showPopupAddress();
        });

        this.chooseAddress(workplace);
        this.createPopupAddress();
        //#endregion

        vr.createSeparator(
            {
                orientation: vr.OrientationEnum.Horizontal
            }, this._container);
        //#endregion

        //#region Timeslots
        let divTimeslotsContainer = vr.div(this._container, { css: "display: flex; justify-content: space-between;" });
        let divBack = vr.div(divTimeslotsContainer, { css: "width: 40px;" });
        this._btnBack = vr.createButton({
            icon: vr.IconClassicLight.ChevronLeft,
            css: "font-size: 2em; border: none;",
            enable: false,
            colorSettings: { textColor: Rotator.color() },
            onClick: (e) =>
            {
                this._startDate = this._startDate.addDays(-3);
                if (this._startDate.isLessThan(this._initialDate, true))
                {
                    this._startDate = this._initialDate;
                    this._btnBack.disable();
                }
                this.getTimeslots();
            }
        }, divBack);

        this._divTimeslots = vr.div(divTimeslotsContainer, { css: "width: 100%; overflow-x: hidden; white-space: nowrap;" });
        this._startDate = new Date(this._parameters.timeslot.day);
        this._startDate.setHours(0, 0, 0, 0);
        this._initialDate = new Date(this._startDate);
        this.getTimeslots();

        let divNext = vr.div(divTimeslotsContainer, { css: "width: 40px;" });
        vr.createButton({
            icon: vr.IconClassicLight.ChevronRight,
            css: "font-size: 2em; border: none;",
            colorSettings: { textColor: Rotator.color() },
            onClick: (e) =>
            {
                this._btnBack.enable();
                this._startDate = this._startDate.addDays(3);
                this.getTimeslots();
            }
        }, divNext);
        //#endregion
    }

    private static chooseAddress(workplace: WorkplaceResponse)
    {
        puma(this._divWorkplace).empty();
        let address = Support.getAddressName(workplace);
        vr.createLabel(
            {
                text: "<span>" + address + "</span><br /><span style='font-size: 0.9em;'>" + workplace.name + "</span>",
                width: "Calc(100% - 30px)",
                colorSettings: { textColor: Rotator.textColor() }
            }, this._divWorkplace);
        vr.icon(vr.IconClassicLight.ChevronDown, this._divWorkplace, { css: "margin-left: 10px; color: #FFF; font-size: 1.5em; position: relative; top: 9px;" });

        this._parameters.workplace = workplace;
    }

    private static hidePopupAddress()
    {
        puma(this._popupAddress).hide();
        puma(this._popupAddressBackground).hide();
    }

    private static showPopupAddress()
    {
        puma(this._popupAddress).show();
        puma(this._popupAddressBackground).show();
    }

    private static createPopupAddress()
    {
        this._popupAddress = vr.div(this._container, { class: "vrPatient_popupAddress" });
        puma(this._popupAddress).hide();

        this._popupAddressBackground = vr.div(puma(".vrPatientDivContainer"), { class: "vrPatient_popupBackground" });
        puma(this._popupAddressBackground).hide();
        puma(this._popupAddressBackground).on("click", (e: JQuery.ClickEvent) =>
        {
            this.hidePopupAddress();
        });

        for (let workplace of Rotator.bookingParameters().workplaceList)
        {
            if (workplace.id == 0 || workplace.id == null)
                continue;

            let divWorkplace = vr.div(this._popupAddress, { class: "vrPatient_popupAddressDivWorkplace" });
            vr.icon(vr.IconClassicLight.MapPin, divWorkplace, { css: "margin-right: 5px;" });
            let address = Support.getAddressName(workplace);
            vr.createLabel(
                {
                    text: workplace.name + "<br /><span style='font-size: 0.9em;'>" + address + "</span>",
                    width: "Calc(100% - 45px)"
                }, divWorkplace);

            let iconSelected = vr.icon(vr.IconClassicLight.Star, divWorkplace, { css: "margin-left: 5px; display: none; color: #FFF; font-size: 1.5em; position: relative; top: 10px; color: " + Rotator.color() });
            if (this._parameters.workplace.id == workplace.id)
                puma(iconSelected).show();

            puma(divWorkplace).on("click", (e: JQuery.ClickEvent) =>
            {
                puma(this._popupAddress).find("." + vr.IconClassicLight.Star.split(" ")[1]).hide();
                puma(e.currentTarget).find("." + vr.IconClassicLight.Star.split(" ")[1]).show();

                this.hidePopupAddress();
                this.chooseAddress(workplace);
                this.getTimeslots();
            });
        }
    }

    private static getTimeslots()
    {
        this._startDate.setHours(0, 0, 0, 0);

        let request = new GetTimeslotsRequest();
        request.from = Date.convertDateFromClient(this._startDate)!;
        request.to = Date.convertDateFromClient(this._startDate.addDays(3).addSeconds(-1))!;

        let requestMultiplantList: AvailabilityRequestMultiplant[] = [];
        let requestMultiPlant = new AvailabilityRequestMultiplant();
        requestMultiPlant.getNext = false;
        requestMultiPlant.activityTypeId = Rotator.bookingParameters().activityType.id;
        requestMultiPlant.workplaceIdList = [this._parameters.workplace.id];
        requestMultiPlant.resourceId = (this._parameters.resource != null) ? this._parameters.resource.id : 0;
        requestMultiplantList.push(requestMultiPlant);
        request.request = requestMultiplantList;

        vrshared.LoaderManager().show(Rotator.container());
        request.call(
            {
                success: (response: GetTimeslotsResponse) =>
                {
                    vrshared.LoaderManager().hide();
                    puma(this._divTimeslots).empty();
                    this.drawTimeslots(response.availabilityList, response.allTimeslots);
                },
                mode: vrshared.WebApiModeEnum.Async,
                loader: false
            });
    }

    private static drawTimeslots(availabilityList: AvailabilityResponse[], timeslotList: Timeslot[])
    {
        this._startDate.setHours(0, 0, 0, 0);

        //#region Map day/timeslots
        let usableTimeslots: Timeslot[] = [];

        let dayTimeslots = new Map<Date, Timeslot[]>();
        let startDateTimeslots = timeslotList.filter(k =>
        {
            let date = new Date(k.day);
            date.setHours(0, 0, 0, 0);
            return Date.equals(this._startDate, date);
        });
        dayTimeslots.set(this._startDate, startDateTimeslots);
        usableTimeslots.pushRange(startDateTimeslots);

        let secondDate = this._startDate.addDays(1);
        let secondDateTimeslots = timeslotList.filter(k =>
        {
            let date = new Date(k.day);
            date.setHours(0, 0, 0, 0);
            return Date.equals(secondDate, date);
        });
        dayTimeslots.set(secondDate, secondDateTimeslots);
        usableTimeslots.pushRange(secondDateTimeslots);

        let thirdDate = this._startDate.addDays(2);
        let thirdDateTimeslots = timeslotList.filter(k =>
        {
            let date = new Date(k.day);
            date.setHours(0, 0, 0, 0);
            return Date.equals(thirdDate, date);
        });
        dayTimeslots.set(thirdDate, thirdDateTimeslots);
        usableTimeslots.pushRange(thirdDateTimeslots);
        //#endregion

        let maxLength = 0;
        dayTimeslots.forEach((value: Timeslot[], key: Date) =>
        {
            if (value.length > maxLength)
                maxLength = value.length;
        });

        dayTimeslots.forEach((value: Timeslot[], key: Date) =>
        {
            let divTimeslot = vr.div(this._divTimeslots, { css: "display: inline-flex; width: 33.5%; justify-content: center; justify-items: center; flex-direction: column;" });

            vr.createLabel({
                text: Support.getDayWeekName(key, true),
                css: "font-size: 1.1em; font-weight: 600;",
                cssContainer: "justify-content: center;"
            }, divTimeslot);

            vr.createLabel({
                text: key.getDate() + " " + Support.getMonthName(key, true),
                css: "font-size: 1.1em;",
                cssContainer: "justify-content: center; margin-bottom: 10px;"
            }, divTimeslot);

            for (let i = 0; i < maxLength; i++)
            {
                let timeslot = value[i];
                let fromHours = "";
                let fromMinutes = "";
                if (timeslot != null)
                {
                    fromHours = String(timeslot.hourFrom.hours).padStart(2, "0");
                    fromMinutes = String(timeslot.hourFrom.minutes).padStart(2, "0");
                }

                vr.createButton(
                    {
                        text: (timeslot != null) ? fromHours + ":" + fromMinutes : "<span style='font-weight: 600; color: #CCC;'>x</span>",
                        colorSettings: { textColor: (timeslot != null) ? Rotator.color() : "#000" },
                        css: ((timeslot != null) ? "box-shadow: 0 1px 3px " + Rotator.color() : "box-shadow: 0 1px 3px rgba(0,0,0,.3)") + "; width: Calc(100% - 10px);",
                        cssContainer: "width: 100%; justify-content: center; margin-bottom: 10px;",
                        onClick: (e) =>
                        {
                            if (timeslot != null)
                            {
                                let resourceList = availabilityList.map(k => k.resource);
                                let resource = resourceList.filter(k => k.id == timeslot.resourceSubjectId)[0];

                                let parameters = new TimeslotListParameters();
                                parameters.resource = (this._parameters.resource != null) ? this._parameters.resource : resource;
                                parameters.price = this._parameters.price;
                                parameters.workplace = this._parameters.workplace;
                                parameters.timeslot = timeslot;

                                Rotator.confirmationParameters(parameters);
                                Rotator.page(PageEnum.Confirmation1, parameters);
                            }
                        }
                    }, divTimeslot);
            }
        });

        //#region No Availabilities
        if (usableTimeslots.length == 0)
        {
            let divNoAvailabilities = vr.div(this._divTimeslots, { css: "display: flex; margin-top: 5px; flex-direction: column; align-items: center; min-height: 80px;" });
            let lblText = vr.createLabel({
                cssContainer: "margin-bottom: 10px; font-size: 1.2em;",
                colorSettings: { textColor: "#adadad" }
            }, divNoAvailabilities);

            vrshared.LoaderManager().hide();
            ResourceTimeslots.getNextAvailability((this._parameters.resource != null) ? this._parameters.resource.id : null, this._parameters.workplace.id, this._startDate, (e) =>
            {
                vrshared.LoaderManager().hide();
                let nextTimeslots = e.nextAvailabilities;

                if (nextTimeslots.length == 0)
                    lblText.value("Nessuna disponibilità");
                else
                {
                    lblText.value("Prossima disponibilità");
                    vr.createButton({
                        text: new Date(nextTimeslots.first().day).toItalyLongString(),
                        colorSettings: { textColor: Rotator.color() },
                        css: "box-shadow: 0 1px 3px rgba(0,0,0,.3)",
                        width: "100%",
                        onClick: (e) =>
                        {
                            this._btnBack.enable();
                            this._startDate = new Date(nextTimeslots.first().day);
                            this.getTimeslots();
                        }
                    }, divNoAvailabilities);
                }
            }, Rotator.container());

            return;
        }
        vrshared.LoaderManager().hide();
        //#endregion
    }
}

//#region Classes
export class TimeslotListParameters
{
    timeslots: Timeslot[];
    resource: ResourceResponse | null;
    price: number;
    workplace: WorkplaceResponse;
    timeslot: Timeslot;
    subjectId: number;
    resourceInstrumentId: number;
    resourceSpaceId: number;
}

class GetTimeslotsRequest extends WebApiRequestBase
{
    public method: string = "/api/BookingWebApi/GetTimeslots";

    public request: AvailabilityRequestMultiplant[];
    public from: Date;
    public to: Date;
}

class GetTimeslotsResponse extends WebApiResponseBase
{
    public allTimeslots: Timeslot[];
    public availabilityList: AvailabilityResponse[];
}

//#endregion