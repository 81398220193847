import { WebApiRequestBase, WebApiResponseBase } from "../../src/shared/webApi";
import { Rotator, puma, PageEnum } from "../../src/rotator";
import { LoginParameters } from "./login";

export class ForgotPassword
{
    private static _container: HTMLElement;
    private static _txtUsername: vr.TextBox;
    private static _btnSendPassword: vr.Button;

    static initialize()
    {
        this._container = puma("<div class='forward padContainer'></div>").appendTo(Rotator.container())[0];
        Rotator.title("PASSWORD");

        this.createControls();
    }

    static createControls()
    {
        puma(this._container).empty();
        this._txtUsername = vr.createTextBox(
            {
                label: "Nome utente",
                placeholder: "Nome utente",
                onEnterKey: () => this._btnSendPassword.click(),
                mode: vr.TextModeEnum.Text,
                width: "100%",
                cssContainer: "margin-bottom: 10px;",
                labelSettings: { colorSettings: { textColor: Rotator.color() } }
            }, this._container);

        vr.createLabel(
            {
                text: "Una nuova password di accesso sarà inviata tramite SMS al numero di telefono cellulare inserito in fase di registrazione dell'utente",
                mode: vr.LabelModeEnum.Default,
                cssContainer: "margin-bottom: 10px;"
            }, this._container);

        this._btnSendPassword = vr.createButton({
            text: "Recupera password",
            colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
            css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
            cssContainer: "margin-top: 10px;",
            width: "100%",
            class: "vrButtonRecoverPassword vrButtonPatient",
            onClick: (e) =>
            {
                //#region Check
                let error = false;
                if (this._txtUsername.isEmpty())
                {
                    this._txtUsername.error("Nome utente obbligatorio", vr.ErrorModeEnum.Solid);
                    error = true;
                }

                if (error)
                    return;
                //#endregion

                this.recoverPassword(this._txtUsername.value()).then(() =>
                {
                    let loginParameters = new LoginParameters();
                    loginParameters.fromRegister = true;
                    loginParameters.username = this._txtUsername.value();
                    Rotator.page(PageEnum.Login, loginParameters);
                });
            }
        }, this._container);
    }

    static recoverPassword(username: string)
    {
        let promise = new Promise<void>((callback) =>
        {
            let request = new ForgotPasswordRequest();
            request.username = username;
            request.call(
                {
                    success: (response: ForgotPasswordResponse) =>
                    {
                        callback();
                    },
                    loader: Rotator.container()
                });
        });
        return promise;
    }
}

//#region ForgotPassword
class ForgotPasswordRequest extends WebApiRequestBase 
{
    public method: string = "/api/LoginWebApi/ForgotPassword";
    public username: string;
}

class ForgotPasswordResponse extends WebApiResponseBase 
{
}
//#endregion