import { WebApiRequestBase, WebApiResponseBase } from "../shared/webApi";
import { PageEnum, puma, Rotator } from "../rotator";
import { FileTypeEnum, Support } from "../support";
import { ReportItem, SendingAttachmentsDisplay } from "../../src/shared/common";
import { ReportSendingStorageListParameters } from "./reportSendingStorageList";

export class ReportList
{
	private static _container: HTMLElement;
	private static _reports: ReportItem[];
	private static _divReports: HTMLElement;
	private static _wndPrivacy: vr.Window;

	static initialize()
	{
		this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
		this._container.style.cssText += "position: relative;";
		Rotator.title("REFERTI");

		Support.checkLogin(() => this.getReports((e) => 
		{
			this._reports = e.reports;
			this._divReports = vr.div(this._container);
			this.drawReports();
		}), null, () => Rotator.page(PageEnum.ReportList));
	}

	private static drawReports()
	{
		puma(this._divReports).empty();
		if (this._reports.length == 0)
		{
			let divNoRecords = vr.div(this._divReports, { class: "vrPatientDivSlot" });
			vr.createLabel({
				text: "Non sono presenti referti",
				width: "100%",
				align: vr.TextAlignEnum.Center
			}, divNoRecords);
			return;
		}

		for (let report of this._reports)
			this.drawReportDetail(report, this._divReports);
	}

	static drawReportDetail(report: ReportItem, container: HTMLElement): HTMLElement
	{
		let divReport = vr.div(container, { class: "vrPatientDivSlot" });

		//#region Icon/Info
		let divIconInfo = vr.div(divReport, { css: "display: flex;" });
		vr.icon(vr.IconClassicLight.FileLines, divIconInfo, { css: "font-size: 4em; margin-top: 6px; margin-right: 10px; color: #84d8ff;" });

		let divInfo = vr.div(divIconInfo, { css: "text-align: left; width: 100%;" });
		vr.createLabel({
			text: report.activityTypeName,
			bold: true,
			css: "text-overflow: ellipsis; overflow: hidden; width: 100%;",
			cssContainer: "width: Calc(100% - 55px);"
		}, divInfo);

		vr.createLabel({
			text: new Date(report.date).toItalyLongString(),
			width: "100%"
		}, divInfo);

		vr.createLabel({
			text: report.doctorName.titleCase(),
			width: "100%"
		}, divInfo);

		if (report.representerOfName != null && report.representerOfName.length > 0)
		{
			vr.createLabel(
				{
					text: "Referto di " + report.representerOfName,
					noBr: true,
					width: "100%"
				}, divInfo);
		}

		if (Rotator.configuration().appShowPlantInFiles)
		{
			vr.createLabel(
				{
					text: report.plantCompanyName,
					noBr: true,
					width: "100%"
				}, divInfo);
		}
		//#endregion

		//#region Buttons
		let divButtons = vr.div(divReport, { css: "margin-top: 10px; display: flex; justify-content: space-between;" });
		vr.createButton({
			text: "Visualizza",
			colorSettings: { textColor: Rotator.color() },
			css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
			cssContainer: "width: 100%;",
			onClick: (e) => 
			{
				if (Rotator.version() < 20)
					this.downloadReport(report.storedFileId, report.date);
				else
				{
					let request = new GetPacsInfoRequest();
					request.reportSendingId = report.reportSendingId;
					request.call(
						{
							success: (response: GetPacsInfoResponse) => 
							{
								if (response.hasLicense)
								{
									if (response.deliveryStatus == null || response.deliveryStatus === true)
										this.manageDownloadReport(report, response);
									else
									{
										CredentialsPopup.open(this._container).then(() =>
										{
											this.manageDownloadReport(report, response);
										});
									}
								}
								else
									this.manageDownloadReport(report, response);
							},
							loader: Rotator.container()
						});
				}
			}
		}, divButtons);

		let privacyHealthInfo = (Rotator.configuration().privacyHealthInfoText != "") ? Rotator.configuration().privacyHealthInfoText : "Consenso per visualizzazione fascicolo sanitario";
		this._wndPrivacy = vr.createWindow(
			{
				title: "Consenso Fasciscolo Sanitario",
				maximized: false,
				content: "<div>" + privacyHealthInfo + "</div>",
				css: "overflow-y: auto;",
				cssContainer: "width: 100% !important; height: " + (Rotator.container().clientHeight - 20) + "px !important; position: absolute !important; left: 0px !important;",
				draggable: false,
				footer: true,
				modal: false,
				hideCloseIcon: true
			}, Rotator.shadowRoot() as any);
		puma(this._wndPrivacy.container()).appendToPuma(this._container);
		//#endregion

		return divReport;
	}

	private static manageDownloadReport(report: ReportItem, response: GetPacsInfoResponse)
	{
		if (response.storageList.length > 0 || response.openViewer)
		{
			let parameters = new ReportSendingStorageListParameters();
			parameters.storageFileList = response.storageList;
			parameters.report = report;
			parameters.openViewer = response.openViewer;
			Rotator.page(PageEnum.ReportSendingStorageList, parameters)
		}
		else
			this.downloadReport(report.storedFileId, report.date);
	}

	static downloadReport(storedFileId: number, date: Date)
	{
		Support.getFileData(storedFileId, (e) =>
		{
			if (e.requiredHealthRecordPrivacy)
			{
				this._wndPrivacy.open([{
					value: "ok", callback: () =>
					{
						this.setPrivacyEHealthRecord(() =>
						{
							this._wndPrivacy.close();
							vrshared.UtilityManager().downloadFile(e.base64, "referto_" + new Date(date).toItalyShortString() + ".pdf", FileTypeEnum.Pdf, !Rotator.isApp());
						});
					}
				}], false, { top: Rotator.container().scrollTop });
			}
			else
				vrshared.UtilityManager().downloadFile(e.base64, "referto_" + new Date(date).toItalyShortString() + ".pdf", FileTypeEnum.Pdf, !Rotator.isApp());
		});
	}

	static setPrivacyEHealthRecord(callback?: Function)
	{
		let request = new SetPrivacyEHealthRecordRequest();
		request.plantId = Rotator.configuration().plant.id;
		request.privacyValue = true;
		request.call(
			{
				success: (response: SetPrivacyEHealthRecordResponse) =>
				{
					if (callback != null)
						callback();
				},
				mode: vrshared.WebApiModeEnum.Async,
				loader: Rotator.container()
			});
	}

	static getReports(callback?: (e: GetReportsResponse) => void)
	{
		let request = new GetReportsRequest();
		request.plantIdList = [Rotator.configuration().plant.id];
		request.call(
			{
				success: (response: GetReportsResponse) =>
				{
					if (callback != null)
						callback(response);
				},
				mode: vrshared.WebApiModeEnum.Async,
				loader: Rotator.container()
			});
	}
}

//#region Classes
class GetReportsRequest extends WebApiRequestBase
{
	public method: string = "/api/PrivateAreaWebApi/GetReports";
	public plantIdList: number[];
}

class GetReportsResponse extends WebApiResponseBase
{
	public reports: ReportItem[];
}

class SetPrivacyEHealthRecordRequest extends WebApiRequestBase
{
	public method: string = "/api/PrivateAreaWebApi/SetPrivacyEHealthRecord";
	public privacyValue: boolean;
	public plantId: number;
}

class SetPrivacyEHealthRecordResponse extends WebApiResponseBase
{

}

class GetPacsInfoRequest extends WebApiRequestBase
{
	public method: string = "/api/PrivateAreaWebApi/GetPacsInfo";
	public reportSendingId: number;
}

class GetPacsInfoResponse extends WebApiResponseBase
{
	public storageList: SendingAttachmentsDisplay[];
	public urlPacs: string;
	public deliveryStatus?: boolean;
	public hasLicense: boolean;
	public openViewer: boolean;
}
//#endregion

//#region Popup credentials
class CredentialsPopup
{
	//#region Variables
	static KEY = "10P9mU3aMttOeo10elo19dev13per";
	private static _divContainer: HTMLElement;
	private static _wndCredentialsPopup: vr.Window;

	private static _itemId?: number | null;
	private static _saveWindow?: Function;

	private static _txtPassword: vr.TextBox;
	private static _txtPin: vr.TextBox;
	private static _txtOtp: vr.TextBox;
	//#endregion

	//#region Initialize
	private static initialize(container: HTMLElement)
	{
		let promise = new Promise((callback: Function) =>
		{
			if (this._wndCredentialsPopup != null) { callback(); return; }

			//#region Window
			this._wndCredentialsPopup = vr.createWindow(
				{
					title: "Inserire credenziali",
					maximized: false,
					css: "overflow-y: auto;",
					cssContainer: "width: 100% !important; height: " + (Rotator.container().clientHeight - 20) + "px !important; position: absolute !important; left: 0px !important;",
					draggable: false,
					modal: false,
					hideCloseIcon: true,
					footer: [
						{ type: vr.WindowFooterItemTypeEnum.Close },
						{ type: vr.WindowFooterItemTypeEnum.Ok, text: "Accedi" }
					]
				}, Rotator.shadowRoot() as any);
			puma(this._wndCredentialsPopup.container()).appendToPuma(container);
			//#endregion

			this._divContainer = vr.div(this._wndCredentialsPopup.element());
			callback();
		});
		return promise;
	}
	//#endregion

	//#region Open
	static open(container: HTMLElement, closeCallback?: () => void | null)
	{
		let promise = new Promise((callback: Function) =>
		{
			this.initialize(container).then(() =>
			{
				//#region Window opening
				this._saveWindow = callback;
				let saveWindowOriginal = () => { this.save(); };
				let closeWindowOriginal = () => { if (closeCallback != null) closeCallback(); }
				this._wndCredentialsPopup.open([{ value: "ok", callback: saveWindowOriginal }]).then(closeWindowOriginal);
				//#endregion

				this.createControls().then(() => 
				{
					this.clear();
				});
			});
		});
		return promise;
	}
	//#endregion

	//#region Create controls
	private static createControls()
	{
		let promise = new Promise((callback: Function) =>
		{
			if (this._txtPassword != null)
			{
				callback();
				return;
			}

			this._txtPassword = vr.createTextBox(
				{
					label: "Password",
					placeholder: "Inserisci Password",
					onEnterKey: () => this.save(),
					mode: vr.TextModeEnum.Text,
					width: "100%",
					cssContainer: "margin-bottom: 10px;",
					labelSettings: { colorSettings: { textColor: Rotator.color() } }
				}, this._divContainer);

			this._txtPin = vr.createTextBox(
				{
					label: "PIN",
					placeholder: "Inserici PIN",
					onEnterKey: () => this.save(),
					mode: vr.TextModeEnum.Password,
					width: "100%",
					cssContainer: "margin-bottom: 10px;",
					labelSettings: { colorSettings: { textColor: Rotator.color() } }
				}, this._divContainer);

			this._txtOtp = vr.createTextBox(
				{
					label: "OTP",
					visible: false,
					placeholder: "Inserisci OTP ricevuto",
					onEnterKey: () => this.save(),
					mode: vr.TextModeEnum.Password,
					width: "100%",
					cssContainer: "margin-bottom: 10px;",
					labelSettings: { colorSettings: { textColor: Rotator.color() } }
				}, this._divContainer);

			callback();
		});
		return promise;
	}
	//#endregion

	//#region Clear
	private static clear()
	{
		this._txtPassword.clear();
		this._txtPin.clear();
		this._txtOtp.clear();
		this._txtOtp.hide();
	}
	//#endregion

	//#region Common popup methods
	static window()
	{
		return this._wndCredentialsPopup;
	}

	static title(title?: string)
	{
		if (title != null)
			this._wndCredentialsPopup.title(title);
		return this._wndCredentialsPopup.title();
	}

	static close()
	{
		this._wndCredentialsPopup.close();
	}

	private static save()
	{
		//#region Check
		let error = false;
		if (this._txtPin.isEmpty())
		{
			vr.notifyError("PIN obbligatorio");
			error = true;
		}

		if (this._txtPassword.isEmpty())
		{
			vr.notifyError("Password obbligatoria");
			error = true;
		}

		if (this._txtOtp.visible() && this._txtOtp.isEmpty())
		{
			vr.notifyError("OTP obbligatorio");
			error = true;
		}

		if (error)
			return;
		//#endregion

		let request = new ReportSendingLoginRequest();
		request.pin = this._txtPin.value();
		request.password = this._txtPassword.value();
		request.otp = this._txtOtp.value<string>();
		request.call(
			{
				success: (response: ReportSendingLoginResponse) => 
				{
					if (response.requireOTP)
					{
						this._txtOtp.show();
						return;
					}

					if (this._saveWindow != null) this._saveWindow();
					this.close();
				},
				error: (e) =>
				{
					this._txtOtp.clear();
				},
				loader: Rotator.container()
			});
	}
	//#endregion
}

//#region Classes
class ReportSendingLoginRequest extends WebApiRequestBase
{
	public method: string = "/api/PrivateAreaWebApi/ReportSendingLogin";

	public pin: string;
	public password: string;
	public otp?: string | null;
}

class ReportSendingLoginResponse extends WebApiResponseBase
{
	public requireOTP: boolean;
	public fileInfo: FileInfo;
}

class FileInfo
{
	public dataBase64: string;
	public fileName: string;
	public reportSendingId: number;
	public reportId?: number;
}
//#endregion

//#endregion