import { Rotator, puma, PageEnum } from "../../src/rotator";
import { WebApiRequestBase, WebApiResponseBase } from "../shared/webApi";
import { AuthenticationManager, PasswordRules } from "../../src/shared/authenticationManager";
import { VrManager } from "../../src/shared/vrManager";

export class ChangePassword
{
    private static _container: HTMLElement;
    private static _parameters?: ChangePasswordParameters;
    private static _txtPassword: vr.TextBox;
    private static _txtPasswordConfirm: vr.TextBox;
    private static _btnChangePassword: vr.Button;
    private static _passwordRules: PasswordRules;

    static initialize(parameters?: ChangePasswordParameters)
    {
        this._parameters = parameters;
        this._container = puma("<div class='forward padContainer'></div>").appendTo(Rotator.container())[0];
        Rotator.title("PASSWORD");

        this._passwordRules = AuthenticationManager.getPasswordRules();
        this.createControls();
    }

    static createControls()
    {
        puma(this._container).empty();
        if (this._parameters != null && this._parameters.passwordExpiredGuid != null)
        {
            vr.createLabel(
                {
                    text: "La password è scaduta, per cui è necessario impostarne una nuova",
                    mode: vr.LabelModeEnum.Default,
                    cssContainer: "margin-bottom: 10px;"
                }, this._container);
        }

        this._txtPassword = vr.createTextBox(
            {
                label: "Password",
                placeholder: "Password",
                onEnterKey: () => this._btnChangePassword.click(),
                mode: vr.TextModeEnum.Password,
                width: "100%",
                cssContainer: "margin-bottom: 10px;",
                labelSettings: { colorSettings: { textColor: Rotator.color() } },
                onKeyUp: (e) =>
                {
                    let result = AuthenticationManager.checkPasswordStrength(String(e.value), this._passwordRules);
                    if (!result.IsValid && e.value != "")
                        this._txtPassword.error(result.ErrorMessage, vr.ErrorModeEnum.Solid, vr.ErrorPositionEnum.Bottom, vr.ErrorHideModeEnum.Never);
                    else
                        this._txtPassword.hideError();
                }
            }, this._container);
        this._txtPassword.focus();

        this._txtPasswordConfirm = vr.createTextBox(
            {
                label: "Conferma password",
                placeholder: "Conferma password",
                onEnterKey: () => this._btnChangePassword.click(),
                mode: vr.TextModeEnum.Password,
                width: "100%",
                cssContainer: "margin-bottom: 10px;",
                labelSettings: { colorSettings: { textColor: Rotator.color() } },
                onKeyUp: (e) =>
                {
                    if (this._txtPassword.value() != this._txtPasswordConfirm.value() && e.value != "")
                        this._txtPasswordConfirm.error("Le password devono coincidere!", vr.ErrorModeEnum.Solid, vr.ErrorPositionEnum.Bottom, vr.ErrorHideModeEnum.Never);
                    else
                        this._txtPasswordConfirm.hideError();
                }
            }, this._container);

        this._btnChangePassword = vr.createButton({
            text: "Conferma modifica",
            colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
            css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
            width: "100%",
            class: "vrButtonChangePassword vrButtonPatient",
            onClick: (e) =>
            {
                //#region Check
                let error = false;
                if (this._txtPassword.isEmpty())
                {
                    this._txtPassword.error("Password obbligatoria", vr.ErrorModeEnum.Solid, vr.ErrorPositionEnum.Bottom);
                    error = true;
                }

                if (this._txtPasswordConfirm.isEmpty())
                {
                    this._txtPasswordConfirm.error("Conferma password obbligatoria", vr.ErrorModeEnum.Solid, vr.ErrorPositionEnum.Bottom);
                    error = true;
                }

                if (this._txtPassword.value() !== this._txtPasswordConfirm.value())
                {
                    this._txtPasswordConfirm.error("Le password devono coincidere", vr.ErrorModeEnum.Solid, vr.ErrorPositionEnum.Bottom);
                    error = true;
                }

                if (this._txtPassword.hasError() || this._txtPasswordConfirm.hasError())
                    error = true;

                if (error)
                    return;
                //#endregion

                let isPasswordStrong = AuthenticationManager.isPasswordStrong(this._txtPassword.value());
                if (!isPasswordStrong)
                {
                    VrManager.notify("La password non è abbastanza sicura");
                    return;
                }

                let request = new ChangePasswordRequest();
                request.passwordExpireGuid = (this._parameters != null) ? this._parameters.passwordExpiredGuid : null;
                request.password = this._txtPassword.value();
                request.call(
                    {
                        success: (response: ChangePasswordResponse) =>
                        {
                            if (Rotator.inBookingFlow())
                                VrManager.notify("Password cambiata con successo. A breve verrai reindirizzato alla pagina di login. Attendi...");
                            else
                                VrManager.notify("Password cambiata con successo");

                            if (Rotator.inBookingFlow())
                            {
                                vrshared.LoaderManager().show();
                                window.setTimeout(() => 
                                {
                                    vrshared.LoaderManager().hide();
                                    Rotator.page(PageEnum.Login);
                                }, 1500);
                            }
                            else
                                Rotator.back();
                        },
                        loader: Rotator.container()
                    });
            }
        }, this._container);
    }
}

//#region Classes
export class ChangePasswordParameters
{
    passwordExpiredGuid?: string;
}

//#region ChangePassword
class ChangePasswordRequest extends WebApiRequestBase 
{
    public method: string = "/api/LoginWebApi/ChangePassword";
    public passwordExpireGuid?: string | null;
    public password: string;
}

class ChangePasswordResponse extends WebApiResponseBase 
{
}
//#endregion

//#endregion