import { Rotator, puma } from "../../src/rotator";
import { CashAdvanceInfo } from "../../src/privateArea/paymentData";
import { Confirmation1 } from "./confirmation1";

declare var leafletImage: any;

export class PaymentResult
{
	private static _container: HTMLElement;
	private static _parameters: PaymentResultParameters;
	private static _base64Map: string;

	static initialize(parameters: PaymentResultParameters)
	{
		this._parameters = parameters;
		this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
		Rotator.title("CONFERMA");

		this.createControls();
		window.setTimeout(() => Rotator.burgerButton().show(), 300);
		Rotator.confirmationParameters(null);
	}

	static createControls()
	{
		let divSummary = vr.div(this._container, { class: "vrPatientDivSlot" });
		Confirmation1.drawSummary(divSummary);

		let map = vr.createMap(
			{
				latitude: Rotator.confirmationParameters()!.workplace.latitude,
				longitude: Rotator.confirmationParameters()!.workplace.longitude,
				cssContainer: "margin-top: 15px;",
				height: 120,
				enable: false,
				mode: vr.MapModeEnum.Streets,
				onClick: (e) => 
				{
					vrshared.UtilityManager().openUrl("https://www.google.com/maps/search/?api=1&query=" + Rotator.confirmationParameters()!.workplace.latitude + "," + Rotator.confirmationParameters()!.workplace.longitude, true);
				}
			}, divSummary);

		leafletImage(map.map(), (err: any, canvas: any) =>
		{
			this._base64Map = canvas.toDataURL("image/jpeg").replace("data:image/jpeg;base64,", "");
		});

		let divSlot = vr.div(this._container, { class: "vrPatientDivSlot", css: "margin-bottom: 30px;" });
		vr.createLabel({
			width: "100%",
			cssContainer: "margin-bottom: 10px;",
			align: vr.TextAlignEnum.Center,
			text: (this._parameters.cashAdvanceInfo == null) ? "Pagamento riuscito con successo!" : "Acconto di " + this._parameters.cashAdvanceInfo.cashAdvancePrice + "€ pagato con successo!"
		}, divSlot);

		vr.createButton(
			{
				text: "Prenota un altro",
				colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
				css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
				cssContainer: "margin-top: 10px;",
				width: "100%",
				onClick: (e) => Rotator.goToMainPage()
			}, divSlot);

		if (this._parameters.documentId > 0)
		{
			vr.createButton(
				{
					text: "Scarica fattura",
					colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
					css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
					cssContainer: "margin-top: 10px;",
					width: "100%",
					onClick: (e) => vrshared.UtilityManager().downloadFile(this._parameters.documentBase64, this._parameters.documentName)
				}, divSlot);
		}
	}
}

//#region Classes
export class PaymentResultParameters
{
	appointmentId: number;
	documentId: number;
	documentName: string;
	documentBase64: string;
	cashAdvanceInfo: CashAdvanceInfo;
}
//#endregion