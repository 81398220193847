import { WebComponentSourceModeEnum, WebComponentConfig, WebComponentModeEnum } from "./main";
import { Booking, BookingParameters } from "./booking/booking";
import { ActivityList } from "./booking/activityList";
import { WorkplaceList } from "./booking/workplaceList";
import { DoctorList } from "./booking/doctorList";
import { FromList } from "./booking/fromList";
import { ResourceTimeslots } from "./booking/resourceTimeslots";
import { TimeslotList, TimeslotListParameters } from "./booking/timeslotList";
import { Confirmation1 } from "./booking/confirmation1";
import { Login } from "./login/login";
import { Register } from "./login/register";
import { Confirmation2 } from "./booking/confirmation2";
import { ChangePassword } from "./login/changePassword";
import { ForgotPassword } from "./login/forgotPassword";
import { AppointmentList } from "./privateArea/appointmentList";
import { Menu } from "./shared/menu";
import { AppointmentDetail } from "./privateArea/appointmentDetail";
import { DocumentList } from "./privateArea/documentList";
import { ReportList } from "./privateArea/reportList";
import { FileList } from "./privateArea/fileList";
import { Profile } from "./privateArea/profile";
import { PaymentData } from "./privateArea/paymentData";
import { PaymentResult } from "./booking/paymentResult";
import { TrackingCookieInfo } from "./support";
import { WorkplaceTimeslots } from "./booking/workplaceTimeslots";
import { SailorHome } from "./sailor/sailorHome";
import { SailorRegister } from "./sailor/sailorRegister";
import { InfoPage } from "./info/infoPage";
import { NoInternetConnection } from "./shared/noInternetConnection";
import { WaitingList } from "./booking/waitingList";
import { Dashboard } from "./shared/dashboard";
import { ReportSendingStorageList } from "./privateArea/reportSendingStorageList";
import { FileLoading } from "./booking/fileLoading";

export class Rotator
{
	//#region Variables
	private static _configuration: Configuration;
	private static _webComponentParameters: WebComponentConfig[];
	private static _pageHistory: PageEnum[] = [];
	private static _shadowRoot: ShadowRoot;
	private static _backgroundColor: string;
	private static _color: string;
	private static _appHeaderColor: string;
	private static _appHeaderTextColor: string;
	private static _token: string;
	private static _inBookingFlow: boolean;
	private static _cookieSessionTimeout: number | null;
	private static _bookingParameters: BookingParameters;
	private static _confirmationParameters: TimeslotListParameters | null;
	private static _trackingCookiesInfo: TrackingCookieInfo[];
	private static _sailorType: SailorTypeEnum;
	private static _campaignSourceId: number;
	private static _campaignMediumId: number;
	private static _campaignName: string;
	private static _mainButton: vr.Button;
	private static _filterAppointmentOnPast: boolean;
	//#endregion

	static initialize()
	{
		if (this.isMobile() || this.isApp())
			$(window).on("popstate", () => this.back());
	}

	static patientUrl()
	{
		let url = "";
		if (Rotator.webComponentParameters()[0].source == null)
			Rotator.webComponentParameters()[0].source = WebComponentSourceModeEnum.Crm;

		switch (Rotator.webComponentParameters()[0].source)
		{
			case WebComponentSourceModeEnum.Crm: url = (location.href.includes("localhost")) ? "" : "https://booking.vrapp.it"; break;
			case WebComponentSourceModeEnum.Vettore: url = "https://booking.vrapp.it"; break;
			case WebComponentSourceModeEnum.CrmLink: url = (location.href.includes("localhost")) ? "" : "https://booking.vrapp.it"; break;
			case WebComponentSourceModeEnum.Test: url = "https://test1.vrapp.it"; break;
		}
		return url;
	}

	static cookieSessionTimeout(cookieSessionTimeout?: number | null)
	{
		if (cookieSessionTimeout != null)
			this._cookieSessionTimeout = cookieSessionTimeout;

		return this._cookieSessionTimeout;
	}

	static setCookieSession()
	{
		let sessionId = vrshared.UtilityManager().getUrlParameter("sessionId");
		if (sessionId == null || sessionId == "")
			sessionId = vrshared.CookieManager().getCookie("sessionId");

		if (sessionId == null || sessionId.length == 0)
			sessionId = vrshared.UtilityManager().createGuid();

		vrshared.CookieManager().deleteCookie("sessionId");
		if (Rotator.configuration() == null || !Rotator.isApp())
			vrshared.CookieManager().setCookieMinutes("sessionId", sessionId, 30, { sameSite: vrshared.CookieSameSiteEnum.Lax, secured: true });
		else if (Rotator.isApp())
			vrshared.CookieManager().setCookieYears("sessionId", sessionId, 1, { sameSite: vrshared.CookieSameSiteEnum.Lax, secured: true });

		return sessionId;
	}

	static version()
	{
		let version = this.configuration().vettoreVersion;
		if (version == null)
			return 19;

		return Number(version.slice(0, version.length - (version.length - 2)));
	}

	static mode()
	{
		if (Rotator.webComponentParameters()[0].mode == null)
			Rotator.webComponentParameters()[0].mode = WebComponentModeEnum.AllModules;

		return Rotator.webComponentParameters()[0].mode;
	}

	static token(token?: string | null)
	{
		if (token != null)
			this._token = token;

		return this._token;
	}

	static color(color?: string)
	{
		if (color != null)
			this._backgroundColor = color;

		return this._backgroundColor;
	}

	static textColor(color?: string)
	{
		if (color != null)
			this._color = color;

		return this._color;
	}

	static appHeaderColor(color?: string)
	{
		if (color != null)
			this._appHeaderColor = color;

		return this._appHeaderColor;
	}

	static appHeaderTextColor(color?: string)
	{
		if (color != null)
			this._appHeaderTextColor = color;

		return this._appHeaderTextColor;
	}

	static mainButton(button?: vr.Button)
	{
		if (button != null)
			this._mainButton = button;

		return this._mainButton;
	}

	//#region Marketing UTM
	static campaignSourceId(sourceId?: number)
	{
		if (sourceId != null)
			this._campaignSourceId = sourceId;

		return this._campaignSourceId;
	}

	static campaignMediumId(mediumId?: number)
	{
		if (mediumId != null)
			this._campaignMediumId = mediumId;

		return this._campaignMediumId;
	}

	static campaignName(name?: string)
	{
		if (name != null)
			this._campaignName = name;

		return this._campaignName;
	}
	//#endregion

	static header()
	{
		return puma(".vettorePatientHeader")[0] as HTMLElement;
	}

	static title(title?: string)
	{
		if (title != null)
			vr.getControl<vr.Label>("vettorePatientTitle").value(title);

		return vr.getControl<vr.Label>("vettorePatientTitle");
	}

	static burgerButton()
	{
		return vr.getControl<vr.Button>("vettorePatientBurgerButton");
	}

	static backButton()
	{
		return vr.getControl<vr.Button>("vettorePatientBackButton");
	}

	static container()
	{
		return puma(".vettorePatientContainer")[0] as HTMLElement;
	}

	static headerTitleLogo()
	{
		return puma(".vettorePatientDivTitleLogo")[0] as HTMLElement;
	}

	//#region Sailor
	static sailor()
	{
		return this.webComponentParameters()[0].sailor;
	}

	static sailorType(sailorType?: SailorTypeEnum)
	{
		if (sailorType != null)
			this._sailorType = sailorType;

		return this._sailorType;
	}
	//#endregion

	static shadowRoot(shadowRoot?: ShadowRoot)
	{
		if (shadowRoot != null)
			this._shadowRoot = shadowRoot;

		return this._shadowRoot;
	}

	static configuration(configuration?: Configuration)
	{
		if (configuration != null)
		{
			this._configuration = configuration;
			Rotator.initialize();
		}
		return this._configuration;
	}

	static webComponentParameters(parameters?: WebComponentConfig[])
	{
		if (parameters != null)
		{
			let demoUrl = vrshared.UtilityManager().getUrlParameter("demovrurl");
			if (demoUrl != null && demoUrl != "")
				parameters[0].url = "https://" + demoUrl + ".vettoreweb.it";

			this._webComponentParameters = parameters;
		}
		return this._webComponentParameters;
	}

	static doctorMode()
	{
		return this.patientId() != null;
	}

	static patientId()
	{
		let patientId = this.webComponentParameters()[0].patientId;
		if (patientId != null && patientId != 0)
			return patientId;

		return null;
	}

	static isModal()
	{
		if (Rotator.webComponentParameters()[0].modal == null)
			Rotator.webComponentParameters()[0].modal = true;

		return Rotator.webComponentParameters()[0].modal;
	}

	static isApp()
	{
		return Rotator.configuration().appMobile;
	}

	static isMobile()
	{
		return vrshared.DeviceManager().isMobile();
	}

	static isTablet()
	{
		return vrshared.DeviceManager().isTablet();
	}

	static bookingParameters(bookingParameters?: BookingParameters | null)
	{
		if (bookingParameters === null)
			this._bookingParameters = new BookingParameters();
		else if (bookingParameters != null)
			this._bookingParameters = bookingParameters;

		return this._bookingParameters;
	}

	static confirmationParameters(confirmationParameters?: TimeslotListParameters | null): TimeslotListParameters | null
	{
		if (confirmationParameters === null)
			this._confirmationParameters = null;
		else if (confirmationParameters != null)
			this._confirmationParameters = confirmationParameters;

		return this._confirmationParameters;
	}

	static inBookingFlow(inBookingFlow?: boolean)
	{
		if (inBookingFlow != null)
			this._inBookingFlow = inBookingFlow;

		return this._inBookingFlow;
	}

	static filterAppointmentOnPast(state?: boolean)
	{
		if (state != null)
			this._filterAppointmentOnPast = state;

		return this._filterAppointmentOnPast;
	}

	static jqueryVariable()
	{
		return (this.webComponentParameters()[0].jqueryVariable != null) ? eval(this.webComponentParameters()[0].jqueryVariable) : jQuery;
	}

	static trackingCookiesInfo(trackingCookiesInfo?: TrackingCookieInfo[])
	{
		if (trackingCookiesInfo != null)
			this._trackingCookiesInfo = trackingCookiesInfo;

		return this._trackingCookiesInfo;
	}

	static page(page?: PageEnum, parameters?: any)
	{
		if (page != null)
		{
			if (this._pageHistory.last() != page)
				this._pageHistory.push(page);

			this.setHeader(page);
			puma(Rotator.container()).removeClass("vettorePatientContainerAppDashboard");

			puma(this.container()).empty();
			let pageTitle = "";
			switch (page)
			{
				//#region Booking
				case PageEnum.Booking: Booking.initialize(); break;
				case PageEnum.ActivityList: ActivityList.initialize(); break;
				case PageEnum.WorkplaceList: WorkplaceList.initialize(); break;
				case PageEnum.DoctorList: DoctorList.initialize(); break;
				case PageEnum.FromList: FromList.initialize(); break;
				case PageEnum.ResourceTimeslots: ResourceTimeslots.initialize(); break;
				case PageEnum.WorkplaceTimeslots: WorkplaceTimeslots.initialize(); break;
				case PageEnum.TimeslotList: TimeslotList.initialize(parameters); break;
				case PageEnum.Confirmation1: Confirmation1.initialize(parameters); break;
				case PageEnum.Confirmation2: Confirmation2.initialize(); break;
				case PageEnum.PaymentResult: PaymentResult.initialize(parameters); break;
				case PageEnum.WaitingList: WaitingList.initialize(parameters); break;
				case PageEnum.FileLoading: FileLoading.initialize(); break;
				//#endregion

				//#region Login
				case PageEnum.Login: Login.initialize(parameters); break;
				case PageEnum.Register: Register.initialize(parameters); break;
				case PageEnum.ForgotPassword: ForgotPassword.initialize(); break;
				case PageEnum.ChangePassword: ChangePassword.initialize(parameters); break;
				//#endregion

				//#region Private Area
				case PageEnum.AppointmentList: AppointmentList.initialize(); break;
				case PageEnum.AppointmentDetail: AppointmentDetail.initialize(parameters); break;
				case PageEnum.DocumentList: DocumentList.initialize(); break;
				case PageEnum.ReportList: ReportList.initialize(); break;
				case PageEnum.ReportSendingStorageList: ReportSendingStorageList.initialize(parameters); break;
				case PageEnum.FileList: FileList.initialize(); break;
				case PageEnum.Profile: Profile.initialize(); break;
				case PageEnum.PaymentData: PaymentData.initialize(parameters); break;
				//#endregion

				//#region Info
				case PageEnum.Info: InfoPage.initialize(); break;
				//#endregion

				//#region Sailor
				case PageEnum.SailorHome: SailorHome.initialize(); break;
				case PageEnum.SailorRegister: SailorRegister.initialize(parameters); break;
				//#endregion

				//#region Other
				case PageEnum.NoInternetConnection: NoInternetConnection.initialize(); break;
				case PageEnum.Dashboard: Dashboard.initialize(); break;
				//#endregion
			}

			pageTitle = "";

			if (this.isMobile() || this.isApp())
				window.history.pushState("", "", pageTitle);

			//#region Header button
			if (page == PageEnum.Booking || page == PageEnum.AppointmentList || page == PageEnum.DocumentList || page == PageEnum.ReportList
				|| page == PageEnum.FileList || page == PageEnum.Profile || page == PageEnum.PaymentResult || page == PageEnum.SailorHome
				|| page == PageEnum.Info || page == PageEnum.Dashboard)
				Rotator.backButton().hide();
			else
				Rotator.backButton().show();

			if (page == PageEnum.Booking || page == PageEnum.AppointmentList || page == PageEnum.DocumentList || page == PageEnum.ReportList
				|| page == PageEnum.FileList || page == PageEnum.Profile || page == PageEnum.Login || page == PageEnum.SailorHome
				|| page == PageEnum.Info || page == PageEnum.Dashboard)
			{
				if (page != PageEnum.Login)
					Rotator.burgerButton().show();

				if (page == PageEnum.SailorHome || page == PageEnum.Dashboard)
					Menu.highlightItem(PageEnum.Booking);
				else
					Menu.highlightItem(page);
			}
			else
				Rotator.burgerButton().hide();

			if (Rotator.sailor() && page == PageEnum.Booking)
			{
				Rotator.backButton().show();
				Rotator.burgerButton().hide();
			}

			if (page == PageEnum.NoInternetConnection)
			{
				Rotator.backButton().hide();
				Rotator.burgerButton().hide();
			}
			//#endregion
		}
		return this._pageHistory.last();
	}

	private static setHeader(page: PageEnum)
	{
		if (Rotator.isApp() && Rotator.configuration().appCustomHomeAppMobile
			&& page == PageEnum.Booking && Rotator.configuration().appLogo != null && Rotator.configuration().appLogo != "")
		{
			Rotator.headerTitleLogo().style.cssText += "padding-bottom: 20px;";
			Rotator.burgerButton().container().style.cssText += "align-items: start;";
			Rotator.burgerButton().element().style.cssText += "background-color: " + Rotator.appHeaderColor() + "; color: " + Rotator.appHeaderTextColor() + ";";
			puma(Rotator.header()).addClass("vettorePatientHeaderApp");
			Rotator.header().style.cssText += "background-color: " + Rotator.appHeaderColor() + ";";
			puma(Rotator.container()).addClass("vettorePatientContainerApp");
			Rotator.title().element().style.cssText += "color: " + Rotator.appHeaderTextColor() + ";";
		}
		else
		{
			Rotator.headerTitleLogo().style.cssText += "padding-bottom: 0px;";
			Rotator.burgerButton().container().style.cssText += "align-items: center;";
			Rotator.burgerButton().element().style.cssText += "background-color: " + (Rotator.isMobile() ? Rotator.color() : "#FFF") + "; color: " + (Rotator.isMobile() ? ((Rotator.configuration().appTextColorWhite) ? "#FFF" : "#000") : Rotator.color() + ";");
			puma(Rotator.header()).removeClass("vettorePatientHeaderApp");
			Rotator.header().style.cssText += "background-color: " + (Rotator.isMobile() ? Rotator.color() : "#FFF") + ";";
			puma(Rotator.container()).removeClass("vettorePatientContainerApp");
			Rotator.title().element().style.cssText += "color: " + (Rotator.isMobile() ? ((Rotator.configuration().appTextColorWhite) ? "#FFF" : "#000") : Rotator.color() + ";");
		}
	}

	static goToFirstPage()
	{
		this.page(this._pageHistory.first());
		this._pageHistory = [];
	}

	static firstPage()
	{
		return this._pageHistory.first();
	}

	static lastPage()
	{
		return this._pageHistory.last();
	}

	static pageHistory()
	{
		return this._pageHistory;
	}

	static clearPageHistory()
	{
		this._pageHistory = [];
	}

	static goToMainPage(parameters?: any)
	{
		if (Rotator.sailor())
			Rotator.page(PageEnum.SailorHome, parameters);
		else if (Rotator.configuration().appShowDashboard && Rotator.isApp())
			Rotator.page(PageEnum.Dashboard)
		else
			Rotator.page(PageEnum.Booking, parameters);
	}

	static back(parameters?: any)
	{
		let firstPage = Rotator.sailor() ? PageEnum.SailorHome : PageEnum.Booking;
		this._pageHistory.pop();

		if (this._pageHistory.length > 0)
			this.page(this._pageHistory.last(), parameters);
		else if (this._pageHistory.length == 0)
			this.goToMainPage(parameters);

		if (this._pageHistory.last() == firstPage)
			this.clearPageHistory();
	}
}

export function puma(element: any)
{
	return (Rotator.shadowRoot() != null && Rotator.jqueryVariable()(element).length == 0) ? Rotator.jqueryVariable()(Rotator.shadowRoot()).find(element) : Rotator.jqueryVariable()(element);
}

//#region Classes

export enum PageEnum
{
	Booking = "Booking",
	Login = "Login",
	ActivityList = "ActivityList",
	WorkplaceList = "WorkplaceList",
	DoctorList = "DoctorList",
	FromList = "FromList",
	ResourceTimeslots = "ResourceTimeslots",
	TimeslotList = "TimeslotList",
	Confirmation1 = "Confirmation1",
	ForgotPassword = "ForgotPassword",
	Register = "Register",
	Confirmation2 = "Confirmation2",
	ChangePassword = "ChangePassword",
	AppointmentList = "AppointmentList",
	AppointmentDetail = "AppointmentDetail",
	DocumentList = "DocumentList",
	ReportList = "ReportList",
	ReportSendingStorageList = "ReportSendingStorageList",
	FileList = "FileList",
	Profile = "Profile",
	PaymentData = "PaymentData",
	PaymentResult = "PaymentResult",
	WorkplaceTimeslots = "WorkplaceTimeslots",
	SailorHome = "SailorHome",
	SailorRegister = "SailorRegister",
	Info = "Info",
	NoInternetConnection = "NoInternetConnection",
	WaitingList = "WaitingList",
	Dashboard = "Dashboard",
	FileLoading = "FileLoading"
}

export class Configuration
{
	public keyMessageCheck: string;
	public appColor: string;
	public appTextColorWhite: boolean;
	public appCustomHomeAppMobile: boolean;
	public appMobileHeaderColor: string;
	public appMobileHeaderTextColor: string;
	public appShowDashboard: boolean;
	public appDashboardShowLogo: boolean;
	public appDashboardBackgroundColor: string;
	public appDashboardBackgroundImage: string;
	public appName: string;
	public appNameSociety: string;
	public appPhoneNumber: string;
	public appEmail: string;
	public appWebsite: string;
	public appPaypalSandboxMode: boolean;
	public appPaypalClientId: string;
	public appPaypalClientSecret: string;
	public appPaypalClientIdSandbox: string;
	public appPaypalClientSecretSandbox: string;
	public appShowPlantInFiles: boolean;
	public appCancelAppointment: boolean;
	public appDaysBeforeCancelAppointment: number;
	public appHoursBeforeTakeAppointment: number;
	public appShowAppointmentDuration: boolean;
	public appShowAppointmentAborted: boolean;
	public appShowFiles: boolean;
	public appUploadFiles: boolean;
	public appShowReports: boolean;
	public appUploadReports: boolean;
	public appShowDocuments: boolean;
	public appShowWorkplaces: boolean;
	public appRequiredWorkplaceChoice: boolean;
	public appNoOnlineBookingMessage: string;
	public appNoAvailabilitiesMessage: string;
	public appNoAvailabilitiesPhoneNumber: string;
	public appNoAvailabilitiesMail: string;
	public appCreateNewPatient: boolean;
	public appWebRtcRecordSessionAudio: boolean;
	public appWebRtcMessageRecordSessionAudio: string;
	public appLoginRequired: AppLoginModeEnum;
	public appBookingAppointments: boolean;
	public appLogo: string;
	public appOnlyActivitiesOnline: boolean;
	public appPreregisterSubject: boolean;
	public appCanPatientEditProfileData: boolean;
	public privacyCommercial: boolean;
	public privacyText: string;
	public privacyHealthInfoText: string;
	public plant: PlantInfo;
	public appShowAppointmentPrice: boolean;
	public appAppointmentPriceZeroManagement: OnlineBookingPriceZeroEnum;
	public appShowActivityTypePrice: boolean;
	public appActivityTypePriceZeroManagement: OnlineBookingPriceZeroEnum;
	public appConfirmationMessageAddedToWaitingList: string;
	public appUTMCampaignName: string;
	public appUTMCampaignMedium: string;
	public appUTMCampaignSource: string;
	public appPrivacyLabelText: string;
	public appPrivacyCommercialLabelText: string;
	public appHidePrivacyCommercial: boolean;
	public appMobile: boolean;
	public appUseGeolocationInsteadOfWorkplaceOrder: boolean;
	public appPlaceholderPatientNotes: string;
	public appNoBookingMinor: boolean;
	public vettoreVersion: string;
	public appHideRegistrationIntoLogin: boolean;
	public appHideRegistrationIntoConfirmationPage: boolean;
	public appFileCssBase64: string;
	public appFileCssBase64TextContent: string;
	public appMessagePrePayment: string;
	public appMessagePaymentRequired: string;
}

export enum OnlineBookingPriceZeroEnum
{
	Free,
	Decimal,
	Hide
}

export enum AppLoginModeEnum
{
	Never,
	Always,
	OnlyBeforeBooking
}

class PlantInfo
{
	public id: number;
	public name: string;
	public street: string;
	public city: string;
	public zip: string;
	public province: string;
	public geoCoordinate: GeoCoordinate;
	public phoneNumbers: string[];
	public workplaces: WorkplacesInfo[];
	public emails: string[];
}

export class WorkplacesInfo
{
	public id: number;
	public name: string;
	public street: string;
	public city: string;
	public zip: string;
	public province: string;
	public geoCoordinate: GeoCoordinate;
	public phoneNumbers: string[];
	public emails: string[];
}

class GeoCoordinate
{
	public latitude: number;
	public longitude: number;
}

export enum SailorTypeEnum
{
	Sasn = 10,
	Usmaf = 20
}
//#endregion