import { Rotator, puma } from "../rotator";
import { SelectionListControl } from "./selectionListControl";
import { Booking, StepEnum } from "./booking";
import { DoctorList } from "./doctorList";
import { WebApiRequestBase, WebApiResponseBase } from "../../src/shared/webApi";
import { ActivityTypeGroupResult, ActivityTypeResponse, OnlineBookingModeEnum } from "../../src/shared/common";
import { Support } from "../../src/support";
import { WorkplaceList } from "./workplaceList";

export class ActivityList
{
    private static _container: HTMLElement;
    private static _parameters?: ActivityListParameters;

    static initialize(parameters?: ActivityListParameters)
    {
        this._parameters = parameters;
        this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
        Rotator.title("PRENOTA");
        this.createControls();
    }

    static createControls()
    {
        Booking.createStep(StepEnum.Activity, this._container, true, () => Rotator.back());

        let selectionListControl = new SelectionListControl(
            {
                container: this._container,
                selectionFocusMode: true,
                rebind:
                {
                    authKey: "10(P9m+U3aM@10tt-Oeo",
                    method: "/api/BookingWebApi/GetActivityTypeGroups",
                    itemsPropertyName: "activityTypeGroupList",
                    rebindAtStartup: true,
                    parameters: () =>
                    {
                        return {
                            "plantIdList": [Rotator.configuration().plant.id],
                            "activityTypeGroupId": Rotator.webComponentParameters()[0].specificActivityTypeGroupId,
                            "activityTypeGroupIdList": Rotator.webComponentParameters()[0].specificActivityTypeGroupIdList,
                            "activityTypeId": Rotator.webComponentParameters()[0].specificActivityTypeId,
                            "activityTypeIdList": Rotator.webComponentParameters()[0].specificActivityTypeIdList,
                            "resourceIdList": (Rotator.bookingParameters().resource) != null ? [Rotator.bookingParameters().resource.id] : null,
                            "workplaceIdList": (Rotator.bookingParameters().workplace) != null ? [Rotator.bookingParameters().workplace!.id] : null,
                            "sailor": Rotator.sailor()
                        }
                    },
                    callback: () => 
                    {
                        let specificActivityTypeGroupId = Rotator.webComponentParameters()[0].specificActivityTypeGroupId;
                        let specificActivityTypeGroupIdList = Rotator.webComponentParameters()[0].specificActivityTypeGroupIdList;

                        if (specificActivityTypeGroupId != null
                            || (specificActivityTypeGroupIdList != null && specificActivityTypeGroupIdList.length == 1)
                            || selectionListControl.items().length == 1)
                            puma(".vrPatientSelectionItemText").first().click();

                        if (Rotator.bookingParameters().activityType != null)
                            selectionListControl.select(Rotator.bookingParameters().activityType.id);
                    }
                },
                onSelect: (e) =>
                {
                    let activityTypeResponse = e.dataItem as ActivityTypeResponse;
                    if (activityTypeResponse.onlineBookingModeEnum == OnlineBookingModeEnum.ShowAlways
                        || activityTypeResponse.onlineBookingModeEnum == OnlineBookingModeEnum.ShowOnlyIfAvailable)
                    {
                        Rotator.bookingParameters().activityType = activityTypeResponse;
                        DoctorList.getResources(undefined, Rotator.container());
                        Rotator.back();

                        if (activityTypeResponse.onlineBookingDoNotChooseDoctor === true)
                            Booking.doctorSlotVisible(false);
                    }
                    else
                    {
                        //#region Footer items
                        let footerItems: vr.WindowFooterItem[] = [];
                        if (Rotator.configuration().appNoAvailabilitiesPhoneNumber != null && Rotator.configuration().appNoAvailabilitiesPhoneNumber.length > 0)
                        {
                            let callPhoneItem: vr.WindowFooterItem =
                            {
                                text: "Chiama",
                                colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
                                onClick: (e) =>
                                {
                                    let link = document.createElement("a");
                                    link.href = "tel:" + Rotator.configuration().appNoAvailabilitiesPhoneNumber;
                                    link.click();
                                }
                            }
                            footerItems.push(callPhoneItem);
                        }

                        if (Rotator.configuration().appNoAvailabilitiesMail != null && Rotator.configuration().appNoAvailabilitiesMail.length > 0)
                        {
                            let mailItem: vr.WindowFooterItem =
                            {
                                text: "Email",
                                colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
                                onClick: (e) =>
                                {
                                    let link = document.createElement("a");
                                    link.href = "mailto:" + Rotator.configuration().appNoAvailabilitiesMail;
                                    link.click();
                                }
                            }
                            footerItems.push(mailItem);
                        }

                        let closeItem: vr.WindowFooterItem =
                        {
                            text: "Chiudi",
                            type: vr.WindowFooterItemTypeEnum.Close,
                            colorSettings: { textColor: Rotator.color() }
                        }
                        footerItems.push(closeItem);
                        //#endregion

                        Support.dialog(Rotator.configuration().appNoOnlineBookingMessage, footerItems);
                    }
                },
                onClear: (e) =>
                {
                    (Rotator.bookingParameters().activityType as any) = null;
                    puma(".vrPatientDivBookingStep").find(".vrPatient_bookingChoice").html("Cerca una prestazione");

                    //#region Manage workplaces
                    //This piece of code fix the behavior described below: 
                    //if you select an activityType used only on one workplace and you have a parameter "appShowWorkplaces" set to false, 
                    //then you cannot change workplace anymore, so you will only see the activityTypes related to this workplace
                    if (!Rotator.configuration().appShowWorkplaces)
                    {
                        WorkplaceList.getWorkplaces((e) =>
                        {
                            if (e.workplacesList.length > 2)
                            {
                                Rotator.bookingParameters().workplace = null;
                                selectionListControl.rebind();
                            }
                        });
                    }
                    //#endregion
                }
            }
        );
    }

    static getActivityTypeGroups(callback?: (e: ActivityTypeGroupResult[]) => void, async = true)
    {
        let request = new GetActivityTypeGroupsRequest();
        request.plantIdList = [Rotator.configuration().plant.id];
        request.resourceIdList = (Rotator.bookingParameters().resource) != null ? [Rotator.bookingParameters().resource.id] : null;
        request.workplaceIdList = (Rotator.bookingParameters().workplace) != null ? [Rotator.bookingParameters().workplace!.id] : null;
        request.activityTypeGroupId = Rotator.webComponentParameters()[0].specificActivityTypeGroupId;
        request.activityTypeGroupIdList = Rotator.webComponentParameters()[0].specificActivityTypeGroupIdList;
        request.activityTypeId = Rotator.webComponentParameters()[0].specificActivityTypeId;
        request.activityTypeIdList = Rotator.webComponentParameters()[0].specificActivityTypeIdList;
        request.isSailor = Rotator.sailor();
        request.call(
            {
                success: (response: GetActivityTypeGroupsResponse) =>
                {
                    if (callback != null)
                        callback(response.activityTypeGroupList);
                },
                mode: (async) ? vrshared.WebApiModeEnum.Async : vrshared.WebApiModeEnum.Sync,
                loader: false
            });
    }
}

//#region Classes
class GetActivityTypeGroupsRequest extends WebApiRequestBase
{
    public method: string = "/api/BookingWebApi/GetActivityTypeGroups";

    public plantIdList: number[];
    public onlyBookableOnline?: boolean;
    public resourceIdList: number[] | null;
    public workplaceIdList: number[] | null;
    public activityTypeGroupId: number;
    public activityTypeGroupIdList: number[];
    public activityTypeId: number;
    public activityTypeIdList: number[];
    public isSailor: boolean;
}

class GetActivityTypeGroupsResponse extends WebApiResponseBase
{
    public activityTypeGroupList: ActivityTypeGroupResult[];
}

export class ActivityListParameters
{
    loader: HTMLElement;
}
//#endregion