import { Rotator, puma } from "../rotator";
import { SelectionListControl } from "./selectionListControl";
import { Booking, StepEnum } from "./booking";
import { WebApiRequestBase, WebApiResponseBase } from "../../src/shared/webApi";
import { WorkplaceResponse } from "../../src/shared/common";
import { DoctorList } from "./doctorList";

export class WorkplaceList
{
    private static _container: HTMLElement;

    static initialize()
    {
        this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
        Rotator.title("PRENOTA");
        this.createControls();
    }

    static createControls()
    {
        Booking.createStep(StepEnum.Workplace, this._container, true, () => Rotator.back());

        let selectionListControl = new SelectionListControl(
            {
                container: this._container,
                rebind:
                {
                    authKey: "10(P9m+U3aM@10tt-Oeo",
                    method: "/api/BookingWebApi/GetWorkplaces",
                    itemsPropertyName: "workplacesList",
                    rebindAtStartup: true,
                    parameters: () =>
                    {
                        return {
                            "plantIdList": [Rotator.configuration().plant.id],
                            "latitude": Rotator.bookingParameters().latitude,
                            "longitude": Rotator.bookingParameters().longitude,
                            "activityTypeId": (Rotator.bookingParameters().activityType != null) ? Rotator.bookingParameters().activityType.id : 0,
                            "onlyBookableOnline": true
                        }
                    },
                    callback: () => 
                    {
                        if (Rotator.bookingParameters().workplace != null)
                            selectionListControl.select(Rotator.bookingParameters().workplace!.id);
                    }
                },
                onSelect: (e) =>
                {
                    Rotator.bookingParameters().workplace = (e.dataItem.id == 0) ? null : e.dataItem;
                    Rotator.back();

                    DoctorList.getResources();
                }
            }
        );
    }

    static getWorkplaces(callback?: (e: GetWorkplacesResponse) => void, mode = vrshared.WebApiModeEnum.Async)
    {
        let request = new GetWorkplacesRequest();
        request.plantIdList = [Rotator.configuration().plant.id];
        request.latitude = Rotator.bookingParameters().latitude;
        request.longitude = Rotator.bookingParameters().longitude;
        request.activityTypeId = (Rotator.bookingParameters().activityType != null) ? Rotator.bookingParameters().activityType.id : 0;
        request.onlyBookableOnline = true;
        request.call(
            {
                success: (response: GetWorkplacesResponse) =>
                {
                    Rotator.bookingParameters().workplaceList = response.workplacesList;
                    if (callback != null)
                        callback(response);
                },
                mode: mode,
                loader: false
            });
    }
}

//#region Classes
class GetWorkplacesRequest extends WebApiRequestBase
{
    public method: string = "/api/BookingWebApi/GetWorkplaces";

    public plantIdList: number[];
    public latitude: number;
    public longitude: number;
    public onlyBookableOnline?: boolean;
    public activityTypeId: number;
}

class GetWorkplacesResponse extends WebApiResponseBase
{
    public workplacesList: WorkplaceResponse[];
}
//#endregion