import { Support } from "../../src/support";
import { PageEnum, Rotator, SailorTypeEnum } from "../../src/rotator";
import { LoginParameters } from "../../src/login/login";

export class SailorHome
{
	private static _container: HTMLElement;

	static initialize()
	{
		this._container = Rotator.container();
		(Rotator.bookingParameters().activityType as any) = null;

		Rotator.title("PRENOTA");
		this.createControls();
	}

	private static createControls()
	{
		let divInfo = vr.div(this._container, { class: "vrPatientDivSlot" });

		vr.createImage({
			value: "https://booking.vrapp.it/images/patient/sailor.png",
			cssContainer: "display: flex; margin-bottom: 5px;",
			css: "margin: 0 auto;"
		}, divInfo)

		vr.createLabel({
			text: `Benvenuto sulla piattaforma <span style='font-weight: 600; color: ` + Rotator.color() + `'>"Il Navigante"</span>

<div style='margin-top: 10px;'>
Tramite questa piattaforma il personale autorizzato avente diritto (naviganti
ed aeronaviganti) potrà prenotare tutte le prestazioni sanitarie disponibili:
visite preventive di imbarco, visite biennali, visite per il lavoratore
marittimo, visite di abilità (denuncia apertura malattia/infortunio e controllo medico in scadenza),
visite generiche etc. che verranno effettuate presso la sede SASN
competente.
</div>

<div style='margin-top: 10px;'>
Per il primo accesso sarà necessario registrarsi inserendo nell’apposita
pagina tutti i dati anagrafici richiesti.
</div>

<div style='margin-top: 10px;'>
Una volta portata a termine la prenotazione l’interessato dovrà presentarsi
alla sede SASN competente nei modi e nei tempi che saranno indicati nella
mail/sms di conferma.
</div>

<div style='margin-top: 10px;'>
Nel caso di prestazione soggetta al pagamento di ticket sarà indicato
l’importo e la modalità di pagamento
</div>`,
			width: "100%",
			align: vr.TextAlignEnum.Justify,
			cssContainer: "margin-top: 5px;"
		}, divInfo);

		let divButtons = vr.div(divInfo, { css: "display: flex; justify-content: space-between; margin-top: 20px;" });

		vr.createButton({
			text: "SASN",
			colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
			css: "width: 100%; box-shadow: 0 1px 3px " + Rotator.color() + ";",
			cssContainer: "flex: 1;",
			onClick: (e) => this.goNext(SailorTypeEnum.Sasn)
		}, divButtons);

		vr.createButton({
			text: "USMAF",
			colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
			css: "width: 100%; box-shadow: 0 1px 3px " + Rotator.color() + ";",
			cssContainer: "flex: 1; margin-left: 5px;",
			onClick: (e) => this.goNext(SailorTypeEnum.Usmaf)
		}, divButtons);
	}

	private static goNext(sailorType: SailorTypeEnum)
	{
		if (sailorType == SailorTypeEnum.Sasn)
			Rotator.webComponentParameters()[0].specificActivityTypeGroupId = 1;
		else if (sailorType == SailorTypeEnum.Usmaf)
			Rotator.webComponentParameters()[0].specificActivityTypeGroupId = 4;

		Rotator.sailorType(sailorType);
		Support.checkLogin(() => Rotator.page(PageEnum.Booking), () => 
		{
			let parameters = new LoginParameters();
			parameters.successCallback = () => Rotator.page(PageEnum.Booking);
			Rotator.page(PageEnum.Login, parameters);
		});
	}
}