import { WebApiRequestBase, WebApiResponseBase } from "../../src/shared/webApi";
import { Rotator, puma, PageEnum } from "../../src/rotator";
import { Popup, Support } from "../support";
import { BirthAddressInfo, City, ContactInfo, ContactTypeEnum, ForeignAddress, ItalianAddress, WebApiDateTime } from "../../src/shared/common";
import { MandatoryFieldInfo, Register } from "../../src/login/register";
import { VrManager } from "../../src/shared/vrManager";

export class Profile
{
	private static _subjectId: number;
	private static _container: HTMLElement;

	private static _txtUsername: vr.TextBox;
	private static _txtName: vr.TextBox;
	private static _txtSurname: vr.TextBox;
	private static _txtEmail: vr.TextBox;
	private static _txtPhoneNumber: vr.TextBox;
	private static _dtpBirthdate: vr.DatePicker;
	private static _calBirthdate: vr.Calendar;
	private static _txtFiscalCode: vr.TextBox;
	private static _txtLandline: vr.TextBox;
	private static _btgGender: vr.ButtonGroup;
	private static _fields: MandatoryFieldInfo[];

	private static _cmbBirthCity: vr.ComboBox;
	private static _swtItalianForeignBirthCity: vr.Switch;
	private static _cmbForeignBirthCityCountry: vr.ComboBox;
	private static _txtForeignBirthCity: vr.TextBox;

	private static _swtItalianForeignResidenceAddress: vr.Switch;
	private static _txtItalyResidenceAddress: vr.TextBox;
	private static _cmbItalyResidenceCity: vr.ComboBox;
	private static _txtItalyResidenceProvince: vr.TextBox;
	private static _cmbItalyResidenceZip: vr.ComboBox;
	private static _txtOutItalyResidenceAddress: vr.TextBox;
	private static _cmbOutItalyResidenceCountry: vr.ComboBox;

	static initialize()
	{
		this._container = puma("<div></div>").appendTo(Rotator.container())[0];
		Rotator.title("PROFILO");

		Support.checkLogin(() => 
		{
			this.createControls();
			this.getSubject(
				{
					txtName: this._txtName,
					txtSurname: this._txtSurname,
					txtEmail: this._txtEmail,
					btgGender: this._btgGender,
					cmbBirthCity: this._cmbBirthCity,
					cmbForeignBirthCityCountry: this._cmbForeignBirthCityCountry,
					cmbItalyResidenceCity: this._cmbItalyResidenceCity,
					txtItalyResidenceProvince: this._txtItalyResidenceProvince,
					cmbItalyResidenceZip: this._cmbItalyResidenceZip,
					cmbOutItalyResidenceCountry: this._cmbOutItalyResidenceCountry,
					dtpBirthdate: this._dtpBirthdate,
					swtItalianForeignBirthCity: this._swtItalianForeignBirthCity,
					swtItalianForeignResidenceAddress: this._swtItalianForeignResidenceAddress,
					txtFiscalCode: this._txtFiscalCode,
					txtForeignBirthCity: this._txtForeignBirthCity,
					txtItalyResidenceAddress: this._txtItalyResidenceAddress,
					txtLandline: this._txtLandline,
					txtOutItalyResidenceAddress: this._txtOutItalyResidenceAddress,
					txtPhoneNumber: this._txtPhoneNumber
				},
				(response) =>
				{
					this._subjectId = response.id;

					this._txtUsername.value(response.username);
					if (response.username == null || response.username == "")
						this._txtUsername.hide();

					if (!Rotator.configuration().appCanPatientEditProfileData)
					{
						this._txtUsername.disable();
						this._txtName.disable();
						this._txtSurname.disable();
						this._txtEmail.disable();
						this._txtPhoneNumber.disable();
						this._txtLandline.disable();
						this._dtpBirthdate.disable();

						if (this._calBirthdate != null)
							this._calBirthdate.disable();

						this._txtFiscalCode.disable();
						this._btgGender.disable();
						this._cmbBirthCity.disable();
						this._swtItalianForeignBirthCity.disable();
						this._cmbForeignBirthCityCountry.disable();
						this._txtForeignBirthCity.disable();
						this._swtItalianForeignResidenceAddress.disable();
						this._txtItalyResidenceAddress.disable();
						this._cmbItalyResidenceCity.disable();
						this._txtItalyResidenceProvince.disable();
						this._cmbItalyResidenceZip.disable();
						this._txtOutItalyResidenceAddress.disable();
						this._cmbOutItalyResidenceCountry.disable();
					}
				});
			Register.getMandatoryFields((e) => this._fields = e.fields);
		});
	}

	private static createControls()
	{
		//#region Info
		let divInfo = vr.div(this._container, { class: "vrPatientDivSlot" });

		this._txtUsername = vr.createTextBox({
			label: "Nome utente",
			width: "100%",
			enable: false,
			labelSettings: { colorSettings: { textColor: Rotator.color() } }
		}, divInfo);

		this._txtName = vr.createTextBox({
			label: "Nome",
			width: "100%",
			cssContainer: "margin-top: 5px;",
			labelSettings: { colorSettings: { textColor: Rotator.color() } }
		}, divInfo);

		this._txtSurname = vr.createTextBox({
			label: "Cognome",
			width: "100%",
			cssContainer: "margin-top: 5px;",
			labelSettings: { colorSettings: { textColor: Rotator.color() } }
		}, divInfo);

		this._txtEmail = vr.createTextBox({
			label: "Email",
			width: "100%",
			cssContainer: "margin-top: 5px;",
			labelSettings: { colorSettings: { textColor: Rotator.color() } }
		}, divInfo);

		this._txtPhoneNumber = vr.createTextBox({
			label: "Cellulare",
			width: "100%",
			cssContainer: "margin-top: 5px;",
			labelSettings: { colorSettings: { textColor: Rotator.color() } }
		}, divInfo);

		this._txtLandline = vr.createTextBox(
			{
				label: "Telefono fisso",
				placeholder: "Telefono fisso",
				mode: vr.TextModeEnum.Text,
				width: "100%",
				cssContainer: "margin-top: 5px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } }
			}, divInfo);

		this._btgGender = vr.createButtonGroup(
			{
				label: "Sesso",
				width: "100%",
				labelSettings: { colorSettings: { textColor: Rotator.color() } },
				cssContainer: "margin-top: 5px;",
				//selectedColor: Rotator.color(),
				items:
					[
						{ text: "Maschio", value: "M" },
						{ text: "Femmina", value: "F" }
					],
				selectionMode: vr.SelectionModeEnum.Single
			}, divInfo);
		//#endregion

		//#region BirthInfo
		let divBirthInfo = vr.div(this._container, { class: "vrPatientDivSlot" });

		this._dtpBirthdate = vr.createDatePicker(
			{
				label: "Data di nascita",
				width: "100%",
				cssContainer: "margin-bottom: 10px;",
				labelSettings: { colorSettings: { textColor: Rotator.color() } },
				onAfterChange: (e) => 
				{
					if (this._calBirthdate != null)
						this._calBirthdate.value(e.value, false);
				}
			}, divBirthInfo);

		//#region Birthdate mobile
		if (Rotator.isMobile())
		{
			let birthdatePopup = new Popup(this._container, { height: 365, width: 320 });
			this._calBirthdate = vr.createCalendar(
				{
					width: "100%",
					selectedColor: Rotator.color(),
					onAfterChange: (e) =>
					{
						this._dtpBirthdate.value(e.value, false);
						birthdatePopup.close();
					}
				}, birthdatePopup.element());
			puma(this._calBirthdate.element()).find(".vrDatePickerPopup_table")[0].style.cssText += "min-height: 285px;";

			puma(this._dtpBirthdate.container()).find(".vrIcon").hide();
			vr.createButton({
				icon: vr.IconClassicLight.Calendar,
				css: "border: none; background: none;",
				cssContainer: "position: absolute; right: 15px; margin-top: 17px;",
				onClick: (e) => birthdatePopup.open()
			}, divBirthInfo);
		}
		//#endregion

		vr.createLabel({
			text: "Città di nascita",
			colorSettings: { textColor: Rotator.color() },
		}, divBirthInfo);

		this._swtItalianForeignBirthCity = vr.createSwitch({
			labelOff: "Italia",
			labelOn: "Fuori Italia",
			width: "100%",
			cssContainer: "margin-bottom: 5px;",
			onChange: (e) => 
			{
				if (e.checked)
				{
					puma(divItalyBirthCity).hide();
					puma(divForeignBirthCity).show();
				}
				else
				{
					puma(divItalyBirthCity).show();
					puma(divForeignBirthCity).hide();
				}
			}
		}, divBirthInfo);

		let divItalyBirthCity = vr.div(divBirthInfo);
		this._cmbBirthCity = vr.createComboBox(
			{
				label: "Città di nascita",
				width: "100%",
				cssContainer: "margin-right: 10px;",
				webService: {
					method: Rotator.patientUrl() + "/api/UtilityWebApi/GetAllCities",
					itemsPropertyName: "cities",
					typedTextPropertyName: "searchText",
					parameters: () =>
					{
						return {
							onlyActive: false
						}
					}
				}
			}, divItalyBirthCity);

		let divForeignBirthCity = vr.div(divBirthInfo, { css: "display: none;" });
		this._cmbForeignBirthCityCountry = vr.createComboBox(
			{
				label: "Nazione",
				width: "100%"
			}, divForeignBirthCity);

		this._txtForeignBirthCity = vr.createTextBox(
			{
				label: "Città",
				width: "100%",
				cssContainer: "margin-right: 10px;"
			}, divForeignBirthCity);
		//#endregion

		//#region Fiscal code
		let divFiscalCode = vr.div(this._container, { class: "vrPatientDivSlot" });
		this._txtFiscalCode = vr.createTextBox({
			label: "Codice fiscale",
			width: "100%",
			labelSettings: { colorSettings: { textColor: Rotator.color() } },
			inputMode: vr.TextTransformModeEnum.Uppercase,
			onBlur: (e) =>
			{
				this.checkFiscalCode(this._txtFiscalCode.value<string>(), this._cmbForeignBirthCityCountry.value<number>(), (e) =>
				{
					if (!e.isValid)
						this._txtFiscalCode.error();
					else
						this._txtFiscalCode.hideError();
				});
			}
		}, divFiscalCode);

		if (Rotator.configuration().appCanPatientEditProfileData)
		{
			vr.createButton({
				text: "Calcola codice",
				width: "100%",
				cssContainer: "margin-top: 10px;",
				colorSettings: { textColor: Rotator.color() },
				css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
				onClick: (e) =>
				{
					if (!Rotator.configuration().appCanPatientEditProfileData)
					{
						e.preventDefault();
						return;
					}

					let birthAddressInfo = null;
					if (!this._swtItalianForeignBirthCity.checked())
					{
						//#region Italian
						if (this._cmbBirthCity.value() != null && this._cmbBirthCity.value() != "")
						{
							birthAddressInfo = new BirthAddressInfo();
							birthAddressInfo.istatCode = this._cmbBirthCity.value<string>()!;
						}
						//#endregion
					}
					else
					{
						//#region Foreign
						let countrySelected = this._cmbForeignBirthCityCountry.getSelectedItem<any>();
						if (countrySelected != null)
						{
							birthAddressInfo = new BirthAddressInfo();
							birthAddressInfo.countryCode = this._cmbForeignBirthCityCountry.value<string>()!;
							birthAddressInfo.address = this._txtForeignBirthCity.value();
						}
						//#endregion
					}

					this.computeFiscalCode(this._txtName.value(), this._txtSurname.value(), this._btgGender.getSelectedValues()[0], this._dtpBirthdate.value()!, birthAddressInfo,
						(e) =>
						{
							if (e.invalidFieldsMessage != null && e.invalidFieldsMessage != "")
								VrManager.notify(e.invalidFieldsMessage, "", { type: vr.NotifierTypeEnum.Error });
							else
							{
								this._txtFiscalCode.value(e.fiscalCode);
								this._txtFiscalCode.hideError();
							}
						});
				}
			}, divFiscalCode);
		}
		//#endregion

		//#region Address
		let divResidence = vr.div(this._container, { class: "vrPatientDivSlot", css: "margin-top: 10px;" });

		vr.createLabel({
			text: "Indirizzo di residenza",
			colorSettings: { textColor: Rotator.color() },
		}, divResidence);

		this._swtItalianForeignResidenceAddress = vr.createSwitch({
			labelOff: "Italia",
			labelOn: "Fuori Italia",
			width: "100%",
			cssContainer: "margin-bottom: 5px;",
			onChange: (e) => 
			{
				if (e.checked)
				{
					puma(divItalyResidence).hide();
					puma(divOutItalyResidence).show();
				}
				else
				{
					puma(divItalyResidence).show();
					puma(divOutItalyResidence).hide();
				}
			}
		}, divResidence);

		let divItalyResidence = vr.div(divResidence);
		this._txtItalyResidenceAddress = vr.createTextBox(
			{
				label: "Via",
				width: "100%"
			}, divItalyResidence);

		this._cmbItalyResidenceCity = vr.createComboBox(
			{
				label: "Comune",
				width: "100%",
				cssContainer: "margin-right: 10px;",
				webService: {
					method: Rotator.patientUrl() + "/api/UtilityWebApi/GetAllCities",
					itemsPropertyName: "cities",
					typedTextPropertyName: "searchText",
					parameters: () =>
					{
						return {
							onlyActive: true
						}
					}
				},
				onAfterChange: (e) =>
				{
					Register.calcProvinceAndZipByCity(e.value, (e) =>
					{
						this._txtItalyResidenceProvince.clear();
						this._cmbItalyResidenceZip.clearItems();

						this._txtItalyResidenceProvince.value(e.city.provinceAbbreviation);
						this._cmbItalyResidenceZip.items(e.city.zipCodes.map(k => { return { text: k.zipCode, value: k.zipCode } }));
						this._cmbItalyResidenceZip.value(e.city.zipCodes.first().zipCode);
					});
				}
			}, divItalyResidence);

		this._txtItalyResidenceProvince = vr.createTextBox(
			{
				label: "Provincia",
				enable: false,
				align: vr.TextAlignEnum.Center,
				width: "Calc(50% - 10px)",
				cssContainer: "margin-right: 10px;"
			}, divItalyResidence);

		this._cmbItalyResidenceZip = vr.createComboBox(
			{
				label: "CAP",
				placeholder: "",
				width: "50%"
			}, divItalyResidence);

		let divOutItalyResidence = vr.div(divResidence, { css: "display: none;" });
		this._txtOutItalyResidenceAddress = vr.createTextBox(
			{
				label: "Indirizzo",
				width: "100%",
				cssContainer: "margin-right: 10px;"
			}, divOutItalyResidence);

		this._cmbOutItalyResidenceCountry = vr.createComboBox(
			{
				label: "Nazione",
				width: "100%"
			}, divOutItalyResidence);
		//#endregion

		//#region Buttons
		let divButtons = vr.div(this._container, { class: "vrPatientDivSlot", css: "margin-bottom: 30px;" });

		if (Rotator.configuration().appCanPatientEditProfileData)
		{
			vr.createButton({
				text: "Aggiorna dati",
				width: "100%",
				colorSettings: { textColor: Rotator.color() },
				css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
				class: "vrButtonUpdateData vrButtonPatient",
				onClick: (e) => 
				{
					if (Rotator.configuration().appCanPatientEditProfileData)
					{
						this.updateSubject(
							{
								txtName: this._txtName,
								txtSurname: this._txtSurname,
								txtEmail: this._txtEmail,
								btgGender: this._btgGender,
								cmbBirthCity: this._cmbBirthCity,
								cmbForeignBirthCityCountry: this._cmbForeignBirthCityCountry,
								cmbItalyResidenceCity: this._cmbItalyResidenceCity,
								txtItalyResidenceProvince: this._txtItalyResidenceProvince,
								cmbItalyResidenceZip: this._cmbItalyResidenceZip,
								cmbOutItalyResidenceCountry: this._cmbOutItalyResidenceCountry,
								dtpBirthdate: this._dtpBirthdate,
								swtItalianForeignBirthCity: this._swtItalianForeignBirthCity,
								swtItalianForeignResidenceAddress: this._swtItalianForeignResidenceAddress,
								txtFiscalCode: this._txtFiscalCode,
								txtForeignBirthCity: this._txtForeignBirthCity,
								txtItalyResidenceAddress: this._txtItalyResidenceAddress,
								txtLandline: this._txtLandline,
								txtOutItalyResidenceAddress: this._txtOutItalyResidenceAddress,
								txtPhoneNumber: this._txtPhoneNumber
							}, this._subjectId, true, true, this._fields,
							(e) =>
							{
								VrManager.notify("Salvataggio effettuato correttamente");
							});
					}
				}
			}, divButtons);

			vr.createSeparator(
				{
					size: 2,
					orientation: vr.OrientationEnum.Horizontal
				}, divButtons);
		}

		vr.createButton({
			text: "Cambia password",
			width: "100%",
			colorSettings: { textColor: Rotator.color() },
			css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
			class: "vrButtonChangePassword vrButtonPatient",
			onClick: (e) => Rotator.page(PageEnum.ChangePassword)
		}, divButtons);

		vr.createButton({
			text: "Esci dal profilo",
			width: "100%",
			colorSettings: { textColor: "red" },
			css: "box-shadow: 0 1px 3px red;",
			cssContainer: "margin-top: 10px;",
			class: "vrButtonLogout vrButtonPatient",
			icon: vr.IconClassicLight.Logout,
			onClick: (e) => 
			{
				Support.confirm("Sicuro di voler uscire?", () => Support.logout());
			}
		}, divButtons);
		//#endregion
	}

	static fillControls(controls: ControlProfile, response: GetSubjectResponse)
	{
		controls.txtName.value(response.name);
		controls.txtSurname.value(response.surname);
		controls.txtFiscalCode.value(response.fiscalCode);
		controls.btgGender.select([response.gender]);

		//#region Birthdate
		if (response.webApiBirthDate != null)
		{
			let webApiBirthDate = response.webApiBirthDate!;
			let birthdate = new Date(webApiBirthDate.year, webApiBirthDate.month - 1, webApiBirthDate.day, webApiBirthDate.hours, webApiBirthDate.minutes, webApiBirthDate.seconds, webApiBirthDate.milliSeconds);
			controls.dtpBirthdate.value(birthdate);
		}
		//#endregion

		//#region Contacts
		if (response.contacts != null && response.contacts.length > 0)
		{
			let emailContactInfo = response.contacts.filter(k => k.conactType == ContactTypeEnum.PrivateEmail)[0];
			if (emailContactInfo != null)
			{
				controls.txtEmail.tag(emailContactInfo.contactId);
				controls.txtEmail.value(emailContactInfo.value);
			}

			let mobilePhoneContactInfo = response.contacts.filter(k => k.conactType == ContactTypeEnum.PrivateMobile)[0];
			if (mobilePhoneContactInfo != null)
			{
				controls.txtPhoneNumber.tag(mobilePhoneContactInfo.contactId);
				controls.txtPhoneNumber.value(mobilePhoneContactInfo.value);
			}

			let landlineContactInfo = response.contacts.filter(k => k.conactType == ContactTypeEnum.HomePhone)[0];
			if (landlineContactInfo != null)
			{
				controls.txtLandline.tag(landlineContactInfo.contactId);
				controls.txtLandline.value(landlineContactInfo.value);
			}
		}
		//#endregion

		Register.getAllCountries((e) =>
		{
			controls.cmbOutItalyResidenceCountry.items(e.countries);
			controls.cmbForeignBirthCityCountry.items(e.countries);
		}, vrshared.WebApiModeEnum.Sync);

		//#region BirthCity
		if (response.birthAddressInfo != null)
		{
			if (response.birthAddressInfo.istatCode != null && response.birthAddressInfo.istatCode != ""
				|| (response.birthAddressInfo.countryCode != null && response.birthAddressInfo.countryCode == "IT")) // Italian
			{
				controls.swtItalianForeignBirthCity.checked(false);
				controls.cmbBirthCity.value(response.birthAddressInfo.istatCode);
			}
			else if ((response.birthAddressInfo.countryCode != null && response.birthAddressInfo.countryCode != "")
				|| (response.birthAddressInfo.address != null && response.birthAddressInfo.address != "")) // Foreign
			{
				controls.swtItalianForeignBirthCity.checked(true);
				controls.cmbForeignBirthCityCountry.value(response.birthAddressInfo.countryCode);
				controls.txtForeignBirthCity.value(response.birthAddressInfo.address);
			}
		}
		//#endregion

		//#region Residence Address
		if (response.italianAddress != null)
		{
			controls.swtItalianForeignResidenceAddress.checked(false);
			controls.txtItalyResidenceAddress.value(response.italianAddress.street);
			controls.cmbItalyResidenceCity.value(response.italianAddress.istatCode);
			controls.cmbItalyResidenceZip.value(response.italianAddress.zipCode);
		}
		else if (response.foreignAddress != null)
		{
			controls.swtItalianForeignResidenceAddress.checked(true);
			controls.txtOutItalyResidenceAddress.value(response.foreignAddress.address);
			controls.cmbOutItalyResidenceCountry.value(response.foreignAddress.countryCode);
		}
		//#endregion
	}

	static getSubject(controls?: ControlProfile | null, callback?: (e: GetSubjectResponse) => void,
		errorCallback?: (e: vrshared.WebApiError) => void, async = true)
	{
		let request = new GetSubjectRequest();
		request.call(
			{
				success: (response: GetSubjectResponse) =>
				{
					if (controls != null)
						this.fillControls(controls, response);

					if (callback != null)
						callback(response);
				},
				error: (e) =>
				{
					if (errorCallback != null)
						errorCallback(e);
				},
				mode: (async) ? vrshared.WebApiModeEnum.Async : vrshared.WebApiModeEnum.Sync,
				loader: Rotator.container()
			});
	}

	static updateSubject(controls: ControlProfile, subjectId: number, check = true, async = true, fields: MandatoryFieldInfo[] | null = null,
		callback?: (e: UpdateSubjectResponse) => void, errorCallback?: (e: vrshared.WebApiError) => void)
	{
		//#region Check
		if (check && fields != null)
		{
			let error = Register.checkMandatoryFields(fields, controls.txtFiscalCode, controls.dtpBirthdate, controls.swtItalianForeignBirthCity,
				controls.cmbBirthCity, controls.cmbForeignBirthCityCountry, controls.txtForeignBirthCity, controls.btgGender,
				controls.swtItalianForeignResidenceAddress, controls.txtItalyResidenceAddress, controls.cmbItalyResidenceCity,
				controls.txtItalyResidenceProvince, controls.cmbItalyResidenceZip, controls.txtOutItalyResidenceAddress,
				controls.cmbOutItalyResidenceCountry, controls.txtLandline);

			if (error)
				return;
		}
		//#endregion

		let request = new UpdateSubjectRequest();
		request.plantId = Rotator.configuration().plant.id;
		request.id = subjectId;
		request.name = controls.txtName.value();
		request.surname = controls.txtSurname.value();
		request.webApiBirthDate = Date.toWebApiDateTime(controls.dtpBirthdate.value());
		request.fiscalCode = controls.txtFiscalCode.value();
		request.gender = controls.btgGender.getSelectedValues()[0];

		//#region Contacts
		let contacts: ContactInfo[] = [];
		if (!controls.txtEmail.isEmpty())
		{
			let emailContactInfo = new ContactInfo();
			emailContactInfo.contactId = controls.txtEmail.tag();
			emailContactInfo.conactType = ContactTypeEnum.PrivateEmail;
			emailContactInfo.value = controls.txtEmail.value();
			contacts.push(emailContactInfo);
		}

		if (!controls.txtPhoneNumber.isEmpty())
		{
			let mobilePhoneContactInfo = new ContactInfo();
			mobilePhoneContactInfo.contactId = controls.txtPhoneNumber.tag();
			mobilePhoneContactInfo.conactType = ContactTypeEnum.PrivateMobile;
			mobilePhoneContactInfo.value = controls.txtPhoneNumber.value();
			contacts.push(mobilePhoneContactInfo);
		}

		if (!controls.txtLandline.isEmpty())
		{
			let landlineContactInfo = new ContactInfo();
			landlineContactInfo.contactId = controls.txtLandline.tag();
			landlineContactInfo.conactType = ContactTypeEnum.HomePhone;
			landlineContactInfo.value = controls.txtLandline.value();
			contacts.push(landlineContactInfo);
		}
		request.contacts = contacts;
		//#endregion

		request.birthAddressInfo = new BirthAddressInfo();
		if (!controls.swtItalianForeignBirthCity.checked())
		{
			//#region Italian
			request.birthAddressInfo.istatCode = controls.cmbBirthCity.value<string>()!;
			//#endregion
		}
		else
		{
			//#region Foreign
			let countrySelected = controls.cmbForeignBirthCityCountry.getSelectedItem<any>();
			if (countrySelected != null)
				request.birthAddressInfo.countryCode = controls.cmbForeignBirthCityCountry.value<string>()!;

			request.birthAddressInfo.address = controls.txtForeignBirthCity.value();
			//#endregion
		}

		if (!controls.swtItalianForeignResidenceAddress.checked())
		{
			//#region Italian address
			let italianAddress = new ItalianAddress();
			italianAddress.street = controls.txtItalyResidenceAddress.value();
			italianAddress.istatCode = controls.cmbItalyResidenceCity.value<string>()!;
			italianAddress.zipCode = controls.cmbItalyResidenceZip.text();
			request.italianAddress = italianAddress;
			//#endregion
		}
		else 
		{
			//#region Foreign address
			let foreignAddress = new ForeignAddress();
			foreignAddress.address = controls.txtOutItalyResidenceAddress.value();

			let countrySelected = controls.cmbOutItalyResidenceCountry.getSelectedItem<any>();
			if (countrySelected != null)
				foreignAddress.countryCode = controls.cmbOutItalyResidenceCountry.value<string>()!;

			request.foreignAddress = foreignAddress;
			//#endregion
		}

		request.call(
			{
				success: (response: UpdateSubjectResponse) =>
				{
					if (callback != null)
						callback(response);
				},
				error: (e) =>
				{
					if (errorCallback != null)
						errorCallback(e);
				},
				mode: (async) ? vrshared.WebApiModeEnum.Async : vrshared.WebApiModeEnum.Sync,
				loader: Rotator.container()
			});
	}

	static computeFiscalCode(name: string, surname: string, gender: string, birthdate: Date, birthAddressInfo: BirthAddressInfo | null, callback: (e: ComputeFiscalCodeResponse) => void)
	{
		//#region Check
		let error = false;

		if (name == null || name == "")
		{
			VrManager.notify("Inserire un nome", "", { type: vr.NotifierTypeEnum.Error });
			error = true;
		}

		if (surname == null || surname == "")
		{
			VrManager.notify("Inserire un cognome", "", { type: vr.NotifierTypeEnum.Error });
			error = true;
		}

		if (gender == null || gender == "")
		{
			VrManager.notify("Indicare il proprio genere", "", { type: vr.NotifierTypeEnum.Error });
			error = true;
		}

		if (birthdate == null)
		{
			VrManager.notify("Inserire una data di nascita", "", { type: vr.NotifierTypeEnum.Error });
			error = true;
		}

		if (birthAddressInfo == null)
		{
			VrManager.notify("Inserire una città di nascita", "", { type: vr.NotifierTypeEnum.Error });
			error = true;
		}
		else
		{
			if (birthAddressInfo.countryCode != null && birthAddressInfo.countryCode != "")
			{
				if (birthAddressInfo.address == null || birthAddressInfo.address == "")
				{
					VrManager.notify("Inserire l'indirizzo di nascita", "", { type: vr.NotifierTypeEnum.Error });
					error = true;
				}
			}
			else
			{
				if (birthAddressInfo.istatCode == null || birthAddressInfo.istatCode == "")
				{
					VrManager.notify("Inserire la città di nascita", "", { type: vr.NotifierTypeEnum.Error });
					error = true;
				}
			}
		}

		if (error)
			return;
		//#endregion

		let request = new ComputeFiscalCodeRequest();
		request.name = name;
		request.surname = surname;
		request.gender = gender;
		request.birthDate = Date.toWebApiDateTime(birthdate);
		request.birthAddressInfo = birthAddressInfo!;
		request.call(
			{
				success: (response: ComputeFiscalCodeResponse) =>
				{
					if (callback != null)
						callback(response);
				},
				mode: vrshared.WebApiModeEnum.Async,
				loader: Rotator.container()
			});
	}

	static checkFiscalCode(fiscalCode: string, countryId?: number | null, callback?: (e: CheckFiscalCodeResponse) => void)
	{
		if (fiscalCode == null || fiscalCode == "")
			return;

		let request = new CheckFiscalCodeRequest();
		request.countryId = (countryId == null) ? 0 : countryId;
		request.fiscalCode = fiscalCode;
		request.call(
			{
				success: (response: CheckFiscalCodeResponse) =>
				{
					if (callback != null)
						callback(response);
				},
				mode: vrshared.WebApiModeEnum.Async,
				loader: Rotator.container()
			});
	}
}

//#region Classes
class GetSubjectRequest extends WebApiRequestBase
{
	public method: string = "/api/PrivateAreaWebApi/GetSubject";
}

export class GetSubjectResponse extends WebApiResponseBase
{
	public id: number;
	public name: string;
	public surname: string;
	public birthdate?: Date | null;
	public webApiBirthDate?: WebApiDateTime;
	public fiscalCode: string;
	public username: string;
	public gender: string;
	public contacts: ContactInfo[];
	public birthAddressInfo: BirthAddressInfo;
	public italianAddress: ItalianAddress;
	public foreignAddress: ForeignAddress;
	public patientAge: number;
	public hasPrivacy: boolean;
}

class UpdateSubjectRequest extends WebApiRequestBase
{
	public method: string = "/api/PrivateAreaWebApi/UpdateSubject";

	public plantId: number;
	public id: number;
	public name: string;
	public surname: string;
	public contacts: ContactInfo[];
	public birthDate: Date | null;
	public fiscalCode: string;
	public gender: string;
	public birthAddressInfo: BirthAddressInfo;
	public italianAddress: ItalianAddress;
	public foreignAddress: ForeignAddress;
	public webApiBirthDate: WebApiDateTime;
}

class UpdateSubjectResponse extends WebApiResponseBase
{

}

class ControlProfile
{
	txtName: vr.TextBox;
	txtSurname: vr.TextBox;
	txtEmail: vr.TextBox;
	txtPhoneNumber: vr.TextBox;
	dtpBirthdate: vr.DatePicker;
	txtFiscalCode: vr.TextBox;
	txtLandline: vr.TextBox;
	btgGender: vr.ButtonGroup;
	cmbOutItalyResidenceCountry: vr.ComboBox;
	cmbForeignBirthCityCountry: vr.ComboBox;
	swtItalianForeignBirthCity: vr.Switch;
	cmbBirthCity: vr.ComboBox;
	txtForeignBirthCity: vr.TextBox;
	swtItalianForeignResidenceAddress: vr.Switch;
	txtItalyResidenceAddress: vr.TextBox;
	cmbItalyResidenceCity: vr.ComboBox;
	txtItalyResidenceProvince: vr.TextBox;
	cmbItalyResidenceZip: vr.ComboBox;
	txtOutItalyResidenceAddress: vr.TextBox;
}

class ComputeFiscalCodeRequest extends WebApiRequestBase
{
	public method: string = "/api/UtilityWebApi/ComputeFiscalCode";

	public name: string;
	public surname: string;
	public birthDate?: WebApiDateTime | null;
	public gender: string;
	public birthAddressInfo: BirthAddressInfo;
}

class ComputeFiscalCodeResponse extends WebApiResponseBase
{
	public invalidFieldsMessage: string;
	public fiscalCode: string;
}

class CheckFiscalCodeRequest extends WebApiRequestBase
{
	public method: string = "/api/UtilityWebApi/CheckFiscalCode";

	public countryId?: number | null;
	public fiscalCode: string;
}

class CheckFiscalCodeResponse extends WebApiResponseBase
{
	public isValid: boolean;
}
//#endregion