import { puma, Rotator } from "../rotator";
import { WebApiRequestBase } from "../../src/shared/webApi";

export class SelectionListControl
{
    private _options: SelectionListControlOptions;
    private _items: ControlItem[];
    private _txtSearchFilter: vr.TextBox;
    private _value?: string | number | null;

    constructor(options: SelectionListControlOptions)
    {
        if (options.selectionFocusMode == null)
            options.selectionFocusMode = false;

        this._options = options;
        if (this._options.rebind != null && this._options.rebind.rebindAtStartup === true)
            this.rebind();
    }

    private getAllItems()
    {
        let items: ControlItem[] = [];
        for (let item of this.items())
        {
            if (item == null)
                continue;

            items.push(item);
            if (item.children != null && item.children.length > 0)
            {
                for (let child of item.children)
                {
                    if (child != null)
                        items.push(child);
                }
            }
        }
        return items;
    }

    items(items?: ControlItem[]): ControlItem[]
    {
        if (items != null)           
        {
            this._items = items;
            this.drawDataSource();
        }
        return this._items;
    }

    private drawDataSource(searchedText?: string | null): void
    {
        puma(this.getOptions().container).find(".vrPatientSelectionUl").remove();

        let timeout = 0;
        this._txtSearchFilter = vr.createTextBox(
            {
                width: "100%",
                icon: vr.IconClassicLight.Search,
                placeholder: "Cerca...",
                class: "vrComboBoxFilter",
                css: "height: 100%; width: 100%; padding-top: 0px; padding-bottom: 0px;",
                cssContainer: "margin-bottom: 5px; margin-top: 5px; height: 35px;",
                onKeyUp: (e) =>
                {
                    clearTimeout(timeout);
                    timeout = window.setTimeout(() => 
                    {
                        let text: string = e.sender.value();
                        this.filter(text, this._txtSearchFilter.element());
                    }, 200);
                }
            }, this.getOptions().container);

        let items = this.items();
        if (searchedText != null)
            items = this.getFilteredArrayByInputText(searchedText);

        if (items.length == 1 && items[0] == null)
            return;

        items = items.filter(k => (k.children != null && k.children.length > 0) || k.children == null);
        this.drawItems(items, this.getOptions().container);
    }

    private drawItems(items: ControlItem[], elementToAppend: HTMLElement): void
    {
        let options = this.getOptions();
        if (items.length == 0)
            return;

        let i = 0;
        let ul = puma("<ul class='vrPatientSelectionUl'></ul>").appendToPuma(elementToAppend);
        for (let item of items)
        {
            let children = this.getOnlyChildrenItems(item);
            let li = puma("<li class='vrPatientSelectionLi'></li>").appendToPuma(ul);

            if (item.children == null && this.isChildren(item))
                puma(li).closest(".vrPatientSelectionUl").hide();

            let rowText = item.text.replace(/'/g, "&#39;");
            if (options.template != null)
                rowText = options.template({ dataItem: item });

            let comboItem = puma("<div class='vrPatientSelectionItemContainer'></div>").appendToPuma(li);
            let comboItemText = puma("<span value='" + item.value + "' text='" + item.text.replace(/'/g, "&#39;") + "' class='vrPatientSelectionItemText' style='color: " + Rotator.color() + "'>" + rowText + "</span>").appendToPuma(comboItem);
            puma(comboItemText).click((e: any) =>
            {
                if (item.children == null)
                {
                    this._value = item.value;
                    this.select(this._value);
                    if (options.onSelect != null)
                    {
                        let selectEvent = new OnSelectEvent();
                        selectEvent.text = item.text;
                        selectEvent.value = item.value;
                        selectEvent.dataItem = item;
                        options.onSelect(selectEvent);
                    }
                }
                else
                {
                    puma(comboItem).closest("li").siblings().hide();
                    puma(comboItem).closest("li").show();

                    if (this._value != null || items.length > 1)
                        puma(comboItem).closest("li").find("." + vr.IconClassicLight.Close.split(" ")[1]).show();

                    let visibleItems = puma(comboItem).closest("li").find("li:visible");
                    if (visibleItems.length == 0)
                    {
                        //#region Expand
                        puma(comboItem).closest("li").find("ul").show();
                        puma(comboItem).closest("li").find("li").show();
                        puma(comboItem).closest("li").find(".bold").css("font-weight", "600");
                        //#endregion
                    }
                }
            });

            if (items.length == 1 && i == 0 && item.children != null)
                puma(comboItemText).click();

            if (children != null && children.length > 0)
            {
                puma(comboItemText).addClass("bold");
                this.drawItems(this.getOnlyChildrenItems(item), li);
            }

            if (!this.isChildren(item) && options.selectionFocusMode)
            {
                let iconClear = vr.icon(vr.IconClassicLight.Close, comboItem, { css: "background-color: " + Rotator.color() + "; color: " + Rotator.textColor() + "; padding: 3px; border-radius: 10px; padding-left: 4px; padding-right: 4px;position: absolute; right: 10px; top: 7px; font-size: 1.5em; display: none;" });
                puma(iconClear).on("click", (e: JQuery.ClickEvent) =>
                {
                    this.clear(true, true);
                });

                if (items.length <= 1)
                    puma(iconClear).hide();
            }

            i++;
        }
    }

    private getFilteredArrayByInputText(value: string): ControlItem[]
    {
        value = value.toLowerCase();
        let filteredArray: ControlItem[] = [];
        let arrayWhereSearch = this.getAllItems().map(k => String(k.text).toLowerCase());
        arrayWhereSearch!.forEach((k, index) => 
        {
            if (k.indexOf(value) != -1)
                filteredArray.push(this.getAllItems()[index]);
        });
        return filteredArray;
    }

    private getOnlyChildrenItems(parentItem: ControlItem): ControlItem[]
    {
        return parentItem.children;
    }

    private getAllChildrenItems(): ControlItem[]
    {
        let children: ControlItem[] = [];
        for (let item of this.items())
        {
            if (item.children != null && item.children.length > 0)
                children.pushRange(item.children);
        }
        return children;
    }

    private isChildren(item: ControlItem)
    {
        return this.getAllChildrenItems().map(k => k.value).includes(item.value);
    }

    private getDataChildrenItems(parentItem?: ControlItem | null): ControlItem[]
    {
        let dataItems = (parentItem == null) ? this.items() : this.getOnlyChildrenItems(parentItem);
        let items: ControlItem[] = [];
        for (let dataElement of dataItems)
        {
            items.push(dataElement);
            if (this.getOnlyChildrenItems(dataElement).length > 0)
            {
                for (let dateChildrenItem of this.getDataChildrenItems(dataElement))
                    items.push(dateChildrenItem);
            }
        }
        return items;
    }

    private filter(text: string, element: HTMLElement): void
    {
        let options = this.getOptions();
        if (text == "")
            this.clear(false);
        else
        {
            puma(options.container).find(".vrPatientSelectionItemText.bold").css("font-weight", "600");
            puma(options.container).find("li").hide();

            let items = this.getFilteredArrayByInputText(text);
            let texts = items.map(k => k.text);

            if (texts.length > 0)
            {
                for (let comboItemText of Array.from<HTMLSpanElement>(puma(options.container).find(".vrPatientSelectionItemText")))
                {
                    if (texts.includes(puma(comboItemText).attr("text")))
                    {
                        puma(comboItemText).closest(".vrPatientSelectionLi").show();
                        puma(comboItemText).closest(".vrPatientSelectionLi").closest(".vrPatientSelectionUl").show();
                        puma(comboItemText).closest(".vrPatientSelectionLi").closest(".vrPatientSelectionUl").closest(".vrPatientSelectionLi").show();
                    }
                }
            }
        }
    }

    rebind()
    {
        puma(this.getOptions().container).find(".vrPatientSelectionUl").remove();
        puma(this.getOptions().container).find(".vrComboBoxFilter").parent().remove();

        let rebindRequest = this.getOptions().rebind!;

        let request = new WebApiRequestBase();
        if (rebindRequest.itemsPropertyName == null)
            rebindRequest.itemsPropertyName = "items";

        (request as any).itemsPropertyName = rebindRequest.itemsPropertyName;

        //#region Method
        if (!rebindRequest.method!.startsWith("/api/"))
        {
            if (rebindRequest.method!.startsWith("/"))
                rebindRequest.method!.substring(1);

            rebindRequest.method = "/api/" + rebindRequest.method;
        }
        request.method = rebindRequest.method!;
        //#endregion

        //#region Paramaters
        if (rebindRequest.parameters != null)
        {
            let parameters = rebindRequest.parameters();
            let jsonParameters = Object.getOwnPropertyNames(parameters);
            for (let param of jsonParameters)
                (request as any)[param] = parameters[param];
        }
        //#endregion

        request.call(
            {
                success: (response: any) =>
                {
                    //#region Set data source if rebind
                    if (response[(request as RebindRequest).itemsPropertyName!] != null)
                        this.items(response[(request as RebindRequest).itemsPropertyName!]);

                    if (this._txtSearchFilter != null)
                        this._txtSearchFilter.focus();
                    //#endregion

                    if (rebindRequest!.callback != null)
                        rebindRequest!.callback(response);
                },
                mode: vrshared.WebApiModeEnum.Async,
                loader: Rotator.container(),
                loaderDelay: 500
            });
    }

    select(value: string | number, triggerChange = false)
    {
        this.clear();
        this._value = value;
        let options = this.getOptions();

        for (let comboItemText of Array.from<HTMLSpanElement>(puma(options.container).find(".vrPatientSelectionItemText")))
        {
            if (puma(comboItemText).attr("value") == value)
            {
                puma(comboItemText).closest(".vrPatientSelectionLi").show();
                puma(comboItemText).closest(".vrPatientSelectionLi").closest(".vrPatientSelectionUl").show();
                puma(comboItemText).css("background-color", Rotator.color());
                puma(comboItemText).css("color", Rotator.textColor());
                puma(comboItemText).closest(".vrPatientSelectionLi").siblings().show();

                if (options.selectionFocusMode)
                {
                    puma(comboItemText).closest(".vrPatientSelectionLi").closest(".vrPatientSelectionUl").closest("li").siblings().hide();
                    puma(comboItemText).closest(".vrPatientSelectionLi").closest(".vrPatientSelectionUl").closest("li").find("." + vr.IconClassicLight.Close.split(" ")[1]).show();
                }

                puma(comboItemText).closest(".vrPatientSelectionLi").closest(".vrPatientSelectionUl").closest("li").show();
                puma(comboItemText).closest(".vrPatientSelectionLi").closest(".vrPatientSelectionUl").closest("li").find(".bold").css("font-weight", "600");
                break;
            }
        }

        if (triggerChange)
        {
            let item = this.getAllItems().filter(k => k.value == value)[0];
            if (options.onSelect != null)
            {
                let selectEvent = new OnSelectEvent();
                selectEvent.text = item.text;
                selectEvent.value = item.value;
                selectEvent.dataItem = item;
                options.onSelect(selectEvent);
            }
        }
    }

    focus()
    {
        this._txtSearchFilter.focus();
    }

    clear(clearValue = true, triggerChange = false): void
    {
        let options = this.getOptions();
        if (options.selectionFocusMode)
            puma(options.container).find("li").hide();

        puma(options.container).find(".vrPatientSelectionItemText.bold").css("font-weight", "normal");

        let liList = Array.from(puma(options.container).find("li"));
        for (let li of liList)
        {
            if (puma(li).find("ul").length > 0)
            {
                puma(li).find("ul").hide();
                puma(li).show();
            }
        }

        for (let comboItemText of Array.from<HTMLSpanElement>(puma(options.container).find(".vrPatientSelectionItemText")))
        {
            puma(comboItemText).css("background-color", "#FFF");
            puma(comboItemText).css("color", Rotator.color());
        }

        puma(options.container).find("." + vr.IconClassicLight.Close.split(" ")[1]).hide();

        if (this.items() != null && this.items().length == 1)
        {
            let comboItemText = puma(options.container).find("li:first-child .vrPatientSelectionItemText")[0];
            puma(comboItemText).closest("li").siblings().hide();
            puma(comboItemText).closest("li").show();

            let visibleItems = puma(comboItemText).closest("li").find("li:visible");
            if (visibleItems.length == 0)
            {
                //#region Expand
                puma(comboItemText).closest("li").find("ul").show();
                puma(comboItemText).closest("li").find("li").show();
                puma(comboItemText).closest("li").find(".bold").css("font-weight", "600");
                //#endregion
            }
        }

        if (clearValue)
            this._value = null;
        else if (this._value != null)
            this.select(this._value);

        if (triggerChange)
        {
            if (options.onClear != null)
            {
                let clearEvent = new OnClearEvent();
                clearEvent.sender = this;
                options.onClear(clearEvent);
            }
        }
    }

    container()
    {
        return this.getOptions().container;
    }

    private getOptions()
    {
        return this._options;
    }
}

class SelectionListControlOptions
{
    container: HTMLElement;
    rebind?: RebindRequest;
    filter?: boolean;
    selectionFocusMode?: boolean;
    template?: (e: TemplateEvent) => string;

    onSelect?: (e: OnSelectEvent) => void;
    onClear?: (e: OnClearEvent) => void;
}

class OnClearEvent
{
    sender: SelectionListControl;
}

class TemplateEvent
{
    dataItem?: any;
}

class RebindRequest
{
    authKey?: string;
    method?: string;
    rebindAtStartup?: boolean;
    itemsPropertyName?: string;

    callback?: (response?: any) => void;
    parameters?: () => any;
}

class OnSelectEvent
{
    value: string | number;
    text: string;
    dataItem: any;
}

class ControlItem
{
    text: string;
    value: string | number;
    children: ControlItem[];
}