import { WebApiRequestBase, WebApiResponseBase } from "../shared/webApi";
import { PageEnum, puma, Rotator } from "../rotator";
import { Support } from "../support";
import { ReportItem, SendingAttachmentsDisplay } from "../../src/shared/common";
import { ReportList } from "./reportList";
import { FileList } from "./fileList";

export class ReportSendingStorageList
{
	private static _container: HTMLElement;
	private static _parameters: ReportSendingStorageListParameters;
	private static _divStorageFiles: HTMLElement;

	static initialize(parameters: ReportSendingStorageListParameters)
	{
		this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
		this._container.style.cssText += "position: relative;";
		Rotator.title("ALLEGATI");

		this._parameters = parameters;

		Support.checkLogin(() =>
		{
			this._divStorageFiles = vr.div(this._container);
			this.drawStorageFiles();
		}), () => Rotator.page(PageEnum.ReportList), () => Rotator.page(PageEnum.ReportSendingStorageList);
	}

	private static drawStorageFiles()
	{
		puma(this._divStorageFiles).empty();

		//#region Report
		let divReport = this.drawReport(this._parameters.report, this._divStorageFiles);

		vr.createSeparator({
			orientation: vr.OrientationEnum.Horizontal,
			cssContainer: "margin-top: -16px !important;"
		}, this._divStorageFiles);
		//#endregion

		//#region PACS
		if (this._parameters.openViewer)
		{
			vr.createButton({
				text: "Visualizza immagini",
				width: "100%",
				cssContainer: "margin-top: 5px;",
				colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
				onClick: (e) => 
				{
					let request = new GetPacsOrdersRequest();
					request.reportSendingId = this._parameters.report.reportSendingId;
					request.call(
						{
							success: (response: GetPacsOrdersResponse) => 
							{
								switch (response.pacsType)
								{
									case PacsType.O3_Web:
										{
											$.ajax(
												{
													type: "GET",
													method: "GET",
													url: response.webLink,
													success: (response2, cb) =>
													{
														let finalURL = response.windowUrl + "?q=" + response2;
														vr.openBrowserWindow(finalURL);
													},
													error: (response, cb) => 
													{
														vr.notifyError(response.responseText)
													}
												});
										}
										break;
								}
							},
							mode: vrshared.WebApiModeEnum.Async,
							loader: Rotator.container()
						});
				}
			}, puma(divReport).find(".divReportButtons")[0])
		}
		//#endregion

		//#region Files
		vr.createLabel({
			text: "File allegati",
			cssContainer: "padding-bottom: 5px; margin-bottom: 10px; margin-left: 5px; border-bottom: solid 2px " + Rotator.color() + ";"
		}, this._divStorageFiles)

		if (this._parameters.storageFileList.length == 0)
		{
			let divNoRecords = vr.div(this._divStorageFiles, { class: "vrPatientDivSlot" });
			vr.createLabel({
				text: "Non sono presenti allegati",
				width: "100%",
				align: vr.TextAlignEnum.Center
			}, divNoRecords);
			return;
		}
		else
		{
			for (let storageFile of this._parameters.storageFileList)
				this.drawDetail(storageFile, this._divStorageFiles);
		}
		//#endregion
	}

	private static drawReport(report: ReportItem, container: HTMLElement)
	{
		let divReport = vr.div(container, { class: "vrPatientDivSlot" });

		//#region Info
		let divIconInfo = vr.div(divReport, { css: "display: flex;" });

		let divInfo = vr.div(divIconInfo, { css: "text-align: left; width: 100%;" });
		vr.createLabel({
			text: report.activityTypeName,
			bold: true,
			css: "text-overflow: ellipsis; overflow: hidden; width: 100%;",
			cssContainer: "width: Calc(100% - 55px);"
		}, divInfo);
		//#endregion

		//#region Buttons
		let divButtons = vr.div(divReport, { class: "divReportButtons", css: "margin-top: 10px;" });
		vr.createButton({
			text: "Scarica referto",
			colorSettings: { textColor: Rotator.textColor(), background: Rotator.color() },
			css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
			cssContainer: "width: 100%;",
			onClick: (e) => 
			{
				ReportList.downloadReport(report.storedFileId, report.date);
			}
		}, divButtons);
		//#endregion

		return divReport;
	}

	private static drawDetail(file: SendingAttachmentsDisplay, container: HTMLElement): HTMLElement
	{
		let divDetail = vr.div(container, { class: "vrPatientDivSlot" });

		//#region Icon/Info
		let divIconInfo = vr.div(divDetail, { css: "display: flex;" });
		let fileInfo = FileList.getFileInfo(file.mimeType)
		vr.icon(fileInfo.icon, divIconInfo, { css: "font-size: 3em; margin-top: 2px; margin-right: 10px; color: " + fileInfo.color + ";" });

		let divInfo = vr.div(divIconInfo, { css: "text-align: left; width: 100%;" });
		vr.createLabel({
			text: file.fileName,
			bold: true,
			css: "text-overflow: ellipsis; overflow: hidden; width: 100%;",
			cssContainer: "width: Calc(100% - 55px);"
		}, divInfo);

		vr.createLabel({
			text: new Date(file.uploadDate).toItalyLongString(),
			width: "100%"
		}, divInfo);
		//#endregion

		//#region Buttons
		let divButtons = vr.div(divDetail, { css: "margin-top: 10px; display: flex; justify-content: space-between;" });
		vr.createButton({
			text: "Scarica",
			colorSettings: { textColor: Rotator.color() },
			css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
			cssContainer: "width: 100%;",
			onClick: (e) => 
			{
				this.downloadReportSendingStorage(file.id, file.uploadDate);
			}
		}, divButtons);
		//#endregion

		return divDetail;
	}

	private static downloadReportSendingStorage(id: number, date: Date)
	{
		let request = new DownloadReportSendingStorageRequest();
		request.reportSendingStorageId = id;
		request.call(
			{
				success: (response: DownloadReportSendingStorageResponse) => 
				{
					vrshared.UtilityManager().downloadFile(response.base64, response.fileName, response.mimeType, true);
				},
				loader: Rotator.container()
			});
	}
}

//#region Classes
export class ReportSendingStorageListParameters
{
	storageFileList: SendingAttachmentsDisplay[];
	report: ReportItem;
	openViewer: boolean;
}

class DownloadReportSendingStorageRequest extends WebApiRequestBase
{
	public method: string = "/api/PrivateAreaWebApi/DownloadReportSendingStorage";
	public reportSendingStorageId: number;
}

class DownloadReportSendingStorageResponse extends WebApiResponseBase
{
	public base64: string;
	public fileName: string;
	public mimeType: string;
}

class GetPacsOrdersRequest extends WebApiRequestBase
{
	public method: string = "/api/PrivateAreaWebApi/GetPacsOrders";
	public reportSendingId: number;
}

class GetPacsOrdersResponse extends WebApiResponseBase
{
	public orders: any[];
	public webLink: string;
	public pacsType: PacsType;
	public windowUrl: string;
}

enum PacsType
{
	Sectra = 1,
	O3_Web = 2,
	O3_Local = 3
}
//#endregion