import { Support } from "../../src/support";
import { Rotator, puma, PageEnum, AppLoginModeEnum } from "../../src/rotator";
import { ResourceResponse, WorkplaceResponse, ActivityTypeResponse, Timeslot, ActivityTypeItem, OnlineBookingModeEnum } from "../../src/shared/common";
import { ActivityList } from "./activityList";
import { DoctorList } from "./doctorList";
import { WorkplaceList } from "./workplaceList";
import { VrManager } from "../../src/shared/vrManager";
import { Profile } from "../../src/privateArea/profile";
import { Menu } from "../../src/shared/menu";
import { FileLoaded } from "./confirmation2";

export class Booking
{
    private static _container: HTMLElement;

    static initialize()
    {
        Rotator.bookingParameters().filesLoaded = null;
        if ((Rotator.webComponentParameters()[0].vrSessionId == null || Rotator.webComponentParameters()[0].vrSessionId == "")
            && !Rotator.configuration().appBookingAppointments)
        {
            Rotator.page(PageEnum.AppointmentList);
            return;
        }

        if (Rotator.configuration().appLoginRequired == AppLoginModeEnum.Always)
        {
            Support.checkLogin(() => this.initializeInternal(), () => Rotator.page(PageEnum.Login));
            return;
        }

        this.initializeInternal();
    }

    private static initializeInternal()
    {
        this._container = Rotator.container();
        Rotator.title("PRENOTA");

        Rotator.confirmationParameters(null);
        Rotator.inBookingFlow(true);
        this.createControls();

        //#region Specific parameters
        if (Rotator.webComponentParameters()[0].specificActivityTypeIdList != null && Rotator.webComponentParameters()[0].specificActivityTypeIdList.length == 1)
            Rotator.webComponentParameters()[0].specificActivityTypeId = Rotator.webComponentParameters()[0].specificActivityTypeIdList.first();

        if (Rotator.webComponentParameters()[0].specificDoctorIdList != null && Rotator.webComponentParameters()[0].specificDoctorIdList.length == 1)
            Rotator.webComponentParameters()[0].specificDoctorId = Rotator.webComponentParameters()[0].specificDoctorIdList.first();

        this.assignSpecificParameters(StepEnum.Activity);

        WorkplaceList.getWorkplaces((e) => 
        {
            if (e.workplacesList.filter(k => k.id != 0).length > 1 && Rotator.configuration().appUseGeolocationInsteadOfWorkplaceOrder)
                Support.getLocation();

            if (e.workplacesList.length <= 2)
            {
                puma(".vrPatientDivBookingStep." + StepEnum.Workplace).hide();

                if (e.workplacesList.length == 2)
                    Rotator.bookingParameters().workplace = e.workplacesList[1];
                else
                {
                    Rotator.bookingParameters().workplace = e.workplacesList[0];

                    if (Rotator.page() != PageEnum.Login)
                    {
                        Rotator.configuration().appBookingAppointments = false;
                        Menu.hideItem(PageEnum.Booking);
                        Rotator.inBookingFlow(false);
                        Rotator.page(PageEnum.Login);
                        return;
                    }
                }
            }
            else if (Rotator.configuration().appShowWorkplaces)
                puma(".vrPatientDivBookingStep." + StepEnum.Workplace).css({ "display": "flex" });

            this.assignSpecificParameters(StepEnum.Workplace);
        });
        //#endregion
    }

    static createControls()
    {
        puma(this._container).empty();
        this.createStep(StepEnum.Activity);
        this.createStep(StepEnum.Workplace, null, false, null, Rotator.configuration().appShowWorkplaces);

        let hideDoctorStep = (Rotator.bookingParameters().activityType == null || (Rotator.bookingParameters().activityType != null && Rotator.bookingParameters().activityType.onlineBookingDoNotChooseDoctor));
        if (Rotator.bookingParameters().resourceList != null && Rotator.bookingParameters().resourceList.length <= 2)
            hideDoctorStep = true;
        this.createStep(StepEnum.Doctor, null, false, null, !hideDoctorStep);

        this.createStep(StepEnum.From);

        vr.createButton(
            {
                text: "Cerca",
                colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
                css: "width: 100%; box-shadow: 0 1px 3px " + Rotator.color() + ";",
                cssContainer: "float: right",
                width: 120,
                class: "vrButtonBookingSearch vrButtonPatient",
                onClick: (e) =>
                {
                    //#region Check minor age
                    if (Rotator.configuration().appNoBookingMinor === true)
                    {
                        Support.checkLogin(() =>
                        {
                            Profile.getSubject(null, (response) =>
                            {
                                if (response.patientAge < 18 && response.patientAge > 0)
                                {
                                    VrManager.notify("Prenotazione appuntamenti permessa unicamente ai maggiorenni", "", { type: vr.NotifierTypeEnum.Error });
                                    e.preventDefault();
                                    return;
                                }
                            }, undefined, false);
                        }, null, null, false);
                    }
                    //#endregion

                    if (e.isDefaultPrevented())
                        return;

                    if (Rotator.webComponentParameters()[0].fullscreen === true && Rotator.isMobile())
                        vrshared.UtilityManager().enterFullScreen(puma(".vrPatientDivContainer")[0]);

                    if (Rotator.bookingParameters().from == null)
                        Rotator.bookingParameters().from = new Date();

                    if (Rotator.bookingParameters().activityType != null)
                    {
                        if (Rotator.configuration().appRequiredWorkplaceChoice === true && Rotator.bookingParameters().workplace == null)
                        {
                            VrManager.notify("Scegliere una sede", "", { type: vr.NotifierTypeEnum.Error });
                            puma(".vrPatient_spanErrorWorkplace").show();
                            return;
                        }

                        if (!Rotator.bookingParameters().activityType.onlineBookingDoNotChooseDoctor)
                            Rotator.page(PageEnum.ResourceTimeslots);
                        else
                            Rotator.page(PageEnum.WorkplaceTimeslots);
                    }
                    else
                    {
                        let error = "Scegliere ";
                        if (Rotator.bookingParameters().activityType == null)
                            error += "una prestazione";

                        VrManager.notify(error, "", { type: vr.NotifierTypeEnum.Error });
                        puma(".vrPatient_spanErrorActivity").show();
                    }
                }
            }, this._container);
    }

    static createStep(stepEnum: StepEnum, container?: HTMLElement | null, highlighted = false, spanIconExpandCallback: Function | null = null, visible = true)
    {
        let divStep = vr.div((container != null) ? container : this._container, { class: "vrPatientDivBookingStep " + stepEnum });

        let backgroundColor = (highlighted) ? Rotator.color() : "#FFF";
        let textColor = (highlighted) ? Rotator.textColor() : "#000"
        divStep.style.cssText += "background-color: " + backgroundColor + "; box-shadow: 0 1px 5px " + ((highlighted) ? Rotator.color() : "rgba(0,0,0,.3);");

        if (!visible)
            puma(divStep).hide();

        //#region Description
        let question = "";
        let choice = "";
        let icon = vr.IconClassicLight.Bell;
        switch (stepEnum)
        {
            case StepEnum.Activity:
                {
                    question = "Cosa vuoi prenotare?";
                    icon = vr.IconClassicLight.FileLines;

                    choice = "Cerca una prestazione";
                    if (Rotator.bookingParameters().activityType != null && Rotator.bookingParameters().activityType.onlineBookingName.length > 0)
                        choice = Rotator.bookingParameters().activityType.onlineBookingName;
                }
                break;
            case StepEnum.Workplace:
                {
                    question = "Dove vuoi prenotare?";
                    icon = vr.IconClassicLight.Map;

                    choice = "Non ho preferenze";
                    if (Rotator.bookingParameters().workplace != null && Rotator.bookingParameters().workplace!.name.length > 0)
                        choice = Rotator.bookingParameters().workplace!.name;

                    if (!highlighted && (Rotator.bookingParameters().workplaceList == null || Rotator.bookingParameters().workplaceList.length <= 2))
                        puma(divStep).hide();
                }
                break;
            case StepEnum.Doctor:
                {
                    question = "Sai già con chi prenotare?";
                    icon = vr.IconClassicLight.UserDoctor;

                    choice = "Non ho preferenze";
                    if (Rotator.bookingParameters().resource != null && Rotator.bookingParameters().resource.name.length > 0)
                        choice = Rotator.bookingParameters().resource.name;
                }
                break;
            case StepEnum.From:
                {
                    question = "A partire dal giorno";
                    icon = vr.IconClassicLight.Clock;

                    choice = new Date().toItalyLongString();
                    if (Rotator.bookingParameters().from != null)
                        choice = new Date(Rotator.bookingParameters().from).toItalyLongString();
                }
                break;
        }

        let spanIcon = vr.span(divStep);
        vr.icon(icon, spanIcon, { fontSize: (stepEnum == StepEnum.Workplace) ? "1.5em" : "2em", css: "margin-left: 5px; margin-right: 15px;", color: (highlighted) ? Rotator.textColor() : Rotator.color() });

        let spanDescription = vr.span(divStep, { css: "display: inline-block; width: 100%;" });
        vr.createLabel(
            {
                text: question,
                width: "100%",
                class: "vrPatient_bookingQuestion",
                cssContainer: "margin-bottom: 5px;",
                colorSettings: { textColor: textColor }
            }, spanDescription);
        vr.createLabel(
            {
                text: choice,
                width: "100%",
                css: "font-weight: 600;",
                class: "vrPatient_bookingChoice",
                colorSettings: { textColor: (highlighted) ? Rotator.textColor() : Rotator.color() }
            }, spanDescription);
        //#endregion

        //#region Click
        if (container == null)
        {
            puma(divStep).click(() =>
            {
                switch (stepEnum)
                {
                    case StepEnum.Activity:
                        {
                            if (Rotator.webComponentParameters()[0].specificActivityTypeId == null)
                                Rotator.page(PageEnum.ActivityList);
                        }
                        break;
                    case StepEnum.Workplace:
                        {
                            if (Rotator.webComponentParameters()[0].specificWorkplaceId == null)
                                Rotator.page(PageEnum.WorkplaceList);
                        }
                        break;
                    case StepEnum.Doctor:
                        {
                            if (Rotator.webComponentParameters()[0].specificDoctorId == null)
                                Rotator.page(PageEnum.DoctorList);
                        }
                        break;
                    case StepEnum.From:
                        {
                            Rotator.page(PageEnum.FromList);
                        }
                        break;
                }
            });
        }
        //#endregion

        if (stepEnum == StepEnum.Activity)
        {
            let spanError = vr.span(divStep, { css: "display: none;", class: "vrPatient_spanErrorActivity" });
            vr.icon(vr.IconClassicLight.TriangleExclamation, spanError, { fontSize: "1.5em", css: "margin-right: 5px;", color: "red" });
        }

        if (stepEnum == StepEnum.Workplace)
        {
            let spanError = vr.span(divStep, { css: "display: none;", class: "vrPatient_spanErrorWorkplace" });
            vr.icon(vr.IconClassicLight.TriangleExclamation, spanError, { fontSize: "1.5em", css: "margin-right: 5px;", color: "red" });
        }

        let spanIconExpand = vr.span(divStep);
        vr.icon(vr.IconClassicLight.AngleDown, spanIconExpand, { fontSize: "1.5em", css: "margin-right: 5px;", color: (highlighted) ? Rotator.textColor() : Rotator.color() });
        puma(spanIconExpand).on("click", (e: JQuery.ClickEvent) =>
        {
            if (spanIconExpandCallback != null)
                spanIconExpandCallback();
        });
    }

    static doctorSlotVisible(visible: Boolean)
    {
        let stepDoctor = puma(".vrPatientDivBookingStep." + StepEnum.Doctor);
        if (stepDoctor[0] != null)
        {
            if (visible)
                stepDoctor[0].style.cssText += "display: flex;";
            else
                stepDoctor.hide();
        }
    }

    private static assignSpecificParameters(stepMode: StepEnum)
    {
        let divStep = puma(".vrPatientDivBookingStep." + stepMode);
        switch (stepMode)
        {
            case StepEnum.Activity:
                {
                    //#region SpecificActivityTypeId
                    ActivityList.getActivityTypeGroups((activityTypeGroupList) =>
                    {
                        if (activityTypeGroupList.length == 0)
                        {
                            if (Rotator.page() != PageEnum.Login)
                            {
                                if (Rotator.bookingParameters().workplace != null || Rotator.bookingParameters().resource != null)
                                {
                                    (Rotator.bookingParameters().activityType as any) = null;
                                    divStep.find(".vrPatient_bookingChoice").html("Cerca una prestazione");
                                    VrManager.notify("Non sono presenti prestazioni prenotabili", "", { type: vr.NotifierTypeEnum.Default });
                                    return;
                                }

                                if (!Rotator.sailor())
                                {
                                    Rotator.configuration().appBookingAppointments = false;
                                    Menu.hideItem(PageEnum.Booking);
                                }

                                Rotator.inBookingFlow(false);
                                Support.checkLogin(() => Rotator.page(PageEnum.AppointmentList), () => Rotator.page(PageEnum.Login));
                                return;
                            }
                        }

                        let activityType: ActivityTypeResponse | null = null;
                        let specificActivityTypeId = Rotator.webComponentParameters()[0].specificActivityTypeId;
                        if (specificActivityTypeId != null)
                        {
                            if (specificActivityTypeId != null)
                            {
                                for (let activityTypeGroup of activityTypeGroupList)
                                {
                                    let activityTypeTemp = activityTypeGroup.children.filter(k => k.id == specificActivityTypeId)[0] as ActivityTypeResponse;
                                    if (activityTypeTemp != null)
                                    {
                                        if (activityTypeTemp.onlineBookingModeEnum == OnlineBookingModeEnum.ShowAlways
                                            || activityTypeTemp.onlineBookingModeEnum == OnlineBookingModeEnum.ShowOnlyIfAvailable)
                                        {
                                            activityType = activityTypeTemp;
                                        }
                                        else
                                        {
                                            //#region Footer items
                                            let footerItems: vr.WindowFooterItem[] = [];
                                            if (Rotator.configuration().appNoAvailabilitiesPhoneNumber != null && Rotator.configuration().appNoAvailabilitiesPhoneNumber.length > 0)
                                            {
                                                let callPhoneItem: vr.WindowFooterItem =
                                                {
                                                    text: "Chiama",
                                                    colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
                                                    onClick: (e) =>
                                                    {
                                                        let link = document.createElement("a");
                                                        link.href = "tel:" + Rotator.configuration().appNoAvailabilitiesPhoneNumber;
                                                        link.click();
                                                    }
                                                }
                                                footerItems.push(callPhoneItem);
                                            }

                                            if (Rotator.configuration().appNoAvailabilitiesMail != null && Rotator.configuration().appNoAvailabilitiesMail.length > 0)
                                            {
                                                let mailItem: vr.WindowFooterItem =
                                                {
                                                    text: "Email",
                                                    colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
                                                    onClick: (e) =>
                                                    {
                                                        let link = document.createElement("a");
                                                        link.href = "mailto:" + Rotator.configuration().appNoAvailabilitiesMail;
                                                        link.click();
                                                    }
                                                }
                                                footerItems.push(mailItem);
                                            }

                                            let closeItem: vr.WindowFooterItem =
                                            {
                                                text: "Chiudi",
                                                type: vr.WindowFooterItemTypeEnum.Close,
                                                colorSettings: { textColor: Rotator.color() }
                                            }
                                            footerItems.push(closeItem);
                                            //#endregion

                                            Support.dialog(Rotator.configuration().appNoOnlineBookingMessage, footerItems);
                                        }

                                        break;
                                    }
                                }
                            }
                        }
                        else if (activityTypeGroupList.length == 1 && activityTypeGroupList.first().children.length == 1)
                        {
                            activityType = activityTypeGroupList.first().children.first() as ActivityTypeResponse;
                            Rotator.webComponentParameters()[0].specificActivityTypeId = activityType.id;
                        }

                        if (activityType != null)
                        {
                            divStep.find(".vrPatient_bookingChoice").html(activityType.onlineBookingName);
                            Rotator.bookingParameters().activityType = activityType;
                        }

                        let activityTypes: ActivityTypeItem[] = [];
                        for (let activityTypeGroup of activityTypeGroupList)
                            activityTypes.pushRange(activityTypeGroup.children);

                        if (Rotator.bookingParameters().activityType != null && !activityTypes.map(k => k.id).includes(Rotator.bookingParameters().activityType.id))
                        {
                            (Rotator.bookingParameters().activityType as any) = null;
                            divStep.find(".vrPatient_bookingChoice").html("Cerca una prestazione");
                        }

                        DoctorList.getResources((e) => 
                        {
                            this.assignSpecificParameters(StepEnum.Doctor);

                            let stepDoctor = puma(".vrPatientDivBookingStep." + StepEnum.Doctor);
                            if (stepDoctor[0] != null)
                            {
                                if (Rotator.bookingParameters().activityType == null || (Rotator.bookingParameters().activityType != null && Rotator.bookingParameters().activityType.onlineBookingDoNotChooseDoctor)
                                    || e.resourcesList.length <= 2)
                                    stepDoctor.hide();
                                else
                                    stepDoctor[0].style.cssText += "display: flex;";
                            }
                        }, false);
                    });
                    //#endregion
                }
                break;
            case StepEnum.Doctor:
                {
                    //#region SpecificDoctorId
                    let specificDoctorId = Rotator.webComponentParameters()[0].specificDoctorId;
                    if (specificDoctorId != null)
                    {
                        let resource = Rotator.bookingParameters().resourceList.filter(k => k.id == specificDoctorId)[0];
                        divStep.find(".vrPatient_bookingChoice").html(resource.name);
                        Rotator.bookingParameters().resource = resource;
                    }
                    //#endregion
                }
                break;
            case StepEnum.Workplace:
                {
                    //#region SpecificWorkplaceId
                    let specificWorkplaceId = Rotator.webComponentParameters()[0].specificWorkplaceId;
                    if (specificWorkplaceId != null)
                    {
                        let workplace = Rotator.bookingParameters().workplaceList.filter(k => k.id == specificWorkplaceId)[0];
                        divStep.find(".vrPatient_bookingChoice").html(workplace.name);
                        Rotator.bookingParameters().workplace = workplace;
                    }
                    //#endregion
                }
                break;
        }
    }
}

//#region Classes
export enum StepEnum
{
    Activity = "Activity",
    Workplace = "Workplace",
    Doctor = "Doctor",
    From = "From",
}

export class BookingParameters
{
    activityType: ActivityTypeResponse;
    workplace: WorkplaceResponse | null;
    workplaceList: WorkplaceResponse[];
    from: Date;
    resource: ResourceResponse;
    price: number;
    timeslot: Timeslot;
    resourceList: ResourceResponse[];
    latitude: number;
    longitude: number;
    filesLoaded: File[] | null;
}
//#endregion