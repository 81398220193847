import { ActivityTypeResponse, FileTypeEnum, GenderEnum } from "../../src/shared/common";
import { PageEnum, puma, Rotator } from "../../src/rotator";
import { ActivityStatusEnum, AppointmentConfirmationStatusEnum, AppointmentItem, AppointmentList } from "./appointmentList";
import { WebApiRequestBase, WebApiResponseBase } from "../../src/shared/webApi";
import { PriceTypeEnum, Support } from "../../src/support";
import { PaymentData, PaymentDataParameters } from "./paymentData";
import { VrManager } from "../../src/shared/vrManager";
import { Menu } from "../../src/shared/menu";
import { ActivityList } from "../../src/booking/activityList";

declare var leafletImage: any;

export class AppointmentDetail
{
	private static _container: HTMLElement;
	private static _appointment: AppointmentItem;
	private static _parameters: AppointmentDetailParameter;
	private static _base64Map: string;

	static initialize(parameters: AppointmentDetailParameter)
	{
		this._parameters = parameters;
		this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
		Rotator.title("DETTAGLIO");

		if (this._parameters == null)
		{
			window.setTimeout(() => Rotator.page(PageEnum.AppointmentList));
			return;
		}

		if (this._parameters != null)
		{
			if (this._parameters.appointment != null)
				this.createStructure(this._parameters.appointment);
			else
				AppointmentList.getAppointments((e) => this.createStructure(e.appointments.first()), this._parameters.appointmentId);

			if (this._parameters.showMenuBurger === true)
			{
				window.setTimeout(() =>
				{
					Rotator.backButton().hide();
					Rotator.burgerButton().show();
					Menu.highlightItem(PageEnum.AppointmentList);
				}, 500);
			}
		}
	}

	private static createStructure(appointment: AppointmentItem)
	{
		this.drawAppointmentDetail(appointment, this._container);
		this.createButtons();
	}

	static drawAppointmentDetail(appointment: AppointmentItem, container: HTMLElement): HTMLElement
	{
		this._appointment = appointment;
		let divAppointment = vr.div(container, { class: "vrPatientDivSlot" });

		//#region Header
		let divHeader = vr.div(divAppointment, { css: "padding: 10px; margin-bottom: 10px; border-radius: 10px; box-shadow: 0 1px 3px rgba(0,0,0,.3);" });

		let statusBackgroundColor = "";
		let statusColor = "";
		switch (appointment.statusEnum)
		{
			case ActivityStatusEnum.Aborted:
				{
					statusColor = "#868686";
					statusBackgroundColor = "#F0F0F0";
				}
				break;
			case ActivityStatusEnum.Quote:
				{
					statusColor = "coral";
					statusBackgroundColor = "#ffff94";
				}
				break;
			case ActivityStatusEnum.Contract:
			case ActivityStatusEnum.Terminated:
				{
					statusColor = "green";
					statusBackgroundColor = "#c6f7c6";
				}
				break;
		}
		divHeader.style.cssText += "background-color: " + statusBackgroundColor + ";";

		vr.createLabel({
			text: "<i class='" + vr.IconClassicLight.Circle + "'></i> " + ((appointment.statusEnum == ActivityStatusEnum.Aborted) ? "Disdetto" : ((appointment.status != null) ? appointment.status : "")),
			colorSettings: { textColor: statusColor },
			bold: true,
			width: "100%"
		}, divHeader);

		let from = new Date(appointment.from);
		let to = new Date(appointment.to);
		let time = from.toTimeString();
		if (Rotator.configuration().appShowAppointmentDuration)
			time += " - " + to.toTimeString();

		vr.createLabel({
			text: from.toItalyLongString() + " <span style='float: right;'>" + time + "</span>",
			width: "100%",
		}, divHeader);
		//#endregion

		let divSummary = vr.div(divAppointment);

		if (appointment.activityTypeItem == null || !appointment.activityTypeItem.onlineBookingDoNotChooseDoctor)
		{
			let divResourceInfo = vr.div(divSummary, { css: "display: flex;" });

			//#region Image
			let resource = appointment.doctor;
			if (resource.imageBase64 != null && resource.imageBase64 != "")
			{
				vr.createImage(
					{
						value: resource.imageBase64,
						base64: true,
						width: 70,
						height: 70,
						cssContainer: "justify-content: center; margin-right: 10px;",
						css: "border-radius: 35px;"
					}, divResourceInfo);
			}
			else
				vr.icon(vr.IconClassicLight.UserDoctor, divResourceInfo, { css: "justify-content: center; margin-right: 10px; font-size: 5em; color: " + Rotator.color() + ";" });
			//#endregion

			//#region Info
			let divInfo = vr.div(divResourceInfo, { css: "display: inline-block; width: Calc(100% - 75px); min-height: 70px; text-align: left;" });

			let title = (resource.title != null && resource.title != "") ? resource.title : ((resource.genderEnum == GenderEnum.Female) ? "Dott.ssa" : "Dott.");
			vr.createLabel(
				{
					text: "<span style='font-size: 0.8em; font-weight: normal;'>" + title + "</span> " + resource.name,
					width: "100%",
					css: "font-weight: 600;",
					noBr: true
				}, divInfo);

			if (resource.qualification != null && resource.qualification.length > 0)
			{
				vr.createLabel(
					{
						text: resource.qualification,
						width: "100%",
						css: "font-size: 1em;"
					}, divInfo);
			}

			if (resource.rating != null && resource.rating.values.length > 0)
			{
				vr.createRating(
					{
						cssContainer: "margin-top: 7px;",
						max: resource.rating.total,
						value: resource.rating.values.sum() / resource.rating.values.length,
						total: resource.rating.values.length,
						enable: false
					}, divInfo);
			}
			//#endregion
		}

		//#region ActivityType
		let divActivityType = vr.div(divSummary, { css: "margin-top: 10px;" });
		vr.icon(vr.IconClassicLight.FileLines, divActivityType, { css: "margin-right: 5px; color: " + Rotator.color() });

		let displayPrice = Support.displayPrice(appointment.price, PriceTypeEnum.Appointment, true);
		vr.createLabel(
			{
				text: appointment.activityTypeName + displayPrice,
				width: "Calc(100% - 20px)"
			}, divActivityType);
		//#endregion

		//#region Workplace
		let divWorkplace = vr.div(divSummary, { css: "margin-top: 5px;" });
		vr.icon(vr.IconClassicLight.MapPin, divWorkplace, { css: "margin-right: 5px; color: " + Rotator.color() });
		let address = Support.getAddressName(appointment.workplace);
		vr.createLabel(
			{
				text: address + "<br /><span style='font-size: 0.9em; color: #737373'>" + appointment.workplace.name + "</span>",
				width: "Calc(100% - 20px)"
			}, divWorkplace);

		let map = vr.createMap(
			{
				latitude: appointment.workplace.latitude,
				longitude: appointment.workplace.longitude,
				cssContainer: "margin-top: 15px;",
				height: 120,
				enable: false,
				mode: vr.MapModeEnum.Streets,
				onClick: (e) => 
				{
					vrshared.UtilityManager().openUrl("https://www.google.com/maps/search/?api=1&query=" + appointment.workplace.latitude + "," + appointment.workplace.longitude, true);
				}
			}, divAppointment);

		leafletImage(map.map(), (err: any, canvas: any) =>
		{
			this._base64Map = canvas.toDataURL("image/jpeg").replace("data:image/jpeg;base64,", "");
		});
		//#endregion

		return divAppointment;
	}

	private static createButtons()
	{
		if (this._appointment.statusEnum != ActivityStatusEnum.Terminated && new Date(this._appointment.from).getTime() >= new Date().getTime())
		{
			if (Rotator.version() >= 19)
			{
				let request = new GetConfirmationToggleButtonVisibilityRequest();
				request.appointmentId = this._appointment.appointmentId;
				request.call(
					{
						success: (response: GetConfirmationToggleButtonVisibilityResponse) =>
						{
							if (response.showConfirm)
							{
								vr.createButton(
									{
										text: "Conferma appuntamento",
										colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
										css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
										width: "100%",
										confirmationMessage: "Vuoi confermare questo appuntamento?",
										class: "vrButtonCancelAppointment vrButtonPatient",
										onClick: (e) =>
										{
											this.customerConfirmationToggleAppointment(AppointmentConfirmationStatusEnum.ConfirmedByCustomer, () =>
											{
												VrManager.notify("Appuntamento confermato con successo!");
												Rotator.back();
											});
										}
									}, this._container);
							}
							else if (response.showAbort && Rotator.configuration().appCancelAppointment)
							{
								vr.createButton(
									{
										text: "Disdici appuntamento",
										colorSettings: { textColor: Rotator.color() },
										css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
										width: "100%",
										class: "vrButtonCancelAppointment vrButtonPatient",
										onClick: (e) =>
										{
											Support.confirm("Confermi di voler disdire questo appuntamento?", () =>
											{
												this.customerConfirmationToggleAppointment(AppointmentConfirmationStatusEnum.RejectedByCustomer, () =>
												{
													VrManager.notify("Appuntamento disdetto con successo!");
													Rotator.back();
												});
											});
										}
									}, this._container);
							}
						},
						mode: vrshared.WebApiModeEnum.Sync
					});
			}
			else // version 16
			{
				if (Rotator.configuration().appCancelAppointment && this._appointment.statusEnum != ActivityStatusEnum.Aborted)
				{
					vr.createButton(
						{
							text: "Disdici appuntamento",
							colorSettings: { textColor: Rotator.color() },
							css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
							width: "100%",
							class: "vrButtonCancelAppointment vrButtonPatient",
							onClick: (e) =>
							{
								Support.confirm("Confermi di voler disdire questo appuntamento?", () =>
								{
									let request = new DeleteAppointmentRequest();
									request.appointmentId = this._appointment.appointmentId;
									request.call(
										{
											success: (response: DeleteAppointmentResponse) => 
											{
												VrManager.notify("Appuntamento disdetto con successo!");
												Rotator.back();
											},
											mode: vrshared.WebApiModeEnum.Async,
											loader: Rotator.container()
										});
								});
							}
						}, this._container);
				}
			}

			if (this._appointment.statusEnum != ActivityStatusEnum.Aborted)
			{
				if (this._appointment.billingStatus != null)
				{
					if (this._appointment.billingStatus.billableOnline && (this._appointment.billingStatus.invoiceId == null || this._appointment.billingStatus.invoiceId == 0))
					{
						vr.createButton(
							{
								text: "Paga",
								colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
								css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
								cssContainer: "margin-top: 10px;",
								width: "100%",
								class: "vrButtonPayAppointment vrButtonPatient",
								onClick: (e) =>
								{
									let parameters = new PaymentDataParameters();
									parameters.appointmentId = this._appointment.appointmentId;
									parameters.price = this._appointment.billingStatus.toBill;
									parameters.itemName = this._appointment.activityTypeName;
									parameters.callbackAfterPayment = (e) =>
									{
										PaymentData.createInvoice(e.appointmentId, (response) =>
										{
											if (response.invoiceId > 0)
												VrManager.notify("Fattura creata con successo!");
											else
												VrManager.notify("Pagamento effettuato con successo!");

											Rotator.page(PageEnum.DocumentList);
										});
									}
									Rotator.page(PageEnum.PaymentData, parameters);
								}
							}, this._container);
					}
					else if (this._appointment.billingStatus.invoiceId != null && this._appointment.billingStatus.invoiceId != 0)
					{
						vr.createButton({
							text: "Scarica documento",
							colorSettings: { textColor: Rotator.color() },
							css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
							cssContainer: "width: 100%;",
							onClick: (e) => Support.downloadDocument(this._appointment.billingStatus.invoiceId!, "fattura.pdf")
						}, this._container);
					}
				}
			}
		}

		for (let report of this._appointment.reports)
		{
			if (report.date == null)
				continue;

			vr.createButton(
				{
					text: "Vedi referto (" + new Date(report.date).toItalyShortString() + ")",
					css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
					cssContainer: "margin-top: 10px",
					width: "100%",
					class: "vrButtonShowReport vrButtonPatient",
					colorSettings:
					{
						textColor: Rotator.textColor(),
						background: Rotator.color()
					},
					onClick: (e) =>
					{
						Support.downloadFile(report.storedFileId, "referto_" + new Date(report.date).toItalyShortString() + ".pdf", FileTypeEnum.Pdf);
					}
				}, this._container);
		}
	}

	static customerConfirmationToggleAppointment(status: AppointmentConfirmationStatusEnum, successCallback?: Function)
	{
		let request = new CustomerConfirmationToggleAppointmentRequest();
		request.appointmentId = this._appointment.appointmentId;
		request.customerConfirmationStatusEnum = status;
		request.call(
			{
				success: (response: CustomerConfirmationToggleAppointmentResponse) =>
				{
					if (successCallback != null)
						successCallback();
				},
				mode: vrshared.WebApiModeEnum.Async,
				loader: Rotator.container()
			});
	}

	static printReport(appointmentId: number, base64Map: string)
	{
		AppointmentList.getAppointments((e) => 
		{
			let request = new PrintAppointmentRequest();
			request.appointment = e.appointments.first();
			request.base64Logo = Rotator.configuration().appLogo;
			request.base64Map = base64Map;
			request.call(
				{
					success: (response: PrintAppointmentResponse) =>
					{
						vrshared.UtilityManager().downloadFile(response.base64Data, response.fileName, FileTypeEnum.Pdf);
					},
					mode: vrshared.WebApiModeEnum.Async,
					loader: Rotator.container()
				});
		}, appointmentId);
	}
}

//#region Classes
export class AppointmentDetailParameter
{
	public appointment: AppointmentItem;
	public appointmentId: number;
	public showMenuBurger: boolean;
}

class DeleteAppointmentRequest extends WebApiRequestBase 
{
	public method: string = "/api/PrivateAreaWebApi/DeleteAppointment";
	public appointmentId: number;
}

class DeleteAppointmentResponse extends WebApiResponseBase 
{

}

class DownloadBookingConfirmationReportRequest extends WebApiRequestBase
{
	public method: string = "/api/PrivateAreaWebApi/DownloadBookingReport";
	public appointmentId: number;
}

class DownloadBookingConfirmationReportResponse extends WebApiResponseBase
{
	public fileName: string;
	public pdfDataBase64: string;
}

class CustomerConfirmationToggleAppointmentRequest extends WebApiRequestBase
{
	public method: string = "/api/PrivateAreaWebApi/CustomerConfirmationToggleAppointment";

	public appointmentId: number;
	public customerConfirmationStatusEnum: AppointmentConfirmationStatusEnum;
}

class CustomerConfirmationToggleAppointmentResponse extends WebApiResponseBase
{

}

class PrintAppointmentRequest extends WebApiRequestBase
{
	public method: string = "/api/PrivateAreaWebApi/PrintAppointment";

	public appointment: AppointmentItem;
	public base64Logo: string;
	public base64Map: string;
}

class PrintAppointmentResponse extends WebApiResponseBase
{
	public base64Data: string;
	public fileName: string;
}

class GetConfirmationToggleButtonVisibilityRequest extends WebApiRequestBase
{
	public method: string = "/api/PrivateAreaWebApi/GetConfirmationToggleButtonVisibility";

	public appointmentId: number;
}

class GetConfirmationToggleButtonVisibilityResponse extends WebApiResponseBase
{
	public showConfirm: boolean;
	public showAbort: boolean;
	public showUnknown: boolean;
}
//#endregion