import { Rotator, PageEnum, puma } from "../rotator";

export class InfoPage
{
    private static _container: HTMLElement;

    static initialize()
    {
        this._container = puma("<div style='height: 100%;'></div>").appendTo(Rotator.container())[0];

        let appName = Rotator.configuration().appName;
        Rotator.title((appName == null || appName == "") ? "INFO" : appName);

        this.createControls();
    }

    private static createControls()
    {
        let logo = Rotator.configuration().appLogo;
        if (logo != null && logo != "")
        {
            vr.createImage({
                base64: true,
                value: logo,
                width: "100%",
                position: vr.ImagePositionTypeEnum.Center,
                cssContainer: "max-height: 80px;",
                height: 80
            }, this._container);
        }

        //#region Contacts
        let email = Rotator.configuration().appEmail;
        let phoneNumber = Rotator.configuration().appPhoneNumber;
        let website = Rotator.configuration().appWebsite;

        if ((email != null && email != "") || (phoneNumber != null && phoneNumber != "") || (website != null && website != ""))
        {
            let divContactsContainer = vr.div(this._container, { class: "vrPatientDivSlot", css: "margin-top: 15px;" });

            vr.createLabel({
                text: "CONTATTI",
                width: "100%",
                css: "color: " + Rotator.color() + ";",
                cssContainer: "padding-bottom: 10px; border-bottom: solid 1px " + Rotator.color() + ";",
                align: vr.TextAlignEnum.Center,
                bold: true,
                noBr: true
            }, divContactsContainer);

            if (email != null && email != "")
            {
                vr.createLabel({
                    text: email,
                    width: "100%",
                    mode: vr.LabelModeEnum.Mail,
                    linkCss: "text-decoration: none; color: #000;",
                    cssContainer: "border-top: solid 1px #E6E6E6; padding: 10px; width: Calc(100% - 20px); padding-bottom: 15px;",
                    noBr: true
                }, divContactsContainer);
            }

            if (phoneNumber != null && phoneNumber != "")
            {
                vr.createLabel({
                    text: phoneNumber,
                    width: "100%",
                    mode: vr.LabelModeEnum.Phone,
                    linkCss: "text-decoration: none; color: #000;",
                    cssContainer: "border-top: solid 1px #E6E6E6; padding: 10px; width: Calc(100% - 20px); padding-bottom: 15px;",
                    noBr: true
                }, divContactsContainer);
            }

            if (website != null && website != "")
            {
                if (!website.startsWith("http"))
                    website = "https://" + website;

                vr.createLabel({
                    text: website,
                    width: "100%",
                    mode: vr.LabelModeEnum.Link,
                    linkCss: "text-decoration: none; color: #000;",
                    cssContainer: "border-top: solid 1px #E6E6E6; padding: 10px; width: Calc(100% - 20px); padding-bottom: 15px;",
                    noBr: true
                }, divContactsContainer);
            }
        }
        //#endregion

        //#region Workplaces
        let workplaces = Rotator.configuration().plant.workplaces;
        if (workplaces != null && workplaces.length > 0)
        {
            let divWorkplacesContainer = vr.div(this._container, { class: "vrPatientDivSlot", css: "margin-top: 15px;" });

            vr.createLabel({
                text: "SEDI",
                width: "100%",
                css: "color: " + Rotator.color() + ";",
                cssContainer: "padding-bottom: 10px; border-bottom: solid 1px " + Rotator.color() + ";",
                align: vr.TextAlignEnum.Center,
                bold: true
            }, divWorkplacesContainer);

            let divWorkplaces = vr.div(divWorkplacesContainer, { css: "max-height: " + (Rotator.container().clientHeight - divWorkplacesContainer.offsetTop - 60) + "px; overflow-y: auto;" });
            for (let workplace of workplaces)
            {
                if (workplace == null || workplace.street == null)
                    continue;

                let textWorkplace = workplace.street + " - " + workplace.zip + ", " + workplace.city + " (" + workplace.province + ")";
                if (workplace.geoCoordinate != null)
                    textWorkplace = "<a style='text-decoration: none; color: #000;' href='https://maps.google.com/?q=" + workplace.geoCoordinate.latitude + "," + workplace.geoCoordinate.longitude + "'>" + textWorkplace + "</a>";

                vr.createLabel({
                    text: textWorkplace,
                    width: "100%",
                    cssContainer: "border-top: solid 1px #E6E6E6; padding: 10px; width: Calc(100% - 20px); padding-bottom: 15px;"
                }, divWorkplaces);
            }
        }
        //#endregion

        let divCredits = vr.div(this._container, { css: "position: absolute; bottom: 0px; padding: 10px; padding-top: 5px; padding-bottom: 5px; width: Calc(100% - 20px); background-color: #FFF; border-top: solid 1px " + Rotator.color() + "; color: " + Rotator.color() + "; text-align: right;" });
        vr.createLabel({
            text: "© Vettore.Medical",
            css: "font-size: 1em;",
            colorSettings: { textColor: Rotator.color() }
        }, divCredits);
    }
}