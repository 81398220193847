import { Rotator, puma, PageEnum } from "../../src/rotator";
import { Confirmation1 } from "./confirmation1";
import { FileTypeEnum, OnlineBookingLoadingFileMode, OnlineBookingPaymentModeEnum, WebApiDateTime } from "../../src/shared/common";
import { WebApiRequestBase, WebApiResponseBase } from "../../src/shared/webApi";
import { EventTypeEnum, Support } from "../../src/support";
import { PaymentData, PaymentDataParameters } from "../../src/privateArea/paymentData";
import { PaymentResultParameters } from "./paymentResult";
import { AppointmentDetailParameter } from "../../src/privateArea/appointmentDetail";
import { Profile } from "../../src/privateArea/profile";
import { VrManager } from "../../src/shared/vrManager";

export class Confirmation2
{
    private static _container: HTMLElement;
    private static _btnPay: vr.Button;
    private static _btnBook: vr.Button;
    private static _appointmentId: number;
    private static _divPayBook: HTMLElement;
    private static _divConfirmations: HTMLElement;
    private static _divInvoice: HTMLElement;
    private static _txtNotes: vr.TextBox;

    static initialize()
    {
        this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
        Rotator.title("RIEPILOGO");

        let activityType = Rotator.bookingParameters().activityType;
        if (Rotator.bookingParameters().filesLoaded == null && activityType.onlineBookingLoadingFileModeId == OnlineBookingLoadingFileMode.RequireLoading)
            Rotator.page(PageEnum.FileLoading);
        else
            this.createControls();
    }

    static createControls()
    {
        Confirmation1.drawSummary(vr.div(this._container, { class: "vrPatientDivSlot" }));

        if (Rotator.bookingParameters().activityType.preparationInfo != null && Rotator.bookingParameters().activityType.preparationInfo != "")
        {
            let divPreparationInfo = vr.div(this._container, { class: "vrPatientDivSlot" });
            vr.createLabel(
                {
                    text: "Note informative",
                    width: "100%",
                    colorSettings: { textColor: Rotator.color() }
                }, divPreparationInfo);

            vr.createLabel(
                {
                    text: Rotator.bookingParameters().activityType.preparationInfo,
                    width: "100%"
                }, divPreparationInfo);
        }

        //#region Check minor age
        let stopBooking = false;
        if (Rotator.configuration().appNoBookingMinor === true)
        {
            Profile.getSubject(null, (response) =>
            {
                if (response.patientAge < 18 && response.patientAge > 0)
                {
                    let divNoBookingMinor = vr.div(this._container, { class: "vrPatientDivSlot" });
                    vr.createLabel({
                        label: "Attenzione!",
                        text: "Prenotazione appuntamenti permessa unicamente ai maggiorenni",
                        width: "100%",
                        labelSettings: { colorSettings: { textColor: Rotator.color() } }
                    }, divNoBookingMinor);

                    vr.createButton(
                        {
                            text: "Annulla prenotazione",
                            colorSettings: { textColor: Rotator.color() },
                            css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
                            cssContainer: "margin-top: 10px;",
                            width: "100%",
                            class: "vrButtonBookingCancelAppointment vrButtonPatient",
                            onClick: (e) => 
                            {
                                Rotator.confirmationParameters(null);
                                Rotator.goToMainPage();
                            }
                        }, divNoBookingMinor);

                    stopBooking = true;
                    return;
                }
            }, undefined, false);
        }

        if (stopBooking)
            return;
        //#endregion

        let divNotes = vr.div(this._container, { class: "vrPatientDivSlot" });
        this._txtNotes = vr.createTextBox({
            placeholder: (Rotator.configuration().appPlaceholderPatientNotes != "" && Rotator.configuration().appPlaceholderPatientNotes != null) ? Rotator.configuration().appPlaceholderPatientNotes : "Note per la segreteria del centro",
            mode: vr.TextModeEnum.MultiLine,
            rows: (Rotator.isMobile()) ? 4 : 3,
            css: "font-size: 1.2em;",
            width: "100%"
        }, divNotes);

        //#region Pay/Book
        this._divPayBook = vr.div(this._container);

        this._btnPay = vr.createButton(
            {
                text: "Prenota e Paga",
                colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
                css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
                width: "100%",
                class: "vrButtonBookingPayAppointment vrButtonPatient",
                onClick: (e) => 
                {
                    let parameters = new PaymentDataParameters();
                    parameters.price = Rotator.confirmationParameters()!.price;
                    parameters.itemName = Rotator.bookingParameters().activityType.onlineBookingName;
                    parameters.callbackAfterPayment = (e) =>
                    {
                        PaymentData.createInvoice(e.appointmentId, (response) =>
                        {
                            let paymentResultParameters = new PaymentResultParameters();
                            paymentResultParameters.appointmentId = e.appointmentId;
                            paymentResultParameters.documentId = response.invoiceId;
                            paymentResultParameters.cashAdvanceInfo = response.cashAdvanceInfo;
                            paymentResultParameters.documentName = response.pdfDocumentName;
                            paymentResultParameters.documentBase64 = response.pdfDocument;
                            Rotator.page(PageEnum.PaymentResult, paymentResultParameters);
                        })
                    }
                    Rotator.page(PageEnum.PaymentData, parameters);
                }
            }, this._divPayBook);

        this._btnBook = vr.createButton(
            {
                text: "Prenota",
                colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
                css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
                cssContainer: "margin-top: 5px;",
                class: "vrButtonBookingAppointment vrButtonPatient",
                width: "100%",
                onClick: (e) => this.createAppointment((e) =>
                {
                    this._appointmentId = e.appointmentId;
                    this.manageAppointmentCreation();
                })
            }, this._divPayBook);

        switch (Rotator.bookingParameters().activityType.paymentMode)
        {
            case OnlineBookingPaymentModeEnum.None:
                {
                    this._btnPay.hide();
                    this._btnBook.show();
                }
                break;
            case OnlineBookingPaymentModeEnum.Optional:
                {
                    this._btnPay.show();
                    this._btnBook.show();
                }
                break;
            case OnlineBookingPaymentModeEnum.Required:
                {
                    this._btnPay.show();
                    this._btnBook.hide();
                }
                break;
        }

        if (Rotator.confirmationParameters()!.price <= 0)
            this._btnPay.hide();

        let paypalClientId = (Rotator.configuration().appPaypalSandboxMode) ? Rotator.configuration().appPaypalClientIdSandbox : Rotator.configuration().appPaypalClientId;
        let paypalClientSecret = (Rotator.configuration().appPaypalSandboxMode) ? Rotator.configuration().appPaypalClientSecretSandbox : Rotator.configuration().appPaypalClientSecret;
        if (paypalClientId == null || paypalClientId == "" || paypalClientSecret == null || paypalClientSecret == "")
            this._btnPay.hide();

        Support.checkLogin(() => { }, () => this._btnPay.hide());

        let activityType = Rotator.bookingParameters().activityType;
        if (activityType.onlineBookingLoadingFileModeId == OnlineBookingLoadingFileMode.YesLoading
            || activityType.onlineBookingLoadingFileModeId == OnlineBookingLoadingFileMode.RequireLoading)
        {
            vr.createButton(
                {
                    text: "Carica file",
                    colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
                    css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
                    cssContainer: "margin-top: 5px;",
                    class: "vrButtonBookingAppointment vrButtonPatient",
                    width: "100%",
                    onClick: (e) => 
                    {
                        Rotator.page(PageEnum.FileLoading);
                    }
                }, this._divPayBook);
        }

        vr.createButton(
            {
                text: "Annulla prenotazione",
                colorSettings: { textColor: Rotator.color() },
                css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
                cssContainer: "margin-top: 10px;",
                width: "100%",
                class: "vrButtonBookingCancelAppointment vrButtonPatient",
                onClick: (e) => 
                {
                    Rotator.confirmationParameters(null);
                    Rotator.goToMainPage();
                }
            }, this._divPayBook);
        //#endregion

        //#region Confirmation buttons
        this._divConfirmations = vr.div(this._container, { css: "display: none;" });

        vr.createLabel({
            text: "Prenotazione effettuata con successo!",
            cssContainer: "margin-top: 10px; margin-bottom: 10px;",
            align: vr.TextAlignEnum.Center,
            fontSize: "1.5em",
            width: "100%"
        }, this._divConfirmations);

        let btnViewAppointment = vr.createButton(
            {
                text: "Consulta appuntamento",
                colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
                css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
                cssContainer: "margin-top: 10px;",
                width: "100%",
                visible: false,
                class: "vrButtonBookingViewAppointment vrButtonPatient",
                onClick: (e) => 
                {
                    let parameters = new AppointmentDetailParameter();
                    parameters.appointmentId = this._appointmentId;
                    parameters.showMenuBurger = true;
                    Rotator.page(PageEnum.AppointmentDetail, parameters);
                }
            }, this._divConfirmations);

        Support.checkLogin(() => 
        {
            btnViewAppointment.show();
        });

        let btnBookAnother = vr.createButton(
            {
                text: "Prenota un altro",
                colorSettings: { textColor: Rotator.color() },
                css: "box-shadow: 0 1px 3px " + Rotator.color() + "; width: 100%;",
                cssContainer: "margin-top: 10px;",
                width: "100%",
                class: "vrButtonBookingBookAnother vrButtonPatient",
                onClick: (e) => Rotator.goToMainPage()
            }, this._divConfirmations);

        if (Rotator.doctorMode())
            btnBookAnother.hide();
        //#endregion
    }

    private static manageAppointmentCreation(showInvoice = false)
    {
        puma(this._divConfirmations).show();
        puma(this._divPayBook).hide();
        this._txtNotes.disable();

        if (showInvoice)
            puma(this._divInvoice).show();

        window.scrollTo(0, document.body.scrollHeight);

        Rotator.burgerButton().show();
        Rotator.backButton().hide();
        Rotator.confirmationParameters(null);
    }

    static createAppointment(callback?: (e: CreateAppointmentResponse) => void, errorCallback?: (e: vrshared.WebApiError) => void, async = true)
    {
        let request = new CreateAppointmentRequest();
        request.activityTypeId = Rotator.bookingParameters().activityType.id;
        request.price = Rotator.confirmationParameters()!.price;

        let resource = Rotator.confirmationParameters()!.resource;
        if (resource != null)
            request.resourceId = resource.id;

        request.resourceInstrumentId = Rotator.confirmationParameters()!.resourceInstrumentId;
        request.resourceSpaceId = Rotator.confirmationParameters()!.resourceSpaceId;
        request.workplaceId = Rotator.confirmationParameters()!.workplace.id;
        request.createInvoice = false;
        request.note = this._txtNotes.value();

        let from = new Date(Rotator.confirmationParameters()!.timeslot.day);
        from.setHours(Rotator.confirmationParameters()!.timeslot.hourFrom.hours, Rotator.confirmationParameters()!.timeslot.hourFrom.minutes);
        request.webApiFrom = Date.toWebApiDateTime(from);

        if (Rotator.confirmationParameters()!.subjectId != null)
            request.subjectId = Rotator.confirmationParameters()!.subjectId;

        if (Rotator.patientId() != null)
            request.subjectId = Rotator.patientId();

        //#region Marketing UTM
        request.marketingUTMData = new MarketingUTMData();
        request.marketingUTMData.campaignSourceId = Rotator.campaignSourceId();
        request.marketingUTMData.campaignMediumId = Rotator.campaignMediumId();
        request.marketingUTMData.campaignName = Rotator.campaignName();
        //#endregion

        request.createFollowUp = Rotator.webComponentParameters()[0].createFollowUp;

        if (Rotator.bookingParameters().filesLoaded != null)
        {
            vr.filesToBase64(Rotator.bookingParameters().filesLoaded!).then((base64List) =>
            {
                let i = 0;
                request.filesLoaded = [];
                for (let file of Rotator.bookingParameters().filesLoaded!)
                {
                    let fileLoaded = new FileLoaded();
                    fileLoaded.base64 = base64List[i];
                    fileLoaded.fileName = file.name;
                    fileLoaded.mimeType = file.type;
                    request.filesLoaded.push(fileLoaded);
                    i++;
                }

                VrManager.notify("Attendere caricamento file in corso prima della prenotazione");
                this.createAppointmentWebApi(request, callback, errorCallback, async);
            });
        }
        else
            this.createAppointmentWebApi(request, callback, errorCallback, async);
    }

    private static createAppointmentWebApi(request: CreateAppointmentRequest, callback?: (e: CreateAppointmentResponse) => void,
        errorCallback?: (e: vrshared.WebApiError) => void, async = true)
    {
        request.call(
            {
                success: (response: CreateAppointmentResponse) =>
                {
                    Support.manageTrackingCookiesEvent(EventTypeEnum.Booked, Rotator.bookingParameters().activityType.id, { amount: Rotator.confirmationParameters()!.price });

                    if (callback != null)
                        callback(response);
                },
                error: (e) =>
                {
                    if (errorCallback != null)
                        errorCallback(e);
                },
                mode: (async) ? vrshared.WebApiModeEnum.Async : vrshared.WebApiModeEnum.Sync,
                loader: Rotator.container()
            });
    }

    static print(appointmentId: number)
    {
        let request = new DownloadBookingConfirmationReportRequest();
        request.appointmentId = Number(appointmentId);
        request.call(
            {
                success: (response: DownloadBookingConfirmationReportResponse) =>
                {
                    vrshared.UtilityManager().downloadFile(response.pdfDataBase64, response.fileName, FileTypeEnum.Pdf);
                },
                mode: vrshared.WebApiModeEnum.Async,
                loader: Rotator.container()
            });
    }
}

//#region Classes
class CreateAppointmentRequest extends WebApiRequestBase
{
    public method: string = "/api/BookingWebApi/CreateAppointment";

    subjectId?: number | null;
    from: Date;
    activityTypeId: number;
    price: number;
    resourceId: number;
    note: string;
    workplaceId: number;
    createInvoice: boolean;
    webApiFrom: WebApiDateTime;
    resourceInstrumentId: number;
    resourceSpaceId: number;
    marketingUTMData: MarketingUTMData;
    createFollowUp: boolean;
    filesLoaded: FileLoaded[];
}

export class FileLoaded
{
    base64: string;
    fileName: string;
    mimeType: string;
}

class MarketingUTMData
{
    public campaignSourceId: number;
    public campaignMediumId: number;
    public campaignName: string;
}

class CreateAppointmentResponse extends WebApiResponseBase
{
    public appointmentId: number;
    public documentId: number;
    public needsToAddStampRow: boolean;
}

class DownloadBookingConfirmationReportRequest extends WebApiRequestBase
{
    public method: string = "/api/PrivateAreaWebApi/DownloadBookingReport";
    public appointmentId: number;
}

class DownloadBookingConfirmationReportResponse extends WebApiResponseBase
{
    public fileName: string;
    public pdfDataBase64: string;
}

export class Confirmation2Parameters
{
    public subjectId?: number;
}
//#endregion