export class ActivityTypeGroupResult
{
    public value: number;
    public text: string;
    public plantId: number;
    public activityTypesInThisGroup: string[];
    public isOnlineBooking: boolean;
    public children: ActivityTypeItem[];
}

export class WorkplaceResponse
{
    public id: number;
    public code: string;
    public name: string;
    public plantId: number;
    public latitude: number;
    public longitude: number;
    public street: string;
    public city: string;
    public province: string;
    public zip: string;
    public tagObject: any;
}

export class ResourceResponse
{
    public id: number;
    public code: string;
    public name: string;
    public minutesDuration: number;
    public plantIdList: number[];
    public title: string;
    public qualification: string;
    public genderEnum: GenderEnum;
    public isTechnician: boolean;
    public onlyBookableOnline?: boolean;
    public imageBase64?: string;
    public rating: ResourceRating;
}

export class ResourceRating
{
    public total: number;
    public values: number[];
}

export class ActivityTypeResponse
{
    public id: number;
    public code: string;
    public name: string;
    public plantId: number;
    public workplacesInThisActivity: WorkplaceResponse[];
    public activityNote: string;
    public paymentMode: OnlineBookingPaymentModeEnum;
    public doNotRequireLogin: boolean;
    public preparationInfo: string;
    public onlineBookingModeEnum: OnlineBookingModeEnum;
    public onlineBookingDoNotChooseDoctor: boolean;
    public onlineBookingShowOnlyFromDirectLink: boolean;
    public onlineBookingName: string;
    public useWaitingListIfNoAvailabilities: boolean;
    public onlineBookingLoadingFileModeId: OnlineBookingLoadingFileMode;
    public onlineBookingLoadingFileMessage: string;

    public get isOnlineBooking()
    {
        return (this.onlineBookingModeEnum == OnlineBookingModeEnum.ShowAlways || this.onlineBookingModeEnum == OnlineBookingModeEnum.ShowOnlyIfAvailable);
    }
}

export enum OnlineBookingModeEnum
{
    Disabled = 1,
    ShowOnlyIfAvailable = 2,
    ShowAlways = 3,
    ShowAlwaysButNotBookable = 4
}

export enum OnlineBookingLoadingFileMode
{
    NoLoading,
    YesLoading,
    RequireLoading
}

export class ActivityTypeItem
{
    public id: number;
    public name: string;
    public plantId: number;
    public minutesDuration?: number;
    public workplacesInThisActivity: WorkplaceResponse[];
    public paymentMode: OnlineBookingPaymentModeEnum;
    public onlineBookingDoNotChooseDoctor: boolean;
    public onlineBookingShowOnlyFromDirectLink: boolean;
    public useWaitingListIfNoAvailabilities: boolean;
}

export enum OnlineBookingPaymentModeEnum
{
    None = 1,
    Optional = 2,
    Required = 3
}

export class Timeslot
{
    public day: string;
    public hourFrom: any;
    public hourTo: any;
    public price: number;
    public workplaceId: number;
    public workplaceName: string;
    public resourceSubjectId: number;
    public resourceInstrumentId: number;
    public resourceSpaceId: number;
}

export class AvailabilityRequestMultiplant
{
    public workplaceIdList: number[] | null;
    public resourceId?: number | null;
    public activityTypeId: number;
    public resourceInstrumentId: number;
    public resourceSpaceId: number;
    getNext: boolean;
}

export class AvailabilityResponse
{
    public id: number;
    public workplaceId: number;
    public resourceId: number;
    public activityTypeId: number;
    public price: number;
    public timeslots: Timeslot[];
    public nextTimeslots: Timeslot[];
    public resource: ResourceResponse;
}

export enum FileTypeEnum
{
    Pdf = "application/pdf",
    Txt = "text/plain",
    Image = "image/png",
    Zip = "application/octet-stream",
    General = "application/octet-stream"
}

export enum GenderEnum
{
    Male = 10,
    Female = 20,
    NotSpecified = 30
}

export class ReportItem
{
    public doctorName: string;
    public activityTypeName: string;
    public date: Date;
    public storedFileId: number;
    public workplaceName: string;
    public plantCompanyName: string;
    public reportSendingId: number;
    public representerOfName: string;
}

export class SendingAttachmentsDisplay
{
    public id: number;
    public fileName: string;
    public uploadDate: Date;
    public storageRecordId: number;
    public mimeType: string;
}

export class DocumentItem
{
    public id: number;
    public date: Date;
    public paymentDate: Date;
    public number: string;
    public netPrice: number;
    public grossPrice: number;
    public expirationDate: Date;
    public expirationDateRows: DocumentExpirationInfo[];
    public plantCompanyName: string;
    public documentId: number;
}

class DocumentExpirationInfo
{
    public id: number;
    public documentId: number;
    public paymentCodeId: number;
    public paymentCodeName: string;
    public expirationDate: Date;
    public grossPrice: number;
    public paymentDate?: Date;
}

export class FileItem
{
    public id: number;
    public size: number;
    public fileName: string;
    public mimeType: string;
    public uploadDate?: Date;
    public uploadedByPatient: boolean;
    public fileType: StorageFileTypeBaseEnum;
    public plantCompanyName: string;
    public displaySize: string;
    public representerOfName: string;
}

export enum StorageFileTypeBaseEnum
{
    GenericFile = 1,
    ImageGallery = 2,
    SignaturePresenceFile = 3,
    ImageStartingImage = 4,
    Privacy = 5,
    MedicalReportAttachment = 6,
    FiscalDocument = 7,
    MedicalReport = 8,
    InformedConsent = 9,
    Evaluation = 10,
    Report = 11
}

export enum AddressTypeEnum
{
    Residence,
    Domicile
}

export class City
{
    public id: number;
    public istat: number;
    public fisco: string;
    public active: boolean;
    public name: string;
    public replacedByIstatCode?: number;
    public replacedByName: string;
    public zipCodes: Zip[];
    public latitude?: number;
    public longitude?: number;
    public population: number;
    public surface: number;
    public aslSpecificCode: string;
    public provinceAbbreviation: string;
}

export class Zip
{
    public zipCode: string;
}

export class BirthAddressInfo
{
    public countryCode: string;
    public istatCode: string;
    public address: string;
}

export class ItalianAddress
{
    public street: string;
    public zipCode: string;
    public istatCode: string;
    public countryCode: string;
}

export class ForeignAddress
{
    public address: string;
    public countryCode: string;
    public zIPCode: string;
}

export class ContactInfo
{
    public contactId: number;
    public value: string;
    public conactType: ContactTypeEnum;
}

export enum ContactTypeEnum
{
    HomePhone = 1,
    WorkPhone = 2,
    HomeFax = 3,
    WorkFax = 4,
    PrivateEmail = 5,
    WorkEmail = 6,
    PrivateMobile = 7,
    WorkMobile = 8,
    WebSite = 9,
    PEC = 10
}

export class WebApiDateTime
{
    public day: number;
    public month: number;
    public year: number;
    public hours: number;
    public minutes: number;
    public seconds: number;
    public milliSeconds: number;
}