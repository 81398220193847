import { WebApiRequestBase, WebApiResponseBase } from "../../src/shared/webApi";
import { Rotator, puma, PageEnum, SailorTypeEnum } from "../../src/rotator";
import { LoginParameters } from "./login";
import { BirthAddressInfo, City, ForeignAddress, ItalianAddress, WebApiDateTime } from "../../src/shared/common";
import { EventTypeEnum, Popup, Support } from "../../src/support";
import { Profile } from "../../src/privateArea/profile";
import { SailorSasnModeEnum as SailorSasnModeEnum } from "../../src/sailor/sailorRegister";
import { VrManager } from "../../src/shared/vrManager";

export class Register
{
    private static _container: HTMLElement;
    private static _txtName: vr.TextBox;
    private static _txtSurname: vr.TextBox;
    private static _txtFiscalCode: vr.TextBox;
    private static _txtLandline: vr.TextBox;
    private static _dtpBirthdate: vr.DatePicker;
    private static _cmbBirthCity: vr.ComboBox;
    private static _swtItalianForeignBirthCity: vr.Switch;
    private static _cmbForeignBirthCityCountry: vr.ComboBox;
    private static _txtForeignBirthCity: vr.TextBox;
    private static _btgGender: vr.ButtonGroup;
    private static _btnRegister: vr.Button;
    private static _parameters: RegisterParameters;
    private static _fields: MandatoryFieldInfo[];

    private static _swtItalianForeignResidenceAddress: vr.Switch;
    private static _txtItalyResidenceAddress: vr.TextBox;
    private static _cmbItalyResidenceCity: vr.ComboBox;
    private static _txtItalyResidenceProvince: vr.TextBox;
    private static _cmbItalyResidenceZip: vr.ComboBox;
    private static _txtOutItalyResidenceAddress: vr.TextBox;
    private static _cmbOutItalyResidenceCountry: vr.ComboBox;

    static initialize(parameters: RegisterParameters)
    {
        this._parameters = parameters;
        this._container = puma("<div class='forward padContainer'></div>").appendTo(Rotator.container())[0];
        Rotator.title("REGISTRATI");

        Support.checkLogin(() => Rotator.goToMainPage(), () => 
        {
            this.getMandatoryFields((e) =>
            {
                this._fields = e.fields;
                this.createControls();
            });
        });
    }

    static createControls()
    {
        puma(this._container).empty();

        //#region Info
        let divInfo = vr.div(this._container, { class: "vrPatientDivSlot" });
        this._txtName = vr.createTextBox(
            {
                label: "Nome",
                placeholder: "Nome",
                onEnterKey: () => this._btnRegister.click(),
                mode: vr.TextModeEnum.Text,
                width: "100%",
                cssContainer: "margin-bottom: 10px;",
                labelSettings: { colorSettings: { textColor: Rotator.color() } }
            }, divInfo);
        this._txtName.focus();

        this._txtSurname = vr.createTextBox(
            {
                label: "Cognome",
                placeholder: "Cognome",
                onEnterKey: () => this._btnRegister.click(),
                mode: vr.TextModeEnum.Text,
                width: "100%",
                cssContainer: "margin-bottom: 10px;",
                labelSettings: { colorSettings: { textColor: Rotator.color() } }
            }, divInfo);

        this._txtLandline = vr.createTextBox(
            {
                label: "Telefono fisso",
                placeholder: "Telefono fisso",
                mode: vr.TextModeEnum.Text,
                width: "100%",
                cssContainer: "margin-bottom: 10px;",
                labelSettings: { colorSettings: { textColor: Rotator.color() } }
            }, divInfo);
        this.manageFields(this._fields, OnlineBookingMandatoryFieldTypeEnum.Landline, this._txtLandline);

        this._btgGender = vr.createButtonGroup(
            {
                label: "Sesso",
                width: "100%",
                labelSettings: { colorSettings: { textColor: Rotator.color() } },
                cssContainer: "margin-bottom: 10px;",
                //selectedColor: Rotator.color(),
                items:
                    [
                        { text: "Maschio", value: "M" },
                        { text: "Femmina", value: "F" }
                    ],
                selectionMode: vr.SelectionModeEnum.Single
            }, divInfo);
        this.manageFields(this._fields, OnlineBookingMandatoryFieldTypeEnum.Gender, this._btgGender);
        //#endregion

        //#region BirthCity
        let divBirthInfo = vr.div(this._container, { class: "vrPatientDivSlot", css: "margin-top: 10px;" });

        this._dtpBirthdate = vr.createDatePicker(
            {
                label: "Data di nascita",
                width: "100%",
                labelSettings: { colorSettings: { textColor: Rotator.color() } },
                cssContainer: "margin-bottom: 10px;",
            }, divBirthInfo);
        this.manageFields(this._fields, OnlineBookingMandatoryFieldTypeEnum.BirthDate, this._dtpBirthdate);

        //#region Birthdate mobile
        if (Rotator.isMobile() && this._dtpBirthdate.visible())
        {
            let birthdatePopup = new Popup(this._container, { height: 365, width: 320 });
            let calBirthdate = vr.createCalendar(
                {
                    width: "100%",
                    selectedColor: Rotator.color(),
                    onAfterChange: (e) =>
                    {
                        this._dtpBirthdate.value(e.value, false);
                        birthdatePopup.close();
                    }
                }, birthdatePopup.element());
            puma(calBirthdate.element()).find(".vrDatePickerPopup_table")[0].style.cssText += "min-height: 285px;";

            puma(this._dtpBirthdate.container()).find(".vrIcon").hide();
            vr.createButton({
                icon: vr.IconClassicLight.Calendar,
                css: "border: none; background: none;",
                cssContainer: "position: absolute; right: 15px; margin-top: 17px;",
                onClick: (e) => birthdatePopup.open()
            }, divBirthInfo);
        }
        //#endregion

        let divBirthCity = vr.div(divBirthInfo);
        this.manageFields(this._fields, OnlineBookingMandatoryFieldTypeEnum.BirthLocation, null, divBirthCity);

        if (!this._dtpBirthdate.visible() && !puma(divBirthCity).is(":visible"))
            puma(divBirthInfo).hide();

        vr.createLabel({
            text: "Città di nascita",
            colorSettings: { textColor: Rotator.color() },
        }, divBirthCity);

        this._swtItalianForeignBirthCity = vr.createSwitch({
            labelOff: "Italia",
            labelOn: "Fuori Italia",
            width: "100%",
            cssContainer: "margin-bottom: 5px;",
            onChange: (e) => 
            {
                if (e.checked)
                {
                    puma(divItalyBirthCity).hide();
                    puma(divForeignBirthCity).show();
                }
                else
                {
                    puma(divItalyBirthCity).show();
                    puma(divForeignBirthCity).hide();
                }
            }
        }, divBirthCity);

        let divItalyBirthCity = vr.div(divBirthCity);
        this._cmbBirthCity = vr.createComboBox(
            {
                label: "Città di nascita",
                width: "100%",
                cssContainer: "margin-right: 10px;",
                webService: {
                    authKey: "h]K)x@)k2j.@{=7D",
                    method: Rotator.patientUrl() + "/api/UtilityWebApi/GetAllCities",
                    itemsPropertyName: "cities",
                    typedTextPropertyName: "searchText",
                    parameters: () =>
                    {
                        return {
                            onlyActive: false
                        }
                    }
                }
            }, divItalyBirthCity);

        let divForeignBirthCity = vr.div(divBirthCity, { css: "display: none;" });
        this._cmbForeignBirthCityCountry = vr.createComboBox(
            {
                label: "Nazione",
                width: "100%"
            }, divForeignBirthCity);

        this._txtForeignBirthCity = vr.createTextBox(
            {
                label: "Città",
                width: "100%",
                cssContainer: "margin-right: 10px;"
            }, divForeignBirthCity);
        //#endregion

        //#region Fiscal code
        let divFiscalCode = vr.div(this._container, { class: "vrPatientDivSlot" });
        this._txtFiscalCode = vr.createTextBox({
            label: "Codice fiscale",
            width: "100%",
            labelSettings: { colorSettings: { textColor: Rotator.color() } },
            inputMode: vr.TextTransformModeEnum.Uppercase,
            onBlur: (e) =>
            {
                Profile.checkFiscalCode(this._txtFiscalCode.value<string>(), this._cmbForeignBirthCityCountry.value<number>(), (e) =>
                {
                    if (!e.isValid)
                        this._txtFiscalCode.error();
                    else
                        this._txtFiscalCode.hideError();
                });
            }
        }, divFiscalCode);
        this.manageFields(this._fields, OnlineBookingMandatoryFieldTypeEnum.FiscalCode, this._txtFiscalCode);

        let btnCalcFiscalCode = vr.createButton({
            text: "Calcola codice",
            width: "100%",
            cssContainer: "margin-top: 10px;",
            colorSettings: { textColor: Rotator.color() },
            css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
            visible: false,
            onClick: (e) =>
            {
                let birthAddressInfo = null;
                if (!this._swtItalianForeignBirthCity.checked())
                {
                    //#region Italian
                    if (this._cmbBirthCity.value() != null && this._cmbBirthCity.value() != "")
                    {
                        birthAddressInfo = new BirthAddressInfo();
                        birthAddressInfo.istatCode = this._cmbBirthCity.value<string>()!;
                    }
                    //#endregion
                }
                else
                {
                    //#region Foreign
                    let countrySelected = this._cmbForeignBirthCityCountry.getSelectedItem<any>();
                    if (countrySelected != null)
                    {
                        birthAddressInfo = new BirthAddressInfo();
                        birthAddressInfo.countryCode = this._cmbForeignBirthCityCountry.value<string>()!;
                        birthAddressInfo.address = this._txtForeignBirthCity.value();
                    }
                    //#endregion
                }

                Profile.computeFiscalCode(this._txtName.value(), this._txtSurname.value(), this._btgGender.getSelectedValues()[0], this._dtpBirthdate.value()!, birthAddressInfo,
                    (e) =>
                    {
                        if (e.invalidFieldsMessage != null && e.invalidFieldsMessage != "")
                            VrManager.notify(e.invalidFieldsMessage, "", { type: vr.NotifierTypeEnum.Error });
                        else
                        {
                            this._txtFiscalCode.value(e.fiscalCode);
                            this._txtFiscalCode.hideError();
                        }
                    });
            }
        }, divFiscalCode);

        if (this._txtName.visible() && this._txtSurname.visible() && this._btgGender.visible() && this._dtpBirthdate.visible() && puma(divBirthCity).is(":visible"))
            btnCalcFiscalCode.show();
        //#endregion

        //#region Address
        let divResidence = vr.div(this._container, { class: "vrPatientDivSlot", css: "margin-top: 10px;" });
        this.manageFields(this._fields, OnlineBookingMandatoryFieldTypeEnum.ResidenceAddress, null, divResidence);

        vr.createLabel({
            text: "Indirizzo di residenza",
            colorSettings: { textColor: Rotator.color() },
        }, divResidence);

        this._swtItalianForeignResidenceAddress = vr.createSwitch({
            labelOff: "Italia",
            labelOn: "Fuori Italia",
            width: "100%",
            cssContainer: "margin-bottom: 5px;",
            onChange: (e) => 
            {
                if (e.checked)
                {
                    puma(divItalyResidence).hide();
                    puma(divOutItalyResidence).show();
                }
                else
                {
                    puma(divItalyResidence).show();
                    puma(divOutItalyResidence).hide();
                }
            }
        }, divResidence);

        let divItalyResidence = vr.div(divResidence);
        this._txtItalyResidenceAddress = vr.createTextBox(
            {
                label: "Via",
                width: "100%"
            }, divItalyResidence);

        this._cmbItalyResidenceCity = vr.createComboBox(
            {
                label: "Comune",
                width: "100%",
                cssContainer: "margin-right: 10px;",
                webService: {
                    authKey: "h]K)x@)k2j.@{=7D",
                    method: Rotator.patientUrl() + "/api/UtilityWebApi/GetAllCities",
                    itemsPropertyName: "cities",
                    typedTextPropertyName: "searchText",
                    parameters: () =>
                    {
                        return {
                            onlyActive: true
                        }
                    }
                },
                onAfterChange: (e) =>
                {
                    this.calcProvinceAndZipByCity(e.value, (e) =>
                    {
                        this._txtItalyResidenceProvince.clear();
                        this._cmbItalyResidenceZip.clearItems();

                        this._txtItalyResidenceProvince.value(e.city.provinceAbbreviation);
                        this._cmbItalyResidenceZip.items(e.city.zipCodes.map(k => { return { text: k.zipCode, value: k.zipCode } }));
                        this._cmbItalyResidenceZip.value(e.city.zipCodes.first().zipCode);
                    });
                }
            }, divItalyResidence);

        this._txtItalyResidenceProvince = vr.createTextBox(
            {
                label: "Provincia",
                enable: false,
                align: vr.TextAlignEnum.Center,
                width: "Calc(50% - 10px)",
                cssContainer: "margin-right: 10px;"
            }, divItalyResidence);

        this._cmbItalyResidenceZip = vr.createComboBox(
            {
                label: "CAP",
                placeholder: "",
                width: "50%"
            }, divItalyResidence);

        let divOutItalyResidence = vr.div(divResidence, { css: "display: none;" });
        this._txtOutItalyResidenceAddress = vr.createTextBox(
            {
                label: "Indirizzo",
                width: "100%",
                cssContainer: "margin-right: 10px;"
            }, divOutItalyResidence);

        this._cmbOutItalyResidenceCountry = vr.createComboBox(
            {
                label: "Nazione",
                width: "100%"
            }, divOutItalyResidence);
        //#endregion

        this._btnRegister = vr.createButton({
            text: "Registrati",
            colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
            css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
            cssContainer: "margin-bottom: 30px;",
            width: "100%",
            class: "vrButtonRegister vrButtonPatient",
            onClick: (e) =>
            {
                //#region Check
                let error = false;
                if (this._txtName.isEmpty())
                {
                    this._txtName.error("Nome obbligatorio", vr.ErrorModeEnum.Solid);
                    error = true;
                }

                if (this._txtSurname.isEmpty())
                {
                    this._txtSurname.error("Cognome obbligatorio", vr.ErrorModeEnum.Solid);
                    error = true;
                }

                if (error)
                    return;
                //#endregion

                this.register();
            }
        }, this._container);

        if (this._parameters != null)
        {
            if (this._parameters.customerName != null && this._parameters.customerName != "" && this._parameters.customerName.trim().toLowerCase() != "prenotazione online")
                this._txtName.value(this._parameters.customerName);

            if (this._parameters.customerSurname != null && this._parameters.customerSurname != "" && this._parameters.customerSurname.trim().toLowerCase() != "non completata")
                this._txtSurname.value(this._parameters.customerSurname);

            if (this._parameters.customerFiscalCode != null && this._parameters.customerFiscalCode != "")
                this._txtFiscalCode.value(this._parameters.customerFiscalCode);
        }

        this.getAllCountries((e: GetAllCountriesResponse) =>
        {
            this._cmbOutItalyResidenceCountry.items(e.countries);
            this._cmbForeignBirthCityCountry.items(e.countries);
        });
    }

    static getAllCountries(callback?: (e: GetAllCountriesResponse) => void, mode = vr.WebApiModeEnum.Async)
    {
        let request = new GetAllCountriesRequest();
        request.call(
            {
                success: (response: GetAllCountriesResponse) =>
                {
                    if (callback != null)
                        callback(response);
                },
                mode: mode
            }
        );
    }

    static getAllCategories(callback?: (e: GetAllCategoriesResponse) => void, mode = vr.WebApiModeEnum.Async)
    {
        let request = new GetAllCategoriesRequest();
        request.plantIdList = [Rotator.configuration().plant.id];
        request.call(
            {
                success: (response: GetAllCategoriesResponse) =>
                {
                    if (callback != null)
                        callback(response);
                },
                mode: mode
            }
        );
    }

    static manageFields(fields: MandatoryFieldInfo[], fieldTypeEnum: OnlineBookingMandatoryFieldTypeEnum, control?: vr.VrControl | null, container?: HTMLElement)
    {
        let field = fields.filter(k => k.fieldType == fieldTypeEnum)[0];
        if (field == null || (field != null && !field.isVisible))
        {
            if (control != null)
                control.hide();

            if (container != null)
                puma(container).hide();
        }
    }

    static calcProvinceAndZipByCity(cityIstatCode: string, callback?: (e: GetCityInfoByIstatCodeResponse) => void)
    {
        let request = new GetCityInfoByIstatCodeRequest();
        request.cityOrCountryIstatCode = cityIstatCode;
        request.call(
            {
                success: (response: GetCityInfoByIstatCodeResponse) =>
                {
                    let cityInfo = response.city;
                    if (cityInfo == null)
                        return;

                    if (callback != null)
                        callback(response);
                },
                mode: vrshared.WebApiModeEnum.Async
            }
        );
    }

    private static register()
    {
        //#region Check
        let error = this.checkMandatoryFields(this._fields, this._txtFiscalCode, this._dtpBirthdate, this._swtItalianForeignBirthCity,
            this._cmbBirthCity, this._cmbForeignBirthCityCountry, this._txtForeignBirthCity, this._btgGender, this._swtItalianForeignResidenceAddress,
            this._txtItalyResidenceAddress, this._cmbItalyResidenceCity, this._txtItalyResidenceProvince, this._cmbItalyResidenceZip,
            this._txtOutItalyResidenceAddress, this._cmbOutItalyResidenceCountry, this._txtLandline);

        if (error)
            return;
        //#endregion

        let request = new RegisterRequest();
        if (this._parameters != null)
        {
            if (this._parameters.preregisterId != null)
                request.id = this._parameters.preregisterId;

            if (this._parameters.customerPhone != null)
                request.phone = this._parameters.customerPhone;

            if (this._parameters.customerMail != null)
                request.mail = this._parameters.customerMail;
        }

        request.webApiBirthDate = Date.toWebApiDateTime(this._dtpBirthdate.value());
        request.surname = this._txtSurname.value();
        request.name = this._txtName.value();
        request.fiscalCode = this._txtFiscalCode.value();
        request.landline = this._txtLandline.value();
        request.gender = this._btgGender.getSelectedValues()[0];
        request.privacy = true;
        request.privacyCommercial = true;

        request.birthAddressInfo = new BirthAddressInfo();
        if (!this._swtItalianForeignBirthCity.checked())
        {
            //#region Italian
            request.birthAddressInfo.istatCode = this._cmbBirthCity.value<string>()!;
            //#endregion
        }
        else
        {
            //#region Foreign
            let countrySelected = this._cmbForeignBirthCityCountry.getSelectedItem<any>();
            if (countrySelected != null)
                request.birthAddressInfo.countryCode = this._cmbForeignBirthCityCountry.value<string>()!;

            request.birthAddressInfo.address = this._txtForeignBirthCity.value();
            //#endregion
        }

        if (!this._swtItalianForeignResidenceAddress.checked())
        {
            //#region Italian address
            let italianAddress = new ItalianAddress();
            italianAddress.street = this._txtItalyResidenceAddress.value();
            italianAddress.istatCode = this._cmbItalyResidenceCity.value<string>()!;
            italianAddress.zipCode = this._cmbItalyResidenceZip.text();
            request.italianAddress = italianAddress;
            //#endregion
        }
        else 
        {
            //#region Foreign address
            let foreignAddress = new ForeignAddress();
            foreignAddress.address = this._txtOutItalyResidenceAddress.value();

            let countrySelected = this._cmbOutItalyResidenceCountry.getSelectedItem<any>();
            if (countrySelected != null)
                foreignAddress.countryCode = this._cmbOutItalyResidenceCountry.value<string>()!;

            request.foreignAddress = foreignAddress;
            //#endregion
        }

        request.throwException = false;
        request.createUserAccount = true;
        request.call(
            {
                success: (response: RegisterResponse) => 
                {
                    if (response.registerResult == RegisterResult.PatientCreated || response.registerResult == RegisterResult.PatientFound)
                    {
                        let loginParameters = new LoginParameters();
                        loginParameters.fromRegister = true;
                        loginParameters.username = response.username;
                        Rotator.page(PageEnum.Login, loginParameters);

                        Support.manageTrackingCookiesEvent(EventTypeEnum.Registered);
                    }
                    else
                        Register.manageRegisterResult(response.registerResult, response.ExceptionMessage);
                },
                loader: Rotator.container()
            });
    }

    static manageRegisterResult(registerResult: RegisterResult, exceptionMessage: string | null, impersonificate = false)
    {
        switch (registerResult)
        {
            case RegisterResult.UsernameAlreadyExist:
                {
                    if (!impersonificate)
                        VrManager.notify("Esiste già un utente con questo username", "", { type: vr.NotifierTypeEnum.Error });
                    else
                        VrManager.notify("L'utente associato a questo codice fiscale ha un numero di telefono corrispondente allo username di un altro utente", "", { type: vr.NotifierTypeEnum.Error });

                    Rotator.page(PageEnum.ForgotPassword);
                }
                break;
            case RegisterResult.UserExistSamePhoneNumber:
                VrManager.notify("Esiste già un utente con questo username", "", { type: vr.NotifierTypeEnum.Error });
                break;
            case RegisterResult.UserExistDifferentPhoneNumber:
                VrManager.notify("Esiste già un utente registrato, con differenti dati. Contattare il centro", "", { type: vr.NotifierTypeEnum.Error });
                break;
            case RegisterResult.UserExistNoPhoneNumber:
                VrManager.notify("La persona trovata non ha il telefono cellulare impostato per potergli mandare la password di verifica. Contattare il centro", "", { type: vr.NotifierTypeEnum.Error });
                break;
            case RegisterResult.PatientNotFound:
                VrManager.notify("Utente non trovato", "", { type: vr.NotifierTypeEnum.Error });
                break;
            case RegisterResult.OtherError:
                VrManager.notify((exceptionMessage != null) ? exceptionMessage : "Errore generico", "", { type: vr.NotifierTypeEnum.Error });
                break;
        }
    }

    static checkMandatoryFields(fields: MandatoryFieldInfo[], txtFiscalCode: vr.TextBox, dtpBirthdate: vr.DatePicker,
        swtItalianForeignBirthCity: vr.Switch, cmbBirthCity: vr.ComboBox, cmbForeignBirthCityCountry: vr.ComboBox,
        txtForeignBirthCity: vr.TextBox, btgGender: vr.ButtonGroup, swtItalianForeignResidenceAddress: vr.Switch,
        txtItalyResidenceAddress: vr.TextBox, cmbItalyResidenceCity: vr.ComboBox, txtItalyResidenceProvince: vr.TextBox,
        cmbItalyResidenceZip: vr.ComboBox, txtOutItalyResidenceAddress: vr.TextBox, cmbOutItalyResidenceCountry: vr.ComboBox,
        txtLandline: vr.TextBox)
    {
        let error = false;

        let fieldFiscalCode = fields.filter(k => k.fieldType == OnlineBookingMandatoryFieldTypeEnum.FiscalCode)[0];
        if (txtFiscalCode.isEmpty() && fieldFiscalCode != null && fieldFiscalCode.isMandatory)
        {
            VrManager.notify("Codice fiscale obbligatorio", "", { type: vr.NotifierTypeEnum.Error });
            error = true;
        }

        let fieldLandline = fields.filter(k => k.fieldType == OnlineBookingMandatoryFieldTypeEnum.Landline)[0];
        if (txtLandline.isEmpty() && fieldLandline != null && fieldLandline.isMandatory)
        {
            VrManager.notify("Telefono fisso obbligatorio", "", { type: vr.NotifierTypeEnum.Error });
            error = true;
        }

        let fieldBirthdate = fields.filter(k => k.fieldType == OnlineBookingMandatoryFieldTypeEnum.BirthDate)[0];
        if (dtpBirthdate.value() == null && fieldBirthdate != null && fieldBirthdate.isMandatory)
        {
            VrManager.notify("Data di nascita obbligatoria", "", { type: vr.NotifierTypeEnum.Error });
            error = true;
        }

        let fieldBirthCity = fields.filter(k => k.fieldType == OnlineBookingMandatoryFieldTypeEnum.BirthLocation)[0];
        if (fieldBirthCity != null && fieldBirthCity.isMandatory)
        {
            let errorBirthCity = false;
            if (!swtItalianForeignBirthCity.checked()) // Italy
            {
                if (cmbBirthCity.value() == null)
                    errorBirthCity = true;
            }
            else // Foreign
            {
                if (cmbForeignBirthCityCountry.value() == null)
                    errorBirthCity = true;

                if (txtForeignBirthCity.isEmpty())
                    errorBirthCity = true;
            }

            if (errorBirthCity)
            {
                VrManager.notify("Riempire la città di nascita", "", { type: vr.NotifierTypeEnum.Error });
                error = true;
            }
        }

        let fieldGender = fields.filter(k => k.fieldType == OnlineBookingMandatoryFieldTypeEnum.Gender)[0];
        if (btgGender.getSelectedValues().length == 0 && fieldGender != null && fieldGender.isMandatory)
        {
            VrManager.notify("Genere obbligatorio", "", { type: vr.NotifierTypeEnum.Error });
            error = true;
        }

        let fieldAddress = fields.filter(k => k.fieldType == OnlineBookingMandatoryFieldTypeEnum.ResidenceAddress)[0];
        if (fieldAddress != null && fieldAddress.isMandatory)
        {
            let errorResidence = false;
            if (!swtItalianForeignResidenceAddress.checked()) // Italian address
            {
                if (txtItalyResidenceAddress.isEmpty())
                    errorResidence = true;

                if (cmbItalyResidenceCity.value() == null)
                    errorResidence = true;

                if (txtItalyResidenceProvince.isEmpty())
                    errorResidence = true;

                if (cmbItalyResidenceZip.value() == null)
                    errorResidence = true;
            }
            else // Foreign address
            {
                if (txtOutItalyResidenceAddress.isEmpty())
                    errorResidence = true;

                if (cmbOutItalyResidenceCountry.value() == null)
                    errorResidence = true;
            }

            if (errorResidence)
            {
                VrManager.notify("Riempire l'indirizzo di residenza", "", { type: vr.NotifierTypeEnum.Error });
                error = true;
            }
        }
        return error;
    }

    static preregister(settings: PreregisterSettings, callback?: (e: PreregisterResponse) => void, async = true): void
    {
        //#region Check
        let error = false

        if (settings.phoneNumber == null || settings.phoneNumber == "")
        {
            VrManager.notify("Cellulare non inserito", "", { type: vr.NotifierTypeEnum.Error });
            error = true;
        }

        if (error)
            return;
        //#endregion

        let request = new PreregisterRequest();
        request.name = settings.name;
        request.surname = settings.surname;
        request.phoneNumber = settings.phoneNumber;
        request.emailAddress = settings.emailAddress;
        request.privacyForCommercialUse = settings.privacyForCommercialUse;
        request.privacyForMedicalBooking = settings.privacyForMedicalBooking;
        request.call(
            {
                success: (response: PreregisterResponse) => 
                {
                    if (callback != null)
                        callback(response);
                },
                loader: Rotator.container(),
                mode: (async) ? vr.WebApiModeEnum.Async : vr.WebApiModeEnum.Sync
            });
    }

    static getMandatoryFields(callback?: (e: GetMandatoryFieldsResponse) => void)
    {
        let request = new GetMandatoryFieldsRequest();
        request.call(
            {
                success: (response: GetMandatoryFieldsResponse) => 
                {
                    if (callback != null)
                        callback(response);
                },
                loader: false
            });
    }
}

export class RegisterParameters
{
    public customerName: string;
    public customerSurname: string;
    public preregisterId: number;
    public customerPhone: string;
    public customerMail: string;
    public customerFiscalCode: string;
}

//#region Register
export class RegisterRequest extends WebApiRequestBase 
{
    public method: string = "/api/LoginWebApi/Register";

    public id: number;
    public mail: string;
    public phone: string;
    public surname: string;
    public name: string;
    public gender: string;
    public privacy: boolean;
    public privacyCommercial: boolean;
    public fiscalCode: string;
    public landline: string;
    public fiscalCodeAlreadyPatient: string;
    public createUserAccount: boolean;
    public birthDate?: Date | null;
    public birthAddressInfo: BirthAddressInfo;
    public italianAddress: ItalianAddress;
    public foreignAddress: ForeignAddress;
    public throwException: boolean;
    public preregisterId: number;
    public webApiBirthDate: WebApiDateTime;
    public italianAddressDomicile: ItalianAddress;
    public foreignAddressDomicile: ForeignAddress;
    public identityCard: IdentityDocumentInfo;
    public passport: IdentityDocumentInfo;
    public categoryId: number;

    public isSailor: boolean;
    public sailorType: SailorTypeEnum;
    public sailorSasnMode: SailorSasnModeEnum;
    public sailorSasnSerialNumber: string;
    public sailorSasnCompanyName: string;
    public sailorUsmafCompanyName: string;
    public sailorUsmafPortAuthorityContactName: string;
    public sailorUsmafPortAuthorityContactPhoneNumber: string;
    public sailorUsmafPortAuthorityContactEmail: string;
    public sailorUsmafShipOwnerContactName: string;
    public sailorUsmafShipOwnerContactPhoneNumber: string;
    public sailorUsmafShipOwnerContactEmail: string;
}

export class IdentityDocumentInfo
{
    public number: string;
    public emissionDate?: WebApiDateTime | null;
}

export class RegisterResponse extends WebApiResponseBase 
{
    public customerId: number;
    public username: string;
    public registerResult: RegisterResult;
}


export enum RegisterResult
{
    PatientFound = 1,
    PatientCreated = 2,
    UsernameAlreadyExist = 3,
    UserExistSamePhoneNumber = 4,
    UserExistDifferentPhoneNumber = 5,
    UserExistNoPhoneNumber = 6,
    OtherError = 7,
    PatientNotFound
}
//#endregion

//#region Preregister
class PreregisterRequest extends WebApiRequestBase
{
    public method: string = "/api/LoginWebApi/Preregister";

    public name: string;
    public surname: string | null;
    public phoneNumber: string;
    public emailAddress: string;
    public privacyForMedicalBooking: boolean;
    public privacyForCommercialUse: boolean;
}

export class PreregisterResponse extends WebApiResponseBase 
{
    public preregisterId: number;
}

export class PreregisterSettings
{
    public name: string;
    public surname: string | null;
    public phoneNumber: string;
    public emailAddress: string;
    public privacyForMedicalBooking: boolean;
    public privacyForCommercialUse: boolean;
}
//#endregion

//#region GetMandatoryFields
export class GetMandatoryFieldsRequest extends WebApiRequestBase 
{
    public method: string = "/api/LoginWebApi/GetMandatoryFields";
}

export class GetMandatoryFieldsResponse extends WebApiResponseBase 
{
    public fields: MandatoryFieldInfo[];
}

export class MandatoryFieldInfo
{
    public fieldType: OnlineBookingMandatoryFieldTypeEnum;
    public isMandatory: boolean;
    public isVisible: boolean;
    public fieldName: string;
}

export enum OnlineBookingMandatoryFieldTypeEnum
{
    BirthLocation = 1,
    BirthDate = 2,
    Gender = 3,
    FiscalCode = 4,
    EMail = 5,
    ResidenceAddress = 6,
    Landline = 7
}
//#endregion

class GetCityInfoByIstatCodeRequest extends WebApiRequestBase
{
    public AuthKey: string = "%r9^3rRZVtyZ+Fzp";
    public method: string = "/api/UtilityWebApi/GetCityInfoByIstatCode";

    public cityOrCountryIstatCode: string;
}

class GetCityInfoByIstatCodeResponse extends WebApiResponseBase
{
    public city: City;
}

class GetAllCountriesRequest extends WebApiRequestBase
{
    public method: string = "/api/UtilityWebApi/GetAllCountries";
}

class GetAllCountriesResponse extends WebApiResponseBase
{
    public countries: vr.ComboBoxItem[];
}

class GetAllCategoriesRequest extends WebApiRequestBase
{
    public method: string = "/api/UtilityWebApi/GetAllCategories";

    public plantIdList: number[];
}

class GetAllCategoriesResponse extends WebApiResponseBase
{
    public categories: vr.ComboBoxItem[];
}