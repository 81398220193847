import { Rotator, puma, PageEnum } from "../rotator";
import { Support } from "../support";
import { ResourceTimeslots } from "./resourceTimeslots";

export class WorkplaceTimeslots
{
	private static _container: HTMLElement;

	static initialize()
	{
		this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
		Rotator.title("DISPONIBILITÀ");

		this.drawWorkplacesSlots();
	}

	private static drawWorkplacesSlots()
	{
		let workplaces = Rotator.bookingParameters().workplaceList.filter(k => k.id != 0);
		if (Rotator.bookingParameters().workplace != null)
			workplaces = workplaces.filter(k => k.id == Rotator.bookingParameters().workplace!.id);

		if (workplaces.length == 0)
		{
			vr.createLabel({
				text: "Non è presente alcuna sede disponibile per effettuare la prestazione selezionata",
				width: "100%",
				align: vr.TextAlignEnum.Center
			}, this._container);
		}

		for (let workplace of workplaces)
		{
			let divSlot = vr.div(this._container, { class: "vrPatientDivSlot" });

			//#region Image/Icon
			vr.icon(vr.IconClassicLight.Map, divSlot, { css: "justify-content: center; float: left; margin-top: 5px; margin-right: 10px; font-size: 4em; color: " + Rotator.color() + ";" });
			//#endregion

			//#region Info
			let divInfo = vr.div(divSlot, { css: "display: inline-block; width: Calc(100% - 75px); text-align: left; min-height: 70px;" });

			vr.createLabel(
				{
					text: workplace.name,
					width: "100%",
					css: "font-weight: 600;",
					noBr: true
				}, divInfo);

			let address = Support.getAddressName(workplace);
			vr.createLabel(
				{
					text: "<span style='font-weight: normal;'>" + address + "</span>",
					width: "Calc(100% - 25px)"
				}, divInfo);
			//#endregion

			//#region ActivityType
			let divActivityType = vr.div(divSlot, { css: "margin-top: 10px;" });
			vr.icon(vr.IconClassicLight.FileLines, divActivityType, { css: "margin-right: 5px; position: relative; top: -1px; color: " + Rotator.color() });
			vr.createLabel(
				{
					text: Rotator.bookingParameters().activityType.onlineBookingName,
					width: "Calc(100% - 25px)",
					class: "vrPatient_lblActivityType"
				}, divActivityType);
			//#endregion

			//#region Slots
			let divSlots = vr.div(divSlot, { id: "vrPatientDivSlots", css: "margin-top: 10px; min-height: 88px;" });
			ResourceTimeslots.getNextAvailability(null, workplace.id, Rotator.bookingParameters().from, (e) =>
			{
				ResourceTimeslots.drawWorkplaceTimeslots(divSlots, workplace, null, e.nextAvailabilities);
			}, divSlots);
			//#endregion
		}
	}
}

//#region Classes

//#endregion