import { Rotator } from "../../src/rotator";
import { Support } from "../../src/support";

export class VrManager
{
	static notify(text: string, stackTrace?: string, options?: vr.NotifierOptions | null, container?: HTMLElement | JQuery | string | null): vr.Notifier
	{
		if (options == null) options = {};
		options.css = "padding-top: 3px; padding-bottom: 3px;";
		options.fontSize = "1.2em !important";
		//options.hideSettings = { clickToHide: true }

		if (stackTrace != null && stackTrace != "" && !Rotator.isMobile())
		{
			options.onClick = () =>
			{
				vr.createWindow({
					title: "Gestione eccezione",
					content: "<div style='overflow-wrap: anywhere; overflow-y: auto; height: 100%;'><span style='color: #51B3E1'>StackTrace: </span>" + stackTrace + "</div>",
					footer: [{ type: vr.WindowFooterItemTypeEnum.Close }]
				}, Rotator.shadowRoot() as any).open();
			};
		}

		let result = vr.notify(text, options);
		Support.fixNotifyPosition();
		return result;
	}
}