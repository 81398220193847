import { Support } from "../../src/support";
import { AppLoginModeEnum, PageEnum, puma, Rotator } from "../../src/rotator";
import { WebComponentModeEnum } from "../../src/main";

export class Menu
{
	private static _divMenuContainer: HTMLElement;
	private static _divMenu: HTMLElement;
	private static _menuItemList: MenuItem[] = [];
	private static _background: HTMLElement;

	static initialize()
	{
		this._divMenuContainer = vr.div(puma(".vrPatientDivContainer"), { class: "vettorePatientMenu" })
		this._divMenu = vr.div(this._divMenuContainer, { css: "height: Calc(100% - 33px); overflow-y: auto;" });

		//#region Profile
		let profileState = (Rotator.mode() == WebComponentModeEnum.OnlyBooking) ? MenuStateEnum.Hide : MenuStateEnum.ShowIfLogged;
		this.createMenuItem("Profilo", PageEnum.Profile, vr.IconClassicLight.User, profileState);
		//#endregion

		//#region Booking
		let bookingState = (Rotator.configuration().appLoginRequired == AppLoginModeEnum.Always) ? MenuStateEnum.ShowIfLogged : MenuStateEnum.ShowAlways;
		if ((Rotator.webComponentParameters()[0].vrSessionId == null || Rotator.webComponentParameters()[0].vrSessionId == "")
			&& (!Rotator.configuration().appBookingAppointments || Rotator.mode() == WebComponentModeEnum.OnlyPrivateArea))
			bookingState = MenuStateEnum.Hide;

		let titleBooking = (Rotator.configuration().appShowDashboard) ? "Home" : "Prenota";
		let iconBooking = (Rotator.configuration().appShowDashboard) ? vr.IconClassicLight.House : vr.IconClassicLight.Calendar;
		this.createMenuItem(titleBooking, PageEnum.Booking, iconBooking, bookingState, (e) => Rotator.goToMainPage());
		//#endregion

		//#region Appointments
		let appointmentsState = (Rotator.mode() == WebComponentModeEnum.OnlyBooking) ? MenuStateEnum.Hide : MenuStateEnum.ShowIfLogged;
		this.createMenuItem("Appuntamenti", PageEnum.AppointmentList, vr.IconClassicLight.CalendarCheck, appointmentsState);
		//#endregion

		//#region Documents
		let documentState = (Rotator.mode() == WebComponentModeEnum.OnlyBooking) ? MenuStateEnum.Hide : MenuStateEnum.ShowIfLogged;
		if (Rotator.configuration().appShowDocuments)
			this.createMenuItem("Fatture", PageEnum.DocumentList, vr.IconClassicLight.FileLines, documentState);
		//#endregion

		//#region Reports
		let reportsState = (Rotator.mode() == WebComponentModeEnum.OnlyBooking) ? MenuStateEnum.Hide : MenuStateEnum.ShowIfLogged;
		if (Rotator.configuration().appShowReports)
			this.createMenuItem("Referti", PageEnum.ReportList, vr.IconClassicLight.Book, reportsState);
		//#endregion

		//#region Files
		let filesState = (Rotator.mode() == WebComponentModeEnum.OnlyBooking) ? MenuStateEnum.Hide : MenuStateEnum.ShowIfLogged;
		if (Rotator.configuration().appShowFiles)
			this.createMenuItem("Lista File", PageEnum.FileList, vr.IconClassicLight.FileLines, filesState);
		//#endregion

		//#region Info
		this.createMenuItem("Info", PageEnum.Info, vr.IconClassicLight.CircleInfo, MenuStateEnum.ShowAlways);
		//#endregion

		//#region Login
		let loginState = (Rotator.mode() == WebComponentModeEnum.OnlyBooking) ? MenuStateEnum.Hide : MenuStateEnum.ShowIfNotLogged;
		this.createMenuItem("Accedi", PageEnum.Login, vr.IconClassicLight.Login, loginState,
			(e) =>
			{
				Rotator.page(PageEnum.Login);
			});
		//#endregion

		this.createStructure();
		this.draw();

		Support.checkLogin();
	}

	private static createMenuItem(text: string, value: string, icon: vr.IconClassicLight, stateEnum: MenuStateEnum, callback?: (e: ClickMenuEvent) => void)
	{
		let menuItem = new MenuItem();
		menuItem.text = text;
		menuItem.value = value;
		menuItem.icon = icon;
		menuItem.stateEnum = stateEnum;
		menuItem.onClick = (e) =>
		{
			Rotator.inBookingFlow(value == PageEnum.Booking);
			if (value == PageEnum.AppointmentList) Rotator.filterAppointmentOnPast(false);

			if (callback != null)
				callback(e);
			else
				Rotator.page(<PageEnum>value);
		}
		this._menuItemList.push(menuItem);
	}

	private static createStructure()
	{
		this._background = vr.div(puma(".vrPatientDivContainer"), { class: "vrPatient_popupBackground", css: "opacity: 0 !important;" });
		puma(this._background).hide();
		puma(this._background).on("click", (e: JQuery.ClickEvent) => this.hide());

		let colorSeparator = (Rotator.isMobile()) ? "#FFF" : Rotator.color()
		vr.div(this._divMenu, { css: "background-color: " + colorSeparator + "; height: 1px;", class: "vrPatientDivMenuTitleSeparator" });

		let divCredits = vr.div(this._divMenuContainer, { css: "position: absolute; bottom: 0px; padding: 10px; padding-top: 5px; padding-bottom: 5px; width: Calc(100% - 20px); background-color: #FFF; border-top: solid 1px " + Rotator.color() + "; color: " + Rotator.color() + "; text-align: right;" });
		vr.createLabel({
			text: "© Vettore.Medical"
		}, divCredits);
	}

	private static draw()
	{
		for (let menuItem of this._menuItemList)
		{
			let divMenuItem = vr.div(this._divMenu, { class: "vrPatientDivMenuItem" });
			puma(divMenuItem).attr("value", menuItem.value);
			puma(divMenuItem).attr("state", menuItem.stateEnum);

			vr.icon(menuItem.icon, divMenuItem, { css: "font-size: 1.6em; margin-right: 7px; color: " + Rotator.color() });

			vr.createLabel({
				text: menuItem.text,
				fontSize: "1.6em",
				css: "cursor: pointer;"
			}, divMenuItem);

			puma(divMenuItem).on("click", (e: JQuery.ClickEvent) =>
			{
				this.hide();

				let clickEvent = new ClickMenuEvent();
				clickEvent.text = menuItem.text;
				clickEvent.value = menuItem.value;
				menuItem.onClick!(clickEvent);
			});
		}
	}

	static show()
	{
		puma(this._divMenuContainer).show();
		puma(this._background).show();
	}

	static hide()
	{
		puma(this._divMenuContainer).hide();
		puma(this._background).hide();
	}

	static visible(state?: boolean)
	{
		if (state != null)
		{
			if (state) this.show();
			else this.hide();
		}
		return puma(this._divMenuContainer).is(":visible");
	}

	static manageState(logged: boolean)
	{
		puma(".vrPatientDivMenuItem[state='" + MenuStateEnum.Hide + "']").hide();

		if (logged)
		{
			puma(".vrPatientDivMenuItem[state='" + MenuStateEnum.ShowIfNotLogged + "']").hide();
			puma(".vrPatientDivMenuItem[state='" + MenuStateEnum.ShowIfLogged + "']").show();
		}
		else
		{
			puma(".vrPatientDivMenuItem[state='" + MenuStateEnum.ShowIfNotLogged + "']").show();
			puma(".vrPatientDivMenuItem[state='" + MenuStateEnum.ShowIfLogged + "']").hide();
		}
	}

	static highlightItem(value: string | PageEnum)
	{
		puma(".vrPatientDivMenuItem").css("background-color", "#FFF");
		puma(".vrPatientDivMenuItem i").css("color", Rotator.color());
		puma(".vrPatientDivMenuItem label").css("color", "#000");

		puma(".vrPatientDivMenuItem[value='" + value + "']").css("background-color", Rotator.color());
		puma(".vrPatientDivMenuItem[value='" + value + "'] i").css("color", "#FFF");
		puma(".vrPatientDivMenuItem[value='" + value + "'] label").css("color", "#FFF");
	}

	static hideItem(value: string | PageEnum)
	{
		puma(".vrPatientDivMenuItem[value='" + value + "']").hide();
	}
}

//#region Classes
class MenuItem
{
	text: string;
	value: string;
	icon: vr.IconClassicLight;
	stateEnum: MenuStateEnum;
	onClick?: (e: ClickMenuEvent) => void;
}

class ClickMenuEvent
{
	text: string;
	value: string;
}

enum MenuStateEnum
{
	ShowAlways,
	ShowIfLogged,
	ShowIfNotLogged,
	Hide
}
//#endregion