import { Rotator, PageEnum, Configuration, puma, AppLoginModeEnum } from "./rotator";
import { WebApiRequestBase, WebApiResponseBase } from "./shared/webApi";
import { BookingParameters } from "./booking/booking";
import { Menu } from "./shared/menu";
import { Support } from "./support";
import { AppointmentDetailParameter } from "./privateArea/appointmentDetail";
import { VrManager } from "./shared/vrManager";

class VettorePatient extends HTMLElement
{
    private _webComponentConfigs: WebComponentConfig[];
    private _btnOpenPopup: vr.Button;

    constructor()
    {
        super();
        this.attachShadow({ mode: "open" }); // open=I can interact with outside JavaScript, closed=opposite
    }

    // Called if observedAttributes have a value
    attributeChangedCallback(name: string, oldValue: string, newValue: string)
    {
        if (oldValue !== newValue)
        {
            switch (name)
            {
                case "configuration":
                    {
                        try
                        {
                            newValue = newValue.replace(/'/g, "\"");
                            this._webComponentConfigs = JSON.parse(newValue);
                        }
                        catch (e)
                        {
                            VrManager.notify("Json del tag errato, controllare attentamente", "", { type: vr.NotifierTypeEnum.Error });
                            throw Error("Json del tag errato, controllare attentamente");
                        }
                    }
                    break;
            }
        }
    }

    // WebComponent's attributes
    static get observedAttributes()
    {
        return ["configuration"];
    }

    // Called when the webComponent tag is inserted into the dom
    connectedCallback()
    {
        this.createComponent();
    }

    createComponent()
    {
        //#region SessionId
        let sessionId = vrshared.UtilityManager().getUrlParameter("sessionId");
        if (sessionId == null || sessionId == "")
            sessionId = vrshared.CookieManager().getCookie("sessionId");

        if (sessionId != null && sessionId != "")
        {
            vrshared.CookieManager().deleteCookie("sessionId");
            vrshared.CookieManager().setCookieYears("sessionId", sessionId, 1, { sameSite: vrshared.CookieSameSiteEnum.Lax, secured: true });
        }
        //#endregion

        Rotator.shadowRoot(this.shadowRoot!);
        customElements.whenDefined("vr-patient").then(() =>
        {
            Rotator.webComponentParameters(this._webComponentConfigs);
            let prePath = Rotator.patientUrl();

            let version = String((new Date().getTime() * 10000) + 621355968000000000);
            Rotator.jqueryVariable().when(
                Rotator.jqueryVariable().getScript(prePath + "/scripts/leaflet-image.js?v=" + version),
                Rotator.jqueryVariable().getScript(prePath + "/scripts/shared.all.min.js?v=" + version),
                Rotator.jqueryVariable().getScript(prePath + "/scripts/vrcontrols.all.min.js?v=" + version),
                Rotator.jqueryVariable().getScript(prePath + "/scripts/extensions.all.min.js?v=" + version),
                Rotator.jqueryVariable().getScript(prePath + "/map/leaflet.js?v=" + version),
                Rotator.jqueryVariable().getScript(prePath + "/scripts/pdf.js?v=" + version),
                Rotator.jqueryVariable().getScript(prePath + "/scripts/print.min.js?v=" + version),
                Rotator.jqueryVariable().getScript(prePath + "/scripts/pdf.worker.js?v=" + version)
            ).done(() =>
            {
                //Rotator.webComponentParameters()[0].jqueryVariable = "jQueryVrBooking";

                let style = document.createElement("style");
                let css: Text = document.createTextNode(`
                    @import '` + prePath + `/styles/vrcontrols.all.min.css?v=` + version + `'; 
                    @import '` + prePath + `/styles/vrpatient.all.min.css?v=` + version + `';
                    @import '` + prePath + `/FontsAwesomePro/css/all.min.css?v=` + version + `';
                    @import '` + prePath + `/map/leaflet.css?v=` + version + `';

                    @keyframes loaderManager_loader1 { 0% { transform: scale(0); } 100% { transform: scale(1); } }
                    @keyframes loaderManager_loader3 { 0% {  transform: scale(1); } 100% {  transform: scale(0); } }
                    @keyframes loaderManager_loader2 { 0% { transform: translate(0, 0); } 100% { transform: translate(24px, 0); } }
                `);
                style.appendChild(css);

                if (Rotator.webComponentParameters()[0].css != null)
                    style.appendChild(document.createTextNode(Rotator.webComponentParameters()[0].css));

                this.shadowRoot!.appendChild(style);

                puma("head").append(`
                    <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no' />
                    <meta name='HandheldFriendly' content='true' />
                    <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate" />                     
                    <meta http-equiv="Pragma" content="no-cache" />                     
                    <meta http-equiv="Expires" content= "0" />
                    <style>
                        @import '` + prePath + `/styles/vrcontrols.all.min.css?v=` + version + `';
                        @import '` + prePath + `/styles/vrpatient.all.min.css?v=` + version + `';
                        @import '` + prePath + `/FontsAwesomePro/css/all.min.css?v=` + version + `';
                        @import '` + prePath + `/map/leaflet.css?v=` + version + `';
                    </style> 
                `);

                if (!location.protocol.startsWith("https"))
                {
                    vr.createLabel(
                        {
                            text: "Per il corretto funzionamento della prenotazione online è necessario utilizzare un protocollo sicuro https",
                            colorSettings: { textColor: "red" }
                        }, this.shadowRoot! as any);

                    throw Error("Per il corretto funzionamento della prenotazione online è necessario utilizzare un protocollo sicuro https");
                }

                let request = new GetConfigurationRequest();
                request.configurations = Rotator.webComponentParameters();
                request.checkConnection = false;
                request.call(
                    {
                        success: (response: GetConfigurationResponse) =>
                        {
                            if (response.configuration.keyMessageCheck != null && response.configuration.keyMessageCheck != "")
                            {
                                vr.createLabel(
                                    {
                                        text: response.configuration.keyMessageCheck,
                                        colorSettings: { textColor: "red" }
                                    }, this.shadowRoot! as any);

                                throw Error(response.configuration.keyMessageCheck);
                            }

                            window.onload = () => { window.setTimeout(() => { window.scrollTo(0, 1); }, 0); }
                            Rotator.configuration(response.configuration);

                            // Paypal
                            let paypalClientId = (Rotator.configuration().appPaypalSandboxMode) ? Rotator.configuration().appPaypalClientIdSandbox : Rotator.configuration().appPaypalClientId;
                            if (paypalClientId != null && paypalClientId != "")
                            {
                                puma("head").append(`<script src='https://www.paypal.com/sdk/js?client-id=` + paypalClientId
                                    + `&currency=EUR&commit=false&locale=it_IT'></script>`);
                            }

                            // Token
                            let token = vrshared.CookieManager().getCookie("token");
                            if (token == null || token.length == 0)
                            {
                                let tokenFromUrl = vrshared.UtilityManager().getUrlParameter("token");
                                if (tokenFromUrl != null && tokenFromUrl.length > 0)
                                    token = tokenFromUrl;
                            }
                            Rotator.token(token);

                            vr.shadowRoot(this.shadowRoot!);
                            vr.jqueryVariable(Rotator.jqueryVariable());
                            vrshared.shadowRoot(this.shadowRoot!);
                            vrshared.jqueryVariable(Rotator.jqueryVariable());

                            let appColor = (response.configuration.appColor != null && response.configuration.appColor.length > 0) ? response.configuration.appColor : "#51B3E1";
                            Rotator.color(appColor);
                            Rotator.textColor((response.configuration.appTextColorWhite) ? "#FFF" : "#000");
                            Rotator.appHeaderColor((response.configuration.appMobileHeaderColor == "" || response.configuration.appMobileHeaderColor == null) ? appColor : response.configuration.appMobileHeaderColor);

                            let appMobileHeaderTextColor = response.configuration.appMobileHeaderTextColor;
                            if (response.configuration.appMobileHeaderTextColor == "" || response.configuration.appMobileHeaderTextColor == null)
                                appMobileHeaderTextColor = "#FFF";

                            Rotator.appHeaderTextColor(appMobileHeaderTextColor);

                            //#region Other CSS
                            Support.addCss(`
                                .loaderManager_loader div.loaderManager_step {
                                    background: ` + Rotator.color() + `;
                                    position: absolute; 
                                    top: 33px; 
                                    width: 13px; 
                                    height: 13px; 
                                    border-radius: 50%; 
                                    animation-timing-function: cubic-bezier(0, 1, 1, 0);
                                    z-index: 999999999;
                                }

                                .vrWindowHeader{
                                    background-color: ` + Rotator.color() + `;
                                    color: ` + Rotator.textColor() + `;
                                }

                                .vrWindowFooter .vrButtonPrimaryMode {
                                    background-color: ` + Rotator.color() + `;
                                    color: ` + Rotator.textColor() + `;
                                }

                                div.vettorePatientContainer label.vrUploadFileDescription{
                                    line-height: unset !important;
                                }

                                div.vettorePatientContainer label.vrUploadFileDescription .vrUploadLblUnderFileName{
                                    font-size: 11px !important;
                                }
                            `);

                            if (String.isNotNullOrEmpty(Rotator.configuration().appFileCssBase64TextContent))
                                Support.addCss(Rotator.configuration().appFileCssBase64TextContent);
                            //#endregion

                            this.createButton();
                            this.createStructure();

                            Support.getTrackingCookies();
                            Support.getUTM();
                        },
                        mode: vrshared.WebApiModeEnum.Async,
                        loader: false
                    });
            });
        });
    }

    private createButton()
    {
        let buttonSettings = Rotator.webComponentParameters()[0].buttonSettings;
        let text = (buttonSettings != null && buttonSettings.text != null) ? buttonSettings.text : "Prenota";
        let css = (buttonSettings != null && buttonSettings.css != null) ? buttonSettings.css : "color: " + Rotator.textColor() + "; background-color: " + Rotator.color() + ";";
        let cssContainer = (buttonSettings != null && buttonSettings.cssContainer != null) ? buttonSettings.cssContainer : "";

        let icon: vr.IconClass | undefined = (buttonSettings != null && buttonSettings.icon != null) ? buttonSettings.icon : "";
        if (String(icon) == "")
            icon = undefined;

        let iconCss = (buttonSettings != null && buttonSettings.iconCss != null) ? buttonSettings.iconCss : "";

        this._btnOpenPopup = vr.createButton(
            {
                text: text,
                css: css,
                cssContainer: cssContainer,
                icon: icon,
                iconSettings: { css: iconCss },
                class: "vrPatient_btnOpenPopup",
                visible: (Rotator.webComponentParameters()[0].open === true) ? false : true,
                onClick: (e) =>
                {
                    if (Rotator.isModal())
                        puma(".vrPatientBackground").show();

                    puma(".vrPatientDivContainer").show();
                    if (Rotator.webComponentParameters()[0].fullscreen === true)
                    {
                        if (Rotator.isMobile() && Rotator.webComponentParameters()[0].open !== true)
                            vrshared.UtilityManager().enterFullScreen(puma(".vrPatientDivContainer")[0]);
                    }
                }
            }, this.shadowRoot! as any);

        Rotator.mainButton(this._btnOpenPopup);
        puma(this._btnOpenPopup.container()).addClass("vrPatient_btnOpenPopupContainer");
    }

    private createStructure(): void
    {
        let divBackground = vr.div(this.shadowRoot! as any, { class: "vrPatientBackground" });

        //#region DivContainer
        let divContainer = vr.div(this.shadowRoot! as any, { class: "vrPatientDivContainer" });
        divContainer.style.cssText += "box-shadow: 0 0 10px " + Rotator.color() + ";";

        if (Rotator.isMobile())
            divContainer.style.cssText += "width: 100%; height: 100%; left: 0px; top: 0px;";
        else 
        {
            let windowHeight = window.innerHeight;
            let divContainerHeight = windowHeight - 150;
            if (!Rotator.isModal() && Rotator.webComponentParameters()[0].source != WebComponentSourceModeEnum.CrmLink)
                divContainerHeight = 520;

            divContainer.style.cssText += "height: " + divContainerHeight + "px;";

            let heightParameter = Rotator.webComponentParameters()[0].height;
            if (heightParameter != null && heightParameter > 0)
                divContainer.style.cssText += "height: " + heightParameter + "px;";

            let widthParameter = Rotator.webComponentParameters()[0].width;
            if (widthParameter != null && widthParameter > 0)
                divContainer.style.cssText += "width: " + widthParameter + "px;";

            divContainer.style.cssText += "left: Calc(50% - " + (puma(divContainer).width() / 2) + "px)";
            divContainer.style.cssText += "top: Calc(50% - " + (puma(divContainer).height() / 2) + "px)";
        }

        if (!Rotator.isModal())
            puma(divBackground).hide();

        //#region Open at start
        if (Rotator.webComponentParameters()[0].open === true)
        {
            divContainer.style.cssText += "top: 0px; left: 0px; display: block !important;";

            if (!Rotator.isMobile())
                divContainer.style.cssText += "position: relative;";

            if (Rotator.isModal())
                divBackground.style.cssText += "display: block;";

            if (Rotator.webComponentParameters()[0].fullscreen === true)
            {
                if (Rotator.isMobile() && Rotator.webComponentParameters()[0].open !== true)
                    vrshared.UtilityManager().enterFullScreen(divContainer);
            }
        }
        //#endregion

        if (!Rotator.isMobile() && Rotator.webComponentParameters()[0].position != null)
        {
            divContainer.style.cssText += "top: unset; right: unset; bottom: unset; left: unset;";

            if (Rotator.webComponentParameters()[0].position.top != null)
                divContainer.style.cssText += "top: " + Rotator.webComponentParameters()[0].position.top + "px;";

            if (Rotator.webComponentParameters()[0].position.right != null)
                divContainer.style.cssText += "right: " + Rotator.webComponentParameters()[0].position.right + "px;";

            if (Rotator.webComponentParameters()[0].position.bottom != null)
                divContainer.style.cssText += "bottom: " + Rotator.webComponentParameters()[0].position.bottom + "px;";

            if (Rotator.webComponentParameters()[0].position.left != null)
                divContainer.style.cssText += "left: " + Rotator.webComponentParameters()[0].position.left + "px;";
        }

        if (!Rotator.isMobile())
        {
            puma(divContainer).on("mouseenter", (e: JQuery.MouseEnterEvent) =>
            {
                if (Rotator.webComponentParameters()[0].brutalStopScroll === true)
                {
                    puma("html").css("height", "Calc(100% - 33px)");
                    puma("body").css({ "height": "100%", "overflow": "hidden" });
                }
            });

            puma(divContainer).on("mouseleave", (e: JQuery.MouseLeaveEvent) =>
            {
                if (Rotator.webComponentParameters()[0].brutalStopScroll === true)
                {
                    puma("html").css("height", "inherit");
                    puma("body").css({ "height": "inherit", "overflow": "inherit" });
                }
            });
        }
        //#endregion

        let header = this.createHeader();
        puma(divContainer).append(header);

        if (!Rotator.isMobile() && Rotator.webComponentParameters()[0].draggable === true)
            puma(header).vrDrag(divContainer);

        let container = this.createContainer();
        puma(divContainer).append(container);

        Menu.initialize();

        let bookingParameters = new BookingParameters();
        Rotator.bookingParameters(bookingParameters);

        let appointmentId = Number(vrshared.CookieManager().getCookie("appointmentId"));
        if (appointmentId == null || appointmentId == 0)
        {
            let appointmentIdFromUrl = vrshared.UtilityManager().getUrlParameter("appointmentId");
            if (appointmentIdFromUrl != null && appointmentIdFromUrl.length > 0)
                appointmentId = Number(appointmentIdFromUrl);
        }

        if (appointmentId != null && appointmentId != 0)
        {
            let parameters = new AppointmentDetailParameter();
            parameters.appointmentId = appointmentId;

            Rotator.goToMainPage();
            window.setTimeout(() => Rotator.page(PageEnum.AppointmentDetail, parameters), 200);
            vrshared.CookieManager().deleteCookie("appointmentId");
        }
        else
        {
            if (Rotator.sailor())
                Rotator.page(PageEnum.SailorHome);
            else if ((Rotator.webComponentParameters()[0].vrSessionId == null || Rotator.webComponentParameters()[0].vrSessionId == "")
                && (Rotator.configuration().appLoginRequired == AppLoginModeEnum.Always
                    || !Rotator.configuration().appBookingAppointments || Rotator.mode() == WebComponentModeEnum.OnlyPrivateArea))
                Rotator.page(PageEnum.Login);
            else
                Rotator.goToMainPage();
        }
    }

    private createHeader(): HTMLElement
    {
        let header = puma("<div class='vettorePatientHeader'></div>")[0] as HTMLElement;

        let textColor = Rotator.isMobile() ? "#FFF" : Rotator.color();
        header.style.cssText += "color: " + textColor + ";";

        let backgroundColor = Rotator.isMobile() ? Rotator.color() : "#FFF";
        header.style.cssText += "background-color: " + backgroundColor + ";";

        let btnBurger = vr.createButton(
            {
                icon: vr.IconClassicLight.Burger,
                css: "font-size: 2em; border: none; padding-left: 0px; padding-right: 0px; height: 32px; display: flex; background-color: " + backgroundColor + ";",
                colorSettings: { textColor: textColor },
                onClick: (e) => Menu.visible(!Menu.visible())
            }, header, null, "vettorePatientBurgerButton");

        if (Rotator.doctorMode())
            btnBurger.hide();

        vr.createButton(
            {
                icon: vr.IconClassicLight.ChevronLeft,
                css: "font-size: 1.8em; border: none; padding-left: 0px; padding-right: 0px; height: 28px; display: flex; background-color: " + backgroundColor + ";",
                colorSettings: { textColor: textColor },
                visible: false,
                onClick: (e) => Rotator.back()
            }, header, null, "vettorePatientBackButton");

        //#region Title & Logo
        let divTitleLogo = vr.div(header, { class: "vettorePatientDivTitleLogo" });
        vr.createImage({
            class: "vettorePatientLogoHeader",
            base64: true,
            value: Rotator.configuration().appLogo,
            width: "100%",
            position: vr.ImagePositionTypeEnum.Center,
            cssContainer: "max-height: 80px; margin-bottom: 10px;",
            height: 80,
            visible: Rotator.isApp() && Rotator.configuration().appCustomHomeAppMobile && Rotator.configuration().appLogo != null && Rotator.configuration().appLogo != ""
        }, divTitleLogo)

        vr.createLabel(
            {
                text: "PRENOTA",
                fontSize: "1.5em",
                align: vr.TextAlignEnum.Center,
                width: "100%"
            }, divTitleLogo, null, "vettorePatientTitle");
        //#endregion

        let closeButton = vr.createButton(
            {
                icon: vr.IconClassicLight.Close,
                css: "font-size: 2em; border: none; padding-left: 0px; padding-right: 0px; height: auto; background-color: " + backgroundColor + ";",
                colorSettings: { textColor: textColor },
                onClick: (e) =>
                {
                    puma(".vrPatientBackground").hide();
                    puma(".vrPatientDivContainer").hide();

                    Rotator.confirmationParameters(null);
                    Rotator.bookingParameters(null);
                    Rotator.goToMainPage();

                    if (Rotator.webComponentParameters()[0].fullscreen === true && Rotator.isMobile())
                        vrshared.UtilityManager().exitFullScreen();
                }
            }, header, null, "vettorePatientClose");

        if (Rotator.webComponentParameters()[0].open === true)
            closeButton.container().style.cssText += "visibility: hidden;";

        return header;
    }

    private createContainer(): HTMLElement
    {
        let container = puma("<div class='vettorePatientContainer'></div>")[0] as HTMLElement;
        puma(container).on("mousewheel DOMMouseScroll", (e: any) =>
        {
            let scrollTo = null;
            if (e.type === 'mousewheel')
                scrollTo = (e.originalEvent.wheelDelta * -1);
            else if (e.type === 'DOMMouseScroll')
                scrollTo = 40 * e.originalEvent.detail;

            if (scrollTo)
            {
                e.preventDefault();
                puma(e.currentTarget).scrollTop(scrollTo + puma(e.currentTarget).scrollTop());
            }
        });
        return container;
    }
}

//Define WebComponent's tag
customElements.define("vr-patient", VettorePatient);

//#region Classes
export class WebComponentConfig
{
    public plantId: number;
    public url: string;
    public authKey: string;
    public modal: boolean;
    public draggable: boolean;
    public brutalStopScroll: boolean;
    public open: boolean;
    public height: number;
    public width: number;
    public fullscreen: boolean;
    public jqueryVariable: any;
    public css: string;
    public mode: WebComponentModeEnum;

    public position: WebComponentPosition;
    public buttonSettings: WebComponentButtonSettings;

    public specificActivityTypeGroupId: number;
    public specificActivityTypeGroupIdList: number[];
    public specificActivityTypeId: number;
    public specificActivityTypeIdList: number[];
    public specificWorkplaceId: number;
    public specificDoctorId: number;
    public specificDoctorIdList: number[];

    // Not exposed
    public source: WebComponentSourceModeEnum;
    public sailor: boolean;
    public patientId: number;
    public createFollowUp: boolean;
    public vrSessionId: string;
}

export enum WebComponentModeEnum
{
    AllModules,
    OnlyPrivateArea,
    OnlyBooking
}

class WebComponentPosition
{
    top: number;
    right: number;
    bottom: number;
    left: number;
}

export enum WebComponentSourceModeEnum
{
    Crm,
    Vettore,
    CrmLink,
    Test
}

class WebComponentButtonSettings
{
    text?: string;
    css?: string;
    cssContainer?: string;
    icon?: vr.IconClassicLight;
    iconCss?: string;
}

class GetConfigurationRequest extends WebApiRequestBase
{
    public method: string = "/api/LoginWebApi/GetConfiguration";
    public configurations: WebComponentConfig[];
}

class GetConfigurationResponse extends WebApiResponseBase
{
    public configuration: Configuration;
}
//#endregion