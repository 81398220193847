import { ActivityTypeItem, ReportItem, ResourceResponse, WorkplaceResponse } from "../../src/shared/common";
import { WebApiRequestBase, WebApiResponseBase } from "../../src/shared/webApi";
import { PageEnum, puma, Rotator } from "../../src/rotator";
import { Support } from "../../src/support";
import { AppointmentDetail, AppointmentDetailParameter } from "./appointmentDetail";

export class AppointmentList
{
	private static _container: HTMLElement;
	private static _appointments: AppointmentItem[];
	private static _swtFilter: vr.Switch;
	private static _divAppointments: HTMLElement;

	static initialize()
	{
		this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
		Rotator.title("APPUNTAMENTI");

		Support.checkLogin(() => this.getAppointments((e) => 
		{
			this._appointments = e.appointments;
			if (!Rotator.configuration().appShowAppointmentAborted)
				this._appointments = this._appointments.filter(k => k.statusEnum != ActivityStatusEnum.Aborted);

			puma(".forward").empty();

			this.createFilter();
			this._divAppointments = vr.div(this._container);
			this.drawAppointments();
		}), null, () => Rotator.page(PageEnum.AppointmentList));
	}

	private static createFilter()
	{
		let divFilter = vr.div(this._container, { css: "padding: 5px; margin-bottom: 10px; text-align: center; background-color: " + Rotator.color() + "30;" });
		this._swtFilter = vr.createSwitch({
			checked: Rotator.filterAppointmentOnPast(),
			labelOff: "Prossimi",
			labelOn: "Passati/disdetti",
			onChange: (e) => this.drawAppointments()
		}, divFilter);
	}

	private static drawAppointments()
	{
		Rotator.filterAppointmentOnPast(this._swtFilter.checked());

		let appointments: AppointmentItem[] = [];
		if (this._swtFilter.checked())
			appointments = this._appointments.filter(k => (new Date(k.from).getTime() < new Date().getTime()) || (k.statusEnum == ActivityStatusEnum.Aborted || k.statusEnum == ActivityStatusEnum.Terminated));
		else
			appointments = this._appointments.filter(k => new Date(k.from).getTime() >= new Date().getTime() && k.statusEnum != ActivityStatusEnum.Terminated && k.statusEnum != ActivityStatusEnum.Aborted);

		puma(this._divAppointments).empty();
		if (appointments.length == 0)
		{
			let divNoRecords = vr.div(this._divAppointments, { class: "vrPatientDivSlot" });
			vr.createLabel({
				text: (this._swtFilter.checked()) ? "Non ci sono appuntamenti passati" : "Non ci sono prossimi appuntamenti",
				width: "100%",
				align: vr.TextAlignEnum.Center
			}, divNoRecords);
		}

		for (let appointment of appointments)
		{
			let divAppointment = AppointmentDetail.drawAppointmentDetail(appointment, this._divAppointments);
			vr.createButton(
				{
					text: "Mostra dettagli",
					colorSettings: { textColor: Rotator.color() },
					css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
					cssContainer: "margin-top: 10px",
					width: "100%",
					onClick: (e) =>
					{
						let parameter = new AppointmentDetailParameter();
						parameter.appointment = appointment;
						Rotator.page(PageEnum.AppointmentDetail, parameter);
					}
				}, divAppointment);
		}
	}

	static getAppointments(callback?: (e: GetAppointmentsResponse) => void, appointmentId?: number)
	{
		let request = new GetAppointmentsRequest();
		request.plantIdList = [Rotator.configuration().plant.id];
		request.appointmentId = appointmentId;
		request.call(
			{
				success: (response: GetAppointmentsResponse) =>
				{
					if (callback != null)
						callback(response);
				},
				mode: vrshared.WebApiModeEnum.Async,
				loader: Rotator.container()
			});
	}
}

//#region Classes
class GetAppointmentsRequest extends WebApiRequestBase
{
	public method: string = "/api/PrivateAreaWebApi/GetAppointments";
	public plantIdList: number[];
	public appointmentId?: number;
}

class GetAppointmentsResponse extends WebApiResponseBase
{
	public appointments: AppointmentItem[];
}

export class AppointmentItem
{
	public appointmentId: number;
	public patientId: number;
	public patientName: string;
	public activityTypeId: number;
	public activityTypeItem: ActivityTypeItem;
	public doctorId: number;
	public doctorName: string;
	public doctor: ResourceResponse;
	public workplaceId: number;
	public workplaceName: string;
	public workplace: WorkplaceResponse;
	public activityTypeName: string;
	public confirmed: boolean;
	public from: Date;
	public to: Date;
	public minutesDuration: number;
	public discountRate: number;
	public price: number;
	public status: string;
	public statusEnum: ActivityStatusEnum;
	public note: string;
	public activityNote: string;
	public reports: ReportItem[];
	public billingStatus: BillingStatus;
	public customerConfirmationStatusEnum: AppointmentConfirmationStatusEnum;
}

export enum AppointmentConfirmationStatusEnum
{
	ConfirmedByCustomer = 10,
	RejectedByCustomer = 20,
	Unknown = 30
}

class BillingStatus
{
	public invoiceId?: number | null;
	public cashAdvanceIdList: number[];
	public toBill: number;
	public billableOnline: boolean;
}

export enum ActivityStatusEnum
{
	Unknown = 0,
	Aborted = 1,
	Quote = 10,
	Contract = 20,
	Terminated = 50
}
//#endregion