import { Rotator, puma, PageEnum, AppLoginModeEnum } from "../../src/rotator";
import { TimeslotListParameters } from "./timeslotList";
import { GenderEnum, OnlineBookingModeEnum, OnlineBookingPaymentModeEnum } from "../../src/shared/common";
import { MandatoryFieldInfo, OnlineBookingMandatoryFieldTypeEnum, PreregisterResponse, PreregisterSettings, Register, RegisterParameters, RegisterRequest, RegisterResponse, RegisterResult } from "../../src/login/register";
import { PriceTypeEnum, Support } from "../../src/support";
import { VrManager } from "../../src/shared/vrManager";

export class Confirmation1
{
    private static _container: HTMLElement;
    private static _txtName: vr.TextBox;
    private static _txtSurname: vr.TextBox;
    private static _txtPhoneNumber: vr.TextBox;
    private static _txtEmail: vr.TextBox;
    private static _chkPrivacy: vr.CheckBox;
    private static _chkPrivacyCommercial: vr.CheckBox;
    private static _fields: MandatoryFieldInfo[];

    static initialize(parameters: TimeslotListParameters)
    {
        if (parameters != null)
            Rotator.confirmationParameters(parameters);

        this._container = puma("<div class='forward'></div>").appendTo(Rotator.container())[0];
        this._container.style.cssText += "position: relative;";
        Rotator.title("CONFERMA");

        if (Rotator.doctorMode())
        {
            Rotator.pageHistory().pop();
            Rotator.page(PageEnum.Confirmation2);
        }
        else
        {
            Support.checkLogin(() => 
            {
                Rotator.pageHistory().pop();
                Rotator.page(PageEnum.Confirmation2);
            }, () => 
            {
                this.createControls();
            });
        }
    }

    static createControls()
    {
        this.drawSummary(vr.div(this._container, { class: "vrPatientDivSlot" }));

        let divControls = vr.div(this._container, { css: "padding: 5px;" });

        vr.createButton({
            label: "Prenota la tua visita. Sei un utente registrato?",
            labelSettings: { position: vr.PositionEnum.Top, colorSettings: { textColor: "#000" } },
            text: "Accedi",
            width: "100%",
            colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
            css: "box-shadow: 0 1px 3px " + Rotator.color() + "; margin-top: 5px;",
            class: "vrButtonBookingLogin vrButtonPatient",
            onClick: (e) => 
            {
                Rotator.page(PageEnum.Login);
            }
        }, divControls);

        vr.createLabel({
            text: "Oppure",
            width: "100%",
            cssContainer: "margin-bottom: 10px; margin-top: 10px;"
        }, divControls);

        if (!Rotator.sailor())
        {
            //#region Name if no login
            this._txtName = vr.createTextBox({
                label: "Nome",
                width: "100%",
                labelSettings: { colorSettings: { textColor: Rotator.color() } },
                visible: false
            }, divControls);

            this._txtSurname = vr.createTextBox({
                label: "Cognome",
                width: "100%",
                labelSettings: { colorSettings: { textColor: Rotator.color() } },
                visible: false
            }, divControls);

            if (Rotator.bookingParameters().activityType.doNotRequireLogin || Rotator.configuration().appLoginRequired == AppLoginModeEnum.Never)
            {
                this._txtName.show();
                this._txtSurname.show();
            }
            //#endregion

            this._txtPhoneNumber = vr.createTextBox({
                label: "Cellulare",
                width: "100%",
                mode: vr.TextModeEnum.Numeric,
                align: vr.TextAlignEnum.Left,
                labelSettings: { colorSettings: { textColor: Rotator.color() } }
            }, divControls);

            this._txtEmail = vr.createTextBox({
                label: "Email",
                width: "100%",
                mode: vr.TextModeEnum.Mail,
                labelSettings: { colorSettings: { textColor: Rotator.color() } },
                visible: false
            }, divControls);

            Register.getMandatoryFields((e) =>
            {
                this._fields = e.fields;
                if (e.fields.filter(k => k.fieldType == OnlineBookingMandatoryFieldTypeEnum.EMail)[0].isVisible)
                    this._txtEmail.show();
            });

            //#region Privacy
            let divPrivacy = vr.div(divControls, { css: "text-align: left; display: flex; margin-top: 10px;" });
            this._chkPrivacy = vr.createCheckBox(
                {
                }, divPrivacy);

            let privacyLabelText = Rotator.configuration().appPrivacyLabelText;
            if (privacyLabelText == null || privacyLabelText == "")
                privacyLabelText = "Ho letto e accetto la [privacy] (*)";

            if (privacyLabelText.includes("["))
            {
                let textToHighlight = privacyLabelText.substring(
                    privacyLabelText.lastIndexOf("[") + 1,
                    privacyLabelText.lastIndexOf("]")
                );
                privacyLabelText = privacyLabelText.replace(/\[.*\]/, "<span style='color: " + Rotator.color() + "'>" + textToHighlight + "</span>")
            }
            else
            {
                privacyLabelText = privacyLabelText.replace("privacy", "<span style='color: " + Rotator.color() + "'>privacy</span>");
                privacyLabelText = privacyLabelText.replace("Privacy", "<span style='color: " + Rotator.color() + "'>Privacy</span>");
            }

            vr.createLabel({
                text: privacyLabelText,
                fontSize: "1.1em",
                cssContainer: "top: -1px;",
                width: "Calc(100% - 35px)",
                onClick: (e) => 
                {
                    wndPrivacy.open(null, false, { top: Rotator.container().scrollTop });
                }
            }, divPrivacy);

            let wndPrivacy = vr.createWindow(
                {
                    title: "Privacy",
                    maximized: false,
                    content: Rotator.configuration().privacyText,
                    css: "overflow-y: auto;",
                    cssContainer: "width: 100% !important; height: " + (Rotator.container().clientHeight - 20) + "px !important; position: absolute !important; left: 0px !important;",
                    draggable: false,
                    modal: false,
                    hideCloseIcon: true,
                    footer:
                        [
                            { type: vr.WindowFooterItemTypeEnum.Close, text: "Chiudi" },
                            {
                                type: vr.WindowFooterItemTypeEnum.Ok, text: "Accetta", onClick: (e) => 
                                {
                                    wndPrivacy.close();
                                    this._chkPrivacy.checked(true);
                                }
                            }
                        ]
                }, Rotator.shadowRoot() as any);
            puma(wndPrivacy.container()).appendToPuma(this._container);
            //#endregion

            if (Rotator.configuration().privacyCommercial && !Rotator.configuration().appHidePrivacyCommercial)
            {
                let privacyCommercialLabelText = Rotator.configuration().appPrivacyCommercialLabelText;
                if (privacyCommercialLabelText == null || privacyCommercialLabelText == "")
                    privacyCommercialLabelText = "Presto il consenso al trattamento dei dati personali relativi al mio stato di salute";

                this._chkPrivacyCommercial = vr.createCheckBox({
                    text: privacyCommercialLabelText,
                    width: "100%",
                    cssContainer: "font-size: 0.9em;"
                }, divControls);
            }

            if (Rotator.bookingParameters().activityType.paymentMode != OnlineBookingPaymentModeEnum.Required
                && (Rotator.bookingParameters().activityType.doNotRequireLogin || Rotator.configuration().appLoginRequired == AppLoginModeEnum.Never))
            {
                vr.createButton({
                    text: "Prosegui senza registrarti",
                    colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
                    css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
                    cssContainer: "margin-top: 10px;",
                    width: "100%",
                    class: "vrButtonBookingNoRegister vrButtonPatient",
                    onClick: (e) => this.preregister(() => 
                    {
                        let request = new RegisterRequest();
                        request.name = this._txtName.value();
                        request.surname = this._txtSurname.value();
                        request.phone = String(this._txtPhoneNumber.value());
                        request.mail = this._txtEmail.value();
                        request.privacy = this._chkPrivacy.checked();
                        request.privacyCommercial = (this._chkPrivacyCommercial != null) ? this._chkPrivacyCommercial.checked() : false;
                        request.createUserAccount = false;
                        request.throwException = false;
                        request.call(
                            {
                                success: (response: RegisterResponse) => 
                                {
                                    if (response.registerResult == RegisterResult.PatientCreated || response.registerResult == RegisterResult.PatientFound)
                                    {
                                        Rotator.confirmationParameters()!.subjectId = response.customerId;
                                        Rotator.page(PageEnum.Confirmation2);
                                    }
                                    else
                                        Register.manageRegisterResult(response.registerResult, response.ExceptionMessage);
                                },
                                loader: Rotator.container()
                            });
                    }, true)
                }, divControls);
            }
        }

        if (!Rotator.configuration().appHideRegistrationIntoConfirmationPage)
        {
            vr.createButton({
                text: "Registrati",
                colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
                css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
                cssContainer: (Rotator.sailor()) ? "" : "margin-top: 10px;",
                width: "100%",
                class: "vrButtonBookingRegister vrButtonPatient",
                onClick: (e) => 
                {
                    if (Rotator.sailor())
                    {
                        Rotator.page(PageEnum.SailorRegister);
                    }
                    else if (Rotator.configuration().appPreregisterSubject)
                    {
                        this.preregister((e) => 
                        {
                            let parameters = new RegisterParameters();
                            parameters.preregisterId = e!.preregisterId;
                            parameters.customerName = this._txtName.value();
                            parameters.customerSurname = this._txtSurname.value();
                            parameters.customerMail = this._txtEmail.value();
                            parameters.customerPhone = String(this._txtPhoneNumber.value());
                            Rotator.page(PageEnum.Register, parameters);
                        });
                    }
                    else
                    {
                        let error = this.checkRequiredFields();
                        if (error)
                            return;

                        let parameters = new RegisterParameters();
                        parameters.customerName = this._txtName.value();
                        parameters.customerSurname = this._txtSurname.value();
                        parameters.customerMail = this._txtEmail.value();
                        parameters.customerPhone = String(this._txtPhoneNumber.value());
                        Rotator.page(PageEnum.Register, parameters);
                    }
                }
            }, divControls);
        }
    }

    private static preregister(callback?: (e?: PreregisterResponse) => void, checkNameIfNoLogin = false)
    {
        //#region Check
        let error = this.checkRequiredFields(checkNameIfNoLogin);
        if (error)
            return;
        //#endregion

        if (!checkNameIfNoLogin)
        {
            let preregisterSettings = new PreregisterSettings();
            preregisterSettings.name = this._txtName.value();
            preregisterSettings.surname = this._txtSurname.value();
            preregisterSettings.phoneNumber = String(this._txtPhoneNumber.value());
            preregisterSettings.emailAddress = this._txtEmail.value();
            preregisterSettings.privacyForMedicalBooking = this._chkPrivacy.checked();
            preregisterSettings.privacyForCommercialUse = (this._chkPrivacyCommercial != null) ? this._chkPrivacyCommercial.checked() : false;
            Register.preregister(preregisterSettings, callback);
        }
        else
        {
            if (callback != null)
                callback();
        }
    }

    private static checkRequiredFields(checkNameIfNoLogin = false)
    {
        let error = false;
        if ((Rotator.bookingParameters().activityType.doNotRequireLogin || Rotator.configuration().appLoginRequired == AppLoginModeEnum.Never) && checkNameIfNoLogin)
        {
            if (this._txtName.isEmpty())
            {
                this._txtName.error(undefined, undefined, undefined, vr.ErrorHideModeEnum.Never);
                error = true;
            }
            else
                this._txtName.hideError();

            if (this._txtSurname.isEmpty())
            {
                this._txtSurname.error(undefined, undefined, undefined, vr.ErrorHideModeEnum.Never);
                error = true;
            }
            else
                this._txtSurname.hideError();
        }

        if (this._txtPhoneNumber.isEmpty())
        {
            this._txtPhoneNumber.error(undefined, undefined, undefined, vr.ErrorHideModeEnum.Never);
            error = true;
        }
        else
            this._txtPhoneNumber.hideError();

        let fieldEmail = this._fields.filter(k => k.fieldType == OnlineBookingMandatoryFieldTypeEnum.EMail)[0];
        if (fieldEmail != null && this._txtEmail.isEmpty() && fieldEmail.isVisible && fieldEmail.isMandatory)
        {
            this._txtEmail.error(undefined, undefined, undefined, vr.ErrorHideModeEnum.Never);
            error = true;
        }
        else
            this._txtEmail.hideError();

        //#region Check mail
        let email = this._txtEmail.value<string>();
        if (email != "" && email != null)
        {
            let isValidEmail = vr.isValidEmail(email);
            if (!isValidEmail)
            {
                VrManager.notify("Inserire una mail valida");
                this._txtEmail.error(undefined, undefined, undefined, vr.ErrorHideModeEnum.Never);
                error = true;
            }
        }
        //#endregion

        if (!this._chkPrivacy.checked())
        {
            this._chkPrivacy.error();
            error = true;
        }
        return error;
    }

    static drawSummary(divSummary: HTMLElement)
    {
        let divResourceInfo = vr.div(divSummary, { css: "display: flex;" });

        let resource = Rotator.confirmationParameters()!.resource;
        if (resource != null && resource.id != 0 && !Rotator.sailor() && !Rotator.bookingParameters().activityType.onlineBookingDoNotChooseDoctor)
        {
            //#region Image
            if (resource.imageBase64 != null && resource.imageBase64 != "")
            {
                vr.createImage(
                    {
                        value: resource.imageBase64,
                        base64: true,
                        width: 70,
                        height: 70,
                        cssContainer: "justify-content: center; margin-right: 10px;",
                        css: "border-radius: 35px;"
                    }, divResourceInfo);
            }
            else
                vr.icon(vr.IconClassicLight.UserDoctor, divResourceInfo, { css: "justify-content: center; margin-right: 10px; font-size: 5em; color: " + Rotator.color() + ";" });
            //#endregion

            //#region Info
            let divInfo = vr.div(divResourceInfo, { css: "display: inline-block; width: Calc(100% - 75px); min-height: 70px; text-align: left;" });

            let title = (resource.title != null && resource.title != "") ? resource.title : ((resource.genderEnum == GenderEnum.Female) ? "Dott.ssa" : "Dott.");
            vr.createLabel(
                {
                    text: "<span style='font-size: 0.8em; font-weight: normal;'>" + title + "</span> " + resource.name,
                    width: "100%",
                    css: "font-weight: 600;",
                    noBr: true
                }, divInfo);

            if (resource.qualification != null && resource.qualification.length > 0)
            {
                vr.createLabel(
                    {
                        text: resource.qualification,
                        width: "100%",
                        css: "font-size: 1em;"
                    }, divInfo);
            }

            if (resource.rating != null && resource.rating.values.length > 0)
            {
                vr.createRating(
                    {
                        cssContainer: "margin-top: 7px;",
                        max: resource.rating.total,
                        value: resource.rating.values.sum() / resource.rating.values.length,
                        total: resource.rating.values.length,
                        enable: false
                    }, divInfo);
            }
            //#endregion
        }

        //#region ActivityType
        let divActivityType = vr.div(divSummary, { css: (resource != null && resource.id != 0 && !Rotator.sailor() && !Rotator.bookingParameters().activityType.onlineBookingDoNotChooseDoctor) ? "margin-top: 10px;" : "" });
        vr.icon(vr.IconClassicLight.FileLines, divActivityType, { css: "margin-right: 5px; position: relative; top: -1px; color: " + Rotator.color() });

        let displayPrice = Support.displayPrice(Rotator.confirmationParameters()!.price, PriceTypeEnum.ActivityType, true);
        vr.createLabel(
            {
                text: Rotator.bookingParameters().activityType.onlineBookingName + displayPrice,
                width: "Calc(100% - 25px)"
            }, divActivityType);
        //#endregion

        //#region Workplace
        let divWorkplace = vr.div(divSummary, { css: "margin-top: 5px;" });
        vr.icon(vr.IconClassicLight.MapPin, divWorkplace, { css: "margin-right: 5px; position: relative; top: -1px; color: " + Rotator.color() });
        let workplace = Rotator.confirmationParameters()!.workplace;
        let address = Support.getAddressName(workplace);
        vr.createLabel(
            {
                text: address + "<br /><span style='font-size: 0.9em; color: #737373'>" + workplace.name + "</span>",
                width: "Calc(100% - 25px)"
            }, divWorkplace);
        //#endregion

        //#region Date
        let divDate = vr.div(divSummary, { css: "margin-top: 5px;" });
        vr.icon(vr.IconClassicLight.Calendar, divDate, { css: "margin-right: 5px; position: relative; top: -1px; color: " + Rotator.color() });

        let fromHours = String(Rotator.confirmationParameters()!.timeslot.hourFrom.hours).padStart(2, "0");
        let fromMinutes = String(Rotator.confirmationParameters()!.timeslot.hourFrom.minutes).padStart(2, "0");
        let date = new Date(Rotator.confirmationParameters()!.timeslot.day).toItalyLongString();

        vr.createLabel(
            {
                text: date + " alle ore " + fromHours + ":" + fromMinutes,
                width: "Calc(100% - 25px)"
            }, divDate);
        //#endregion

        //#region Payment required
        if (Rotator.bookingParameters().activityType.paymentMode == OnlineBookingPaymentModeEnum.Required)
        {
            vr.createLabel(
                {
                    text: (String.isNotNullOrEmpty(Rotator.configuration().appMessagePaymentRequired)) ? Rotator.configuration().appMessagePaymentRequired
                        : "<b><font color='" + vr.Color.red500 + "'>Richiesto pagamento online</font></b>",
                    width: "100%",
                    cssContainer: "margin-top: 10px;"
                }, divDate);
        }
        //#endregion
    }
}