import { WebApiRequestBase, WebApiResponseBase } from "./shared/webApi";
import { Rotator, PageEnum, puma, OnlineBookingPriceZeroEnum } from "./rotator";
import { Login, LoginParameters } from "./login/login";
import { Menu } from "./shared/menu";
import { WorkplaceResponse } from "./shared/common";
import { VrManager } from "./shared/vrManager";

declare var gtag: any;
declare var fbq: any;

export class Support
{
	static checkLogin(loggedCallback?: Function | null, notLoggedCallback?: Function | null, afterLoginCallback?: Function | null, async = true)
	{
		let request = new CheckLoginRequest();
		request.call(
			{
				success: (response: CheckLoginResponse) =>
				{
					if (response.logged)
					{
						//#region Logged
						if (loggedCallback == null)
							Rotator.goToMainPage();
						else
							loggedCallback();

						Menu.manageState(true);
						//#endregion
					}
					else
					{
						let credentials = vrshared.CookieManager().getCookie("credentials") as any;
						if (credentials != null && credentials.username != null && credentials.password != null && credentials.username.length > 0 && credentials.password.length > 0)
						{
							Login.login(credentials.username, credentials.password, () => 
							{
								//#region Logged
								if (loggedCallback == null)
									Rotator.goToMainPage();
								else
									loggedCallback();

								Menu.manageState(true);
								//#endregion
							},
								() =>
								{
									//#region Not logged
									if (notLoggedCallback == null)
										Rotator.goToMainPage();
									else
										notLoggedCallback();

									Menu.manageState(false);
									//#endregion
								}, true);
						}
						else
						{
							//#region Not logged
							if (notLoggedCallback == null && afterLoginCallback != null)
							{
								Rotator.inBookingFlow(false);
								let loginParameters = new LoginParameters();
								loginParameters.successCallback = afterLoginCallback;
								Rotator.page(PageEnum.Login, loginParameters);
							}
							else if (notLoggedCallback != null)
								notLoggedCallback();

							Menu.manageState(false);
							//#endregion
						}
					}
				},
				mode: (async) ? vrshared.WebApiModeEnum.Async : vrshared.WebApiModeEnum.Sync,
				loader: false
			});
	}

	static logout(callback?: Function)
	{
		let request = new LogoutRequest();
		request.call(
			{
				success: (response: LogoutResponse) => 
				{
					VrManager.notify("Logout effettuato!");

					Rotator.bookingParameters(null);
					Rotator.confirmationParameters(null);
					Rotator.clearPageHistory();
					//vrshared.CookieManager().deleteCookie("sessionId");

					if (callback != null)
						callback();
					else
						Rotator.goToMainPage();

					Menu.manageState(false);
				},
				loader: false
			});
	}

	static getLocation()
	{
		if (navigator.geolocation)
		{
			navigator.geolocation.getCurrentPosition(
				(position) => 
				{
					Rotator.bookingParameters().latitude = position.coords.latitude;
					Rotator.bookingParameters().longitude = position.coords.longitude;
				},
				(error) =>
				{
					switch (error.code)
					{
						case error.PERMISSION_DENIED:
							console.log("User denied the request for Geolocation.");
							break;
						case error.POSITION_UNAVAILABLE:
							console.log("Location information is unavailable.")
							break;
						case error.TIMEOUT:
							console.log("The request to get user location timed out.")
							break;
						default:
							console.log("An unknown error occurred when get location.")
							break;
					}
				});
		}
	}

	static getFileBase64(fileId: number, callback: (base64: string) => void, loader: boolean | HTMLElement | JQuery | string)
	{
		let request = new DownloadFileRequest();
		request.fileId = fileId;
		request.call(
			{
				success: (response: DownloadFileResponse) =>
				{
					callback(response.base64);
				},
				mode: vrshared.WebApiModeEnum.Async,
				loader: Rotator.container()
			});
	}

	static downloadFile(fileId: number, fileName?: string, mimeType: FileTypeEnum = FileTypeEnum.General)
	{
		this.getFileData(fileId, (e) =>
		{
			vrshared.UtilityManager().downloadFile(e.base64, fileName, mimeType, !Rotator.isApp());
		});
	}

	static getFileData(fileId: number, callback?: (e: FileDataEvent) => void)
	{
		let request = new DownloadFileRequest();
		request.fileId = fileId;
		request.call(
			{
				success: (response: DownloadFileResponse) =>
				{
					if (response.base64 == null || response.base64.length == 0)
					{
						vr.notifyError("Impossibile scaricare o visualizzare il file; risulta vuoto o danneggiato");
						return;
					}

					if (callback != null)
						callback(response);
				},
				mode: vrshared.WebApiModeEnum.Async,
				loader: Rotator.container()
			});
	}

	static downloadDocument(documentId: number, fileName?: string)
	{
		this.getDocumentData(documentId, (base64) =>
		{
			if (base64 == null || base64.length == 0)
			{
				vr.notifyError("Impossibile scaricare o visualizzare il documento; risulta vuoto o danneggiato");
				return;
			}

			vrshared.UtilityManager().downloadFile(base64, fileName, FileTypeEnum.Pdf, !Rotator.isApp());
		});
	}

	static getDocumentData(documentId: number, callback?: (e: string) => void)
	{
		let request = new DownloadDocumentRequest();
		request.documentId = documentId;
		request.call(
			{
				success: (response: DownloadDocumentResponse) =>
				{
					if (response.base64 == null || response.base64.length == 0)
					{
						vr.notifyError("Impossibile scaricare o visualizzare il documento; risulta vuoto o danneggiato");
						return;
					}

					if (callback != null)
						callback(response.base64);
				},
				mode: vrshared.WebApiModeEnum.Async,
				loader: Rotator.container()
			});
	}

	static fixNotifyPosition()
	{
		let divContainer = puma(".vrPatientDivContainer")[0];
		if (divContainer != null)
		{
			puma(".vrNotifierAreaTopCenter").appendToPuma(divContainer);
			puma(".vrNotifierAreaTopCenter").css({ "top": "0px", "left": "0px", "position": "absolute", "width": (Rotator.isMobile()) ? "Calc(100% - 20px)" : "100%" });
			puma(".vrNotifierContainer").css({ "left": "0px", "top": "0px" });
		}
	}

	static addCss(css: string)
	{
		let style = document.createElement("style");
		let cssNode: Text = document.createTextNode(css);
		style.appendChild(cssNode);
		Rotator.shadowRoot().appendChild(style);
	}

	static getAddressName(workplace: WorkplaceResponse)
	{
		let address = "";
		if (workplace != null)
		{
			let street = (workplace.street != null) ? workplace.street : "";
			let zip = (workplace.zip != null) ? (" " + workplace.zip) : "";
			let city = (workplace.city != null) ? (" - " + workplace.city) : "";
			let province = (workplace.province != null) ? (" (" + workplace.province + ")") : "";
			address = street + zip + city + province;
		}

		if (address === "") address = "Dettaglio sede non impostato";
		return address;
	}

	static displayPrice(price: number, type: PriceTypeEnum, colored = false, spaceBefore = true)
	{
		let displayPrice = "";
		if (type == PriceTypeEnum.Appointment)
		{
			if (!Rotator.configuration().appShowAppointmentPrice)
				displayPrice = "";
			else if (price > 0)
				displayPrice = price.toCurrencyString();
			else if (price == 0)
			{
				switch (Rotator.configuration().appAppointmentPriceZeroManagement)
				{
					case OnlineBookingPriceZeroEnum.Decimal: displayPrice = price.toCurrencyString(); break;
					case OnlineBookingPriceZeroEnum.Free: displayPrice = "Gratuito"; break;
					case OnlineBookingPriceZeroEnum.Hide: displayPrice = ""; break;
				}
			}
		}
		else if (type == PriceTypeEnum.ActivityType)
		{
			if (!Rotator.configuration().appShowActivityTypePrice)
				displayPrice = "";
			else if (price > 0)
				displayPrice = price.toCurrencyString();
			else if (price == 0)
			{
				switch (Rotator.configuration().appActivityTypePriceZeroManagement)
				{
					case OnlineBookingPriceZeroEnum.Decimal: displayPrice = price.toCurrencyString(); break;
					case OnlineBookingPriceZeroEnum.Free: displayPrice = "Gratuita"; break;
					case OnlineBookingPriceZeroEnum.Hide: displayPrice = ""; break;
				}
			}
		}

		if (colored && displayPrice.length > 0)
			displayPrice = "<span style='font-weight: 600; color: " + Rotator.color() + ";'>" + displayPrice + "</span>";

		if (spaceBefore)
			displayPrice = " " + displayPrice;

		return displayPrice;
	}

	static fullScreen()
	{
		let container = Rotator.container() as any;
		try
		{
			let doc = window.document as any;
			if (!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement)
			{
				//#region RequestFullScreen
				if (container.requestFullscreen)
					container.requestFullscreen();
				else if (container.mozRequestFullScreen) // Firefox
					container.mozRequestFullScreen();
				else if (container.webkitRequestFullscreen) // Chrome, Safari, Opera
					container.webkitRequestFullscreen();
				else if (container.msRequestFullscreen) // Edge
					container.msRequestFullscreen();
				else if (container.webkitEnterFullscreen) // Safari mobile (if not supported webkitRequestFullScreen) - No custom buttons
					container.onloadedmetadata = () => container.webkitEnterFullscreen();
				//#endregion
			}
		}
		catch (e) { }
	}

	static getDayWeekName(date: Date, short = false)
	{
		if (short)
		{
			let dayWeekNamesShort = ["DOM", "LUN", "MAR", "MER", "GIO", "VEN", "SAB"];
			return dayWeekNamesShort[date.getDay()];
		}
		else
		{
			let dayWeekNamesShort = ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"];
			return dayWeekNamesShort[date.getDay()];
		}
	}

	static getMonthName(date: Date, short = false)
	{
		if (short)
		{
			let dayWeekNamesShort = ["GEN", "FEB", "MAR", "APR", "MAG", "GIU", "LUG", "AGO", "SET", "OTT", "NOV", "DIC"];
			return dayWeekNamesShort[date.getMonth()];
		}
		else
		{
			let dayWeekNamesShort = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
			return dayWeekNamesShort[date.getMonth()];
		}
	}

	static checkInternetConnection()
	{
		if (!window.navigator.onLine)
			return false;

		return true;
	}

	static getTrackingCookies()
	{
		let request = new GetTrackingCookiesRequest();
		request.call(
			{
				success: (response: GetTrackingCookiesResponse) =>
				{
					Rotator.trackingCookiesInfo(response.cookiesInfo);
					for (let cookieInfo of response.cookiesInfo)
						puma("head").append(cookieInfo.code);
				},
				mode: vrshared.WebApiModeEnum.Async,
				loader: false
			});
	}

	static getUTM()
	{
		//#region Properties
		let campaignSourceProperty = Rotator.configuration().appUTMCampaignSource;
		let campaignMediumProperty = Rotator.configuration().appUTMCampaignMedium;
		let campaignNameProperty = Rotator.configuration().appUTMCampaignName;
		//#endregion

		if ((campaignSourceProperty != null && campaignSourceProperty != "")
			|| (campaignMediumProperty != null && campaignMediumProperty != "")
			|| (campaignNameProperty != null && campaignNameProperty != ""))
		{
			let request = new GetMarketingUTMRequest();
			request.plantId = [Rotator.configuration().plant.id];
			request.call(
				{
					success: (response: GetMarketingUTMResponse) =>
					{
						let error = false;
						let campaignSource = vrshared.UtilityManager().getUrlParameter(campaignSourceProperty);
						if (campaignSource != null && campaignSource.length > 0)
						{
							let existingCampaignSource = response.campaignSource.filter(k => k.code == campaignSource)[0];
							if (existingCampaignSource == null)
								error = true;
							else
								Rotator.campaignSourceId(existingCampaignSource.id);
						}

						if (error)
						{
							Support.removeWebComponent();
							vr.createLabel(
								{
									text: "Campaign Source not found",
									colorSettings: { textColor: "red" }
								}, Rotator.shadowRoot() as any);

							throw Error("Campaign Source not found");
						}

						let campaignMedium = vrshared.UtilityManager().getUrlParameter(campaignMediumProperty);
						if (campaignMedium != null && campaignMedium.length > 0)
						{
							let existingCampaignMedium = response.campaignMedium.filter(k => k.code == campaignMedium)[0];
							if (existingCampaignMedium == null)
								error = true;
							else
								Rotator.campaignMediumId(existingCampaignMedium.id);
						}

						if (error)
						{
							Support.removeWebComponent();
							vr.createLabel(
								{
									text: "Campaign Medium not found",
									colorSettings: { textColor: "red" }
								}, Rotator.shadowRoot() as any);

							throw Error("Campaign Medium not found");
						}

						let campaignName = vrshared.UtilityManager().getUrlParameter(campaignNameProperty);
						if (campaignName != null)
							Rotator.campaignName(campaignName);
					},
					mode: vrshared.WebApiModeEnum.Async,
					loader: false
				});
		}
	}

	static removeWebComponent()
	{
		puma(".vrPatientDivContainer").remove();
		Rotator.mainButton().hide();
	}

	static manageTrackingCookiesEvent(eventTypeEnum: EventTypeEnum, activityTypeId?: number, parameters?: TrackingCookiesEventParameters)
	{
		if (Rotator.trackingCookiesInfo() == null || Rotator.trackingCookiesInfo().length == 0)
			return;

		let request = new GetTrackingCookiesEventRequest();
		request.eventTypeId = eventTypeEnum;
		request.activityTypeId = activityTypeId;
		request.call(
			{
				success: (response: GetTrackingCookiesEventResponse) =>
				{
					for (let eventInfo of response.eventInfo)
					{
						switch (eventInfo.serviceTypeId)
						{
							case ServiceTypeEnum.Facebook:
								{
									let json: any = {};
									json.content_ids = [eventInfo.code];
									json.eventref = "";

									if (eventInfo.remoteEventName == "Purchase")
									{
										json.currency = "EUR";
										json.num_items = 1;
										json.value = (parameters != null) ? parameters.amount : 0;
									}
									fbq("track", eventInfo.remoteEventName, json);
								}
								break;
							case ServiceTypeEnum.Google:
								{
									gtag("event", eventInfo.remoteEventName, {
										"send_to": eventInfo.code
									});
								}
								break;
						}
					}
				},
				mode: vrshared.WebApiModeEnum.Async,
				loader: false
			});
	}

	static sendErrorEmail(message: string, appointmentId?: number)
	{
		let request = new SendErrorEmailRequest();
		request.appointmentId = appointmentId;
		request.optionalBody = message;
		request.call(
			{
				success: (response: SendErrorEmailResponse) =>
				{

				},
				mode: vrshared.WebApiModeEnum.Async,
				loader: false
			});
	}

	static openLinkPopup(link: string)
	{
		let popup = new Popup(Rotator.container(),
			{
				closeable: true,
				height: "100%",
				modal: true,
				width: "Calc(100% - 40px)",
				padding: false,
				css: "top: 40px !important; height: Calc(100% - 48px) !important;"
			});
		popup.open();

		let iframe = puma("<iframe style='width: 100%; height: 100%;'></iframe>").appendToPuma(popup.element())[0] as HTMLIFrameElement;
		let iframeBody: HTMLElement | null = null;

		let iframeContentDocument = iframe!.contentDocument;
		puma(iframeContentDocument).find("body").css("margin", "0px");
		if (iframeContentDocument != null)
			iframeBody = iframeContentDocument.body;

		vrshared.LoaderManager().show();
		puma(iframe).attr("src", link);
		puma(iframe).on("load", () => 
		{
			vrshared.LoaderManager().hide();
			let iframeContentDocument = iframe!.contentDocument;
			puma(iframeContentDocument).find("body").css("margin", "0px");
		});
	}

	static confirm(text: string, successCallback?: Function)
	{
		let popup = new Popup(Rotator.container(),
			{
				closeable: false,
				height: "auto",
				modal: true,
				width: "Calc(100% - 40px)"
			});

		vr.createLabel({
			text: text,
			width: "100%",
			align: vr.TextAlignEnum.Center
		}, popup.element());

		let divButtons = vr.div(popup.element(), { css: "display: flex; justify-content: space-between; margin-top: 15px;" });
		vr.createButton({
			text: "Conferma",
			width: "100%",
			colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
			css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
			cssContainer: "margin-right: 5px;",
			class: "vrButtonChangePassword vrButtonPatient",
			onClick: (e) => 
			{
				popup.close();
				if (successCallback != null)
					successCallback();
			}
		}, divButtons)

		vr.createButton({
			text: "Chiudi",
			width: "100%",
			colorSettings: { textColor: Rotator.color() },
			css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
			cssContainer: "margin-left: 5px;",
			class: "vrButtonChangePassword vrButtonPatient",
			onClick: (e) => popup.close()
		}, divButtons);

		popup.open();
	}

	static dialog(text: string, footerItems: vr.WindowFooterItem[])
	{
		let popup = new Popup(Rotator.container(),
			{
				closeable: false,
				height: "auto",
				modal: true,
				width: "Calc(100% - 40px)"
			});

		vr.createLabel({
			text: text,
			width: "100%",
			align: vr.TextAlignEnum.Center
		}, popup.element());

		let divButtons = vr.div(popup.element(), { css: "display: flex; justify-content: space-between; margin-top: 15px;" });
		for (let footerItem of footerItems)
		{
			vr.createButton({
				text: footerItem.text,
				width: "100%",
				colorSettings: (footerItem.colorSettings != null) ? footerItem.colorSettings : { background: Rotator.color(), textColor: Rotator.textColor() },
				css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
				cssContainer: "margin-right: 5px;",
				class: "vrButtonChangePassword vrButtonPatient",
				onClick: (e) => 
				{
					if (footerItem.type == vr.WindowFooterItemTypeEnum.Close)
						popup.close()
					else
					{
						if (footerItem.onClick != null)
							footerItem.onClick(e as any);
					}
				}
			}, divButtons)
		}

		popup.open();
	}
}

//#region CheckLogin
class CheckLoginRequest extends WebApiRequestBase 
{
	public method: string = "/api/LoginWebApi/CheckLogin";
}

class CheckLoginResponse extends WebApiResponseBase 
{
	public logged: boolean;
}
//#endregion

//#region Logout
class LogoutRequest extends WebApiRequestBase 
{
	public method: string = "/api/LoginWebApi/Logout";
}

class LogoutResponse extends WebApiResponseBase 
{
}
//#endregion

//#region DownloadFile
class DownloadFileRequest extends WebApiRequestBase 
{
	public method: string = "/api/PrivateAreaWebApi/DownloadFile";
	public fileId: number;
}

class DownloadFileResponse extends WebApiResponseBase 
{
	public base64: string;
	public requiredHealthRecordPrivacy: boolean;
}

class FileDataEvent
{
	public base64: string;
	public requiredHealthRecordPrivacy: boolean;
}
//#endregion

//#region DownloadDocument
class DownloadDocumentRequest extends WebApiRequestBase 
{
	public method: string = "/api/PrivateAreaWebApi/DownloadDocument";
	public documentId: number;
}

class DownloadDocumentResponse extends WebApiResponseBase 
{
	public base64: string;
}
//#endregion

export enum FileTypeEnum
{
	Pdf = "application/pdf",
	Txt = "text/plain",
	Image = "image/png",
	Zip = "application/octet-stream",
	General = "application/octet-stream"
}

//#region GetTrackingCookies
class GetTrackingCookiesRequest extends WebApiRequestBase 
{
	public method: string = "/api/UtilityWebApi/GetTrackingCookies";
}

class GetTrackingCookiesResponse extends WebApiResponseBase 
{
	public cookiesInfo: TrackingCookieInfo[];
}

export class TrackingCookieInfo
{
	public id: number;
	public serviceTypeId: ServiceTypeEnum;
	public serviceTypeName: string;
	public active: boolean;
	public code: string;
}

export enum ServiceTypeEnum
{
	Google = 1,
	Facebook = 2
}
//#endregion

//#region GetTrackingCookiesEvent
class GetTrackingCookiesEventRequest extends WebApiRequestBase 
{
	public method: string = "/api/UtilityWebApi/GetTrackingCookiesEvent";

	public eventTypeId: EventTypeEnum;
	public activityTypeId?: number | null;
}

class GetTrackingCookiesEventResponse extends WebApiResponseBase 
{
	public eventInfo: TrackingCookieMappingInfo[];
}

class TrackingCookieMappingInfo
{
	public id: number;
	public remoteEventName: string;
	public localEventTypeId: number;
	public localEventTypeName: string;
	public serviceId: number;
	public serviceName: string;
	public serviceTypeId: ServiceTypeEnum;
	public code: string;
	public activityTypeId: number;
	public activityTypeName: string;
	public active: boolean;
	public plantId: number;
}

export enum EventTypeEnum
{
	Search = 1, // Search availabilities
	Selected = 2, // Selected availabilities
	Booked = 3, // Booked appointment
	Registered = 4 // Registered patient
}
//#endregion

//#region SendErrorEmail
class SendErrorEmailRequest extends WebApiRequestBase 
{
	public method: string = "/api/UtilityWebApi/SendErrorEmail";

	public optionalBody: string;
	public appointmentId?: number;
}

class SendErrorEmailResponse extends WebApiResponseBase 
{

}
//#endregion

//#region GetTrackingCookies
class GetMarketingUTMRequest extends WebApiRequestBase
{
	public method: string = "/api/UtilityWebApi/GetMarketingUTMData";
	public plantId: number[];
}

class GetMarketingUTMResponse extends WebApiResponseBase
{
	public campaignSource: UTMItem[];
	public campaignMedium: UTMItem[];
	public channelTypes: CustomerContactBaseTypeEnum[]
}

class UTMItem
{
	public id: number;
	public code: string;
	public name: string;
	public plantId: number;
}

enum CustomerContactBaseTypeEnum
{
	Generic = 1,
	PhoneCall = 2,
	Letter = 3,
	EMail = 4,
	SMS = 5,
	WebSite = 6,
	Fax = 7,
	Meeting = 8,
	Push = 9
}
//#endregion

export class Popup
{
	private _element: HTMLElement;
	private _background: HTMLElement;
	private _btnClose: vr.Button;
	private _settings: PopupSettings;

	constructor(container: HTMLElement, settings?: PopupSettings)
	{
		//#region Settings
		if (settings == null) settings = new PopupSettings();
		if (settings.modal == null) settings.modal = true;
		if (settings.fullScreen == null) settings.fullScreen = false;
		if (settings.height == null) settings.height = "auto";
		if (settings.width == null) settings.width = "100%";
		if (settings.closeable == null) settings.closeable = true;
		if (settings.footer == null) settings.footer = false;
		if (settings.padding == null) settings.padding = true;
		this._settings = settings;
		//#endregion

		this._element = vr.div(container, { class: "vrPatientPopup" });

		if (settings.fullScreen)
		{
			if (!settings.padding)
				this._element.style.cssText += "padding: 0px; top: 0px !important; height: Calc(100% - 5px) !important;";
			else
				this._element.style.cssText += "padding: 10px; top: -10px !important; height: Calc(100% - 20px) !important;";
		}

		if (!settings.padding)
			this._element.style.cssText += "padding: 0px;";
		else
			this._element.style.cssText += "padding: 10px;";

		if (settings.css != null)
			this._element.style.cssText += settings.css;

		if (settings.content != null)
			puma(this._element).html(settings.content);

		this._background = vr.div(container, { class: "vrPatientPopupBackground" });
		puma(this._background).click(() => 
		{
			if (settings!.closeable)
				this.close();
		});

		this._btnClose = vr.createButton({
			icon: vr.IconClassicLight.Close,
			css: "border-radius: 30px; height: 40px;",
			cssContainer: "position: absolute; top: 0px; right: 0px; margin-top: -40px; z-index: 1;",
			onClick: (e) => this.close()
		}, this._element);

		if (!settings.closeable)
			this._btnClose.hide();
	}

	open(center = true)
	{
		let settings = this.settings();
		let top = Rotator.container().scrollTop;
		let left = Rotator.container().scrollLeft;
		let height = settings.height;
		let width = settings.width;

		puma(this._element).show();

		if (typeof (height) == "number")
			height = height + "px";

		if (typeof (width) == "number")
			width = width + "px";

		if (settings.fullScreen)
		{
			top = 0;
			left = 0;
			height = "100%";
			width = "100%";
		}

		if (height != null)
			this._element.style.cssText += "height: " + height + ";";

		if (width != null)
			this._element.style.cssText += "width: " + width + ";";

		if (center)
		{
			top = top + ((Rotator.container().clientHeight - this._element.clientHeight) / 2);
			left = left + ((Rotator.container().clientWidth - this._element.clientWidth) / 2);
		}

		let maxZindexPopup = Array.from<HTMLElement>(puma(this._element)).map(k => Number(puma(k).css("z-index"))).max();
		if (isNaN(maxZindexPopup))
			maxZindexPopup = 0;

		maxZindexPopup = 9997;
		puma(this._element).css("z-index", (maxZindexPopup + 2));
		if (settings.modal)
		{
			puma(this._background).show();
			puma(this._background).css("z-index", (maxZindexPopup + 1));
		}

		this._element.style.cssText += "top: " + top + "px; left: " + left + "px;";
		this._background.style.cssText += "top: " + Rotator.container().scrollTop + "px;";
	}

	close()
	{
		puma(this._element).hide();
		puma(this._background).hide();
	}

	element()
	{
		return this._element;
	}

	background()
	{
		return this._background;
	}

	settings()
	{
		return this._settings;
	}
}

class PopupSettings
{
	fullScreen?: boolean;
	modal?: boolean;
	height?: number | string;
	closeable?: boolean;
	width?: string | number;
	footer?: boolean;
	padding?: boolean;
	css?: string;
	content?: string;
}

export class TrackingCookiesEventParameters
{
	amount?: number;
}

export enum PriceTypeEnum
{
	Appointment,
	ActivityType
}