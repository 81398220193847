import { WebApiRequestBase, WebApiResponseBase } from "../../src/shared/webApi";
import { Rotator, puma, PageEnum, AppLoginModeEnum } from "../../src/rotator";
import { ChangePasswordParameters } from "./changePassword";
import { MandatoryFieldInfo, OnlineBookingMandatoryFieldTypeEnum, PreregisterSettings, Register, RegisterParameters, RegisterRequest, RegisterResponse, RegisterResult } from "./register";
import { Support } from "../../src/support";
import { Menu } from "../../src/shared/menu";
import { ForgotPassword } from "./forgotPassword";
import { VrManager } from "../../src/shared/vrManager";
import { Confirmation1 } from "src/booking/confirmation1";

export class Login
{
    private static _container: HTMLElement;
    private static _txtUsername: vr.TextBox;
    private static _txtPassword: vr.TextBox;
    private static _btnLogin: vr.Button;
    private static _parameters?: LoginParameters;
    private static _txtFiscalCodeAlreadyPatient: vr.TextBox;
    private static _pwdErrorsCount: number;

    private static _fields: MandatoryFieldInfo[];
    private static _txtName: vr.TextBox;
    private static _txtSurname: vr.TextBox;
    private static _txtPhoneNumber: vr.TextBox;
    private static _txtEmail: vr.TextBox;
    private static _chkPrivacy: vr.CheckBox;
    private static _chkPrivacyCommercial: vr.CheckBox;

    static initialize(parameters: LoginParameters)
    {
        this._parameters = parameters;
        this._container = puma("<div class='forward padContainer'></div>").appendTo(Rotator.container())[0];
        Rotator.title("LOGIN");

        Support.checkLogin(() => Rotator.goToMainPage(), () => this.createControls());
    }

    static createControls()
    {
        puma(this._container).empty();
        this._pwdErrorsCount = 0;

        if (!Rotator.inBookingFlow() && !Rotator.sailor())
        {
            Rotator.backButton().hide();
            Rotator.burgerButton().show();
        }
        else
        {
            Rotator.backButton().show();
            Rotator.burgerButton().hide();
        }

        if (this._parameters != null && this._parameters.fromRegister === true)
        {
            vr.createLabel(
                {
                    text: "Hai ricevuto un SMS con le credenziali per accedere al servizio",
                    mode: vr.LabelModeEnum.Default,
                    cssContainer: "margin-bottom: 10px;"
                }, this._container);

            if (this._parameters != null && this._parameters.username != null)
            {
                vr.createButton(
                    {
                        text: "Invia di nuovo",
                        colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
                        css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
                        width: "100%",
                        class: "vrButtonResendSMS vrButtonPatient",
                        onClick: (e) =>
                        {
                            ForgotPassword.recoverPassword(this._parameters!.username);
                            e.sender.disable();
                            window.setTimeout(() => e.sender.enable(), 5000);
                        }
                    }, this._container);
            }

            vr.createSeparator({
                orientation: vr.OrientationEnum.Horizontal
            }, this._container)
        }

        this._txtUsername = vr.createTextBox(
            {
                label: "Nome utente",
                placeholder: "Nome utente",
                onEnterKey: () => this._btnLogin.click(),
                mode: vr.TextModeEnum.Text,
                width: "100%",
                cssContainer: "margin-bottom: 10px;",
                labelSettings: { colorSettings: { textColor: Rotator.color() } }
            }, this._container);
        this._txtUsername.focus();

        if (this._parameters != null && this._parameters.username != null)
            this._txtUsername.value(this._parameters.username);

        this._txtPassword = vr.createTextBox(
            {
                label: "Password",
                placeholder: "Password",
                onEnterKey: () => this._btnLogin.click(),
                mode: vr.TextModeEnum.Password,
                width: "100%",
                cssContainer: "margin-bottom: 10px;",
                labelSettings: { colorSettings: { textColor: Rotator.color() } }
            }, this._container);

        this._btnLogin = vr.createButton({
            text: "Accedi",
            colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
            css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
            width: "100%",
            class: "vrButtonLogin vrButtonPatient",
            onClick: (e) =>
            {
                //#region Check
                let error = false;
                if (this._txtUsername.isEmpty())
                {
                    this._txtUsername.error("Nome utente obbligatorio", vr.ErrorModeEnum.Solid);
                    error = true;
                }

                if (this._txtPassword.isEmpty())
                {
                    this._txtPassword.error("Password obbligatoria", vr.ErrorModeEnum.Solid);
                    error = true;
                }

                if (error)
                    return;
                //#endregion

                this.login(this._txtUsername.value(), this._txtPassword.value());
            }
        }, this._container);

        vr.createLabel(
            {
                text: "Password dimenticata? Recuperala",
                colorSettings:
                {
                    textColor: Rotator.color()
                },
                width: "100%",
                cssContainer: "margin-top: 10px;",
                css: "text-decoration: underline;",
                align: vr.TextAlignEnum.Right,
                onClick: (e) => 
                {
                    Rotator.page(PageEnum.ForgotPassword);
                }
            }, this._container);

        //#region Register with fiscal code
        vr.createSeparator(
            {
                size: 1,
                orientation: vr.OrientationEnum.Horizontal
            }, this._container);

        this._txtFiscalCodeAlreadyPatient = vr.createTextBox(
            {
                label: "Non hai mai fatto l'accesso, ma sei già paziente? Usa il tuo codice fiscale",
                labelSettings: { mode: vr.LabelModeEnum.Default },
                placeholder: "Codice fiscale",
                mode: vr.TextModeEnum.Text,
                width: "100%",
                cssContainer: "margin-bottom: 10px;",
                inputMode: vr.TextTransformModeEnum.Uppercase,
                onEnterKey: () => btnImpersonificate.click()
            }, this._container);

        let btnImpersonificate = vr.createButton(
            {
                text: "Registrati con codice fiscale",
                colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
                css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
                width: "100%",
                class: "vrButtonRegisterWithFiscalCode vrButtonPatient",
                onClick: (e) =>
                {
                    //#region Check
                    let error = false;
                    if (this._txtFiscalCodeAlreadyPatient.isEmpty())
                    {
                        this._txtFiscalCodeAlreadyPatient.error("Codice fiscale obbligatorio", vr.ErrorModeEnum.Solid);
                        error = true;
                    }

                    if (error)
                        return;
                    //#endregion

                    this.impersonificate();
                }
            }, this._container);
        //#endregion

        //#region HideRegistrationIntoLogin
        if (Rotator.configuration().appHideRegistrationIntoLogin === false)
        {
            vr.createSeparator(
                {
                    size: 1,
                    orientation: vr.OrientationEnum.Horizontal
                }, this._container);

            vr.createLabel(
                {
                    text: "Oppure registrati",
                    mode: vr.LabelModeEnum.Default,
                    width: "100%",
                    cssContainer: "margin-bottom: 10px;"
                }, this._container);

            //#region Name if no login
            this._txtName = vr.createTextBox({
                label: "Nome",
                width: "100%",
                labelSettings: { colorSettings: { textColor: Rotator.color() } },
                visible: false
            }, this._container);

            this._txtSurname = vr.createTextBox({
                label: "Cognome",
                width: "100%",
                labelSettings: { colorSettings: { textColor: Rotator.color() } },
                visible: false
            }, this._container);

            if (Rotator.configuration().appLoginRequired == AppLoginModeEnum.Never)
            {
                this._txtName.show();
                this._txtSurname.show();
            }
            //#endregion

            this._txtPhoneNumber = vr.createTextBox({
                label: "Cellulare",
                width: "100%",
                mode: vr.TextModeEnum.Numeric,
                align: vr.TextAlignEnum.Left,
                labelSettings: { colorSettings: { textColor: Rotator.color() } }
            }, this._container);

            this._txtEmail = vr.createTextBox({
                label: "Email",
                width: "100%",
                mode: vr.TextModeEnum.Mail,
                labelSettings: { colorSettings: { textColor: Rotator.color() } },
                visible: false
            }, this._container);

            Register.getMandatoryFields((e) =>
            {
                this._fields = e.fields;
                if (e.fields.filter(k => k.fieldType == OnlineBookingMandatoryFieldTypeEnum.EMail)[0].isVisible)
                    this._txtEmail.show();
            });

            //#region Privacy
            let divPrivacy = vr.div(this._container, { css: "text-align: left; display: flex; margin-top: 10px;" });
            this._chkPrivacy = vr.createCheckBox(
                {
                }, divPrivacy);

            let privacyLabelText = Rotator.configuration().appPrivacyLabelText;
            if (privacyLabelText == null || privacyLabelText == "")
                privacyLabelText = "Ho letto e accetto la [privacy] (*)";

            if (privacyLabelText.includes("["))
            {
                let textToHighlight = privacyLabelText.substring(
                    privacyLabelText.lastIndexOf("[") + 1,
                    privacyLabelText.lastIndexOf("]")
                );
                privacyLabelText = privacyLabelText.replace(/\[.*\]/, "<span style='color: " + Rotator.color() + "'>" + textToHighlight + "</span>")
            }
            else
            {
                privacyLabelText = privacyLabelText.replace("privacy", "<span style='color: " + Rotator.color() + "'>privacy</span>");
                privacyLabelText = privacyLabelText.replace("Privacy", "<span style='color: " + Rotator.color() + "'>Privacy</span>");
            }

            vr.createLabel({
                text: privacyLabelText,
                fontSize: "1.1em",
                cssContainer: "top: -1px;",
                width: "Calc(100% - 35px)",
                onClick: (e) => 
                {
                    wndPrivacy.open(null, false, { top: Rotator.container().scrollTop });
                }
            }, divPrivacy);

            let wndPrivacy = vr.createWindow(
                {
                    title: "Privacy",
                    maximized: false,
                    content: Rotator.configuration().privacyText,
                    css: "overflow-y: auto;",
                    cssContainer: "width: 100% !important; height: " + (Rotator.container().clientHeight - 20) + "px !important; position: absolute !important; left: 0px !important;",
                    draggable: false,
                    modal: false,
                    hideCloseIcon: true,
                    footer:
                        [
                            { type: vr.WindowFooterItemTypeEnum.Close, text: "Chiudi" },
                            {
                                type: vr.WindowFooterItemTypeEnum.Ok, text: "Accetta", onClick: (e) => 
                                {
                                    wndPrivacy.close();
                                    this._chkPrivacy.checked(true);
                                }
                            }
                        ]
                }, Rotator.shadowRoot() as any);
            puma(wndPrivacy.container()).appendToPuma(this._container);
            //#endregion

            if (Rotator.configuration().privacyCommercial && !Rotator.configuration().appHidePrivacyCommercial)
            {
                let privacyCommercialLabelText = Rotator.configuration().appPrivacyCommercialLabelText;
                if (privacyCommercialLabelText == null || privacyCommercialLabelText == "")
                    privacyCommercialLabelText = "Presto il consenso al trattamento dei dati personali relativi al mio stato di salute";

                this._chkPrivacyCommercial = vr.createCheckBox({
                    text: privacyCommercialLabelText,
                    width: "100%",
                    cssContainer: "font-size: 0.9em;"
                }, this._container);
            }

            vr.createButton(
                {
                    text: "Registrati",
                    colorSettings: { background: Rotator.color(), textColor: Rotator.textColor() },
                    css: "box-shadow: 0 1px 3px " + Rotator.color() + ";",
                    cssContainer: "margin-top: 10px;",
                    width: "100%",
                    class: "vrButtonRegister vrButtonPatient",
                    onClick: (e) =>
                    {
                        if (Rotator.sailor())
                        {
                            Rotator.page(PageEnum.SailorRegister);
                        }
                        else if (Rotator.configuration().appPreregisterSubject)
                        {
                            //#region Check
                            let error = this.checkRequiredFields();
                            if (error)
                                return;
                            //#endregion

                            let preregisterSettings = new PreregisterSettings();
                            preregisterSettings.name = this._txtName.value();
                            preregisterSettings.surname = this._txtSurname.value();
                            preregisterSettings.phoneNumber = String(this._txtPhoneNumber.value());
                            preregisterSettings.emailAddress = this._txtEmail.value();
                            preregisterSettings.privacyForMedicalBooking = this._chkPrivacy.checked();
                            preregisterSettings.privacyForCommercialUse = (this._chkPrivacyCommercial != null) ? this._chkPrivacyCommercial.checked() : false;
                            Register.preregister(preregisterSettings, (e) => 
                            {
                                let parameters = new RegisterParameters();
                                parameters.preregisterId = e!.preregisterId;
                                parameters.customerName = this._txtName.value();
                                parameters.customerSurname = this._txtSurname.value();
                                parameters.customerMail = this._txtEmail.value();
                                parameters.customerPhone = String(this._txtPhoneNumber.value());
                                Rotator.page(PageEnum.Register, parameters);
                            });
                        }
                        else
                        {
                            let error = this.checkRequiredFields();
                            if (error)
                                return;

                            let parameters = new RegisterParameters();
                            parameters.customerName = this._txtName.value();
                            parameters.customerSurname = this._txtSurname.value();
                            parameters.customerMail = this._txtEmail.value();
                            parameters.customerPhone = String(this._txtPhoneNumber.value());
                            Rotator.page(PageEnum.Register, parameters);
                        }
                    }
                }, this._container);
        }
        //#endregion
    }

    private static checkRequiredFields()
    {
        let error = false;
        if (this._txtPhoneNumber.isEmpty())
        {
            this._txtPhoneNumber.error(undefined, undefined, undefined, vr.ErrorHideModeEnum.Never);
            error = true;
        }
        else
            this._txtPhoneNumber.hideError();

        let fieldEmail = this._fields.filter(k => k.fieldType == OnlineBookingMandatoryFieldTypeEnum.EMail)[0];
        if (fieldEmail != null && this._txtEmail.isEmpty() && fieldEmail.isMandatory)
        {
            this._txtEmail.error(undefined, undefined, undefined, vr.ErrorHideModeEnum.Never);
            error = true;
        }
        else
            this._txtEmail.hideError();

        if (!this._chkPrivacy.checked())
        {
            this._chkPrivacy.error();
            error = true;
        }
        return error;
    }

    private static impersonificate()
    {
        let request = new RegisterRequest();
        request.fiscalCodeAlreadyPatient = this._txtFiscalCodeAlreadyPatient.value();
        request.call(
            {
                success: (response: RegisterResponse) => 
                {
                    if (response.registerResult == RegisterResult.PatientCreated || response.registerResult == RegisterResult.PatientFound)
                    {
                        let loginParameters = new LoginParameters();
                        loginParameters.fromRegister = true;
                        loginParameters.username = "";
                        Rotator.page(PageEnum.Login, loginParameters);
                    }
                    else
                        Register.manageRegisterResult(response.registerResult, response.ExceptionMessage, true);
                },
                loader: Rotator.container()
            });
    }

    static login(username: string, password: string, callback?: Function, notLoggedCallback?: Function, encrypted = false)
    {
        let request = new DoLoginRequest();
        request.username = username.trim();
        request.password = password.trim();
        request.credentialsEncrypted = encrypted;
        request.deviceId = Rotator.token();
        request.operatingSystemName = (vrshared.DeviceManager().isIphone()) ? "iphone" : "android";
        request.throwException = true;
        request.call(
            {
                success: (response: DoLoginResponse) =>
                {
                    let successCallback = null;
                    if (callback == null)
                    {
                        if (Rotator.inBookingFlow())
                            successCallback = () => Rotator.page(PageEnum.Confirmation2);
                        else if (this._parameters != null && this._parameters.successCallback != null)
                            successCallback = this._parameters!.successCallback;
                        else
                            successCallback = () => Rotator.page(PageEnum.AppointmentList);
                    }
                    else
                        successCallback = callback;

                    switch (response.loginStatus)
                    {
                        case LoginStatusEnum.Ok:
                            {
                                switch (response.loginResult)
                                {
                                    case LoginResult.Success:
                                        {
                                            vrshared.CookieManager().setCookie("credentials", { username: response.encryptedUsername, password: response.encryptedPassword });
                                            successCallback!();
                                            Menu.manageState(true);
                                        }
                                        break;
                                    case LoginResult.WrongUsernameAndPassword:
                                        {
                                            VrManager.notify("Nome utente e password non corretti", "", { type: vr.NotifierTypeEnum.Error });
                                        }
                                        break;
                                    case LoginResult.UsernameExistButWrongPassword:
                                        {
                                            VrManager.notify("Password non corretta", "", { type: vr.NotifierTypeEnum.Error });

                                            this._pwdErrorsCount++;
                                            if (this._pwdErrorsCount == 3)
                                                Rotator.page(PageEnum.ForgotPassword);
                                        }
                                        break;
                                }
                            }
                            break;
                        case LoginStatusEnum.Error:
                            {
                                VrManager.notify("Si è verificato un errore", "", { type: vr.NotifierTypeEnum.Error });
                                Menu.manageState(false);
                            }
                            break;
                        case LoginStatusEnum.PasswordExpired:
                            {
                                let parameters = new ChangePasswordParameters();
                                parameters.passwordExpiredGuid = response.passwordExpiredGuid;
                                Rotator.page(PageEnum.ChangePassword, parameters);
                                Menu.manageState(false);
                            }
                            break;
                    }
                },
                error: (e) =>
                {
                    if (notLoggedCallback != null)
                        notLoggedCallback();

                    if (e.message != null)
                    {
                        let message = e.message;
                        if (e.message.trim() == "Unauthorized")
                            message = "Nome utente o password non corretti";

                        VrManager.notify(message, "", { type: vr.NotifierTypeEnum.Error });
                    }

                    Menu.manageState(false);
                },
                showError: false,
                mode: vrshared.WebApiModeEnum.Async,
                loader: Rotator.container()
            });
    }
}

export class LoginParameters
{
    fromRegister: boolean;
    username: string;
    successCallback?: Function | null;
}

//#region DoLogin
class DoLoginRequest extends WebApiRequestBase 
{
    public method: string = "/api/LoginWebApi/DoLogin";

    public username: string;
    public password: string;
    public credentialsEncrypted: boolean;
    public deviceId: string;
    public operatingSystemName: string;
    public throwException: boolean;
}

class DoLoginResponse extends WebApiResponseBase 
{
    public loginStatus: LoginStatusEnum;
    public passwordExpiredGuid: string;
    public encryptedUsername: string;
    public encryptedPassword: string;
    public loginResult: LoginResult;
    public patientName: string;
    public patientAge: number;
}

enum LoginResult
{
    Success = 1,
    UsernameExistButWrongPassword = 2,
    WrongUsernameAndPassword = 3,
}

enum LoginStatusEnum
{
    Error = 1,
    Ok = 2,
    PasswordExpired = 3
}
//#endregion