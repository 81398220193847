import { WebApiRequestBase, WebApiResponseBase } from "../../src/shared/webApi";

export class AuthenticationManager
{
	private static _passwordRules: PasswordRules;

	static isPasswordStrong(password: string): boolean
	{
		let request = new IsStrongRequest();
		request.password = password;
		return request.call<IsStrongResponse>({ mode: vrshared.WebApiModeEnum.Sync }).result;
	}

	static getPasswordRules(): PasswordRules
	{
		if (this._passwordRules == null)
		{
			let request = new GetRulesRequest();
			this._passwordRules = request.call<GetRulesResponse>({ mode: vrshared.WebApiModeEnum.Sync }).rules;
		}
		return this._passwordRules;
	}

	static generatePassword(): string
	{
		let request = new GeneratePasswordRequest();
		return request.call<GeneratePasswordResponse>({ mode: vrshared.WebApiModeEnum.Sync }).password;
	}

	/** Check if the password is strong based on rules
	 * @param passwordRules The rules to classify password */
	static checkPasswordStrength(password: string, passwordRules: PasswordRules): CheckPasswordStrengthResponse
	{
		let checkPasswordStrengthResponse: CheckPasswordStrengthResponse = new CheckPasswordStrengthResponse();
		let isValid: boolean = true;
		let errorMessage: string = "Password con almeno: <ul style='padding-left: 15px; margin-top: 0px !important; margin-bottom: 0px !important; text-align: left;'>";

		// Minimum and Maximum Length of field - 6 to 12 characters
		if (password.length < passwordRules.minimumLength)
		{
			isValid = false;
			errorMessage += "<li>" + passwordRules.minimumLength + " caratteri</li>";
		}

		let numberCount: number = 0;
		let specialCount: number = 0;
		let lowerCaseCount: number = 0;
		let upperCaseCount: number = 0;

		for (let c of password.split(''))
		{
			if (c == "")
			{
				isValid = false;
				errorMessage = "C'é uno spazio nella password. Password non valida";

				checkPasswordStrengthResponse.IsValid = isValid;
				checkPasswordStrengthResponse.ErrorMessage = errorMessage;
				return checkPasswordStrengthResponse;
			}

			if (vrshared.UtilityManager().isNumber(c))
				numberCount++;
			else if (vrshared.UtilityManager().isLetter(c) && c == c.toUpperCase())
				upperCaseCount++;
			else if (vrshared.UtilityManager().isLetter(c) && c == c.toLowerCase())
				lowerCaseCount++;
			else
				specialCount++;
		}

		if (lowerCaseCount < passwordRules.minimumLowercaseChars)
		{
			isValid = false;
			errorMessage += "<li>" + passwordRules.minimumLowercaseChars + " lettera/e minuscola/e</li>";
		}

		if (upperCaseCount < passwordRules.minimumUppercaseChars)
		{
			isValid = false;
			errorMessage += "<li>" + passwordRules.minimumUppercaseChars + " lettera/e maiuscola/e</li>";
		}

		if (numberCount < passwordRules.minimumDigits)
		{
			isValid = false;
			errorMessage += "<li>" + passwordRules.minimumDigits + " numero/i</li>";
		}

		if (specialCount < passwordRules.minimumSymbols)
		{
			isValid = false;
			errorMessage += "<li>" + passwordRules.minimumSymbols + " simbolo/i</li>";
		}

		checkPasswordStrengthResponse.IsValid = isValid;
		checkPasswordStrengthResponse.ErrorMessage = (isValid) ? "" : (errorMessage + "</ul>");
		return checkPasswordStrengthResponse;
	}
}

//#region Supporting classes
export class PasswordRules
{
	public minimumLength: number;
	public minimumUppercaseChars: number;
	public minimumSymbols: number;
	public minimumDigits: number;
	public minimumLowercaseChars: number;
}

class GeneratePasswordRequest extends WebApiRequestBase
{
	public method: string = "/api/AuthenticationWebApi/GeneratePassword";
}

class GeneratePasswordResponse extends WebApiResponseBase
{
	public password: string;
}

class IsStrongRequest extends WebApiRequestBase
{
	public method: string = "/api/AuthenticationWebApi/IsPasswordStrong";
	public password: string;
}

class IsStrongResponse extends WebApiResponseBase
{
	public rules: PasswordRules;
	public result: boolean;
}

class GetRulesRequest extends WebApiRequestBase
{
	public method: string = "/api/AuthenticationWebApi/GetRules";
}

class GetRulesResponse extends WebApiResponseBase
{
	public rules: PasswordRules;
}

class CheckPasswordStrengthResponse
{
	public IsValid: boolean;
	public ErrorMessage: string;
}
//#endregion